import React, { FC } from "react";

import { Close, CloseFilled, InformationFilled } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

interface OverCapacityModalProps {
  open: boolean;
  // onSubmit: () => void;
  onClose: () => void;
}

const OverCapacityModal: FC<OverCapacityModalProps> = ({
  open,
  // onSubmit,
  onClose,
}) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogHeader className="relative">
          <Close
            size={20}
            onClick={onClose}
            className="cursor-pointer absolute -top-2 -right-2.5"
          />
          <div className="flex justify-center">
            <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full inline-block">
              <CloseFilled size={20} />
            </span>
          </div>
          <DialogTitle className="text-center">
            <p className="text-lg">Tải lên thất bại!</p>
          </DialogTitle>
          <DialogDescription>
            <p className="text-sm text-secondary-600 text-center">
              Dung lượng lưu trữ hiện tại của bạn không đủ. Vui lòng thử lại
              hoặc nâng cấp gói lưu trữ cá nhân.{" "}
              <span className="font-medium  text-secondary-900">
                Tìm hiểu thêm
              </span>
            </p>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="sm:justify-center">
          <Button
            variant="secondary"
            size="sm"
          >
            Quản lý lưu trữ
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default OverCapacityModal;
