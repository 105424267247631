import React, { useEffect, useRef, memo, useState } from "react";

import cx from "classnames";

import Divider from "@/components/Divider/Divider";
import TGNButton from "@/components/TGNButton/TGNButton";
import TGNDrawer from "@/components/TGNDrawer/TGNDrawer";
import TGNTooltip from "@/components/TGNTooltip/TGNTooltip";
import Typography from "@/components/Typography/Typography";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import styles from "./PeekRenderer.module.scss";
import { LogModeEnum, closePeekRenderer } from "../../mindmap";
import ActivityLog from "../ActivityLog/ActivityLog";
import ChatBox from "../ChatBox/ChatBox";
import NodeProperties from "../NodeProperties/NodeProperties";
import Overview from "../Overview/Overview";

const PeekRenderer = () => {
  const { currentSheet, mindmapIO } = useAppSelector((state) => state.mindmap);
  const dispatch = useAppDispatch();
  const [showFull, setShowFull] = useState(false);
  const [showExtend, setShowExtend] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [logMode, setLogMode] = useState<LogModeEnum>(LogModeEnum.ACTIVITY);
  const [isSpacingTop, setIsSpacingTop] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollListener = () => {
      if (wrapperRef.current && wrapperRef.current?.scrollTop > 215) {
        setIsSpacingTop(true);
      } else {
        setIsSpacingTop(false);
      }
    };
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener("scroll", scrollListener);
    }
    const wrapper = wrapperRef.current;

    return () => {
      if (wrapper) {
        wrapper.removeEventListener("scroll", scrollListener);
      }
    };
  }, []);
  useEffect(() => {
    if (mindmapIO && currentSheet.selectedNode) {
      mindmapIO.getChats(currentSheet.selectedNode.data.id);
    }
  }, [
    mindmapIO,
    currentSheet.selectedNode,
    currentSheet.selectedNode?.data.id,
  ]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        !showComment
      ) {
        dispatch(closePeekRenderer());
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showComment, dispatch]);

  const hidePeekRenderer = () => {
    setShowFull(false);
    setShowExtend(false);
    dispatch(closePeekRenderer());
  };
  const showFullScreen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setShowExtend(true);
    setShowFull(true);
  };

  return (
    <>
      {showFull && (
        <TGNDrawer
          show={showComment}
          onClose={() => setShowComment(false)}
        >
          {logMode === LogModeEnum.ACTIVITY ? (
            <ActivityLog />
          ) : (
            <ChatBox onClose={() => setShowComment(false)} />
          )}
        </TGNDrawer>
      )}
      <div
        ref={wrapperRef}
        className={cx(
          styles["peek-renderer"],
          currentSheet.isShowPeekRenderer ? styles["active"] : "",
          showFull ? styles["show-full"] : ""
        )}
      >
        <div>
          <div>
            {showFull ? (
              <>
                <div
                  className={cx(
                    "d-flex align-items-center justify-content-between",
                    styles.header,
                    isSpacingTop && styles["active-header"]
                  )}
                >
                  <div
                    className={cx(
                      "d-flex gap-2",
                      styles["header-left"],
                      !showExtend && styles["hide-border"]
                    )}
                  >
                    {showFull ? (
                      <div
                        role="presentation"
                        className="d-flex align-items-center gap-2 cursor-pointer"
                        onClick={hidePeekRenderer}
                      >
                        <i className="tgn-solid-direction-left-01 fs-20 text-white"></i>
                        <span className="text-tertiary-color-gray-5 fs-14 text-white">
                          Mindmap Workspace
                        </span>
                      </div>
                    ) : (
                      <>
                        <TGNTooltip
                          placement="bottom-start"
                          label={
                            <i
                              role="presentation"
                              className="tgn-outline-direction-right text-tertiary-color-gray-5 cursor-pointer fs-20"
                              onClick={() => dispatch(closePeekRenderer())}
                            ></i>
                          }
                        >
                          <Typography className={styles.tooltip}>
                            Thu nhỏ
                          </Typography>
                        </TGNTooltip>
                        <TGNTooltip
                          placement="bottom-start"
                          label={
                            <i
                              aria-hidden
                              className="tgn-outline-format-line-spacing text-tertiary-color-gray-5 cursor-pointer fs-20"
                              onClick={(e) => showFullScreen(e)}
                            ></i>
                          }
                        >
                          <Typography className={styles.tooltip}>
                            Hiển thị đầy đủ
                          </Typography>
                        </TGNTooltip>
                      </>
                    )}
                  </div>
                  <div className={styles["header-right"]}>
                    <div className="d-flex align-items-center gap-8">
                      <TGNButton
                        className="fw-semibold"
                        variant="ghost"
                        color="#000"
                      >
                        <span className="fs-14">Share</span>
                      </TGNButton>
                      <Divider
                        width={1}
                        height={20}
                      />
                      <TGNTooltip
                        placement="bottom-start"
                        label={
                          <i
                            className="tgn-outline-chat-notification text-color-white cursor-pointer fs-20"
                            role="presentation"
                            onClick={() => {
                              setLogMode(LogModeEnum.COMMENT);
                              setShowComment(true);
                            }}
                          ></i>
                        }
                      >
                        <Typography>Bình luận</Typography>
                      </TGNTooltip>
                      <TGNTooltip
                        placement="bottom-start"
                        label={
                          <i
                            className="tgn-outline-clock-circle text-color-white cursor-pointer fs-20"
                            role="presentation"
                            onClick={() => {
                              setShowComment(true);
                              setLogMode(LogModeEnum.ACTIVITY);
                            }}
                          ></i>
                        }
                      >
                        <Typography>Hoạt động</Typography>
                      </TGNTooltip>
                      <TGNTooltip
                        placement="bottom-start"
                        label={
                          <i className="tgn-solid-more-horizontal text-color-white cursor-pointer fs-20"></i>
                        }
                      >
                        <Typography>hello</Typography>
                      </TGNTooltip>
                    </div>
                  </div>
                </div>
                <div className={styles["bg-linear"]}></div>
                <div className="d-flex px-4 container">
                  <div
                    style={{
                      width: "30%",
                    }}
                  >
                    <NodeProperties isFullScreen />
                  </div>
                  <Overview />
                  {/* <CommentBox /> */}
                </div>
              </>
            ) : (
              <div className="d-flex">
                <div
                  className={cx(
                    styles.left,
                    !showExtend && styles["extend-left"]
                  )}
                >
                  <div
                    className={cx(
                      "d-flex px-12 py-8 gap-2",
                      styles["header-sidebar"]
                    )}
                  >
                    <TGNTooltip
                      placement="bottom-start"
                      label={
                        <i
                          role="presentation"
                          className="tgn-outline-direction-right text-tertiary-color-gray-5 cursor-pointer fs-20"
                          onClick={() => dispatch(closePeekRenderer())}
                        ></i>
                      }
                    >
                      <span className={styles.tooltip}>Thu nhỏ</span>
                    </TGNTooltip>
                    <TGNTooltip
                      placement="bottom-start"
                      label={
                        <i
                          aria-hidden
                          className="tgn-outline-format-line-spacing text-tertiary-color-gray-5 cursor-pointer fs-20"
                          onClick={(e) => showFullScreen(e)}
                        ></i>
                      }
                    >
                      <span className={styles.tooltip}>Hiển thị đầy đủ</span>
                    </TGNTooltip>
                  </div>
                  <NodeProperties />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(PeekRenderer);
