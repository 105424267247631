import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getUserMFA } from "../api/account.api";
import { UserMFAEnums } from "../constants/account.constants";
import { AccountState } from "../types/account.types";

const initialState: AccountState = {
  userMFAList: [],
  pwd: "",
};

export const getUserMFAListThunk = createAsyncThunk<UserMFAEnums[]>(
  "account/getUserMFAList",
  async (_, { rejectWithValue }) => {
    try {
      const result = await getUserMFA();
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setUserMFAList: (state, action: PayloadAction<UserMFAEnums[]>) => {
      state.userMFAList = action.payload;
    },
    setPwd: (state, action: PayloadAction<string>) => {
      state.pwd = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUserMFAListThunk.fulfilled,
      (state, action: PayloadAction<UserMFAEnums[]>) => {
        state.userMFAList = action.payload;
      }
    );
    builder.addCase(getUserMFAListThunk.rejected, (state) => {
      state.userMFAList = [];
    });
  },
});

export const accountAction = accountSlice.actions;

export const accountReducer = accountSlice.reducer;
