import React, { FC, ReactNode, memo, useState } from "react";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Sender } from "@/features/groups/types/group.type";
import { extractUsername } from "@/utils/extractUsername";

interface Props {
  user: Sender;
  button: ReactNode;
}

export const HoverCardUser: FC<Props> = ({ user, button }) => {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>{button}</HoverCardTrigger>
      <HoverCardContent className="w-80 bg-white">
        <div className="flex space-x-4">
          <TGNAvatar
            userName={user.name || extractUsername(user.email || "")}
            userPhoto={user.photoUrl}
            size={"24"}
            className="block"
          />
          <div className="space-y-1">
            <h4 className="text-sm font-semibold">
              {user.name || extractUsername(user.email || "")}
            </h4>
            <p className="text-sm">{user.email || user.phone}</p>
            <div className="flex items-center pt-2">
              <span className="text-xs text-muted-foreground">
                Đã tham gia Taga 3 năm trước
              </span>
            </div>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
