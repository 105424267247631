import React, { FC, memo, useState } from "react";
import "react-multi-carousel/lib/styles.css";

import {
  ArrowLeft,
  ArrowRight,
  Close,
  Email,
  InformationFilled,
  ListChecked,
  Phone,
  ReplyAll,
} from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";

import { LoadingIcon } from "@/assets/icons";
import LockOrg from "@/assets/images/lockorg.png";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { settingGroup } from "@/features/groups/api/group.api";
import { getGroupInfo } from "@/features/groups/group";
import { useAppDispatch } from "@/redux/store";
import { COMMON_MESSAGES, getMessage } from "@/utils/message";

type Props = {
  email?: string;
};

const ContactProfile: FC<Props> = ({ email }) => {
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <>
      <Dialog
        open={openModal}
        onOpenChange={setOpenModal}
      >
        <DialogTrigger asChild>
          <Button
            variant="outline"
            onClick={() => setOpenModal(true)}
          >
            Chỉnh sửa
          </Button>
        </DialogTrigger>
        <DialogContent className={"sm:max-w-[600px]  bg-white "}>
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-0 right-0 rounded-full w-[36px]"
              onClick={() => {
                setOpenModal(false);
                setOpenConfirmModal(false);
              }}
            >
              <Close />
            </Button>
            <DialogTitle className="text-center max-w-[460PX] text-lg mx-auto">
              Thông tin liên hệ
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 pb-[12px] h-full">
            <div className="h-14 px-3 py-4 bg-white rounded border border-secondary-200 justify-start items-center gap-3 inline-flex">
              <Email />
              <div className="h-6 w-full text-left">
                <span className="text-secondary-900 text-base font-normal ">
                  {email}
                </span>
              </div>
              <ArrowRight />
            </div>
            <div className="h-14 px-3 py-4 bg-white rounded border border-secondary-200 justify-start items-center gap-3 inline-flex">
              <Phone />
              <div className="h-6 w-full text-left">
                <span className="text-secondary-900 text-base font-normal ">
                  {email}
                </span>
              </div>
              <ArrowRight />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(ContactProfile);
