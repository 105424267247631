import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  ChangeEvent,
} from "react";

import {
  Add,
  CloudUpload,
  FolderParent,
  Grid,
  List,
} from "@carbon/icons-react";
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useSearchParams } from "react-router-dom";

import ListEmpty from "@/assets/images/Search_empty.png";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Badge } from "@/components/ui/badge";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Select, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from "@/constants/app.constants";
import { GroupSortDateEnum } from "@/features/groups/constants/group.constant";
import useToastError from "@/hooks/useToastError";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { limitCharacter } from "@/utils/formatText";
import { getFingerprint } from "@/utils/local";
import { getCustomMessage, getMessage } from "@/utils/message";

import {
  checkExistName,
  getUrlPersonalUpload,
  getUrlUploadFolder,
  renameContent,
  softDeleteContent,
  uploadFile,
  downloadFolder,
} from "../../api/lib.api";
import ContentDetailPopup from "../../components/ContentDetailPopup";
import ContentItem from "../../components/ContentItem";
import ExistNameModal from "../../components/ExistNameModal";
import ManageContentAccessModal from "../../components/ManageContentAccessModal";
import OverCapacityModal from "../../components/OverCapacityModal";
import RecentContent from "../../components/RecentContent";
import RenameFolderNameDialog from "../../components/RenameFolderDialog";
import SearchEmpty from "../../components/SearchEmpty";
import ShareContentFolderModal from "../../components/ShareContentFolderModal";
import UploadList from "../../components/UploadList";
import FolderListModal from "../../components/move/FolderListModal";
import {
  ContentTypeEnums,
  DownloadContentStatusEnums,
  FolderMenuActions,
  IUploadDetailsStatus,
  ResourceType,
} from "../../constants/lib.constants";
import { LibPathsEnum } from "../../constants/lib.paths";
import {
  downloadFileThunk,
  getChildContentThunk,
  getParentContentDetailThunk,
  libraryActions,
  searchContentThunk,
} from "../../redux/lib.slice";
import {
  IContent,
  IContentUpload,
  IUploadDetailsItem,
} from "../../types/lib.types";

type ContentType = {
  id: string | number;
  content_name: string;
  content_size: number;
  content_type: ResourceType;
  material_path: string;
};

const LibraryList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showToastError } = useToastError();
  const { toast } = useToast();
  const containerRef = useRef<HTMLDivElement>(null);
  const { libraryId } = useAppSelector((state) => state.auth);
  const {
    contents,
    contentDetail,
    loading,
    parentContentDetail,
    pageIndex,
    totalPage,
  } = useAppSelector((state) => state.library);
  const [searchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");
  const [contentId, setContentId] = useState(
    searchParams.get("content_id")
      ? Number(searchParams.get("content_id"))
      : libraryId
  );
  const [openContentDetailPopup, setOpenContentDetailPopup] = useState(false);
  const [openRenameFolderDialog, setOpenRenameFolderDialog] = useState(false);
  const inputFileEl = useRef<HTMLInputElement>(null);
  const inputFolderEl = useRef<HTMLInputElement>(null);
  const [uploadList, setUploadList] = useState<IUploadDetailsItem[]>([]);
  const [openUploadList, setOpenUploadList] = useState(false);
  const [openExistName, setOpenExistName] = useState(false);
  const [newName, setNewName] = useState<string | null>("");
  const [openOverCapacity, setOpenOverCapacity] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | FileList | null>(
    null
  );

  const [contentSelected, setContentSelected] = useState<IContent>();
  const [isShowShare, setIsShowShare] = useState<boolean>(false);
  const onOpenChangeShare = () => {
    setIsShowShare(false);
  };
  const onShare = () => {
    setIsShowShare(true);
  };
  const [isShowManageContentAccessModal, setIsShowManageContentAccessModal] =
    useState(false);
  const onManageContentAccessModalSubmitSuccess = () => {
    setIsShowManageContentAccessModal(false);
  };
  const [isShowMove, setIsShowMove] = useState<boolean>(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const onMove = () => {
    setIsShowMove(true);
  };
  const onDelete = async (content: IContent) => {
    try {
      if (!content) return;
      await softDeleteContent(content?.id);
      fetchContents(PAGE_DEFAULT);
      toast({
        title: "Thành công!",
        variant: "success",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: getCustomMessage("MSGTV36", content.name),
            }}
          ></div>
        ),
      });
    } catch {
      console.log("error");
    }
  };
  //Check action
  const onMenuClick = async (action: FolderMenuActions, content: IContent) => {
    setContentSelected(content);
    switch (action) {
      case FolderMenuActions.Rename:
        setContentSelected(content);
        setOpenRenameFolderDialog(true);
        break;
      case FolderMenuActions.Open:
        handleOpenFolder(content.id);
        break;
      case FolderMenuActions.Detail:
        setContentSelected(content);
        setOpenContentDetailPopup(true);
        dispatch(libraryActions.setContentDetail(content));
        break;
      case FolderMenuActions.Share:
        setContentSelected(content);
        onShare();
        break;
      case FolderMenuActions.Move:
        onMove();
        break;
      case FolderMenuActions.Delete:
        onDelete(content);
        break;
      case FolderMenuActions.Download:
        try {
          if (content.type === ContentTypeEnums.FILE) {
            dispatch(
              libraryActions.addDownloadContent({
                content,
                status: DownloadContentStatusEnums.PENDING,
              })
            );
            dispatch(libraryActions.setShowDownloadPopup(true));
            await dispatch(
              downloadFileThunk({
                content,
              })
            );
          } else {
            dispatch(
              libraryActions.addDownloadContent({
                content,
                status: DownloadContentStatusEnums.PENDING,
              })
            );
            dispatch(libraryActions.setShowDownloadPopup(true));
            await downloadFolder({
              contentId: content.id,
              fingerprint: getFingerprint(),
            });
          }
        } catch {
          toast({
            variant: "error",
            title: "Thất bại",
            description: getMessage("MSG_ERROR"),
          });
        }
        break;
      default:
        break;
    }
  };
  const fetchContents = async (pageNumber: number) => {
    try {
      const keyword = searchParams.get("keyword");
      if (keyword) {
        await dispatch(
          searchContentThunk({
            contentId,
            keyword,
            pageNumber,
            pageSize: PAGE_SIZE_DEFAULT,
          })
        );
      } else {
        await dispatch(
          getChildContentThunk({
            contentId,
            pageNumber,
            pageSize: PAGE_SIZE_DEFAULT,
          })
        );
      }
    } catch (err) {
      showToastError(err);
    }
  };
  const handleOpenFolder = (id: number) => {
    searchParams.set("content_id", id.toString());
    searchParams.delete("keyword");
    navigate(LibPathsEnum.CLOUD + "?" + searchParams.toString());
  };

  useEffect(() => {
    if (contentId && !loading) {
      dispatch(getParentContentDetailThunk({ contentId }));
      fetchContents(PAGE_DEFAULT);
    }
  }, [contentId, keyword]);
  useEffect(() => {
    const content_id = searchParams.get("content_id");
    const keywordParam = searchParams.get("keyword");
    setContentId(content_id && !keywordParam ? Number(content_id) : libraryId);
    if (keywordParam) {
      searchParams.delete("content_id");
    }
    setKeyword(searchParams.get("keyword") || "");
  }, [searchParams, libraryId]);

  useEffect(() => {
    if (containerRef.current === null) {
      return;
    }
    const height = containerRef.current.clientHeight;
    const heightScreen = window.innerHeight;
    const HEIGHT_SEARCH_BAR = 64;
    const HEIGHT_BREAD_CUM = 64;
    if (
      !loading &&
      pageIndex < totalPage &&
      height < heightScreen - HEIGHT_BREAD_CUM - HEIGHT_SEARCH_BAR
    ) {
      fetchContents(pageIndex + 1);
    }
  }, [loading]);
  const handleRenameFolder = async (name: string) => {
    if (!contentSelected) {
      return;
    }
    try {
      setLoadingAction(true);
      await renameContent({
        contentId: contentSelected.id,
        contentName: name,
      });
      toast({
        title: "Thành công!",
        variant: "success",
        description: getMessage("MSG4"),
      });
      setOpenRenameFolderDialog(false);
      await fetchContents(PAGE_DEFAULT);
    } catch (err) {
      showToastError(err);
    } finally {
      setLoadingAction(false);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(libraryActions.resetDownloadContents());
    };
  }, []);

  if (!contentId) {
    return <></>;
  }

  const onAddFile = () => {
    inputFileEl.current?.click();
  };

  const onAddFolder = () => {
    inputFolderEl.current?.click();
  };

  const onFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target.files?.length) {
      const file = e.target.files[0];
      try {
        if (parentContentDetail) {
          const result = await checkExistName(
            file.name,
            file.size,
            ContentTypeEnums.FILE,
            parentContentDetail.id
          );
          if (result.data.duplicate) {
            setOpenExistName(true);
            setNewName(result.data.new_name);
            setSelectedFile(file);
          } else {
            onSubmitFile(file);
          }
        }
      } catch (error) {
        if (inputFileEl.current) {
          inputFileEl.current.value = "";
        }
        showToastError(error);
      }
    }
  };

  const onSubmitFile = async (file: File) => {
    setOpenExistName(false);
    try {
      if (!parentContentDetail) {
        return;
      }
      const res = await getUrlPersonalUpload(
        file.name,
        file.size,
        parentContentDetail.id
      );
      if (res.data) {
        const contentUpload: IContentUpload = res.data;
        const contentId = contentUpload.content.id;
        const urlUpload = contentUpload.upload_url;
        setOpenUploadList(true);
        uploadFile(file, urlUpload)
          .then(() => {
            if (contentId) {
              updateFileUploadSuccess(contentId);
            }
          })
          .catch(() => {
            updateFileUploadError(contentId);
          });
        if (inputFileEl.current) {
          inputFileEl.current.value = "";
        }
        const uploadDetail: IUploadDetailsItem = {
          contentId: contentId,
          isSingleFile: true,
          name: newName ?? file.name,
          extension: file.type,
          status: IUploadDetailsStatus.Uploading,
          process: 0,
          parentId: parentContentDetail?.id,
          parentName: parentContentDetail?.name,
        };
        setUploadList((prev) => [uploadDetail, ...prev]);
      }
    } catch (error) {
      showToastError(error);
    } finally {
      setNewName(null);
    }
  };

  const updateFileUploadSuccess = (contentId: number) => {
    setUploadList((prevState) => {
      const cloneUploadList = JSON.parse(JSON.stringify(prevState));
      const updateItem = cloneUploadList.find(
        (i: IUploadDetailsItem) => i.contentId === contentId
      );
      updateItem.status = IUploadDetailsStatus.Success;
      return cloneUploadList;
    });
  };

  const updateFileUploadError = (contentId: number) => {
    setUploadList((prevState) => {
      const cloneUploadList = JSON.parse(JSON.stringify(prevState));
      const updateItem = cloneUploadList.find(
        (i: IUploadDetailsItem) => i.contentId === contentId
      );
      updateItem.status = IUploadDetailsStatus.Error;
      return cloneUploadList;
    });
  };

  const onFolderSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files || !parentContentDetail) return;

    const folderName = e.target?.files[0].webkitRelativePath.split("/")[0];
    const folderSize = Array.from(e.target?.files).reduce(
      (accumulator, item) => {
        return accumulator + item.size;
      },
      0
    );
    try {
      if (parentContentDetail) {
        const result = await checkExistName(
          folderName,
          folderSize,
          ContentTypeEnums.FOLDER,
          parentContentDetail.id
        );
        if (result.data.duplicate) {
          setOpenExistName(true);
          setNewName(result.data.new_name);
          setSelectedFile(e.target.files);
        } else {
          onSubmitFolder(e.target.files);
        }
      }
    } catch (error) {
      if (inputFolderEl.current) {
        inputFolderEl.current.value = "";
      }
      showToastError(error);
    }
  };

  const onSubmitFolder = async (files: FileList) => {
    if (!files || !parentContentDetail) return;
    setOpenExistName(false);
    try {
      const payload = getPayloadUploadFolder(files);
      if (!payload) return;
      const res = await getUrlUploadFolder({
        parent_id: parentContentDetail?.id,
        contents: payload?.contents,
      });
      if (!res.data) return;
      setOpenUploadList(true);
      setOpenExistName(false);
      addUploadDetailFolderToList(payload.contents, res.data);
      uploadMultipleFiles(payload.contents, payload.filesObj, res.data)
        .then(() => {
          updateFileUploadSuccess(payload.contents[0].id as number);
        })
        .catch(() => {
          updateFileUploadError(payload.contents[0].id as number);
        });
    } catch (error) {
      showToastError(error);
    } finally {
      setNewName(null);
      if (inputFolderEl.current) {
        inputFolderEl.current.value = "";
      }
    }
  };

  const generateString = (length: number) =>
    Array(length)
      .fill("")
      .map(() => Math.random().toString(36).charAt(2))
      .join("");

  const getPayloadUploadFolder = (files: FileList) => {
    if (!files) return null;
    if (files) {
      const filesSelect = files;
      const foldersObj: Record<string, string> = {};
      const filesObj: Record<string, File> = {};
      const contents: {
        id: string | number;
        content_name: string;
        content_size: number;
        content_type: ResourceType;
        material_path: string;
      }[] = [];
      let contentSize = 0;
      Array.from(filesSelect).forEach((file) => {
        const materialPath: string[] = [];
        let paths = file.webkitRelativePath.split("/");
        paths = paths.slice(0, paths.length - 1);
        paths.forEach((path, index) => {
          const key = paths.slice(0, index + 1).join("/");
          if (!foldersObj[key]) {
            const newFolderId = generateString(6);
            materialPath.push(newFolderId);
            foldersObj[key] = newFolderId;
            contents.push({
              id: newFolderId,
              content_name: path,
              content_size: 0,
              content_type: ResourceType.FOLDER,
              material_path: materialPath.join("."),
            });
          } else {
            materialPath.push(foldersObj[key]);
          }
        });
        const newFileId = generateString(6);
        materialPath.push(newFileId);
        contents.push({
          id: newFileId,
          content_name: file.name,
          content_size: file.size,
          content_type: ResourceType.FILE,
          material_path: materialPath.join("."),
        });
        contentSize += file.size;
        filesObj[newFileId] = file;
      });
      return {
        contents,
        filesObj,
        contentSize,
      };
    }
  };

  const addUploadDetailFolderToList = (contents: ContentType[], data: any) => {
    const fileIds = contents
      .filter((c) => c.content_type === ResourceType.FILE)
      .map((c: any) => data[c.id]?.content?.id);
    const firstContent = contents[0];
    const uploadDetail: IUploadDetailsItem = {
      contentId: firstContent.id as number,
      contentFileIds: fileIds,
      contentFileCloudUploadSuccessIds: [],
      isSingleFile: false,
      name: newName ?? firstContent.content_name,
      extension: null,
      status: IUploadDetailsStatus.Uploading,
      process: 0,
      parentId: parentContentDetail?.id,
      parentName: parentContentDetail?.name,
      // parentIsRoot: parentContentDetail,
    };
    setUploadList((prev) => [uploadDetail, ...prev]);
  };

  const uploadMultipleFiles = (
    contents: ContentType[],
    filesObj: any,
    dataUrl: any
  ) => {
    const pms: any[] = [];
    contents.forEach((content: ContentType) => {
      const dataContent = dataUrl[content.id];
      const uploadUrl = dataContent.upload_url;
      if (uploadUrl) {
        pms.push(uploadFile(filesObj[content.id], uploadUrl));
      }
    });
    return Promise.all(pms);
  };

  const onCloseUploadList = () => {
    setOpenUploadList(false);
    setUploadList([]);
  };

  const onCancelUploadFile = () => {
    setSelectedFile(null);
    setOpenExistName(false);
    if (inputFileEl.current) {
      inputFileEl.current.value = "";
    }
  };

  return (
    <div className="container mx-auto">
      {parentContentDetail?.id === libraryId && !keyword && (
        <RecentContent onMenuClick={onMenuClick} />
      )}
      <div>
        {contents.length === 0 &&
        !loading &&
        parentContentDetail?.id === libraryId &&
        !keyword ? (
          <div className="flex items-center justify-center h-[calc(100vh-64px)]">
            <div className="flex flex-col items-center justify-center gap-6 max-w-[350px]">
              <img
                className="w-[124px]"
                src={ListEmpty}
                alt=""
              />
              <div className="text-center">
                <p className="text-base font-semibold mb-2">
                  Oops! Thư viện trống.
                </p>
                <p className="text-xs text-secondary-600">
                  Tạo mới hoặc tải lên nội dung để đồng bộ và quản lý dữ liệu
                  của bạn tốt hơn.
                </p>
              </div>
              <div className="flex gap-3">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      className="min-w-[169px]"
                      iconBefore={<CloudUpload />}
                    >
                      Tải lên
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-56">
                    <DropdownMenuItem className="p-0">
                      <Button
                        className="w-full bg-transparent justify-start"
                        variant="ghost"
                        iconBefore={<CloudUpload />}
                        onClick={onAddFile}
                      >
                        Tải lên Tệp tin
                      </Button>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="p-0">
                      <Button
                        className="w-full bg-transparent justify-start"
                        variant="ghost"
                        iconBefore={<FolderParent />}
                        onClick={onAddFolder}
                      >
                        Tải lên Thư mục
                      </Button>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
                <Button
                  onClick={() => {
                    dispatch(libraryActions.showCreateModal());
                  }}
                  iconBefore={<Add />}
                  className="px-4 py-2.5 text-sm border border-primary-600 rounded text-primary-600 min-w-[169px] bg-transparent hover:bg-transparent"
                >
                  Tạo thư mục
                </Button>
                <input
                  type="file"
                  ref={inputFileEl}
                  style={{ display: "none" }}
                  onChange={onFileSelect}
                />
                {
                  <input
                    type="file"
                    ref={inputFolderEl}
                    style={{ display: "none" }}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    webkitdirectory=""
                    multiple
                    onChange={onFolderSelect}
                  />
                }
                {openUploadList && (
                  <UploadList
                    uploadList={uploadList}
                    onClose={onCloseUploadList}
                  />
                )}
                {selectedFile && (
                  <ExistNameModal
                    open={openExistName}
                    newName={newName}
                    type="Upload"
                    onSubmit={() => {
                      if (selectedFile instanceof FileList) {
                        onSubmitFolder(selectedFile);
                      } else {
                        onSubmitFile(selectedFile);
                      }
                    }}
                    onCancel={onCancelUploadFile}
                  />
                )}
                <OverCapacityModal
                  open={openOverCapacity}
                  onClose={() => {
                    onCancelUploadFile();
                    setOpenOverCapacity(false);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            {keyword ? (
              contents.length > 0 ? (
                <div className="flex items-center gap-2 mb-4 mt-4">
                  <span className="text-secondary-600 font-semibold text-sm">
                    Kết quả tìm kiếm
                  </span>
                  <Badge className="rounded">{contents.length} kết quả</Badge>
                </div>
              ) : (
                <SearchEmpty />
              )
            ) : (
              <div className="mt-7 mb-4 flex justify-between items-center">
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem
                      className="cursor-pointer text-secondary-400 font-semibold"
                      onClick={() => handleOpenFolder(libraryId)}
                    >
                      Tất cả
                    </BreadcrumbItem>
                    {(parentContentDetail?.deep_parent || []).length > 0 &&
                      parentContentDetail?.deep_parent.map((item, index) => {
                        if (item.type === ContentTypeEnums.LIBRARY) {
                          return null;
                        }
                        return (
                          <Fragment key={item.id}>
                            <BreadcrumbSeparator
                              className={clsx(
                                index !==
                                  parentContentDetail?.deep_parent.length
                                  ? "text-secondary-400"
                                  : ""
                              )}
                            >
                              /
                            </BreadcrumbSeparator>
                            <BreadcrumbItem
                              className="cursor-pointer"
                              onClick={() => handleOpenFolder(item.id)}
                            >
                              <p className="text-secondary-400 text-sm font-semibold">
                                {parentContentDetail?.deep_parent.length > 3 &&
                                (index !== 0 ||
                                  index !==
                                    parentContentDetail?.deep_parent.length -
                                      1) ? (
                                  <span>...</span>
                                ) : (
                                  <span>{limitCharacter(item.name, 40)}</span>
                                )}
                              </p>
                            </BreadcrumbItem>
                          </Fragment>
                        );
                      })}
                    {parentContentDetail &&
                      parentContentDetail.type !== ContentTypeEnums.LIBRARY && (
                        <>
                          <BreadcrumbSeparator>/</BreadcrumbSeparator>
                          <BreadcrumbItem
                            className="cursor-pointer"
                            onClick={() =>
                              handleOpenFolder(parentContentDetail.id)
                            }
                          >
                            <p className="text-secondary-600 text-sm font-semibold">
                              {parentContentDetail.name}
                            </p>
                          </BreadcrumbItem>
                        </>
                      )}
                  </BreadcrumbList>
                </Breadcrumb>
                <div className="flex gap-x-4">
                  <div className="flex items-center">
                    <span className="text-secondary-600 text-xs">
                      Sắp xếp theo:
                    </span>
                    <Select defaultValue={GroupSortDateEnum.NEWEST}>
                      <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                        <SelectValue defaultValue={GroupSortDateEnum.NEWEST} />
                      </SelectTrigger>
                    </Select>
                  </div>
                  <div className="flex items-center">
                    <span className="text-secondary-600 text-xs">
                      Nhóm theo:
                    </span>
                    <Select defaultValue={GroupSortDateEnum.NEWEST}>
                      <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                        <SelectValue defaultValue={GroupSortDateEnum.NEWEST} />
                      </SelectTrigger>
                    </Select>
                  </div>
                  <div className="w-6 h-6 bg-secondary-100 rounded-full flex items-center justify-center">
                    <Grid />
                  </div>
                  <div className="w-6 h-6 rounded-full flex items-center justify-center">
                    <List className="text-secondary-300" />
                  </div>
                </div>
              </div>
            )}
            <div ref={containerRef}>
              {loading ? (
                <div className="flex items-center justify-center mt-6">
                  <TGNLoading size="medium" />
                </div>
              ) : (
                <InfiniteScroll
                  style={{
                    height: "unset",
                    overflow: "unset",
                  }}
                  className="px-0"
                  dataLength={contents.length}
                  next={() => {
                    fetchContents(pageIndex + 1);
                  }}
                  hasMore={pageIndex < totalPage}
                  loader={null}
                >
                  <div
                    className={clsx(
                      "flex flex-wrap gap-y-9",
                      openContentDetailPopup ? "w-[calc(100%-344px)]" : ""
                    )}
                  >
                    {contents.map((content) => (
                      <ContentItem
                        key={content.id}
                        content={content}
                        active={contentSelected?.id === content.id}
                        onMenuClick={(action) => onMenuClick(action, content)}
                      />
                    ))}
                  </div>
                </InfiniteScroll>
              )}
            </div>
          </div>
        )}
      </div>
      {contentDetail && (
        <ContentDetailPopup
          open={openContentDetailPopup}
          content={contentDetail}
          onClose={() => setOpenContentDetailPopup(false)}
          onShowChangeContentType={() =>
            setIsShowManageContentAccessModal(true)
          }
          onChangeMember={isShowShare || isShowManageContentAccessModal}
        />
      )}
      {contentSelected && (
        <ShareContentFolderModal
          isShow={isShowShare}
          content={contentSelected}
          onSubmitSuccess={() => setIsShowShare(false)}
          onOpenChange={onOpenChangeShare}
        />
      )}
      <FolderListModal
        isShow={isShowMove}
        sourceContent={contentSelected}
        onOpenChange={() => setIsShowMove(false)}
      />
      <RenameFolderNameDialog
        open={openRenameFolderDialog}
        onClose={() => {
          setOpenRenameFolderDialog(false);
        }}
        content={contentSelected}
        onOk={handleRenameFolder}
        loading={loadingAction}
      />
      {contentSelected && (
        <ManageContentAccessModal
          content={contentSelected}
          isShow={isShowManageContentAccessModal}
          onSubmitSuccess={onManageContentAccessModalSubmitSuccess}
          onOpenChange={() => setIsShowManageContentAccessModal(false)}
        />
      )}
    </div>
  );
};

export default LibraryList;
