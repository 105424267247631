import React from "react";

import {
  BuildingInsights_2,
  CloudApp,
  CloudAuditing,
  DecisionTree,
  EdgeNodeAlt,
  GuiManagement,
  Notification,
  SecurityServices,
  UserMultiple,
} from "@carbon/icons-react";
import cx from "classnames";
import { NavLink, useNavigate, Outlet } from "react-router-dom";

import { AccountPathsEnum } from "../constants/account.paths";

const AccountSettingLayout = () => {
  const navLinks = [
    {
      url: AccountPathsEnum.ACCOUNT,
      end: true,
      icon: <UserMultiple className="mr-2" />,
      label: "Hồ sơ và hiển thị",
    },
    {
      url: AccountPathsEnum.SECURITY,
      end: true,
      icon: <SecurityServices className="mr-2" />,
      label: "Tài khoản và bảo mật",
    },
    {
      url: AccountPathsEnum.NOTIFICATION,
      end: true,
      icon: <Notification className="mr-2" />,
      label: "Thông báo",
    },
    {
      url: AccountPathsEnum.BILLING,
      end: true,
      icon: <EdgeNodeAlt className="mr-2" />,
      label: "Dung lượng",
    },
    {
      url: AccountPathsEnum.ORG_SETTING,
      end: true,
      icon: <BuildingInsights_2 className="mr-2" />,
      label: "Tổ chức",
    },
    {
      url: AccountPathsEnum.PREFERENCES,
      end: true,
      icon: <GuiManagement className="mr-2" />,
      label: "Giao diện & Ngôn ngữ",
    },
  ];
  return (
    <div>
      <div className="bg-white z-10 border-secondary-100 border-b sticky top-0">
        <div className="flex items-center justify-center rounded-md bg-muted text-muted-foreground py-4">
          <div>
            {navLinks.map((navLink, index) => {
              return (
                <NavLink
                  to={navLink.url}
                  end={navLink.end}
                  key={index}
                  className={({ isActive }) =>
                    cx(
                      isActive
                        ? "text-secondary-900 bg-secondary-100 font-medium"
                        : "text-secondary-600 font-medium",
                      "rounded-[8px] mr-4 inline-flex items-center justify-center whitespace-nowrap px-3 py-2 text-sm  transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                    )
                  }
                >
                  {navLink.icon}
                  {navLink.label}
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
      <div className="mt-6">
        <Outlet />
      </div>
    </div>
  );
};

export default AccountSettingLayout;
