import React, { FC, memo } from "react";

import {
  PinFilled,
  UserAvatar,
  OverflowMenuVertical,
  DotMark,
} from "@carbon/icons-react";
import { Link } from "react-router-dom";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { useAppDispatch } from "@/redux/store";
import formatNumber from "@/utils/formatNumber";
import { getMessage } from "@/utils/message";

import JoinButton from "./JoinButton";
import { deleteGroup } from "../../api/group.api";
import { groupActions } from "../../group";
import { GroupDef } from "../../types/group.type";

interface GroupCardProps {
  group: GroupDef;
  type?: "view" | "invite" | "request" | "deleted" | "discover" | "suggest";
  onAccept?: (groupId: number, type: string | null) => void;
  onReject?: (groupId: number, type: string | null) => void;
  onCancel?: (requestId: string, userId: number) => void;
  onRestore?: (groupId: number) => void;
}

const GroupCard: FC<GroupCardProps> = ({ group, type = "view" }) => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const handleDeleteGroup = async () => {
    try {
      if (group) {
        dispatch(groupActions.setFlagLoading(true));
        await deleteGroup(group.groupId);
        toast({
          title: "Thành công!",
          variant: "success",
          description: getMessage("MSG5"),
        });
      }
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    } finally {
      dispatch(groupActions.setFlagLoading(false));
    }
  };
  return (
    <div className="relative rounded-lg border border-secondary-200 hover:shadow-lg overflow-hidden">
      <PinFilled
        className="text-white absolute top-[16px] right-[16px]"
        size={16}
      />
      <img
        className="object-cover h-[140px] w-full"
        src={group.photoUrl ? group.photoUrl : GroupCover}
        alt=""
      />
      <div className="p-4">
        <p className="text-xs text-secondary-600 font-medium uppercase mb-2 truncate">
          Tổ chức
          {!group.isRootGroup && (
            <span className="">
              ・
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Link
                      className="text-primary-600 hover:text-primary-800 truncate text-ellipsis"
                      to={GroupPathsEnum.GROUP_DETAIL.replace(
                        ":id",
                        String(group.rootId)
                      )}
                    >
                      {group.rootName}
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent>
                    <span className="text-xs"> {group.rootName}</span>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </span>
          )}
        </p>
        <div className="min-h-[76px]">
          <p>
            <Link
              className="font-semibold text-base leading-6 text-secondary-900 mb-2 line-clamp-2 hover:text-primary-600 inline"
              to={GroupPathsEnum.GROUP_DETAIL.replace(
                ":id",
                String(group.groupId)
              )}
            >
              {group.name}
            </Link>
          </p>
          <div className="flex items-center">
            {type === "view" && (
              <>
                {group.isOwner ? (
                  <Badge className="bg-orange-25 border border-orange-200 text-orange-500 font-medium text-[10px] leading-[14px]">
                    <UserAvatar
                      className="mr-1"
                      size={12}
                    />
                    Quản trị
                  </Badge>
                ) : (
                  <Badge className="bg-blue-50 border border-blue-200 text-blue-500 font-medium text-[10px] leading-[14px]">
                    <UserAvatar
                      className="mr-1"
                      size={12}
                    />
                    Đã tham gia
                  </Badge>
                )}
                <DotMark
                  size={5}
                  color="#4B5563"
                  className="ml-1 mr-1"
                ></DotMark>
              </>
            )}
            <span className="text-sm text-secondary-600">
              {formatNumber(group.numberOfMembers)} thành viên
            </span>
          </div>
        </div>
        {type === "view" && (
          <div className="flex mt-3">
            <Link
              className="bg-primary-25 px-4 py-2.5 block hover:bg-primary-50 text-primary-600 rounded flex-1 mr-3 text-center text-sm"
              to={GroupPathsEnum.GROUP_DETAIL.replace(
                ":id",
                String(group.groupId)
              )}
            >
              Xem chi tiết
            </Link>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  className="px-3 py-2"
                  variant={"text"}
                >
                  <OverflowMenuVertical />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem>Ghim nhóm</DropdownMenuItem>
                <DropdownMenuItem>Bỏ ghim nhóm</DropdownMenuItem>
                {group.isRootGroup && group.isOwner && (
                  <Button
                    variant={"text"}
                    onClick={() => handleDeleteGroup()}
                  >
                    Xoa To chuc
                  </Button>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}

        {type === "discover" && (
          <div className="mt-3">
            <JoinButton
              status="discover"
              group={group}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(GroupCard);
