import api from "@/api/api_instance";
import {
  AppResponse,
  PaginationParams,
  PaginationResponse,
} from "@/types/app.types";

import { LibraryType } from "../constants/lib.constants";
import { LibEndPointEnum } from "../constants/lib.endpoint";
import {
  CapacityDetail,
  CheckContentNameParams,
  CheckDuplicateContentNameParams,
  CheckDuplicateContentResponse,
  GetChildContentParams,
  GetSharedUsersResponse,
  IContent,
  IContentGetUrlUploadFolder,
  LibraryGroup,
  MySharedContentDef,
  RecentContentDef,
  SearchContentParams,
} from "../types/lib.types";

export const getSharedWithMe = (id: number) => {
  return api.get(LibEndPointEnum.SHARE_WITH_ME.replace(":id", String(id)));
};

export const getChildContent = (
  params: GetChildContentParams
): Promise<PaginationResponse<IContent[]>> => {
  return api.get(LibEndPointEnum.CHILD.replace(":id", String(params.id)), {
    params: {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      contentType: params.contentType,
    },
  });
};

export const getOrgLibrary = (
  params: {
    id: number;
  } & PaginationParams
): Promise<PaginationResponse<LibraryGroup[]>> => {
  return api.get(
    LibEndPointEnum.ORG_LIBRARY.replace(":id", String(params.id)),
    {
      params: {
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
      },
    }
  );
};

export const getGroupLibrary = (
  params: {
    id: number;
    sortByName?: string;
    sortByType?: string;
  } & PaginationParams
): Promise<PaginationResponse<IContent[]>> => {
  return api.get(
    LibEndPointEnum.GROUP_LIBRARY.replace(":id", String(params.id)),
    {
      params: {
        sortByName: "updated_at",
        sortByType: "desc",
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
      },
    }
  );
};

export const createFolder = (
  name: string,
  type: LibraryType,
  parentId: number
) => {
  return api.post(LibEndPointEnum.CREATE_PERSONAL_CONTENT, {
    content_name: name,
    library_type: type,
    parent_id: parentId,
  });
};

export const checkExistName = (
  contentName: string,
  contentSize: number,
  contentType: number,
  parentId: number
) => {
  return api.get(LibEndPointEnum.CHECK_EXIST_NAME, {
    params: {
      contentName,
      contentSize,
      parentId,
      contentType,
    },
  });
};

export const getUrlPersonalUpload = (
  contentName: string,
  contentSize: number,
  parentId: number,
  uuid?: string
) => {
  return api.get(LibEndPointEnum.PERSONAL_UPLOAD, {
    params: {
      contentName,
      contentSize,
      parentId,
      uuid,
    },
  });
};

export const getUrlUploadFolder = (data: IContentGetUrlUploadFolder) => {
  return api.post(LibEndPointEnum.PERSONAL_UPLOAD_FOLDER, data);
};

export const uploadFile = (file: File, url: string) => {
  return api.put(url, file);
};

export const getCapacityDetails = (): Promise<CapacityDetail> => {
  return api.get(LibEndPointEnum.CAPACITY_DETAIL);
};

export const getFriend = () => {
  return api.get(LibEndPointEnum.FRIEND);
};
export const getSharedUsers = (
  contentId: number
): Promise<AppResponse<GetSharedUsersResponse>> => {
  return api.get(
    LibEndPointEnum.SHARED_USER.replace(/:id/, contentId.toString())
  );
};

export const getLibraryList = () => {
  return api.get(LibEndPointEnum.LIBRARY_LIST);
};

export const getContentDetail = (
  contentId: number
): Promise<AppResponse<IContent>> => {
  return api.get(
    LibEndPointEnum.CONTENT_DETAIL.replace(/:id/, contentId.toString())
  );
};

export const shareContent = (contentId: number, user_ids: number[]) => {
  return api.post(
    LibEndPointEnum.SHARED_CONTENT.replace(/:id/, contentId.toString()),
    {
      user_ids,
    }
  );
};
export const removeMemberReference = (contentId: number, memberId: number) => {
  return api.delete(
    LibEndPointEnum.REMOVE_MEMBER_CONTENT.replace(/:id/, contentId.toString()),
    {
      data: {
        user_id: memberId,
      },
    }
  );
};

export const searchContent = (
  params: SearchContentParams
): Promise<PaginationResponse<IContent[]>> => {
  return api.get(LibEndPointEnum.SEARCH.replace(":id", String(params.id)), {
    params: {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      keyword: params.keyword,
    },
  });
};

export const checkContentName = (
  params: CheckContentNameParams
): Promise<AppResponse<boolean>> => {
  return api.post(LibEndPointEnum.CHECK_CONTENT_NAME, {
    contentId: params.contentId,
    contentName: params.contentName,
  });
};

export const softDeleteContent = (contentId: number) => {
  return api.delete(
    LibEndPointEnum.SOFT_DELETE.replace(/:id/, contentId.toString())
  );
};
export const getListSoftDelete = (
  params: GetChildContentParams
): Promise<PaginationResponse<IContent[]>> => {
  return api.get(
    LibEndPointEnum.LIST_SOFT_DELETE.replace(":id", String(params.id)),
    {
      params: {
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
      },
    }
  );
};
export const renameContent = (
  params: CheckContentNameParams
): Promise<AppResponse<boolean>> => {
  return api.put(LibEndPointEnum.RENAME, {
    contentId: params.contentId,
    contentName: params.contentName,
  });
};
export const restoreContent = (
  contentId: number
): Promise<AppResponse<boolean>> => {
  return api.put(LibEndPointEnum.RESTORE, {
    contentId: contentId,
  });
};
export const hardDeleteContent = (contentId: number) => {
  return api.delete(
    LibEndPointEnum.HARD_DELETE.replace(/:id/, contentId.toString())
  );
};

export const checkDuplicateContent = (
  params: CheckDuplicateContentNameParams
): Promise<AppResponse<CheckDuplicateContentResponse>> => {
  return api.get(
    LibEndPointEnum.CHECK_DUPLICATE_CONTENT_NAME.replace(
      /:id/,
      params.sourceId.toString()
    ),
    {
      params: {
        targetId: params.targetId,
      },
    }
  );
};

export const moveContent = (
  params: CheckDuplicateContentNameParams
): Promise<AppResponse<boolean>> => {
  return api.put(
    LibEndPointEnum.MOVE_LOCATION.replace(/:id/, params.sourceId.toString()),
    {
      target_id: params.targetId,
    }
  );
};

export const getRecentContent = (
  libraryId: number
): Promise<AppResponse<RecentContentDef[]>> => {
  return api.get(LibEndPointEnum.RECENT.replace(/:id/, libraryId.toString()));
};

export const getMySharedContent = (
  params: {
    libraryId: number;
  } & PaginationParams
): Promise<PaginationResponse<MySharedContentDef[]>> => {
  return api.get(
    LibEndPointEnum.MY_SHARED.replace(/:id/, params.libraryId.toString()),
    {
      params: {
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
      },
    }
  );
};

export const deleteMySharedContent = (
  contentId: number
): Promise<AppResponse<boolean>> => {
  return api.delete(
    LibEndPointEnum.DELETE_MY_SHARED_CONTENT.replace(
      /:id/,
      contentId.toString()
    )
  );
};

export const getDownloadFileUrl = (params: {
  contentMaskingId: string;
  contentSize: number;
}): Promise<AppResponse<{ download_url: string }>> => {
  return api.get(LibEndPointEnum.DOWNLOAD_FILE, {
    params: {
      contentMaskingId: params.contentMaskingId,
      contentSize: params.contentSize,
    },
  });
};

export const downloadFolder = (params: {
  contentId: number;
  fingerprint: string;
}): Promise<AppResponse<{ download_url: string }>> => {
  return api.get(
    LibEndPointEnum.DOWNLOAD_FOLDER.replace(/:id/, params.contentId.toString()),
    {
      params: {
        fingerprint: params.fingerprint,
      },
    }
  );
};

export const rejectAccessRequest = (requestId: number, isAccept: boolean) => {
  return api.put(
    LibEndPointEnum.ACCEPT_SHARE_CONTENT.replace(/:id/, requestId.toString()),
    {
      is_accept: isAccept,
    }
  );
};
