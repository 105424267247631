import React, { useEffect, useState } from "react";

import { Search } from "@carbon/icons-react";
import { isAxiosError } from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { PAGE_DEFAULT } from "@/constants/app.constants";
import usePagination from "@/hooks/usePagination";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import GroupDeletedCard from "./components/GroupDeletedCard";
import RestoreGroupConfirmDialog from "./components/RestoreGroupConfirmDialog";
import { getGroupDeletedList, recoverGroup } from "../../api/group.api";
import { GroupSortDateEnum } from "../../constants/group.constant";
import { GroupDeletedDef } from "../../types/group.type";

const GroupDeletedScreen = () => {
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const groupInfo = useAppSelector((state) => state.group.data);
  const [groupDeletedList, setGroupDeletedList] = useState<GroupDeletedDef[]>(
    []
  );
  const [groupSelected, setGroupSelected] = useState<GroupDeletedDef>();
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [totalGroup, setTotalGroup] = useState(0);
  const { pagination, setPagination } = usePagination();
  const [sort, setSort] = useState(urlParams.get("sort"));
  const [success, setSuccess] = useState(false);
  const { toast } = useToast();
  const [openRestoreGroupConfirmDialog, setOpenRestoreGroupConfirmDialog] =
    useState(false);

  const fetchInfiniteScroll = async (page: number) => {
    try {
      if (groupInfo) {
        const result = await getGroupDeletedList({
          pageNumber: page,
          pageSize: pagination.size,
          groupId: groupInfo.id,
        });
        setGroupDeletedList((prev) => [...prev, ...result.data]);
        setPagination({
          current: result.pageIndex,
          size: pagination.size,
          total: result.totalPage,
        });
        if (result.totalPage <= pagination.current) {
          setIsLoadMore(false);
        }
        setTotalGroup(result.totalElements);
      }
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    }
  };
  useEffect(() => {
    fetchInfiniteScroll(pagination.current);
  }, []);
  const restoreGroup = (group: GroupDeletedDef) => {
    setSuccess(false);
    setOpenRestoreGroupConfirmDialog(true);
    setGroupSelected(group);
  };
  const handleRestore = async () => {
    if (!groupSelected || !groupInfo) {
      return;
    }
    try {
      dispatch(
        loadingAction.show({
          title: "Đang khôi phục Nhóm",
          description:
            "Quá trình này có thể mất một vài phút. Vui lòng chờ tới khi Nhóm được khôi phục thành công.",
        })
      );
      await recoverGroup({
        recoveryGroupId: groupSelected.id,
        requestGroupId: groupInfo.id,
      });
      dispatch(loadingAction.hide());
      setOpenRestoreGroupConfirmDialog(true);
      setSuccess(true);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
      dispatch(loadingAction.hide());
    } finally {
      setGroupDeletedList([]);
      await fetchInfiniteScroll(PAGE_DEFAULT);
    }
  };

  return (
    <div className="bg-white py-5 min-h-[100vh] text-sm container">
      <div className="flex justify-between px-4 border-b-[1px] pb-5 border-secondary-100 border-solid">
        <div>
          <p className="text-lg font-semibold">Lưu trữ</p>
          <span className="text-xs">
            Bạn có thể tìm thấy danh sách các Tổ chức/Nhóm đã xoá thuộc quyền sở
            hữu.
          </span>
        </div>
        <div>
          <div className="relative">
            <Search className="absolute top-3 left-3 text-secondary-600" />
            <Input
              className="pl-9 h-auto min-w-[300px] rounded-[384px] bg-secondary-50 focus:shadow-lg min-h-[40px]"
              placeholder="Nhập tên nhóm"
              size={16}
            />
          </div>
        </div>
      </div>
      <div className="pt-5 flex justify-between">
        <div className="flex">
          <p className="text-base font-semibold mr-2">Danh sách</p>
          <Badge variant="default">{totalGroup} Nhóm</Badge>
        </div>
        <div className="flex items-center">
          <span className="text-secondary-600 text-xs">Sắp xếp theo:</span>
          <Select defaultValue={sort || GroupSortDateEnum.NEWEST}>
            <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
              <SelectValue defaultValue={sort || GroupSortDateEnum.NEWEST} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem
                  value={GroupSortDateEnum.NEWEST}
                  className={
                    sort == null
                      ? "text-secondary-900 font-medium"
                      : "text-secondary-600  "
                  }
                >
                  Mới nhất
                </SelectItem>
                <SelectItem
                  value={GroupSortDateEnum.OLDEST}
                  className={
                    sort == GroupSortDateEnum.OLDEST
                      ? "text-secondary-900 font-medium"
                      : "text-secondary-600  "
                  }
                >
                  Cũ nhất
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      <InfiniteScroll
        style={{
          height: "unset",
          overflow: "unset",
        }}
        className="px-0"
        dataLength={groupDeletedList.length}
        next={() => {
          fetchInfiniteScroll(pagination.current + 1);
        }}
        hasMore={isLoadMore}
        loader={
          <div className="flex items-center justify-center mt-6">
            <TGNLoading size="medium" />
          </div>
        }
      >
        <div className="grid gap-4 mt-5 grid-cols-3">
          {groupDeletedList.map((group) => {
            return (
              <GroupDeletedCard
                key={group.id}
                group={group}
                onOk={() => restoreGroup(group)}
              />
            );
          })}
        </div>
      </InfiniteScroll>

      <RestoreGroupConfirmDialog
        open={openRestoreGroupConfirmDialog}
        group={groupSelected}
        success={success}
        onClose={() => setOpenRestoreGroupConfirmDialog(false)}
        onOk={handleRestore}
      />
    </div>
  );
};

export default GroupDeletedScreen;
