import React, { FC, memo, useEffect, useMemo, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

import Divider from "@/components/Divider/Divider";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import TGNCheckbox from "@/components/TGNCheckboxRadio/TGNCheckbox";
import TGNInputField from "@/components/TGNInput/TGNInputField";
import Typography from "@/components/Typography/Typography";
import { AssignRolesEnum, getAssignees } from "@/features/mindmap/mindmap";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getUrlImage } from "@/utils/image";

import AssigneeItem from "./AssigneeItem";
import styles from "./AssigneePopup.module.scss";

type AssigneePopupProps = {
  stopEvent?: boolean;
};

const AssigneePopup: FC<AssigneePopupProps> = () => {
  const dispatch = useAppDispatch();
  const { assignees, selectedNode } = useAppSelector(
    (state) => state.mindmap.currentSheet
  );
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getAssignees({
        groupId: Number(searchParams.get("group_id")) || 0,
        pageNum: 0,
        pageSize: 50,
      })
    )
      .then(unwrapResult)
      .finally(() => setLoading(false));
  }, [selectedNode, dispatch, searchParams]);

  const assigneeOptions = useMemo(
    () =>
      assignees.filter((assignee) => assignee.role !== AssignRolesEnum.OWNER),
    [assignees]
  );

  return (
    <div
      aria-hidden
      style={{
        minWidth: 392,
      }}
      className={styles.popup}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="d-flex align-items-center gap-4 mb-8">
        <span className="text-primary-color-gray-10 fs-14 fw-600">
          Set Assignees
        </span>
        <i className="tgn-solid-information-circle text-tertiary-color-gray-5 fs-18"></i>
      </div>
      <TGNInputField
        placeholder="Search for ID, Users"
        isSearch
      />
      <div className="d-flex align-items-center gap-4 mt-8  mb-12">
        <TGNAvatar
          name={selectedNode?.data.description.owner.name}
          round
          size="40"
          src={
            selectedNode?.data.description.owner.photo
              ? getUrlImage(selectedNode?.data.description.owner.photo)
              : ""
          }
        />
        <div className="flex-1">
          <Typography
            className="text-primary-color-gray-10 fs-14 text-truncate"
            style={{
              maxWidth: 100,
            }}
          >
            {selectedNode?.data.description.owner.name}
          </Typography>
          <span className="fs-12 text-tertiary-color-gray-5">
            {selectedNode?.data.description.owner.username}
          </span>
        </div>
        <span className="fs-14 text-primary-color-gray-10">Owner</span>
      </div>

      <Divider
        height={1}
        width="100%"
      />
      <div className="d-flex align-items-center gap-4 my-4">
        <TGNCheckbox label="" />
        <span className="fs-12 text-secondary-color-gray-7">
          Assign to @everyone
        </span>
      </div>
      <Divider
        height={1}
        width="100%"
      />

      {loading ? (
        <div className="d-flex justify-content-center mt-8">
          <Spinner
            variant="primary"
            animation="border"
          />
        </div>
      ) : (
        assigneeOptions.map((assignee) => (
          <AssigneeItem
            key={assignee.user.id}
            assignee={assignee}
          />
        ))
      )}
    </div>
  );
};

export default memo(AssigneePopup);
