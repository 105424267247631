import axios from "axios";

import { getTokenFromStorage } from "@/features/auth/api/auth";

const api = axios.create({
  baseURL: "https://dev.taganow.vn/api/v1",
  headers: {
    "Content-Type": "application/json",
    timeout: 1000,
  },
});
api.interceptors.request.use(
  (request) => {
    const authInfo = getTokenFromStorage();
    request.headers["tg-web-token"] = authInfo
      ? JSON.parse(authInfo).webToken
      : "";
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
