import React, { FC, ReactNode, memo, useState } from "react";

import { Add, CheckmarkFilled, Warning } from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Field, Formik, Form } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import * as Yup from "yup";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import { updateGroup } from "../../api/group.api";
import { GroupPathsEnum } from "../../constants/group.paths";
import { getGroupInfo } from "../../group";
import { IGroupAttribute } from "../../types/group.type";

type GroupAttributeModalProps = {
  button?: ReactNode;
  attribute?: IGroupAttribute;
  edit?: boolean;
};

const GroupAttributeModal: FC<GroupAttributeModalProps> = ({
  button,
  attribute,
  edit,
}) => {
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [submittingGeneral, setSubmittingGeneral] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const attributeSchema = Yup.object().shape({
    groupAttributes: Yup.object().shape({
      label: Yup.string()
        .max(50, "Tiêu đề không được vượt quá 50 ký tự")
        .trim()
        .required("Tiêu đề không được bỏ trống"),
      value: Yup.string().max(200, "Nội dung không được vượt quá 200 ký tự"),
    }),
  });
  return (
    <>
      {/* <Dialog
        open={openSuccessModal}
        onOpenChange={setOpenSuccessModal}
      >
        <DialogContent className="p-6 flex justify-center flex-col">
          <div className="flex justify-center">
            <div className="border-[6px] rounded-full border-success-25 bg-success-100">
              <CheckmarkFilled
                size={20}
                className="text-success-500"
              />
            </div>
          </div>
          <div className="mt-6">
            <p className="text-lg text-secondary-900 text-center">
              Cập nhật thông tin thành công!
            </p>
            <p className="text-sm text-secondary-600 mt-2 text-center">
              Nếu bạn rời khỏi trang này, mọi chỉnh sửa, cập nhật sẽ không được
              lưu lại.
            </p>
          </div>
        </DialogContent>
      </Dialog> */}
      <Dialog
        open={openModal}
        // onOpenChange={setOpenModal}
      >
        <DialogTrigger asChild>
          <div onClick={() => setOpenModal(true)}>
            {button ? (
              button
            ) : (
              <Button
                type="button"
                size="sm"
                variant="outline"
                iconBefore={<Add />}
              >
                Thêm mới
              </Button>
            )}
          </div>
        </DialogTrigger>
        <DialogContent className="grid gap-6 w-[648px]">
          <DialogHeader>
            <DialogTitle className="text-secondary-900 text-base font-semibold">
              Chỉnh sửa thông tin bổ sung
            </DialogTitle>
          </DialogHeader>
          {groupInfo && (
            <Formik
              initialValues={{
                groupId: groupInfo.id ?? 0,
                groupAttributes: {
                  delete: false,
                  key: attribute?.key ?? "",
                  label: attribute?.label ?? "",
                  value: attribute?.value ?? "",
                },
                name: groupInfo.name,
                description: groupInfo.description,
              }}
              validationSchema={attributeSchema}
              onSubmit={(values, { setSubmitting }) => {
                const updateGroupFn = async () => {
                  try {
                    setSubmittingGeneral(true);
                    const attributes = values.groupAttributes;
                    const params = {
                      name: groupInfo.name ?? "",
                      groupId: groupInfo.id ?? 0,
                      description: groupInfo.description,
                      groupAttributes: [
                        {
                          delete: false,
                          key: attributes.key,
                          label: attributes.label,
                          value: attributes.value,
                        },
                      ],
                    };
                    await updateGroup(params);
                    dispatch(
                      getGroupInfo({
                        group_id: groupInfo.id,
                      })
                    )
                      .then(unwrapResult)
                      .catch(() => {
                        navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
                      });
                    toast({
                      title: "Thành công!",
                      variant: "success",
                      description: getMessage("MSG4"),
                    });
                    setOpenModal(false);
                  } catch (error: any) {
                    toast({
                      title: "Thất bại!",
                      variant: "error",
                      description: getMessage(error.response?.data.errors[0]),
                    });
                  } finally {
                    setSubmittingGeneral(false);
                    setSubmitting(false);
                  }
                };
                updateGroupFn();
              }}
            >
              {({
                enableReinitialize,
                handleChange,
                setFieldValue,
                errors,
                touched,
                isSubmitting,
                isValid,
              }) => (
                <Form>
                  <div className="grid gap-4">
                    <div className="grid gap-1">
                      <Label className="text-xs text-secondary-900">
                        Tiêu đề <span className="text-error-600">*</span>
                      </Label>
                      <Field
                        name="groupAttributes.label"
                        as={Input}
                        onChange={(e: React.ChangeEvent<any>) => {
                          if (!edit) {
                            setFieldValue(
                              "groupAttributes.key",
                              slugify(e.currentTarget.value) + Date.now()
                            );
                          }
                          handleChange(e);
                        }}
                        error={errors.groupAttributes?.label}
                      />
                    </div>
                    {errors.groupAttributes?.label ? (
                      <p className="text-error-400 text-xs flex">
                        <Warning
                          className="text-error-400 mr-1"
                          size={16}
                        />
                        {errors.groupAttributes?.label}
                      </p>
                    ) : null}
                    <div className="grid gap-1">
                      <Label className="text-xs text-secondary-900">
                        Nội dung
                      </Label>
                      <Field
                        name="groupAttributes.value"
                        as={Textarea}
                        onChange={handleChange}
                        error={errors.groupAttributes?.value}
                      />
                      {errors.groupAttributes?.value ? (
                        <p className="text-error-400 text-xs flex">
                          <Warning
                            className="text-error-400 mr-1"
                            size={16}
                          />
                          {errors.groupAttributes?.value}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex justify-end gap-2 mt-6">
                    <DialogClose asChild>
                      <Button
                        disabled={isSubmitting}
                        variant="outline"
                        onClick={() => setOpenModal(false)}
                      >
                        Huỷ
                      </Button>
                    </DialogClose>
                    <Button
                      loading={isSubmitting || submittingGeneral}
                      type="submit"
                      variant="primary"
                      disabled={!isValid}
                    >
                      Xác nhận
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(GroupAttributeModal);
