import React, { memo } from "react";

import cx from "classnames";
import Avatar from "react-avatar";
import { Nav } from "react-bootstrap";

import Divider from "@/components/Divider/Divider";
import TGNButton from "@/components/TGNButton/TGNButton";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import styles from "./MindmapHeader.module.scss";
import { removeSheet, setCurrentSheet } from "../../mindmap";
import FileSetting from "../FileSetting/FileSetting";
import SearchForm from "../SearchForm/SearchForm";

const MindmapHeader = () => {
  const { sheets, currentSheet } = useAppSelector((state) => state.mindmap);
  const dispatch = useAppDispatch();
  return (
    <div className={styles.header}>
      <div className={styles.tab}>
        <Nav className="h-100">
          <Nav.Item>
            <div className="d-flex align-items-center h-100">
              <i className="tgn-outline-slack opacity-50"></i>
            </div>
          </Nav.Item>

          <Nav.Item
            className={cx(
              "d-flex align-items-center ms-8",
              currentSheet.id === sheets[0].id ? styles["file-active"] : ""
            )}
          >
            <Divider
              height={20}
              width={1}
            />
            <div
              role="presentation"
              className="d-flex align-items-center me-4 ms-8"
            >
              <i className="tgn-outline-snow me-4"></i>
              <span
                aria-hidden
                className="fs-14 fw-bolder cursor-pointer text-gray-900"
                onClick={() => dispatch(setCurrentSheet(sheets[0]))}
              >
                {sheets[0].name}
              </span>
            </div>
            <FileSetting />
          </Nav.Item>

          {sheets.slice(1).map((sheet) => (
            <React.Fragment key={sheet.id}>
              <Nav.Item
                className={cx(
                  "d-flex align-items-center ms-8",
                  currentSheet.id === sheet.id ? styles["file-active"] : ""
                )}
              >
                <Divider
                  height={20}
                  width={1}
                />
                <i className="tgn-solid-money-minus me-4 ms-8"></i>
                <span
                  aria-hidden
                  className="fs-14 fw-bolder me-8 cursor-pointer"
                  onClick={() => dispatch(setCurrentSheet(sheet))}
                >
                  {sheet.name}
                </span>
                <i
                  aria-hidden
                  className="tgn-solid-remove cursor-pointer"
                  onClick={() => dispatch(removeSheet(sheet.id))}
                ></i>
              </Nav.Item>
            </React.Fragment>
          ))}
        </Nav>

        <div className="d-flex align-items-center">
          <div className="me-1">
            <Avatar
              size="24"
              facebook-id="invalidfacebookusername"
              src="http://www.gravatar.com/avatar/a16a38cdfe8b2cbd38e8a56ab93238d3"
              round
            />
            <Avatar
              size="24"
              facebook-id="invalidfacebookusername"
              src="http://www.gravatar.com/avatar/a16a38cdfe8b2cbd38e8a56ab93238d3"
              round
            />
            <Avatar
              size="24"
              facebook-id="invalidfacebookusername"
              src="http://www.gravatar.com/avatar/a16a38cdfe8b2cbd38e8a56ab93238d3"
              round
            />
          </div>
          <TGNButton
            className="fw-semibold mx-4"
            variant="ghost"
            color="#000"
          >
            <span className="fs-14">Share</span>
          </TGNButton>
          <Divider height={20} />
          <TGNButton
            className="mx-4 fw-semibold"
            variant="ghost"
            color="#000"
          >
            <span className="fs-14">Save</span>
          </TGNButton>
          <TGNButton
            className="fw-semibold"
            variant="primary"
          >
            <span className="fs-14">Publish</span>
          </TGNButton>
        </div>
      </div>

      <SearchForm />
    </div>
  );
};

export default memo(MindmapHeader);
