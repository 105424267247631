import React, { FC, memo, useState, useEffect } from "react";

import {
  ArrowUpRight,
  Close,
  InformationFilled,
  OverflowMenuVertical,
  TrashCan,
} from "@carbon/icons-react";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch } from "@/redux/store";
import { COMMON_MESSAGES, getCustomMessage, getMessage } from "@/utils/message";

import {
  getGroupById,
  getGroupInvitationForUser,
  getUserRequests,
  userCancelRequestToGroup,
  userRequestToGroup,
} from "../../api/group.api";
import {
  GroupUserStatusEnum,
  GroupUserTypeEnum,
} from "../../constants/group.constant";
import { GroupPathsEnum } from "../../constants/group.paths";
import { getGroupInfo, groupActions } from "../../group";
import AcceptGroupModal from "../../screens/GroupInvitesScreen/component/AcceptGroupModal";
import AcceptOrgModal from "../../screens/GroupInvitesScreen/component/AcceptOrgModal";
import ListView from "../../screens/GroupInvitesScreen/component/ListView";
import RejectOrgModal from "../../screens/GroupInvitesScreen/component/RejectOrgModal";
import {
  IGroupInvitation,
  GroupDef,
  IGroupPersonalRequest,
} from "../../types/group.type";

interface Props {
  status?: string;
  group: GroupDef;
}
export enum GroupTypeBtn {
  DEFAULT = "Tham gia nhóm",
  REQUESTED = "Hủy yêu cầu",
  APPROVED = "Truy cập nhóm",
  REQUESTED_ACTION = "Hủy yêu cầu",
  WAIT_ORGANIZATION_APPROVE = "Chờ xử lý",
  REQUESTED_INVITE = "Chấp nhận",
}
const JoinButton: FC<Props> = ({ group }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const [requesting, setRequesting] = useState(false);
  const [requested, setRequested] = useState(false);
  const [content, setContent] = useState(GroupTypeBtn.DEFAULT);
  const [status, setStatus] = useState(GroupUserStatusEnum.DEFAULT);
  const [invites, setInvites] = useState<
    (IGroupInvitation | IGroupPersonalRequest)[]
  >([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (group.inviteRequest != null) {
      switch (group.inviteRequest.groupUserType) {
        case GroupUserTypeEnum.REQUEST:
          switch (group.inviteRequest.groupUserStatus) {
            case GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE:
              setStatus(GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE);
              setContent(GroupTypeBtn.WAIT_ORGANIZATION_APPROVE);
              break;
            case GroupUserStatusEnum.APPROVED:
              setStatus(GroupUserStatusEnum.DEFAULT);
              setContent(GroupTypeBtn.DEFAULT);
              break;
            case GroupUserStatusEnum.REQUESTED:
              setStatus(GroupUserStatusEnum.REQUESTED);
              setContent(GroupTypeBtn.REQUESTED);
              break;
            default:
              setStatus(GroupUserStatusEnum.DEFAULT);
              setContent(GroupTypeBtn.DEFAULT);
              break;
          }
          break;
        case GroupUserTypeEnum.INVITE:
          switch (group.inviteRequest.groupUserStatus) {
            case GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE:
              setStatus(GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE);
              setContent(GroupTypeBtn.WAIT_ORGANIZATION_APPROVE);
              break;
            case GroupUserStatusEnum.APPROVED:
              setStatus(GroupUserStatusEnum.DEFAULT);
              setContent(GroupTypeBtn.DEFAULT);
              break;
            case GroupUserStatusEnum.REQUESTED:
              setStatus(GroupUserStatusEnum.REQUESTED);
              setContent(GroupTypeBtn.REQUESTED_INVITE);
              break;
            default:
              setStatus(GroupUserStatusEnum.DEFAULT);
              setContent(GroupTypeBtn.DEFAULT);
              break;
          }
          break;
        case GroupUserTypeEnum.INVITE_EMAIL:
          switch (group.inviteRequest.groupUserStatus) {
            case GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE:
              setStatus(GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE);
              setContent(GroupTypeBtn.WAIT_ORGANIZATION_APPROVE);
              break;
            case GroupUserStatusEnum.APPROVED:
              setStatus(GroupUserStatusEnum.DEFAULT);
              setContent(GroupTypeBtn.DEFAULT);
              break;
            case GroupUserStatusEnum.REQUESTED:
              setStatus(GroupUserStatusEnum.REQUESTED);
              setContent(GroupTypeBtn.REQUESTED_INVITE);
              break;
            default:
              setStatus(GroupUserStatusEnum.DEFAULT);
              setContent(GroupTypeBtn.DEFAULT);
              break;
          }
          break;
        default:
          break;
      }
    } else {
      setStatus(GroupUserStatusEnum.DEFAULT);
      setContent(GroupTypeBtn.DEFAULT);
    }
  }, [group]);

  const handleSendRequest = async (group: GroupDef) => {
    if (group.groupLock) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG80,
      });
    } else {
      setRequesting(true);
      try {
        const res = await userRequestToGroup(group.groupId);
        switch (res.data.status) {
          case GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE:
            setStatus(GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE);
            setContent(GroupTypeBtn.REQUESTED_ACTION);
            toast({
              title: "Thành công!",
              variant: "success",
              description: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getCustomMessage("MSG70", group.name),
                  }}
                ></div>
              ),
            });
            break;
          case GroupUserStatusEnum.APPROVED:
            setRequested(true);
            setStatus(GroupUserStatusEnum.APPROVED);
            setContent(GroupTypeBtn.APPROVED);
            toast({
              title: "Thành công!",
              variant: "success",
              description: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getCustomMessage("MSG63", group.name),
                  }}
                ></div>
              ),
            });
            break;
          case GroupUserStatusEnum.REQUESTED:
            setStatus(GroupUserStatusEnum.REQUESTED);
            setContent(GroupTypeBtn.REQUESTED);
            toast({
              title: "Thành công!",
              variant: "success",
              description: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getCustomMessage("MSG70", group.name),
                  }}
                ></div>
              ),
            });
            break;
          default:
            setStatus(GroupUserStatusEnum.DEFAULT);
            setContent(GroupTypeBtn.DEFAULT);
            break;
        }
      } catch (error: any) {
        if (error.response?.data.errors[0]) {
          toast({
            title: "Thất bại!",
            variant: "error",
            description: getMessage(error.response?.data.errors[0]),
          });
        } else {
          toast({
            title: "Thất bại!",
            variant: "error",
            description: getMessage("MSG12"),
          });
        }
      } finally {
        setRequesting(false);
      }
    }
  };
  const handleCancelRequest = async (group: GroupDef) => {
    if (group.groupLock) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG80,
      });
    } else {
      try {
        const res = await getGroupById(Number(group.groupId));
        if (res.data.userGroupRelation.id) {
          const childRequests = await getUserRequests({
            rootGroupId: group.rootId,
            pageNumber: 1,
            pageSize: 10,
          });
          const childRequestsAvailable = childRequests.data.filter(
            (item) =>
              item.groupId !== group.rootId && item.groupId !== group.groupId
          );
          const childInvites = await getGroupInvitationForUser({
            rootGroupId: group.rootId,
            pageNumber: 1,
            pageSize: 10,
          });
          const childInviteAvailable = childInvites.data.filter(
            (item) =>
              item.groupId !== group.groupId &&
              item.status !== GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
          );
          if (
            (childRequestsAvailable.length > 0 ||
              childInviteAvailable.length > 0) &&
            group.rootId === group.groupId
          ) {
            setOpenModal(true);
            setInvites([...childRequestsAvailable, ...childInviteAvailable]);
          } else {
            await userCancelRequestToGroup(res.data.userGroupRelation.id);
            toast({
              title: "Thành công!",
              variant: "success",
              description: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getCustomMessage("MSG73", String(group.name)),
                  }}
                ></div>
              ),
            });
            setStatus(GroupUserStatusEnum.DEFAULT);
            setContent(GroupTypeBtn.DEFAULT);
          }
        }
      } catch (error: any) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: COMMON_MESSAGES.MSG12,
        });
      } finally {
        dispatch(loadingAction.hide());
      }
    }
  };
  const handleReject = async () => {
    if (group.groupLock) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG80,
      });
    } else {
      dispatch(
        loadingAction.show({
          title: "Đang xử lý...",
          description: "Vui lòng chờ tới khi xử lý hoàn tất.",
        })
      );
      try {
        const res = await getGroupById(Number(group.groupId));
        dispatch(groupActions.setFlagLoading(true));
        await userCancelRequestToGroup(res.data.userGroupRelation.id);
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: getCustomMessage("MSG73", String(group.name)),
              }}
            ></div>
          ),
        });

        setOpenModal(false);
        dispatch(groupActions.setFlagLoading(false));
      } catch (error: any) {
        dispatch(loadingAction.hide());

        toast({
          title: "Thất bại!",
          variant: "error",
          description: COMMON_MESSAGES.MSG12,
        });
      } finally {
        dispatch(loadingAction.hide());
      }
    }
  };
  return (
    <>
      {group.inviteRequest ? (
        <>
          {group.inviteRequest.groupUserType == GroupUserTypeEnum.REQUEST && (
            <>
              {group.inviteRequest.groupUserStatus ==
              GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE ? (
                <div className="flex gap-3">
                  <Button
                    loading={requesting}
                    type="button"
                    disabled
                    className="w-full"
                    onClick={() => {
                      handleCancelRequest(group);
                    }}
                  >
                    {content}
                  </Button>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        className="px-3 py-2"
                        variant={"text"}
                      >
                        <OverflowMenuVertical />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <Button
                        variant={"ghost"}
                        onClick={() => handleReject()}
                      >
                        <TrashCan className="me-1" />
                        Huỷ tham gia
                      </Button>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              ) : (
                <Button
                  loading={requesting}
                  type="button"
                  variant={requested ? "secondaryColor" : "ghost"}
                  className="w-full"
                  onClick={() => {
                    switch (status) {
                      case GroupUserStatusEnum.DEFAULT:
                        handleSendRequest(group);
                        break;
                      case GroupUserStatusEnum.APPROVED:
                        navigate(
                          `${GroupPathsEnum.GROUP_DETAIL.replace(
                            ":id",
                            String(group.groupId)
                          )}`
                        );

                        break;
                      case GroupUserStatusEnum.REQUESTED:
                        handleCancelRequest(group);
                        break;
                      case GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE:
                        handleCancelRequest(group);
                        break;
                      default:
                        handleSendRequest(group);
                        break;
                    }
                  }}
                >
                  {content}
                </Button>
              )}
            </>
          )}
          {(group.inviteRequest.groupUserType == GroupUserTypeEnum.INVITE ||
            group.inviteRequest.groupUserType ==
              GroupUserTypeEnum.INVITE_EMAIL) && (
            <>
              {group.inviteRequest.groupUserStatus ==
              GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE ? (
                <div className="flex gap-3">
                  <Button
                    loading={requesting}
                    type="button"
                    disabled
                    className="w-full"
                    onClick={() => {
                      handleCancelRequest(group);
                    }}
                  >
                    {content}
                  </Button>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        className="px-3 py-2"
                        variant={"text"}
                      >
                        <OverflowMenuVertical />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <Button
                        variant={"ghost"}
                        className="w-full"
                        onClick={() => handleReject()}
                      >
                        <TrashCan className="me-1" />
                        Huỷ tham gia
                      </Button>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              ) : (
                <>
                  <div className="flex gap-3">
                    {group.rootId == group.groupId ? (
                      <AcceptOrgModal
                        inviteId={group.inviteRequest.id}
                        groupId={group.groupId}
                        groupName={group.name}
                        isDetail={false}
                        rootGroupId={group.rootId}
                        groupLock={group.groupLock}
                        button={<Button className="w-full">Chấp nhận</Button>}
                      />
                    ) : (
                      <AcceptGroupModal
                        rootGroupName={group.rootName}
                        inviteId={group.inviteRequest.id}
                        groupId={group.groupId}
                        groupName={group.name}
                        isDetail={false}
                        rootGroupId={group.rootId}
                        groupLock={group.groupLock}
                        button={<Button className="w-full">Chấp nhận </Button>}
                      />
                    )}

                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          className="px-3 py-2"
                          variant={"text"}
                        >
                          <OverflowMenuVertical />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <RejectOrgModal
                          groupId={group.groupId}
                          groupName={group.name}
                          inviteId={group.inviteRequest.id}
                          rootGroupId={group.rootId}
                          groupLock={false}
                          button={
                            <Button
                              variant="ghost"
                              className="w-full"
                            >
                              <TrashCan className="me-1" />
                              Từ chối lời mời
                            </Button>
                          }
                        />
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Button
          loading={requesting}
          type="button"
          variant={requested ? "secondaryColor" : "ghost"}
          className="w-full"
          onClick={() => {
            switch (status) {
              case GroupUserStatusEnum.DEFAULT:
                handleSendRequest(group);
                break;
              case GroupUserStatusEnum.APPROVED:
                navigate(
                  `${GroupPathsEnum.GROUP_DETAIL.replace(
                    ":id",
                    String(group.groupId)
                  )}`
                );

                break;
              case GroupUserStatusEnum.REQUESTED:
                handleCancelRequest(group);
                break;
              case GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE:
                handleCancelRequest(group);
                break;
              default:
                handleSendRequest(group);
                break;
            }
          }}
        >
          {content}
        </Button>
      )}
      <Dialog open={openModal}>
        <DialogContent className={"sm:max-w-[600px] bg-white"}>
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-0 right-0 rounded-full w-[36px]"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <Close />
            </Button>
            <DialogTitle className="text-center max-w-[460PX] text-lg mx-auto">
              Lời mời tham gia Tổ chức
            </DialogTitle>
          </DialogHeader>
          <Formik
            initialValues={{
              name: "",
              description: "",
            }}
            onSubmit={(values: any, { setSubmitting }) => {
              console.log();
            }}
          >
            {({ setFieldValue, isValid, values }) => (
              <Form className="grid gap-4">
                <div className="flex gap-2  grid-cols-2 bg-primary-25 p-4 rounded-md">
                  <div>
                    <span className="block border-[6px] p-1 text-primary-600 border-primary-50 bg-primary-100 rounded-full">
                      <InformationFilled size={24} />
                    </span>
                  </div>
                  <span className="w-[calc(100%_-_48px)] text-secondary-900 text-sm">
                    Bạn có một số lời mời/yêu cầu liên quan tới các nhóm thuộc
                    tổ chức này. Việc thực hiện từ chối vào tổ chức đồng nghĩa
                    với việc bạn không thể trở thành thành viên của các nhóm
                    thuộc tổ chức này.
                    <span className="font-medium inline-flex items-center">
                      Tìm hiểu thêm <ArrowUpRight size={14} />
                    </span>
                  </span>
                </div>
                {group.rootId && (
                  <ListView
                    rootGroupId={group.rootId}
                    invites={invites}
                  />
                )}
                <label
                  htmlFor="rejectAll"
                  className="items-center flex space-x-2 "
                >
                  <Checkbox
                    name="rejectAll"
                    id="rejectAll"
                    onCheckedChange={(value) => {
                      setFieldValue("rejectAll", value);
                    }}
                  />

                  <p className="text-secondary-600 text-xs">
                    Từ chối lời mời/hoàn tác yêu cầu tham gia các nhóm trong tổ
                    chức này
                  </p>
                </label>
                <DialogFooter className="justify-center sm:justify-center">
                  <Button
                    disabled={!values.rejectAll}
                    onClick={() => {
                      handleReject();
                    }}
                  >
                    Xác nhận
                  </Button>
                </DialogFooter>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(JoinButton);
