import React, { useState } from "react";

import { Close, Folder, Warning } from "@carbon/icons-react";
import { isAxiosError } from "axios";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from "@/constants/app.constants";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import { createFolder } from "../api/lib.api";
import { LibraryType } from "../constants/lib.constants";
import { getChildContentThunk, libraryActions } from "../redux/lib.slice";

const CreateFolderModal = () => {
  const showCreateModal = useAppSelector(
    (state) => state.library.showCreateModal
  );
  const dispatch = useAppDispatch();
  const [folderName, setFolderName] = useState("");
  const { toast } = useToast();
  const [existingNameError, setExistingNameError] = useState("");
  const { parentContentDetail } = useAppSelector((state) => state.library);

  const handleCreateFolder = async () => {
    try {
      if (!parentContentDetail) {
        return;
      }
      await createFolder(
        folderName,
        LibraryType.PERSONAL,
        parentContentDetail.id
      );
      toast({
        title: "Thành công!",
        variant: "success",
        description: getMessage("MSG25", folderName),
      });
      dispatch(libraryActions.hideCreateModal());
      setFolderName("");
      if (parentContentDetail.id) {
        dispatch(
          getChildContentThunk({
            contentId: parentContentDetail.id,
            pageNumber: PAGE_DEFAULT,
            pageSize: PAGE_SIZE_DEFAULT,
          })
        );
      }
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.data.errors.includes("MSG43")) {
          setExistingNameError("Tên thư mục đã tồn tại.");
        } else {
          setExistingNameError("");
          toast({
            title: "Thất bại!",
            variant: "error",
            description: getMessage(error.response?.data.errors[0]),
          });
        }
      } else {
        setExistingNameError("");
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    }
  };
  return (
    <Dialog
      open={showCreateModal}
      onEnterKeyPressWhenOpen={handleCreateFolder}
    >
      <DialogContent>
        <DialogHeader className="relative gap-6">
          <Close
            onClick={() => {
              setExistingNameError("");
              dispatch(libraryActions.hideCreateModal());
            }}
            className="absolute -top-2 -right-2 cursor-pointer"
          />
          <DialogTitle className="text-lg font-semibold flex gap-2 items-center">
            <Folder />
            Tạo mới Thư mục
          </DialogTitle>
          <DialogDescription>
            <p className="text-secondary-600 text-xs mb-1">
              Tên thư mục <span className="text-error-600">*</span>
            </p>
            <Input
              className={`${
                existingNameError !== ""
                  ? "border-error-600 hover:border-error-600 focus:border-error-600 focus:shadow-error"
                  : ""
              }`}
              placeholder="Tên thư mục"
              onChange={(e) => {
                setExistingNameError("");
                if (e.currentTarget.value === "") {
                  setExistingNameError("Tên thư mục không được phép để trống");
                }
                if (e.currentTarget.value.length > 255) {
                  setExistingNameError(
                    "Tên thư mục không được phép vượt quá 255 ký tự"
                  );
                }
                setFolderName(e.currentTarget.value);
              }}
            />
            {existingNameError && (
              <span className="text-error-600 flex items-center mt-1">
                <Warning className="mr-1" />
                {existingNameError}
              </span>
            )}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            onClick={() => {
              setExistingNameError("");
              dispatch(libraryActions.hideCreateModal());
            }}
            variant="outline"
            size="sm"
          >
            Huỷ bỏ
          </Button>
          <Button
            onClick={handleCreateFolder}
            size="sm"
            disabled={existingNameError !== ""}
          >
            Xác nhận
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateFolderModal;
