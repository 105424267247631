import React, { useState } from "react";

import {
  GroupAccess,
  DataError,
  ChevronDown,
  ChevronUp,
  SearchLocate,
  ListChecked,
  ReplyAll,
  DotMark,
} from "@carbon/icons-react";
import cx from "classnames";
import { NavLink, Outlet, useSearchParams } from "react-router-dom";

import SubNav from "@/components/layout/SubNav/SubNav";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { useAppSelector } from "@/redux/store";

import CreateGroupModal from "../components/CreateGroupModal/CreateGroupModal";
import { GroupRoleEnum } from "../constants/group.constant";
import { GroupPathsEnum } from "../constants/group.paths";

const navLinks = [
  {
    url: GroupPathsEnum.GROUP_DISCOVER,
    end: true,
    icon: (
      <SearchLocate
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Khám phá",
  },
  {
    url: GroupPathsEnum.GROUP_INVITE,
    end: true,
    icon: (
      <ListChecked
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Lời mời tham gia",
  },
  {
    url: GroupPathsEnum.GROUP_REQUEST,
    end: true,
    icon: (
      <ReplyAll
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Yêu cầu đã gửi",
  },
  {
    url: GroupPathsEnum.USER_GROUP_DELETED,
    end: true,
    icon: (
      <DataError
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Lưu trữ",
  },
];

const GroupLayout = () => {
  const flagList = useAppSelector((state) => state.group.flagList);
  const [isOpenCollage, setIsOpenCollage] = React.useState(true);
  const [searchParams] = useSearchParams();

  return (
    <div className="">
      <SubNav>
        <div className="w-full">
          <div className="p-4">
            <p className="text-xl leading-8 fw-bold text-secondary-900 mb-0">
              Nhóm
            </p>
          </div>
          <div className="w-full bg-secondary-100 h-px"></div>
          <div className="py-4 px-2">
            <Collapsible
              open={isOpenCollage}
              onOpenChange={setIsOpenCollage}
              className="space-y-2"
            >
              <NavLink
                to={GroupPathsEnum.GROUP}
                end
              >
                {({ isActive }) => (
                  <div
                    className={cx(
                      "flex items-center justify-between px-2 py-2.5 rounded hover:bg-secondary-100",
                      isActive && !searchParams.get("roleFilter")
                        ? "bg-secondary-100 text-secondary-900"
                        : "text-secondary-600"
                    )}
                  >
                    <p
                      className={cx(
                        "text-sm font-medium flex items-center mb-0"
                      )}
                    >
                      <GroupAccess
                        size={16}
                        color="#101828"
                        className={cx("mr-2")}
                      />
                      Nhóm của tôi
                    </p>
                    <CollapsibleTrigger asChild>
                      {isOpenCollage ? <ChevronUp /> : <ChevronDown />}
                    </CollapsibleTrigger>
                  </div>
                )}
              </NavLink>
              <CollapsibleContent className="!mt-0">
                <div className="px-2 ps-4 text-sm text-secondary-600  rounded">
                  <NavLink
                    className={({ isActive }) =>
                      cx(
                        "flex items-center px-2 py-2.5 rounded hover:bg-secondary-100",
                        isActive && searchParams.get("roleFilter")
                          ? "bg-secondary-100"
                          : ""
                      )
                    }
                    end
                    to={`${GroupPathsEnum.GROUP}?roleFilter=${GroupRoleEnum.MANAGER}`}
                    state={{ from: "occupation" }}
                  >
                    <DotMark
                      size={5}
                      className="mr-2 text-orange-500"
                    ></DotMark>
                    Nhóm bạn đang Quản lý
                  </NavLink>
                </div>
              </CollapsibleContent>
            </Collapsible>
            <ul>
              {navLinks.map((navLink, index) => {
                return (
                  <li
                    className="mb-0"
                    key={index}
                  >
                    <NavLink
                      to={navLink.url}
                      end={navLink.end}
                      className={({ isActive }) =>
                        isActive
                          ? "px-2 py-2.5 rounded bg-secondary-100 text-secondary-900 block hover:bg-secondary-100"
                          : "px-2 py-2.5 rounded block hover:bg-secondary-50 text-secondary-600"
                      }
                    >
                      {({ isActive }) => (
                        <div
                          className={cx(
                            "flex items-center justify-start text-sm text-secondary-600",
                            isActive
                              ? "bg-secondary-100 text-secondary-900"
                              : ""
                          )}
                        >
                          {navLink.icon}
                          {navLink.label}
                        </div>
                      )}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
            <div className="mt-2 px-2 pt-2">
              {flagList ? (
                <></>
              ) : (
                <CreateGroupModal
                  isFullWidth
                  haveSubGroup={true}
                />
              )}
            </div>
          </div>
        </div>
      </SubNav>
      <div className="content pl-[280px] pt-[70px]">
        <Outlet />
      </div>
    </div>
  );
};

export default GroupLayout;
