import React, { ReactNode, useState, useRef, FC } from "react";

import cx from "classnames";
import Overlay from "react-bootstrap/Overlay";

import { useAppSelector } from "@/redux/store";

import styles from "./OverlayAction.module.scss";
import { ROOT_ID } from "../../mindmap";

type OverlayActionProps = {
  content: ReactNode;
  icon: string;
  tooltip: string;
  shorthand: string;
  onClick?: () => void;
};

const OverlayAction: FC<OverlayActionProps> = ({
  content,
  icon,
  tooltip,
  shorthand,
  onClick,
}) => {
  const { selectedNode } = useAppSelector(
    (state) => state.mindmap.currentSheet
  );
  const [show, setShow] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  const onHide = () => {
    setShow(false);
  };
  return (
    <>
      <button
        ref={target}
        className={cx(
          "btn d-flex py-4 px-6 align-items-center",
          styles["btn-icon"],
          !selectedNode ? "pe-none" : "",
          icon === "tgn-outline-task-add" && selectedNode?.data.id === ROOT_ID
            ? styles["action-disable"]
            : ""
        )}
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }
          if (selectedNode) {
            setShow(!show);
          }
        }}
        onMouseOver={() => {
          if (selectedNode) {
            setShowTooltip(true);
          }
        }}
        onMouseLeave={() => {
          if (selectedNode) {
            setShowTooltip(false);
          }
        }}
      >
        <i
          className={cx(
            "fs-24",
            selectedNode ? icon : `text-color-disable ${icon}`,
            icon === "tgn-outline-task-add" && selectedNode?.data.id === ROOT_ID
              ? "text-color-disable"
              : "",
            show ? "text-brand-primary" : ""
          )}
        ></i>
        <div className={cx(styles.tooltip, showTooltip ? styles.active : "")}>
          {tooltip}{" "}
          <span className="text-tertiary-color-gray-5">( {shorthand} )</span>
        </div>
      </button>
      {content && (
        <Overlay
          target={target.current}
          show={show}
          rootClose
          placement="top-start"
          onHide={onHide}
        >
          {({
            /* eslint-disable */
            placement: _placement,
            arrowProps: _arrowProps,
            show: _show,
            popper: _popper,
            hasDoneInitialMeasure: _hasDoneInitialMeasure,
            ...props
            /* eslint-enable */
          }) => (
            <div
              {...props}
              className={styles.overlay}
            >
              {content}
            </div>
          )}
        </Overlay>
      )}
    </>
  );
};

export default OverlayAction;
