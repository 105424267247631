import React, { memo, useState } from "react";

import cx from "classnames";
import { Image, Toast } from "react-bootstrap";

import ThumbnailImg from "@/assets/images/background-qr.png";
import Divider from "@/components/Divider/Divider";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import TGNButton from "@/components/TGNButton/TGNButton";
import Typography from "@/components/Typography/Typography";
import { useAppSelector } from "@/redux/store";

import styles from "./TaskUpdate.module.scss";
import { TAB_UPDATE_NOTIFICATION } from "../../mindmap";

const TaskUpdate = () => {
  const { showSearchBar } = useAppSelector((state) => state.mindmap);
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState(TAB_UPDATE_NOTIFICATION.NEW);
  const toggleShow = () => {
    setShow(!show);
  };
  return (
    <div
      className={cx(
        styles["task-update"],
        showSearchBar ? styles["task-update-active"] : ""
      )}
    >
      <TGNButton
        onClick={toggleShow}
        className="mb-4"
        rounded
        variant="outline"
        width={170}
        height={48}
      >
        <Typography
          className="d-flex gap-4"
          color="color-secondary"
        >
          <i className="tgn-outline-notification-03 fs-20 text-secondary-color-gray-7"></i>
          <span className="fw-semibold fs-14">Thông báo</span>
          <i className="tgn-outline-direction-down-01"></i>
        </Typography>
      </TGNButton>
      <Toast
        show={show}
        onClose={toggleShow}
        className={styles.toast}
      >
        <Toast.Body>
          <div className="d-flex align-items-center justify-content-between">
            <Typography className="fw-600 font-14 text-color-secondary">
              Notifications
            </Typography>
            <i
              aria-hidden="true"
              className="tgn-solid-remove fs-20 cursor-pointer"
              onClick={toggleShow}
            ></i>
          </div>

          <div
            className={cx(
              "d-flex justify-content-between align-items-center",
              styles["wrapper-tab"]
            )}
          >
            <div className="d-flex align-items-center gap-12">
              <div
                className={cx(
                  "p-2 d-flex align-items-center gap-2",
                  styles.tab,
                  tab === TAB_UPDATE_NOTIFICATION.NEW && styles.active
                )}
                aria-hidden="true"
                onClick={() => setTab(TAB_UPDATE_NOTIFICATION.NEW)}
              >
                <Typography className="fs-12">New</Typography>
                <span className={styles.count}>99+</span>
              </div>
              <Divider
                width={1}
                height={12}
              />
              <div
                className={cx(
                  "p-2",
                  styles.tab,
                  tab === TAB_UPDATE_NOTIFICATION.ARCHIVED && styles.active
                )}
                aria-hidden="true"
                onClick={() => setTab(TAB_UPDATE_NOTIFICATION.ARCHIVED)}
              >
                <Typography className="fs-12">Archived</Typography>
              </div>
            </div>
            <div className="d-flex align-items-center cursor-pointer">
              <i className="tgn-outline-check-circle fs-16 text-brand-primary me-2"></i>
              <span className="text-brand-primary fs-12">Mark all as read</span>
            </div>
          </div>

          <div className="mt-4">
            <div className="d-flex gap-2 pt-4 pb-8">
              <div className="mt-4">
                <TGNAvatar name="Nguyen Son" />
              </div>
              <div>
                <Typography>
                  <span className="fw-600">Tien Dat Nguyen</span> has to
                  requested to edit the task{" "}
                  <span className="fw-600">General Components Library</span>{" "}
                  node
                </Typography>
                <Typography
                  color="tertiary-color-gray-5"
                  className="fs-12"
                >
                  10 hours ago
                </Typography>
              </div>
            </div>
            <div className={styles.preview}>
              <div className="d-flex gap-2">
                <Image
                  src={ThumbnailImg}
                  width={48}
                  height={48}
                  rounded
                />
                <div>
                  <Typography className="fs-12 fw-600">
                    TAGA/UI Styleguide ver 1.0
                  </Typography>
                  <Typography className="fs-10 text-secondary-color-gray-7 mt-2">
                    Lorem ipsum dolor sit amet consectetur. Mauris sed tortor
                    consequat habitant suscipit. Nec velit...
                  </Typography>
                </div>
              </div>
              <Typography className="fw-semibold fs-10 text-tertiary-color-gray-5 mt-4">
                LINK PREVIEW
              </Typography>
              <div className="d-flex align-items-center gap-4">
                <i className="tgn-solid-link fs-16"></i>
                <Typography className={styles.link}>
                  https://www.taga.com/file/P1.-Ph%C3%A2n-Group-c%C3%A1-nh%C3%A2n
                </Typography>
              </div>
            </div>
            <div className="gap-4 mt-8 d-flex">
              <TGNButton variant="ghost">Deny</TGNButton>
              <TGNButton variant="primary">Approve</TGNButton>
            </div>
          </div>
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default memo(TaskUpdate);
