import React, { memo } from "react";

import { useNavigate } from "react-router-dom";

import Lock from "@/assets/images/lock.svg";
import { Button } from "@/components/ui/button";

import { GroupPathsEnum } from "../../constants/group.paths";

const RemoveGroupSuccessScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="text-sm bg-white w-full h-[100vh] flex items-center justify-center">
      <div className="text-center">
        <div className="flex justify-center mb-4">
          <img
            src={Lock}
            alt=""
          />
        </div>
        <p className="text-lg font-semibold mb-2">
          Bạn hiện không xem được nội dung này!
        </p>
        <p className="text-[#667085]">
          Nội dung này đã bị xoá hoặc không có sẵn, vui lòng làm mới lại trang
          này.
        </p>
        <div className="mt-6">
          <Button
            variant="primary"
            className="mr-3"
            onClick={() => navigate(GroupPathsEnum.GROUP)}
          >
            Nhóm của tôi
          </Button>
          <Button
            className="bg-white text-primary border-primary border-[1px] hover:text-white"
            onClick={() => navigate("/")}
          >
            Trang chủ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RemoveGroupSuccessScreen;
