import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useAppSelector } from "@/redux/store";

import { GroupPathsEnum } from "../constants/group.paths";

export const useCheckRoleAndDevice = (
  roles: string[] | null,
  verifyDevice?: boolean
) => {
  const navigate = useNavigate();
  const { data: groupInfo } = useAppSelector((state) => state.group);
  useEffect(() => {
    if (groupInfo) {
      if (groupInfo.role && !roles?.includes(groupInfo.role.code)) {
        // return navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
      }
      if (verifyDevice && !groupInfo.deviceAccepted)
        return navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
    }
  }, [groupInfo, navigate, roles, verifyDevice]);
};
