import React, { useCallback, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

import Empty from "@/assets/images/List-none.png";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Badge } from "@/components/ui/badge";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import GroupCardInvite from "./component/GroupCardInvite";
import {
  getGroupInvitationForUser,
  getGroupInvitationRootForUser,
} from "../../api/group.api";
import SearchModal from "../../components/SearchModal/SearchModal";
import {
  GroupSortDateEnum,
  GroupSortEnum,
} from "../../constants/group.constant";
import {
  getGroupInvitationForUserThunk,
  updateInvitationRequests,
} from "../../group";
import { IGroupInvitation } from "../../types/group.type";

const GroupInviteScreen = () => {
  const { invitationRequests } = useAppSelector((state) => state.group);
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const [rootGroups, setRootGroups] = useState<IGroupInvitation[]>([]);
  const [page, setPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [totalElement, setTotalElement] = useState(0);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const [rootGroupId, setRootGroupId] = useState(
    Number(urlParams.get("rootGroupId")) || undefined
  );
  const [sort, setSort] = useState(urlParams.get("sort"));

  let isFiltering = false;
  if (rootGroupId || sort) {
    isFiltering = true;
  }
  const fetchMyGroupsInfiniteScroll = async (pageScroll: number) => {
    try {
      setIsLoadMore(true);
      const result = await getGroupInvitationForUser({
        pageNumber: pageScroll,
        pageSize: 9,
        rootGroupId: rootGroupId,
        sort: sort as GroupSortEnum,
      });
      if (!result.data.length || result.data.length < 9) {
        setIsLoadMore(false);
      }
      dispatch(
        updateInvitationRequests([...invitationRequests, ...result.data])
      );
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    }
  };
  const fetchMyGroups = useCallback(async () => {
    try {
      const result = await getGroupInvitationForUser({
        rootGroupId: rootGroupId,
      });
      setTotalElement(result.totalElements);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    }
  }, [rootGroupId, toast]);
  const fetchInvites = useCallback(
    (pageNumber: number) => {
      dispatch(
        getGroupInvitationForUserThunk({
          pageNumber,
          pageSize: 9,
          rootGroupId,
          sort: sort as GroupSortEnum,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          if (!result.length || result.length < 9) {
            setIsLoadMore(false);
          }
        });
    },
    [rootGroupId, sort, dispatch]
  );
  const fetchRootGroups = useCallback(async () => {
    try {
      const result = await getGroupInvitationRootForUser({});
      setRootGroups(result.data);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchMyGroups();
    fetchRootGroups();
    fetchInvites(page);
  }, [rootGroupId, sort, fetchInvites, fetchMyGroups, fetchRootGroups]);
  useEffect(() => {
    fetchRootGroups();
  }, [fetchRootGroups]);
  return (
    <>
      <div className="container bg-white z-50">
        <div className="py-3 flex items-center justify-between">
          <div className="text-xs font-medium text-secondary-600">
            <span className="text-secondary-400">Nhóm</span> / Lời mời tham gia
          </div>
          <SearchModal />
        </div>
      </div>
      <div className="h-px w-full z-50 bg-secondary-100 sticky top-[69px]"></div>
      <div className=" z-50 bg-white sticky top-[70px]">
        <div className="container">
          <div className="flex justify-between">
            <div className="py-6 flex ">
              <div className="font-semibold text-secondary-900 mr-2">
                Lời mời tham gia
              </div>
              <Badge>{totalElement} Nhóm</Badge>
            </div>
            <div
              className={"grid gap-4 auto-cols-max grid-flow-col items-center"}
            >
              <div className="text-xs flex items-center">
                <span className="text-secondary-600">Lọc theo:</span>
                {rootGroups.length > 0 && (
                  <div className="text-xs">
                    <Select
                      defaultValue={
                        rootGroupId ? String(rootGroupId) : "default"
                      }
                      onValueChange={(value) => {
                        if (value === "default") {
                          setRootGroupId(undefined);
                          urlParams.delete("rootGroupId");
                        } else {
                          urlParams.set("rootGroupId", value);
                          setRootGroupId(Number(value));
                          setPage(1);
                        }
                        navigate(
                          `${window.location.pathname}?${urlParams.toString()}`
                        );
                      }}
                    >
                      <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                        <SelectValue
                          defaultValue={
                            rootGroupId ? String(rootGroupId) : "default"
                          }
                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem
                            value="default"
                            className={
                              rootGroupId == undefined
                                ? "text-secondary-900 font-medium"
                                : "text-secondary-600  "
                            }
                          >
                            Tất cả Tổ chức
                          </SelectItem>
                          {rootGroups.length > 0 &&
                            rootGroups.map((group) => {
                              return (
                                <SelectItem
                                  key={group.id}
                                  value={String(group.id)}
                                  className={
                                    rootGroupId == group.id
                                      ? "text-secondary-900 font-medium"
                                      : "text-secondary-600  "
                                  }
                                >
                                  {group.name}
                                </SelectItem>
                              );
                            })}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                )}
              </div>

              <div className="text-xs flex items-center">
                <span className="text-secondary-600">Sắp xếp theo:</span>
                <Select
                  defaultValue={sort || GroupSortDateEnum.NEWEST}
                  onValueChange={(value) => {
                    if (value === GroupSortDateEnum.NEWEST) {
                      setSort(null);
                      urlParams.delete("sort");
                    } else {
                      urlParams.set("sort", value);
                      setPage(1);
                      setSort(value);
                    }
                    navigate(
                      `${window.location.pathname}?${urlParams.toString()}`
                    );
                  }}
                >
                  <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                    <SelectValue
                      defaultValue={sort || GroupSortDateEnum.NEWEST}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem
                        value={GroupSortDateEnum.NEWEST}
                        className={
                          sort == null
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Mới nhất
                      </SelectItem>
                      <SelectItem
                        value={GroupSortDateEnum.OLDEST}
                        className={
                          sort == GroupSortDateEnum.OLDEST
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Cũ nhất
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-6">
        <InfiniteScroll
          style={{
            height: "unset",
            overflow: "unset",
          }}
          className="px-0"
          dataLength={invitationRequests.length}
          next={() => {
            setPage((prev) => prev + 1);
            fetchMyGroupsInfiniteScroll(page + 1);
          }}
          hasMore={isLoadMore}
          loader={
            <div className="flex items-center justify-center mt-6">
              <TGNLoading size="large" />
            </div>
          }
        >
          {invitationRequests.length === 0 ? (
            <div className="flex flex-col h-[calc(100vh-140px)] -mt-10 items-center justify-center flex-1">
              <div className="flex flex-col items-center justify-center max-w-[229px] text-center">
                <img
                  src={Empty}
                  alt=""
                  className="w-[100px] h-[100px] "
                />
                <span className="text-secondary-600 text-xs mt-4 font-semibold">
                  {isFiltering
                    ? "0 kết quả phù hợp"
                    : "Danh sách lời mời tham gia trống!"}
                </span>
              </div>
            </div>
          ) : (
            <div className="grid gap-4">
              <div className="grid gap-4 grid-cols-3">
                {invitationRequests.map((group) => {
                  return (
                    <GroupCardInvite
                      key={group.groupId}
                      group={group}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default GroupInviteScreen;
