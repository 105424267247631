import React, { FC } from "react";

type DividerProps = {
  width?: number | string;
  height?: number | string;
  horizontal?: boolean;
  color?: string;
  className?: string;
};

const Divider: FC<DividerProps> = ({
  width = 1,
  height = "100%",
  color = "#e8e8e8",
  className,
}) => {
  return (
    <div
      className={className}
      style={{
        width: width,
        height,
        background: color,
      }}
    ></div>
  );
};

export default Divider;
