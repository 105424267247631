import React, { useMemo } from "react";

import { InformationFilled, Warning } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";

import {
  ContentTypeEnums,
  FolderTrashMenuActions,
} from "../constants/lib.constants";
// eslint-disable-next-line import/namespace
import { IContent } from "../types/lib.types";

interface IDialogConfirmDeleteContentProps {
  isShow: boolean;
  content: Partial<IContent> | undefined;
  onOpenChange?: (val: boolean) => void;
  onRejectContent?: () => void;
  onAcceptContent?: () => void;
  type: FolderTrashMenuActions;
}
export default function DialogConfirmDeleteContent({
  isShow,
  content,
  onOpenChange,
  onRejectContent,
  onAcceptContent,
  type,
}: IDialogConfirmDeleteContentProps) {
  const isFolder = useMemo(
    () => content?.type === ContentTypeEnums.FOLDER,
    [content]
  );
  return (
    <Dialog
      open={isShow}
      onOpenChange={onOpenChange}
      onEnterKeyPressWhenOpen={onAcceptContent}
    >
      <DialogContent className="bg-white w-[600px] max-w-full">
        <div className="flex flex-row gap-4">
          <div className="p-2 rounded-full bg-[#FEF3F2] h-fit">
            <div className="p-2 rounded-full bg-[#FEE4E2]">
              <InformationFilled className="w-[24px] h-[24px] text-[#D92D20]" />
            </div>
          </div>
          <div className="w-full">
            <div className="text-lg font-semibold">
              {type == FolderTrashMenuActions.Restore && "Khôi phục nội dung"}
              {type == FolderTrashMenuActions.Delete && "Xóa vĩnh viễn"}
            </div>
            <div className="mt-2 text-sm text-secondary-600">
              {type == FolderTrashMenuActions.Restore && (
                <p>
                  <span className="font-medium"> {content?.name} </span>sẽ được
                  khôi phục và xuất hiện trong thư viện của bạn.
                </p>
              )}
              {type == FolderTrashMenuActions.Delete && (
                <p>
                  Bạn sẽ không thể khôi phục
                  <span className="font-medium"> {content?.name} </span>trong
                  tương lai.
                </p>
              )}
            </div>
            <div className="space-x-2 flex justify-end pt-4">
              <Button
                variant="outline"
                onClick={onRejectContent}
              >
                Hủy bỏ
              </Button>
              <Button
                variant="secondary"
                onClick={onAcceptContent}
              >
                Xác nhận
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
