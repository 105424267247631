import React, { FC, memo, useEffect, useLayoutEffect, useState } from "react";

import { ArrowLeft, ChevronRight, Close } from "@carbon/icons-react";
import cloneDeep from "lodash.clonedeep";
import { Loader2 } from "lucide-react";
import InfiniteScroll from "react-infinite-scroll-component";

import Divider from "@/components/Divider/Divider";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { PAGE_SIZE_DEFAULT } from "@/constants/app.constants";
import {
  getMyGroups,
  getSubGroup,
  toggleNotification,
} from "@/features/groups/api/group.api";
import { GroupTypeEnum } from "@/features/groups/constants/group.constant";
import { GroupDef, SubGroupDef } from "@/features/groups/types/group.type";
import usePagination from "@/hooks/usePagination";
import { cn } from "@/lib/utils";

import ToggleNotifySubGroup from "../../component/ToggleNotifySubGroup";

type EnableNotificationSettingScreenProps = {
  open: boolean;
  onClose: () => void;
  rootGroups: GroupDef[];
};

const EnableNotificationSettingScreen: FC<
  EnableNotificationSettingScreenProps
> = ({ open, rootGroups, onClose }) => {
  const [isShowSubGroup, setIsShowSubGroup] = useState(false);
  const [groupSelected, setGroupSelected] = useState<GroupDef>();
  const [rootGroupList, setRootGroupList] = useState<GroupDef[]>([]);
  const [subGroups, setSubGroups] = useState<SubGroupDef[]>([]);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const { pagination, setPagination } = usePagination();

  useEffect(() => {
    setRootGroupList(rootGroups);
  }, [rootGroups]);

  const fetchMoreRootGroup = async (page: number) => {
    const result = await getMyGroups({
      pageNumber: page,
      pageSize: PAGE_SIZE_DEFAULT,
      typeFilter: GroupTypeEnum.ROOT,
    });
    setRootGroupList((prev) => [...prev, ...result.data]);
    setPagination({
      current: result.pageIndex,
      size: pagination.size,
      total: result.totalPage,
    });
    if (result.totalPage <= pagination.current) {
      setIsLoadMore(false);
    }
  };
  const onChangeGroupSelected = (group: GroupDef) => {
    setGroupSelected(group);
    setLoading(true);
    setMode(group.isNotified);
    setSubGroups([]);
    getSubGroup(group.groupId)
      .then((res) => {
        setSubGroups(res.data);
      })
      .finally(() => setLoading(false));
  };
  const onChangeMode = () => {
    if (!groupSelected) {
      return;
    }
    setMode(!mode);
    groupSelected.isNotified = !mode;
    setGroupSelected(cloneDeep(groupSelected));
    toggleNotification(groupSelected.groupId, !mode);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
    >
      <DialogContent className="sm:max-w-[576px]">
        <DialogHeader className="relative space-y-0">
          <div className="flex gap-10 items-center">
            {isShowSubGroup && (
              <ArrowLeft
                size={20}
                className="cursor-pointer"
                onClick={() => setIsShowSubGroup(false)}
              />
            )}
            <p className="text-lg font-bold text-secondary-900">
              {isShowSubGroup && !loading && groupSelected
                ? groupSelected.name
                : "Quản lý thông báo tổ chức của bạn"}
            </p>
          </div>
          <p className="text-sm text-secondary-600 mt-2">
            {isShowSubGroup
              ? "Tuỳ chỉnh chọn thông báo bạn muốn xem — và những thông báo bạn không muốn xem từ tổ chức này."
              : "Để cập nhật thông báo về hoạt động mới, hãy bật thông báo đẩy. Bạn có thể tắt chúng bất cứ lúc nào."}
          </p>
          <Close
            onClick={onClose}
            className="absolute -top-2 -right-2 cursor-pointer"
          />
        </DialogHeader>
        {isShowSubGroup && !loading && groupSelected && (
          <div>
            <div className="flex items-center space-x-1">
              <Switch
                id="isNotified"
                checked={mode}
                onCheckedChange={onChangeMode}
              />
              <Label
                htmlFor="isNotified"
                className={cn(
                  "text-sm font-semibold",
                  mode ? "text-primary " : "text-secondary-600"
                )}
              >
                {mode ? "Bật" : "Tắt"}
              </Label>
            </div>
            <Divider
              width="100%"
              height={1}
              className="mt-6"
            />
          </div>
        )}
        <div
          className="max-h-72 overflow-y-auto"
          id="scrollableDiv"
        >
          {isShowSubGroup && !loading ? (
            subGroups.map((subGroup) => {
              if (subGroup.isRootGroup) {
                return null;
              }
              return (
                <ToggleNotifySubGroup
                  key={subGroup.id}
                  subGroup={subGroup}
                  disabled={!mode}
                  onChangeSwitch={() => {
                    const newSubGroups = cloneDeep(subGroups);
                    const sub = newSubGroups.find(
                      (item) => item.id === subGroup.id
                    );
                    if (sub) {
                      sub.isNotified = !sub.isNotified;
                    }
                    setSubGroups(newSubGroups);
                  }}
                />
              );
            })
          ) : (
            <InfiniteScroll
              style={{
                height: "unset",
                overflow: "unset",
              }}
              className="px-0"
              dataLength={rootGroupList.length}
              next={() => {
                fetchMoreRootGroup(pagination.current + 1);
              }}
              hasMore={isLoadMore}
              loader={
                <div className="flex justify-center">
                  <Loader2 className="mr-2 h-6 w-6 text-primary animate-spin" />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              {rootGroupList.map((item) => (
                <div
                  key={item.groupId}
                  className="flex gap-2 items-center p-3 transition-all hover:bg-secondary-50 rounded-md cursor-pointer"
                  onClick={() => {
                    setIsShowSubGroup(true);
                    onChangeGroupSelected(item);
                  }}
                >
                  <TGNAvatar
                    size="40"
                    round="8px"
                  />
                  <div className="flex-1">
                    <p className="text-sm text-secondary-900 font-medium">
                      {item.name}
                    </p>
                    <p className="text-xs text-secondary-600">
                      {item.numberOfSubGroups} Nhóm
                    </p>
                  </div>
                  <ChevronRight />
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(EnableNotificationSettingScreen);
