import React, { FC } from "react";

import { InformationFilled } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

interface ExistNameModalProps {
  open: boolean;
  newName: string | null;
  type: "Upload" | "Restore";
  onSubmit: () => void;
  onCancel: () => void;
}

const ExistNameModal: FC<ExistNameModalProps> = ({
  open,
  newName,
  onSubmit,
  onCancel,
  type = "Upload",
}) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogHeader className="flex flex-row gap-4">
          <div>
            <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full block">
              <InformationFilled size={20} />
            </span>
          </div>
          <div className="flex-1">
            <DialogTitle className="text-lg">
              Tên nội dung đã tồn tại!
            </DialogTitle>
            <DialogDescription className="text-secondary-600 text-sm mt-2">
              Nội dung có tên này đã tồn tại. Hệ thống sẽ{" "}
              {type === "Upload" ? "tải lên" : "khôi phục"} nội dung với tên “
              {newName}”, bạn có thể thực hiện đổi tên sau đó.
              <p className="mt-5">
                Bạn có muốn tiếp tục{" "}
                {type === "Upload" ? "tải lên" : "khôi phục"} nội dung?
              </p>
            </DialogDescription>
          </div>
        </DialogHeader>
        <DialogFooter className="justify-end gap-2">
          <Button
            size="sm"
            variant="text"
            onClick={() => onCancel()}
          >
            Huỷ bỏ
          </Button>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => onSubmit()}
          >
            Xác nhận
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ExistNameModal;
