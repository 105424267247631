import React, { FC, memo } from "react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";

type UpdateEmailSuccessProps = {
  open: boolean;
  email: string;
  onOK: () => void;
};
const UpdateEmailSuccess: FC<UpdateEmailSuccessProps> = ({
  open,
  email,
  onOK,
}) => {
  return (
    <Dialog open={open}>
      <DialogContent
        className="max-w-[364px]"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <p className="text-lg text-secondary-900 font-semibold">{email}</p>
        <p className="mt-1 text-secondary-600 text-xs">
          Địa chỉ email của bạn đã được thay đổi. Bạn cần tiến hành đăng nhập
          lại để đảm bảo chính sách bảo mật trên TAGA.
        </p>
        <div className="mt-10">
          <Button
            variant="secondary"
            className="w-full"
            onClick={onOK}
          >
            Đăng xuất
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(UpdateEmailSuccess);
