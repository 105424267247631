import React, { useState } from "react";

import { Devices, HelpFilled, InformationFilled } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import { useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";

import ChangePassword from "./components/ChangePassword";
import ContactProfile from "./components/ContactProfile";
import PasswordConfirm from "./components/TwoFAConfirm";

const ProfileSetting = () => {
  const user = useAppSelector((state) => state.auth.user);

  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div>
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-start-3 col-end-11">
            <p className="text-lg font-semibold text-secondary-900">
              Tài khoản & Bảo mật
            </p>
            <p className="text-xs text-secondary-600">
              Quản lý thông tin cá nhân của bạn, đồng thời kiểm soát thông tin
              nào người khác xem được và ứng dụng nào có thể truy cập.
            </p>
            <div className="mt-6">
              <p className="text-secondary-600 text-xs font-medium uppercase">
                Thông tin liên hệ
              </p>
              <div
                className={`mt-4 ${
                  selectedTab !== 0 && selectedTab !== 1 ? "opacity-60" : ""
                } p-6 bg-white border border-secondary-200 rounded-lg flex gap-4 ${
                  activeTab === 0 ? "hover:shadow-lg" : ""
                }`}
              >
                <div className="flex-1">
                  <p className="text-secondary-900 text-sm font-medium flex items-center">
                    <span>{user?.email} </span>
                    {user?.phoneNumber && <span>, {user?.phoneNumber} </span>}
                  </p>
                  <p className="text-secondary-600 text-xs mt-1">
                    Thông tin này chúng tôi sẽ sử dụng để xác minh 2 bước ....
                  </p>
                </div>
                <ContactProfile email={user?.email} />
              </div>
            </div>
            <div className="mt-6">
              <p className="text-secondary-600 text-xs font-medium uppercase">
                Xác thực hai yếu tố
              </p>
              <div
                className={`mt-4 p-6 bg-secondary-25 rounded-lg border border-secondary-200 hover:shadow-lg`}
              >
                <div className="flex gap-4">
                  <div>
                    <span className="border-[6px] p-1 text-primary-600 border-primary-50 bg-primary-100 rounded-full block">
                      <InformationFilled size={20} />
                    </span>
                  </div>
                  <div>
                    <p className="text-xs text-secondary-600 mb-1">
                      Bảo vệ tài khoản của bạn bằng cách tăng cường thêm một lớp
                      bảo mật. Bước đăng nhập thứ hai có thể giữ an toàn cho tài
                      khoản của bạn, ngay cả khi mật khẩu của bạn bị xâm phạm.
                    </p>{" "}
                    <p className="text-xs text-secondary-600 mb-2">
                      Khi bạn bật tính năng xác minh hai bước, chúng tôi sẽ duy
                      trì trạng thái đăng nhập của bạn trên thiết bị này. Chúng
                      tôi có thể đăng xuất bạn khỏi các thiết bị khác. Để đăng
                      nhập lại, hãy nhập mật khẩu và hoàn tất bước xác thực thứ
                      hai.
                    </p>
                    <p className="text-sm text-secondary-600 mb-2">
                      Trạng thái:{" "}
                      <span className="font-medium text-secondary-900">
                        Tắt
                      </span>
                    </p>
                    <div className="flex items-center gap-3">
                      <PasswordConfirm />
                      <p className="text-xs text-secondary-600 font-medium flex">
                        <HelpFilled className="mr-1" /> Tìm hiểu thêm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <p className="text-secondary-600 text-xs font-medium uppercase">
                mật khẩu
              </p>
              <p className="text-xs text-secondary-600 mb-2 mt-1">
                Thay đổi mật khẩu của bạn hoặc khôi phục mật khẩu hiện tại của
                bạn.
              </p>
              <div
                className={`mt-4 ${
                  selectedTab !== 0 && selectedTab !== 1 ? "opacity-60" : ""
                } p-6 bg-white border border-secondary-200 rounded-lg flex gap-4 ${
                  activeTab === 0 ? "hover:shadow-lg" : ""
                }`}
              >
                <div className="flex-1">
                  <p className="text-secondary-600 text-xs mt-1">
                    Khi bạn đổi mật khẩu, chúng tôi sẽ vẫn duy trì trạng thái
                    đăng nhập của bạn trên thiết bị này nhưng đăng xuất bạn khỏi
                    các thiết bị khác.
                  </p>
                </div>

                <ChangePassword />
              </div>
            </div>
            <div className="mt-6">
              <p className="text-secondary-600 text-xs font-medium uppercase">
                Quản lý truy cập
              </p>
              <p className="text-xs text-secondary-600 mb-2 mt-1">
                Vì mục đích bảo mật, Taga cung cấp danh sách các phiên gần đây.
              </p>
              <div
                className={`mt-4 ${
                  selectedTab !== 0 && selectedTab !== 1 ? "opacity-60" : ""
                } p-6 bg-white border border-secondary-200 rounded-lg flex gap-4 ${
                  activeTab === 0 ? "hover:shadow-lg" : ""
                }`}
              >
                <div className="h-16 justify-between items-center flex">
                  <div className="justify-start items-center gap-3 flex">
                    <div className="w-16 h-16 bg-secondary-50 rounded-full flex-col justify-center items-center inline-flex">
                      <Devices
                        className="text-secondary-600 "
                        size={24}
                      />
                    </div>
                    <div className="self-stretch flex-col justify-center items-start gap-2 inline-flex">
                      <div className="flex-col justify-start items-start gap-0.5 flex">
                        <div className="justify-start items-start gap-1 inline-flex">
                          <div className="text-secondary-900 text-sm font-medium">
                            Thiết bị không xác định
                          </div>
                        </div>
                        <div className="text-secondary-400 text-xs font-normal">
                          ID: {user?.name}
                        </div>
                      </div>
                      <div className="h-4 justify-start items-start gap-1 inline-flex">
                        <div className="text-secondary-600 text-xs ">
                          Hoạt động
                        </div>
                        <div className="text-secondary-600 text-xs ">
                          {formatDistance(user?.id || new Date())}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetting;
