import React, { FC, ReactNode } from "react";

import Header from "./Nav/Header";

type InsideLayoutProps = {
  children: ReactNode;
};

const InsideLayout: FC<InsideLayoutProps> = ({ children }) => {
  return (
    <main className=" min-h-screen">
      <Header />
      {children}
    </main>
  );
};

export default InsideLayout;
