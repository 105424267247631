export const getWebSocketURL = ({
  webkey,
  fingerprint,
  token,
}: {
  webkey: string;
  fingerprint: string;
  token: string;
}) => {
  return `wss://dev.taganow.vn/library/webapp-websocket?webkey=${webkey}&fingerprint=${fingerprint}${
    token ? "&token=" + token : ""
  }`;
};
