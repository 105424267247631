import React, { useEffect, useState } from "react";

import {
  Add,
  CheckmarkFilled,
  ChevronDown,
  ChevronUp,
  Close,
  DataStructured,
  Edit,
  Image,
  Information,
  InformationFilled,
  Locked,
  Logout,
  PauseOutlineFilled,
  SettingsView,
  Time,
  UserAvatar,
  Wikis,
} from "@carbon/icons-react";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { unwrapResult } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

import { HomeIcon, ProfileIcon, MessageIcon } from "@/assets/icons";
import GroupDetailCover from "@/assets/images/Group Detail Cover.png";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Progress } from "@/components/ui/progress";
import { useToast } from "@/components/ui/use-toast";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDate, formatViDate } from "@/utils/date";
import { extractUsername } from "@/utils/extractUsername";
import { getMessage } from "@/utils/message";

import {
  uploadGroupPhoto,
  generatePresignedUrl,
  updateGroup,
  cancelStatusLeaveRequest,
} from "../../api/group.api";
import CreateGroupModal from "../../components/CreateGroupModal/CreateGroupModal";
import GroupHeader from "../../components/GroupHeader/GroupHeader";
import GroupPermission from "../../components/GroupPermission";
import InviteModal from "../../components/UserInviteModal/InviteModal";
import {
  GroupUserRequestStatusEnum,
  GroupUserRequestTypeEnum,
  GroupUserRoleEnums,
  GroupUserTypeEnum,
} from "../../constants/group.constant";
import { GroupPathsEnum } from "../../constants/group.paths";
import { getGroupInfo } from "../../group";
import CardInvites from "../GroupInvitesScreen/component/CardInvites";

const GroupDetailScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const [isOpenCollapsible, setIsOpenCollapsible] = React.useState(true);
  const [firstFalseIndex, setFirstFalseIndex] = useState(1);
  const [isSuccess, setIsSuccess] = useState(false);
  const [openConfirmCancelLeave, setOpenConfirmCancelLeave] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const res = urlParams.get("res");

  useEffect(() => {
    if (res) {
      setIsSuccess(true);
    }
    urlParams.delete("res");
    navigate(`${window.location.pathname} `);
  }, [res]);

  useEffect(() => {
    if (groupInfo && groupInfo.initSetupFlag) {
      const values = Object.values(groupInfo.initSetupFlag);

      setFirstFalseIndex(values.filter((value) => value === true).length + 1);
    }
  }, [groupInfo]);

  const [loading, setLoading] = useState(false);
  const { id = "" } = useParams();
  const [urlImage, setUrlImage] = useState<string | null>(null);
  const [fileUpload, setFileUpload] = useState<File | null>(null);

  const onFilesChange = (file: File) => {
    setFileUpload(file);

    if (file) {
      const url = URL.createObjectURL(file);
      setUrlImage(url);
    }
  };

  const handleUploadImage = async () => {
    try {
      if (!groupInfo) {
        return;
      }
      setLoading(true);
      if (fileUpload && groupInfo) {
        const url = await generatePresignedUrl(
          fileUpload.name,
          fileUpload.size,
          groupInfo.id
        );
        await uploadGroupPhoto(
          fileUpload,
          url.data.url,
          url.data.type,
          url.data.len
        );
        if (groupInfo) {
          await updateGroup({
            groupId: groupInfo.id,
            name: groupInfo.name,
            description: groupInfo.description,
            photoId: url.data.id,
            mode: groupInfo.mode,
          });
        }
      }
      toast({
        title: "Thành công",
        variant: "success",
        description: getMessage("MSG4"),
      });
      dispatch(
        getGroupInfo({
          group_id: groupInfo.id,
        })
      )
        .then(unwrapResult)
        .catch(() => {
          navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
        });
    } catch (error: any) {
      toast({
        title: "Thất bại",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    } finally {
      setUrlImage(null);
      setLoading(false);
    }
  };
  const handleCancelLeaveRequest = async () => {
    dispatch(
      loadingAction.show({
        title: "Đang xử lý...",
        description: "Vui lòng chờ tới khi xử lý hoàn tất.",
      })
    );
    try {
      if (groupInfo) {
        await cancelStatusLeaveRequest(
          groupInfo.id,
          groupInfo.rootGroupId ?? groupInfo.id
        );
        setOpenConfirmCancelLeave(false);
        dispatch(
          getGroupInfo({
            group_id: groupInfo.id,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
          });
        toast({
          title: "Thành công!",
          variant: "success",
          description: getMessage(
            "MSG85",
            groupInfo.rootGroupId ? "nhóm." : "tổ chức."
          ),
        });
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    } finally {
      dispatch(loadingAction.hide());
    }
  };
  const checkRole = (type: string) => {
    switch (type) {
      case "OWNER":
        return "orange";
      case "ADMIN":
        return "default";
      default:
        return "default";
    }
  };
  return (
    <div className="h-100">
      {/* <AuthenticateDeviceModal
        show={showAuthenticateDeviceModal}
        uuid={uuid}
        onClose={() => setShowAuthenticateDeviceModal(false)}
        onSuccess={() =>
          setDeviceGroupRequestStatus(DeviceGroupRequestStatusEnums.PENDING)
        }
      /> */}
      {isSuccess && (
        <Dialog
          open={isSuccess}
          onOpenChange={setIsSuccess}
        >
          <DialogContent className="max-w-[464px]">
            <DialogHeader>
              <span className="flex items-center justify-center border-[6px] p-1 text-success-600 border-success-50 bg-success-100 rounded-full mx-auto">
                <CheckmarkFilled
                  size={20}
                  className="text-success-500"
                />
              </span>
              <DialogTitle className="text-center text-lg">
                Khôi phục Tổ chức thành công!
              </DialogTitle>
              <DialogDescription className="text-center text-secondary-600">
                Đã khôi phục Tổ chức{" "}
                <span className="font-semibold"> "{groupInfo?.name}"</span>{" "}
                thành công. Bạn đã có thể xem và thực hiện cập nhật Cơ cấu tổ
                chức của mình.
              </DialogDescription>
            </DialogHeader>
            <div className="mx-auto">
              <Link
                className="px-2 py-3 text-sm font-medium text-primary-600 flex items-center"
                to={""}
              >
                <DataStructured className="-scale-x-100 mr-1" />
                Cơ cấu tổ chức
              </Link>
            </div>
            <DialogFooter className="sm:justify-center">
              <Button
                variant="text"
                onClick={() => {
                  setIsSuccess(false);
                }}
              >
                Để sau
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
      {groupInfo && (
        <>
          <div className="bg-white">
            <div className="relative container mb-6 h-[265px] group">
              {urlImage ? (
                <img
                  className="object-cover h-[265px] w-full"
                  src={urlImage}
                  alt=""
                />
              ) : (
                <img
                  className="object-cover h-[265px] w-full"
                  src={
                    groupInfo.photoUrl ? groupInfo.photoUrl : GroupDetailCover
                  }
                  alt=""
                />
              )}
              {groupInfo.manager && (
                <div className="absolute bottom-4 right-8">
                  {urlImage ? (
                    <div className="flex gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setUrlImage(null)}
                        loading={loading}
                      >
                        Huỷ bỏ
                      </Button>
                      <Button
                        size="sm"
                        onClick={handleUploadImage}
                        loading={loading}
                      >
                        Lưu thay đổi
                      </Button>
                    </div>
                  ) : (
                    <Label
                      htmlFor="cover"
                      className="bg-secondary hidden group-hover:flex text-secondary-foreground hover:bg-secondary-700 hover:shadow-sm focus:shadow-active disabled:bg-secondary-300 disabled:opacity-100 font-medium h-[36px] px-3 py-[7px] text-sm rounded items-center cursor-pointer"
                    >
                      <Edit className="mr-1" />
                      Chỉnh sửa
                    </Label>
                  )}
                  <Input
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    type="file"
                    id="cover"
                    name="cover"
                    onChange={(e) => {
                      const fileList = e.target.files;
                      if (fileList) {
                        const file = fileList[0];
                        onFilesChange(file);
                      }
                    }}
                    hidden
                    className="hidden"
                  />
                </div>
              )}
            </div>
            <GroupHeader />
          </div>
          <div>
            <div className="bg-secondary-50 py-4">
              {(groupInfo.userGroupRelation?.groupUserType ===
                GroupUserTypeEnum.INVITE ||
                groupInfo.userGroupRelation?.groupUserType ===
                  GroupUserTypeEnum.INVITE_EMAIL) && <CardInvites />}
              {groupInfo.groupLock && (
                <div className="container">
                  <div className="p-6 bg-white rounded border border-secondary-200 mb-4">
                    <div className="flex ">
                      <span className="inline-block p-2  bg-secondary-100 rounded-full ">
                        <PauseOutlineFilled
                          size={24}
                          className="text-secondary-600"
                        />
                      </span>
                      <div className="inline-block ml-4">
                        <p className="text-secondary-900 font-medium text-sm">
                          {groupInfo.rootGroupId == groupInfo.id ||
                          !groupInfo.rootGroupId
                            ? "Tổ chức"
                            : "Nhóm"}{" "}
                          này đang tạm thời ở trạng thái cô lập
                        </p>
                        <p className="text-secondary-600 text-xs flex">
                          Bạn đã thực hiện cô lập{" "}
                          {groupInfo.rootGroupId == groupInfo.id ||
                          !groupInfo.rootGroupId
                            ? "Tổ chức"
                            : "Nhóm"}
                          . Vui lòng truy cập
                          <Link
                            className="text-secondary-900 me-1 ml-1 flex items-center font-medium"
                            to={GroupPathsEnum.GROUP_SETTING.replace(
                              ":id",
                              String(groupInfo.id)
                            )}
                          >
                            <SettingsView />
                            Thiết lập nâng cao
                          </Link>
                          để thay đổi.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {groupInfo.initSetupFlag &&
                Object.values(groupInfo.initSetupFlag).some(
                  (item) => item !== true
                ) && (
                  <div className="container">
                    <div className="p-6 bg-white rounded border border-secondary-200">
                      <Collapsible
                        open={isOpenCollapsible}
                        onOpenChange={setIsOpenCollapsible}
                      >
                        <div className="flex justify-between items-center">
                          <p className="text-secondary-900 font-semibold">
                            Tiếp tục thiết lập{" "}
                            {groupInfo.rootGroupId == groupInfo.id ||
                            !groupInfo.rootGroupId
                              ? "Tổ chức"
                              : "Nhóm"}
                          </p>
                          <CollapsibleTrigger>
                            <div className="flex items-center text-secondary-600 text-sm font-medium">
                              {isOpenCollapsible ? (
                                <>
                                  <ChevronUp className="mr-2" />
                                  <span>Ẩn gợi ý</span>
                                </>
                              ) : (
                                <>
                                  <ChevronDown className="mr-2" />
                                  <span>Hiện gợi ý</span>
                                </>
                              )}
                            </div>
                          </CollapsibleTrigger>
                        </div>
                        <CollapsibleContent className="mt-4">
                          <p className="text-secondary-600 text-sm leading-5">
                            Tùy chỉnh, cập nhật thông tin để làm nổi bật{" "}
                            {groupInfo.rootGroupId == groupInfo.id ||
                            !groupInfo.rootGroupId
                              ? "Tổ chức "
                              : "Nhóm "}
                            của bạn và dễ dàng được nhiều người nhìn thấy hơn.
                          </p>
                          <div className="flex items-center mt-2">
                            <Progress
                              value={(100 / 5) * firstFalseIndex}
                              className="flex-1 mr-3 "
                            />
                            <span className="text-xs text-secondary-900 leading-4">
                              {firstFalseIndex}/5
                            </span>
                          </div>
                          <div className="flex gap-3 mt-6 flex-wrap">
                            {!groupInfo.initSetupFlag.haveSubGroup && (
                              <CreateGroupModal
                                rootGroup={groupInfo}
                                rootMode={groupInfo.mode}
                                rootId={groupInfo.id}
                                haveSubGroup={
                                  groupInfo.initSetupFlag.haveSubGroup
                                }
                                button={
                                  <Button
                                    className="pl-4 "
                                    variant={"ghost"}
                                    size="sm"
                                    iconBefore={<Add />}
                                  >
                                    Tạo Nhóm{" "}
                                    {groupInfo.rootGroupId == groupInfo.id ||
                                    !groupInfo.rootGroupId
                                      ? "trong Tổ chức"
                                      : "con"}
                                  </Button>
                                }
                              />
                            )}
                            {!groupInfo.initSetupFlag.haveMember && (
                              <Dialog>
                                <DialogTrigger>
                                  <Button
                                    className="pl-4"
                                    variant={"ghost"}
                                    size="sm"
                                    iconBefore={<Add />}
                                  >
                                    Thêm thành viên
                                  </Button>
                                </DialogTrigger>
                                <DialogContent className="px-3 py-6">
                                  <DialogHeader className="relative">
                                    <DialogClose asChild>
                                      <Button
                                        variant="ghost"
                                        size="sm"
                                        className="absolute top-0 right-3 rounded-full w-[36px]"
                                      >
                                        <Close />
                                      </Button>
                                    </DialogClose>
                                    <DialogTitle className="text-center max-w-[460PX] text-lg mx-auto">
                                      Mời người dùng tham gia
                                      {groupInfo.rootGroupId
                                        ? " Nhóm"
                                        : " Tổ chức"}
                                    </DialogTitle>
                                  </DialogHeader>
                                  <InviteModal inviteType="friend">
                                    <div className="flex p-3 rounded gap-3 items-center cursor-pointer hover:bg-secondary-50">
                                      <div>
                                        <ProfileIcon className="w-[64px] h-[64px]" />
                                      </div>
                                      <div>
                                        <p className="text-sm text-secondary-900 font-semibold mb-1">
                                          Mời bạn bè
                                        </p>
                                        <p className="text-xs text-secondary-600">
                                          Gửi lời mời tham gia Tổ chức tới bạn
                                          bè của bạn trên TAGA.
                                        </p>
                                      </div>
                                    </div>
                                  </InviteModal>
                                  <Link
                                    to={GroupPathsEnum.INVITE_EMAIL.replace(
                                      ":id",
                                      String(groupInfo.id)
                                    )}
                                  >
                                    <div className="flex p-3 rounded gap-3 items-center cursor-pointer hover:bg-secondary-50">
                                      <div>
                                        <MessageIcon className="w-[64px] h-[64px]" />
                                      </div>
                                      <div>
                                        <p className="text-sm text-secondary-900 font-semibold mb-1">
                                          Mời tham gia qua Email
                                        </p>
                                        <p className="text-xs text-secondary-600">
                                          Bạn có thể gửi lời mời tới bạn bè,
                                          đồng nghiệp, những mỗi quan hệ tham
                                          gia{" "}
                                          {groupInfo.rootGroupId
                                            ? "Nhóm"
                                            : "Tổ chức"}{" "}
                                          của mình thông qua email liên lạc.
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                  {groupInfo.rootGroupId && (
                                    <InviteModal inviteType="root">
                                      <div className="flex p-3 rounded gap-3 items-center cursor-pointer">
                                        <div>
                                          <HomeIcon className="w-[64px] h-[64px]" />
                                        </div>
                                        <div>
                                          <p className="text-sm text-secondary-900 font-semibold mb-1">
                                            Mời thành viên trong Tổ chức
                                          </p>
                                          <p className="text-xs text-secondary-600">
                                            Gửi lời mời tham gia Nhóm của bạn
                                            tới các thành viên trong Tổ chức.
                                          </p>
                                        </div>
                                      </div>
                                    </InviteModal>
                                  )}
                                </DialogContent>
                              </Dialog>
                            )}
                            {!groupInfo.initSetupFlag.havePhoto && (
                              <Label
                                htmlFor="cover"
                                className="inline-flex items-center rounded cursor-pointer h-[36px] px-3 py-[7px] text-sm bg-secondary-50 text-secondary-600 hover:bg-secondary-100 hover:text-secondary-900 hover:shadow-sm focus:shadow-active focus:bg-secondary-50 focus:text-secondary-600 disabled:text-secondary-300 disabled:bg-secondary-50 disabled:opacity-100 font-medium"
                              >
                                <Image className="-ml-1 mr-1" />
                                Cập nhật ảnh bìa
                              </Label>
                            )}
                            {!groupInfo.initSetupFlag.haveMoreInfo && (
                              <Button
                                className="pl-4"
                                variant={"ghost"}
                                size="sm"
                                onClick={() =>
                                  navigate(
                                    GroupPathsEnum.GROUP_SETTING.replace(
                                      ":id",
                                      String(groupInfo.id)
                                    )
                                  )
                                }
                                iconBefore={<Information />}
                              >
                                Cập nhật thông tin bổ sung
                              </Button>
                            )}
                          </div>
                        </CollapsibleContent>
                      </Collapsible>
                    </div>
                  </div>
                )}
              {groupInfo.userRequest?.type ===
                GroupUserRequestTypeEnum.LEAVE_REQUEST &&
                groupInfo.userRequest?.status ===
                  GroupUserRequestStatusEnum.WAITING && (
                  <GroupPermission
                    codes={[
                      GroupUserRoleEnums.MEMBER,
                      GroupUserRoleEnums.ADMIN,
                    ]}
                    isVerifyDevice={true}
                  >
                    <div className="container mt-4">
                      <div className="p-6 bg-white rounded border border-secondary-200">
                        <div className="flex justify-between">
                          <div className="flex gap-2">
                            <span className="p-2 w-10 h-10 rounded-full bg-secondary-50">
                              <Logout size="24" />
                            </span>
                            <div>
                              <p className="mb-1 text-sm">
                                Yêu cầu rời{" "}
                                {groupInfo.rootGroupId ? "nhóm" : "tổ chức"} của
                                bạn đang chờ xử lý
                              </p>
                              <p className="text-xs text-secondary-600">
                                Bạn đã yêu cầu rời tổ chức vào lúc{" "}
                                {formatViDate(groupInfo.userRequest?.createdAt)}
                              </p>
                            </div>
                          </div>
                          <Button
                            variant="outline"
                            onClick={() => setOpenConfirmCancelLeave(true)}
                          >
                            Huỷ yêu cầu
                          </Button>
                        </div>
                      </div>

                      <Dialog open={openConfirmCancelLeave}>
                        <DialogContent>
                          <DialogHeader className="flex flex-row gap-4">
                            <div>
                              <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full block">
                                <InformationFilled size={20} />
                              </span>
                            </div>
                            <div className="flex-1">
                              <DialogTitle className="text-lg">
                                Huỷ rời khỏi{" "}
                                {groupInfo?.rootGroupId ? "Nhóm" : "Tổ chức"}
                              </DialogTitle>
                              <DialogDescription className="text-secondary-600 mt-2">
                                <p className="mt-5">
                                  Bạn chắc chắn gỡ bỏ yêu cầu rời khỏi{" "}
                                  {groupInfo?.rootGroupId ? "Nhóm" : "Tổ chức"}{" "}
                                  <span className="font-semibold">
                                    {groupInfo.name}
                                  </span>
                                  ?
                                </p>
                              </DialogDescription>
                            </div>
                          </DialogHeader>
                          <DialogFooter className="justify-end gap-2">
                            <Button
                              size="sm"
                              variant="text"
                              onClick={() => setOpenConfirmCancelLeave(false)}
                            >
                              Huỷ bỏ
                            </Button>
                            <Button
                              size="sm"
                              variant="secondary"
                              onClick={handleCancelLeaveRequest}
                            >
                              Xác nhận
                            </Button>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>
                    </div>
                  </GroupPermission>
                )}
              <div className="container mt-4">
                <div className="p-6 bg-white rounded border border-secondary-200">
                  <p className="text-secondary-900 font-semibold">Giới thiệu</p>
                  {groupInfo.description && (
                    <p className="mt-4 text-sm leading-5">
                      {groupInfo.description}
                    </p>
                  )}
                  <div className="mt-4">
                    <div className="flex gap-2">
                      <div className="w-[40px] h-[40px] p-3 rounded-full bg-secondary-50 text-secondary-600">
                        {groupInfo.mode ? <Locked /> : <Wikis />}
                      </div>
                      <div>
                        <p className="text-sm font-medium leading-5 text-secondary-900 mb-1">
                          {groupInfo.mode ? "Riêng tư" : "Công khai"}
                        </p>
                        <p className="text-xs text-secondary-600 leading-4">
                          {groupInfo.mode
                            ? "Chỉ thành viên nhóm mới có thể tìm thấy nhóm"
                            : "Bất kì ai cũng có thể tìm thấy nhóm"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="flex gap-2">
                      <div className="w-[40px] h-[40px] p-3 rounded-full bg-secondary-50 text-secondary-600">
                        <Time />
                      </div>
                      <div>
                        <p className="text-sm font-medium leading-5 text-secondary-900 mb-1">
                          {groupInfo.createdTime &&
                            formatViDate(groupInfo.createdTime)}
                        </p>
                        <p className="text-xs text-secondary-600 leading-4">
                          Ngày tạo nhóm
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container grid grid-flow-col grid-cols-2 gap-4 mt-4">
                <div className="p-6 bg-white rounded border border-secondary-200 col-span-1">
                  <p className="font-semibold text-secondary-900 mb-2">
                    Thông tin bổ sung
                  </p>
                  {groupInfo.attributes && groupInfo.attributes.length > 0 ? (
                    groupInfo.attributes.map((attribute, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-wrap gap-2 py-3"
                        >
                          <p className="text-sm leading-5 text-secondary-600 w-[calc(36%-4px)]">
                            {attribute.label}
                          </p>
                          <p className="text-sm leading-5 text-secondary-900 font-medium w-[calc(64%-4px)]">
                            {attribute.value}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <div className="flex items-center justify-center h-[calc(100%-32px)]">
                      <p className="text-xs leading-4 text-secondary-600">
                        Tổ chức chưa có thông tin nào
                      </p>
                    </div>
                  )}
                </div>
                <div className="grid gap-4">
                  <div className="p-6 bg-white rounded border border-secondary-200 col-span-1">
                    <div className="flex items-start justify-between">
                      <p className="font-semibold text-secondary-900">
                        Thành viên{" "}
                        <span className="text-secondary-400">・</span>
                        <span className="text-secondary-600 font-normal">
                          {groupInfo.memberCounter}
                        </span>
                      </p>
                      <Link
                        className="text-sm font-medium text-primary-600 leading-5"
                        to={GroupPathsEnum.GROUP_DEPARTMENT_USER.replace(
                          /:id/,
                          id
                        )}
                      >
                        Xem thêm
                      </Link>
                    </div>
                    <p className="text-xs text-secondary-600 mt-1">
                      Quản trị viên và Thành viên mới tham gia nhóm này sẽ hiển
                      thị tại đây.
                    </p>
                    <div className="mt-4">
                      <p className="font-semibold text-secondary-900 text-xs">
                        Quản trị viên
                        <span className="text-secondary-400">・</span>
                        <span className="text-secondary-600 font-normal">
                          {groupInfo.moderatorInfos?.length}
                        </span>
                      </p>
                      <div className="mt-2 grid gap-2">
                        {groupInfo.moderatorInfos?.map((moderatorInfo) => {
                          return (
                            <div
                              key={moderatorInfo.id}
                              className="flex items-center gap-2"
                            >
                              <TGNAvatar
                                size="40"
                                src={moderatorInfo.photoUrl}
                                userName={
                                  moderatorInfo.name ?? moderatorInfo.userId
                                }
                              />
                              <div className="">
                                <div className="flex items-center gap-2 text-sm font-medium">
                                  {moderatorInfo.name ??
                                    extractUsername(moderatorInfo.userId || "")}

                                  <Badge
                                    variant={checkRole(moderatorInfo.role.code)}
                                    className="font-medium text-[10px] leading-[14px]"
                                  >
                                    <UserAvatar
                                      className="mr-1"
                                      size={12}
                                    />
                                    {moderatorInfo.role.label}
                                  </Badge>
                                </div>
                                <p className="text-secondary-600 text-xs">
                                  {moderatorInfo.userId ??
                                    moderatorInfo.email ??
                                    moderatorInfo.phone}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="p-6 bg-white rounded border border-secondary-200 col-span-1">
                    <div className="flex items-start justify-between">
                      <p className="font-semibold text-secondary-900">
                        Cơ cấu tổ chức{" "}
                        <span className="text-secondary-400">・</span>
                        <span className="text-secondary-600 font-normal">
                          3
                        </span>
                      </p>
                      <Link
                        className="text-sm font-medium text-primary-600 leading-5"
                        to={"/"}
                      >
                        Xem thêm
                      </Link>
                    </div>
                    <p className="text-xs text-secondary-600 mt-1">
                      Các nhóm trực thuộc tổ chức này sẽ hiển thị tại đây.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupDetailScreen;
