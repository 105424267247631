import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import { getTokenFromStorage } from "@/features/auth/api/auth";
import { authAction } from "@/features/auth/redux/auth.slice";
import { useAppSelector } from "@/redux/store";

import Default from "./components/layout/Default";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import Auth from "./features/auth/screens/Auth";
import useWebSocket from "./hooks/useWebSocket";
import { ThemeProvider } from "./styles/themes/theme-provider";
import "./styles/global-styles.scss";
import "./App.css";

function App() {
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const token = getTokenFromStorage();
  useEffect(() => {
    dispatch(authAction.loginSuccess(isAuth()));
  }, [dispatch]);
  useWebSocket();
  return (
    <ThemeProvider
      defaultTheme="light"
      storageKey="ui-theme"
    >
      {user || token ? (
        <Default />
      ) : (
        <div className="app">
          <Auth />
        </div>
      )}
    </ThemeProvider>
  );
}

function isAuth() {
  const authInfo = getTokenFromStorage();
  try {
    if (authInfo) {
      return true;
    }
    return false;
  } catch (_) {
    return false;
  }
}

export default App;
