import React, { FC, ReactNode, memo, useState } from "react";

import { ArrowUpRight, Close, InformationFilled } from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import {
  getGroupInvitationForUser,
  getUserRequests,
  rejectGroupInvitation,
} from "@/features/groups/api/group.api";
import { GroupUserStatusEnum } from "@/features/groups/constants/group.constant";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import {
  getGroupInfo,
  getGroupInvitationForUserThunk,
} from "@/features/groups/group";
import {
  IGroupInvitation,
  IGroupPersonalRequest,
} from "@/features/groups/types/group.type";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch } from "@/redux/store";
import { COMMON_MESSAGES, getCustomMessage } from "@/utils/message";

import CarouselInvite from "./CarouselInvite";
import ListView from "./ListView";
type RejectOrgModalProps = {
  button: ReactNode;
  isDetail?: boolean;
  groupId: number;
  inviteId: number;
  groupName: string;
  rootGroupId?: number;
  groupLock: boolean;
};

const RejectOrgModal: FC<RejectOrgModalProps> = ({
  groupName,
  inviteId,
  rootGroupId,
  groupId,
  button,
  isDetail,
  groupLock,
}) => {
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [openOnboarding, setOpenOnboarding] = useState(false);
  const [onboardingBtn, setOnboardingBtn] = useState(true);
  const [invites, setInvites] = useState<
    (IGroupInvitation | IGroupPersonalRequest)[]
  >([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const rejectFn = async (inviteId: number) => {
    if (groupLock) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG80,
      });
    } else {
      dispatch(
        loadingAction.show({
          title: "Đang xử lý...",
          description: "Vui lòng chờ tới khi xử lý hoàn tất.",
        })
      );
      try {
        await rejectGroupInvitation(false, inviteId);
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: getCustomMessage("MSG66", String(groupName)),
              }}
            ></div>
          ),
        });
        if (isDetail) {
          dispatch(
            getGroupInfo({
              group_id: groupId,
            })
          )
            .then(unwrapResult)
            .catch(() => {
              navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
            });
        } else {
          dispatch(
            getGroupInvitationForUserThunk({
              pageNumber: 1,
              pageSize: 9,
            })
          );
        }
        setOpenModal(false);
        setOpenConfirmModal(false);
      } catch (error: any) {
        dispatch(loadingAction.hide());

        toast({
          title: "Thất bại!",
          variant: "error",
          description: COMMON_MESSAGES.MSG12,
        });
      } finally {
        dispatch(loadingAction.hide());
      }
    }
  };

  const handleReject = async (inviteId: number) => {
    if (groupLock) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG80,
      });
    } else {
      dispatch(
        loadingAction.show({
          title: "Đang xử lý...",
          description: "Vui lòng chờ tới khi xử lý hoàn tất.",
        })
      );
      try {
        if (inviteId) {
          const childRequest = await getUserRequests({
            rootGroupId: rootGroupId,
            pageNumber: 1,
            pageSize: 10,
          });
          const childRequestAvailable = childRequest.data.filter(
            (item) => item.groupId !== rootGroupId
          );
          const childInvites = await getGroupInvitationForUser({
            rootGroupId: rootGroupId,
            pageNumber: 1,
            pageSize: 10,
          });
          const childInviteAvailable = childInvites.data.filter(
            (item) =>
              item.groupId !== groupId &&
              item.status !== GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
          );
          if (
            (childInviteAvailable.length > 0 ||
              childRequestAvailable.length > 0) &&
            rootGroupId === groupId
          ) {
            setOpenModal(true);
            setInvites([...childInviteAvailable, ...childRequestAvailable]);
          } else {
            setOpenConfirmModal(true);
          }
          dispatch(
            getGroupInvitationForUserThunk({
              pageNumber: 1,
              pageSize: 9,
            })
          );
        }
      } catch {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: COMMON_MESSAGES.MSG12,
        });
      } finally {
        dispatch(loadingAction.hide());
      }
    }
  };
  return (
    <>
      <div
        className="w-full"
        onClick={() => {
          handleReject(inviteId);
        }}
      >
        {button}
      </div>
      <Dialog open={openModal}>
        <DialogContent className={"sm:max-w-[600px] bg-white"}>
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-0 right-0 rounded-full w-[36px]"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <Close />
            </Button>
            <DialogTitle className="text-center max-w-[460PX] text-lg mx-auto">
              Lời mời tham gia Tổ chức
            </DialogTitle>
          </DialogHeader>
          <Formik
            initialValues={{
              name: "",
              description: "",
            }}
            onSubmit={(values: any) => {
              return;
            }}
          >
            {({ setFieldValue, values }) => (
              <Form className="grid gap-4">
                <div className="flex gap-2  grid-cols-2 bg-primary-25 p-4 rounded-md">
                  <div>
                    <span className="block border-[6px] p-1 text-primary-600 border-primary-50 bg-primary-100 rounded-full">
                      <InformationFilled size={24} />
                    </span>
                  </div>
                  <span className="w-[calc(100%_-_48px)] text-secondary-900 text-sm">
                    Bạn có một số lời mời/yêu cầu liên quan tới các nhóm thuộc
                    tổ chức này. Việc thực hiện từ chối vào tổ chức đồng nghĩa
                    với việc bạn không thể trở thành thành viên của các nhóm
                    thuộc tổ chức này.
                    <span className="font-medium inline-flex items-center">
                      Tìm hiểu thêm <ArrowUpRight size={14} />
                    </span>
                  </span>
                </div>
                {rootGroupId && (
                  <ListView
                    rootGroupId={rootGroupId}
                    invites={invites}
                  />
                )}
                <label
                  htmlFor="rejectAll"
                  className="items-center flex space-x-2 "
                >
                  <Checkbox
                    name="rejectAll"
                    id="rejectAll"
                    onCheckedChange={(value) => {
                      setFieldValue("rejectAll", value);
                    }}
                  />

                  <p className="text-secondary-600 text-xs">
                    Từ chối lời mời/hoàn tác yêu cầu tham gia các nhóm trong tổ
                    chức này
                  </p>
                </label>
                <DialogFooter className="justify-center sm:justify-center">
                  <Button
                    disabled={!values.rejectAll}
                    onClick={() => {
                      rejectFn(inviteId);
                    }}
                  >
                    Xác nhận
                  </Button>
                </DialogFooter>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openOnboarding}
        onOpenChange={setOpenOnboarding}
      >
        <DialogContent className="sm:max-w-[552px] bg-white gap-6 text-center">
          <DialogHeader>
            <DialogTitle className="text-center flex justify-between text-lg gap-2">
              <div className="w-[36px]"></div>
              <div>Lời mời tham gia Tổ chức</div>
              <Button
                variant="ghost"
                size="sm"
                className="rounded-full w-[36px]"
                onClick={() => {
                  setOpenOnboarding(false);
                }}
              >
                <Close />
              </Button>
            </DialogTitle>
          </DialogHeader>
          <CarouselInvite active={() => setOnboardingBtn(false)} />
          <div className="flex justify-center">
            <Button
              className="inline-flex"
              variant={"primary"}
              disabled={onboardingBtn}
              onClick={() => {
                setOpenModal(true);
                setOpenOnboarding(false);
              }}
            >
              Tiếp tục
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openConfirmModal}>
        <DialogContent className="max-w-[600px]">
          <DialogHeader>
            <div className="flex gap-4 grid-cols-2">
              <div>
                <span className="block border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full">
                  <InformationFilled size={24} />
                </span>
              </div>
              <span className="w-[calc(100%_-_48px)]  text-sm">
                <p className="text-secondary-900 text-lg font-semibold mb-2">
                  Từ chối lời mời
                </p>
                <span className="font-normal text-secondary-600 leading-tight">
                  Bạn chắc chắn muốn từ chối tham gia{" "}
                  {rootGroupId !== groupId ? "Nhóm" : "Tổ chức"}
                  <span className="font-semibold"> {groupName}</span>?
                </span>
              </span>
            </div>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant={"outline"}
              type="button"
              onClick={() => {
                setOpenConfirmModal(false);
              }}
              size="sm"
            >
              Hủy bỏ
            </Button>
            <Button
              onClick={() => {
                rejectFn(inviteId);
              }}
              variant="secondary"
              size="sm"
            >
              Xác nhận
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(RejectOrgModal);
