import React, { memo, RefObject, useRef } from "react";

import { Close, Download, QrCode } from "@carbon/icons-react";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useAppSelector } from "@/redux/store";

const MyQR = () => {
  const user = useAppSelector((state) => state.auth.user);
  const printRef = useRef<HTMLDivElement>(null);
  const downloadQR = async () => {
    const element = printRef.current;
    if (!element) {
      return;
    }
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };
  return (
    <div>
      <Dialog>
        <DialogTrigger asChild>
          <div className="border-b border-solid border-secondary-100 py-3 flex gap-2 items-center cursor-pointer">
            <QrCode />
            <span>Mã QR của tôi</span>
          </div>
        </DialogTrigger>
        <DialogContent className="pt-4 pb-10 rounded-xl border border-solid border-secondary-200">
          <div>
            <DialogClose>
              <Close
                size={20}
                className="absolute top-4 right-4 text-end cursor-pointer"
              />
            </DialogClose>
            <p className="text-secondary-900 text-center text-lg font-semibold mb-1">
              QR của tôi
            </p>
            <p className="text-xs text-secondary-600 mb-10 text-center">
              Hãy chia sẻ với bạn bè để dễ dàng kết nối hơn!
            </p>
            <div className="flex justify-center">
              <div
                className="p-3 rounded-2xl bg-secondary-50 border border-solid border-secondary-200"
                ref={printRef}
              >
                <QRCode
                  className="rounded-lg"
                  width="200px"
                  height="200px"
                  value={JSON.stringify({ type: "profile", id: user?.id })}
                  fgColor="#0927ec"
                />
              </div>
            </div>
            <p className="text-center text-secondary-900 text-sm font-semibold mb-1 mt-3">
              {user?.name}
            </p>
            <p className="text-center text-xs text-secondary-600">
              Quét mã để kết nối với tôi trên Taga
            </p>
            <div className="flex justify-center mb-2 mt-6">
              <div
                className="bg-secondary-100 rounded-full p-2 cursor-pointer"
                onClick={downloadQR}
              >
                <Download className="text-secondary-600" />
              </div>
            </div>
            <p className="text-center text-sm text-secondary-600">Tải xuống</p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(MyQR);
