import React, { memo } from "react";

import { Route, Routes, Navigate } from "react-router-dom";

import LandingPage from "@/features/LandingPage";
import LoginScreen from "@/features/auth/screens/LoginScreen/LoginScreen";

const RoutesAuth = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={<LoginScreen />}
      />
      <Route
        path="/home"
        element={<LandingPage />}
      />
      {/* Default route to redirect to /landing */}
      <Route
        path="*"
        element={<Navigate to="/home" />}
      />
    </Routes>
  );
};

export default memo(RoutesAuth);
