import { GroupUserRoleEnums } from "@/features/groups/constants/group.constant";

export type GetWebKeyResponse = {
  webKey: string;
  fingerprint: string;
};

export type QRDef = {
  webKey: string;
  fingerprint: string;
  userAgent: string;
};

export type AuthState = {
  loginSuccess: boolean;
  user: UserDef | null;
  libraryId: number;
  openSetting: boolean;
  accessToken: string;
};

export enum UserRoleEnums {
  ADMIN = "ADMIN",
  OWNER = "OWNER",
  MEMBER = "MEMBER",
  GUEST = "GUEST",
}

export enum UserRelationShipEnums {
  REQUESTED = "REQUESTED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
}

export enum UserMFATypeEnums {
  PHONE_SMS = "PHONE_SMS",
  EMAIL = "EMAIL",
}

export type UserDef = {
  alias: string;
  address: string;
  age: number;
  biography: string;
  company: string;
  createdAt: string;
  deviceId: number | null;
  email: string;
  firstTimeLogin: boolean;
  groupInviteStatus: [] | null;
  groupRequestStatus: [] | null;
  id: number;
  name: string;
  phoneNumber: string;
  photo: string;
  requestFromFriendStatus: string | null;
  requestToFriendStatus: string | null;
  useSmsOtp: boolean;
  userId: string | null;
  username: string;
  location: string;
  userMFAType: UserMFATypeEnums;
};

export type GetRelationUserParams = {
  email: string;
};

export type GetOtpResponse = {
  expiredAt: number;
  uid: string;
};
