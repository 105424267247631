import React, { memo, useState, useEffect, FC } from "react";

import { ColorResult, SketchPicker } from "react-color";

import styles from "./ColorSetting.module.scss";

type ColorSettingProps = {
  value: string;
  onChangeCompleteColor: (color: string) => void;
};

const ColorSetting: FC<ColorSettingProps> = ({
  value,
  onChangeCompleteColor,
}) => {
  const [color, setColor] = useState(value);
  useEffect(() => {
    setColor(value);
  }, [value]);

  const handleChangeComplete = (color: ColorResult) => {
    onChangeCompleteColor(color.hex);
  };
  const handleChange = (color: ColorResult) => {
    setColor(color.hex);
  };
  return (
    <div>
      <SketchPicker
        className={styles.wrapper}
        width="260px"
        color={color}
        onChange={handleChange}
        onChangeComplete={handleChangeComplete}
        presetColors={[
          "#ffffff",
          "#000000",
          "#EF4444",
          "#F97316",
          "#FACC15",
          "#4ADE80",
          "#2DD4BF",
          "#3B82F6",
          "#6467F2",
          "#EC4899",
          "#F43F5E",
          "#D946EF",
          "#8B5CF6",
          "#0EA5E9",
          "#10B981",
          "#84CC16",
        ]}
      />
    </div>
  );
};

export default memo(ColorSetting);
