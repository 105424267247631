import React, { FC, memo, useMemo } from "react";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import TGNCheckbox from "@/components/TGNCheckboxRadio/TGNCheckbox";
import TGNSelect from "@/components/TGNSelect/TGNSelect";
import Typography from "@/components/Typography/Typography";
import { UserDef } from "@/features/auth/types/auth.types";
import {
  AssignRolesEnum,
  AssigneeDef,
  AssigneeRolesOptions,
} from "@/features/mindmap/mindmap";
import { SelectItem } from "@/types/app.types";
import { getUrlImage } from "@/utils/image";

type MemberItemProps = {
  assignee: {
    user: UserDef;
    role: AssignRolesEnum | null;
  };
  onRemove: (assignee: AssigneeDef) => void;
  onUpdate: (assignee: AssigneeDef) => void;
};

const MemberItem: FC<MemberItemProps> = ({ assignee, onRemove, onUpdate }) => {
  const assignRoleSelected = useMemo(() => {
    return AssigneeRolesOptions.find((item) => item.value === assignee.role);
  }, [assignee]);
  const checked = useMemo(() => {
    return Boolean(assignee.role);
  }, [assignee.role]);
  const onChangeCheckbox = () => {
    if (checked) {
      onRemove(assignee);
    } else {
      onUpdate({
        user: assignee.user,
        role: AssignRolesEnum.MEMBER,
      });
    }
  };
  const onChangeRole = (e: SelectItem) => {
    onUpdate({
      user: assignee.user,
      role: e?.value as AssignRolesEnum | null,
    });
  };

  return (
    <div
      className="d-flex align-items-center gap-4 mt-12 mb-8"
      key={assignee.user.id}
      onClick={(e) => e.stopPropagation()}
    >
      <TGNCheckbox
        checked={checked}
        label=""
        onChange={onChangeCheckbox}
      />
      <TGNAvatar
        name={assignee.user.name}
        round
        size="40"
        src={getUrlImage(assignee.user.photo)}
      />
      <div className="flex-1">
        <Typography
          className="text-primary-color-gray-10 fs-14 text-truncate"
          style={{
            maxWidth: 170,
          }}
        >
          {assignee.user.name || assignee.user.username}
        </Typography>
        <span
          className="fs-12 text-tertiary-color-gray-5 text-truncate"
          style={{
            maxWidth: 170,
          }}
        >
          {assignee.user.username}
        </span>
      </div>
      <TGNSelect
        itemList={AssigneeRolesOptions}
        variant="text"
        placeholder="-"
        value={assignRoleSelected}
        disabled={!checked}
        onChange={(e) => onChangeRole(e)}
      />
    </div>
  );
};

export default memo(MemberItem);
