import React, { FC, memo } from "react";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { AssigneeDef } from "@/features/mindmap/mindmap";

import styles from "./Members.module.scss";
import MemberOverlay from "../../MemberOverlay/MemberOverlay";

type MembersProps = {
  assignees: AssigneeDef[];
  nodeId?: string;
  isChildMember?: boolean;
};

const Members: FC<MembersProps> = ({
  assignees,
  nodeId,
  isChildMember = false,
}) => {
  return (
    <div className="d-flex align-items-center gap-4 flex-wrap">
      {assignees.map((assignee) => (
        <MemberOverlay
          key={assignee.user.id}
          isChildMember={isChildMember}
          nodeId={nodeId}
        >
          <div className="d-flex align-items-center gap-4">
            <TGNAvatar
              src={assignee.user.photo}
              name={assignee.user.name}
              size="24"
              status="active"
              round
            ></TGNAvatar>
            <span className="fs-14">
              {assignee.user.name || assignee.user.username}
            </span>
          </div>
        </MemberOverlay>
      ))}
      <MemberOverlay
        isChildMember={isChildMember}
        nodeId={nodeId}
      >
        <div className={styles["btn-add"]}>
          <i className="tgn-solid-plus fs-20"></i>
        </div>
      </MemberOverlay>
    </div>
  );
};

export default memo(Members);
