import React, { FC, ReactNode, memo, useEffect, useRef, useState } from "react";

import {
  Close,
  CloseFilled,
  DotMark,
  GroupAccess,
  InformationFilled,
  Search,
  UserMultiple,
} from "@carbon/icons-react";
import InfiniteScroll from "react-infinite-scroll-component";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useAppSelector } from "@/redux/store";
import { extractUsername } from "@/utils/extractUsername";
import { getMessage } from "@/utils/message";

import {
  createInvitationToFriend,
  getListFriendForGroupInvitation,
  getListMemberForGroupInvitation,
  createInvitationToMember,
} from "../../api/group.api";
import { IFriend } from "../../types/group.type";

interface UserInviteModalProps {
  inviteType: "root" | "friend";
  children?: ReactNode;
}

const UserInviteModal: FC<UserInviteModalProps> = ({
  inviteType,
  children,
}) => {
  const { toast } = useToast();
  const groupInfo = useAppSelector((state) => state.group.data);
  const [openModal, setOpenModal] = useState(false);
  const [openModalExit, setOpenModalExit] = useState(false);
  const [listFriends, setListFriends] = useState<IFriend[] | []>([]);
  const [selectedUser, setSelectedUser] = useState<IFriend[] | []>([]);
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [searchResult, setSearchResult] = useState<IFriend[] | []>([]);
  const [searching, setSearching] = useState(false);
  const [controllerApi, setControllerApi] = useState<AbortController>();
  const [totalElement, setTotalElement] = useState(0);
  const [debounceTimeout, setDebounceTimeout] =
    useState<ReturnType<typeof setTimeout>>();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchFriend = async () => {
      if (groupInfo) {
        if (inviteType === "friend") {
          const listFriendToInvite = await getListFriendForGroupInvitation({
            groupId: groupInfo.id,
          });
          setData(listFriendToInvite.data);
          setTotalElement(listFriendToInvite.totalElements);
        }
        if (inviteType === "root") {
          const listFriendToInvite = await getListMemberForGroupInvitation({
            groupId: groupInfo.id,
          });
          setData(listFriendToInvite.data);
          setTotalElement(listFriendToInvite.totalElements);
        }
      }
    };
    const setData = (result: IFriend[]) => {
      setListFriends(result);
      if (!result.length || result.length < 10) {
        setHasMore(false);
      }
    };
    if (openModal) {
      fetchFriend();
    }
  }, [openModal]);

  const fetchFriendScroll = async (page: number) => {
    try {
      if (groupInfo) {
        if (inviteType === "friend") {
          const listFriendToInvite = await getListFriendForGroupInvitation({
            groupId: groupInfo.id,
            pageNum: page,
            pageSize: 10,
          });
          setListFriends([...listFriends, ...listFriendToInvite.data]);
          if (
            !listFriendToInvite.data.length ||
            listFriendToInvite.data.length < 10
          ) {
            setHasMore(false);
          }
        }
        if (inviteType === "root") {
          const listFriendToInvite = await getListMemberForGroupInvitation({
            groupId: groupInfo.id,
            pageNum: page,
            pageSize: 10,
          });
          setListFriends([...listFriends, ...listFriendToInvite.data]);
          if (
            !listFriendToInvite.data.length ||
            listFriendToInvite.data.length < 10
          ) {
            setHasMore(false);
          }
        }
      }
    } catch (error) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    }
  };

  const handleSelectUser = (user: IFriend) => {
    if (!selectedUser.some((e) => e.id === user.id)) {
      setSelectedUser([...selectedUser, user]);
    } else {
      let pre = [...selectedUser];
      pre = pre.filter((item) => item.id !== user.id);
      setSelectedUser([...pre]);
    }
  };

  const handleSendInvite = async () => {
    const ids = selectedUser.map((items) => {
      return items.id;
    });
    try {
      setSubmitting(true);
      if (groupInfo) {
        if (inviteType === "friend") {
          await createInvitationToFriend(groupInfo.id, ids);
        }
        if (inviteType === "root") {
          await createInvitationToMember(groupInfo.id, ids);
        }
        toast({
          title: "Thành công!",
          variant: "success",
          description: getMessage("MSG22"),
        });
      }
      setOpenModal(false);
      setSelectedUser([]);
    } catch (error: any) {
      if (error.response?.data.errors[0]) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const fetchSearchFriend = async (keyword: string) => {
    try {
      if (groupInfo) {
        if (inviteType === "friend") {
          const listFriendSearch = await getListFriendForGroupInvitation({
            keyword: keyword,
            groupId: groupInfo.id,
            pageNum: page,
            pageSize: 10,
          });
          setSearchResult(listFriendSearch.data);
        }
        if (inviteType === "root") {
          const listFriendSearch = await getListMemberForGroupInvitation({
            keyword: keyword,
            groupId: groupInfo.id,
            pageNum: page,
            pageSize: 10,
          });
          setSearchResult(listFriendSearch.data);
        }
      }
    } catch (error) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    }
  };

  const handleChangeSearch = (keyword: string) => {
    setKeyword(keyword);
    if (keyword !== "") {
      setSearching(true);
      if (debounceTimeout) clearTimeout(debounceTimeout);
      setDebounceTimeout(
        setTimeout(() => {
          fetchSearchFriend(keyword);
        }, 500)
      );
    } else {
      setSearching(false);
    }
  };

  return (
    <div>
      <Dialog open={openModal}>
        <DialogTrigger
          asChild
          onClick={() => setOpenModal(true)}
        >
          {children ?? (
            <span className="flex items-center gap-2 rounded px-2.5 py-2 hover:bg-secondary-50 w-full cursor-pointer">
              {inviteType === "friend" && <UserMultiple />}
              {inviteType === "root" && <GroupAccess />}
              <div>
                <p className="text-sm text-left font-medium text-secondary-900">
                  {inviteType === "friend" && "Mời bạn bè"}
                  {inviteType === "root" && "Mời thành viên Tổ chức"}
                </p>
                <p className="text-sm text-secondary-600">
                  {inviteType === "friend" &&
                    `Mời bạn bè của bạn tham gia ${
                      groupInfo?.rootGroupId ? "Nhóm" : "Tổ chức"
                    }`}
                  {inviteType === "root" &&
                    "Mời thành viên thuộc Tổ chức tham gia"}
                </p>
              </div>
            </span>
          )}
        </DialogTrigger>
        <DialogContent
          className={openModalExit ? "hidden" : "p-0 min-w-[800px]"}
        >
          <DialogHeader className="relative">
            <DialogTitle className="text-lg font-semibold text-secondary-900 p-6 pb-2">
              {inviteType === "friend" &&
                `Mời bạn bè tham gia ${
                  groupInfo?.rootGroupId ? "Nhóm" : "Tổ chức"
                }`}
              {inviteType === "root" && "Mời thành viên Tổ chức"}
            </DialogTitle>
            <Button
              type="button"
              variant="text"
              className="p-0 h-5 mt-0 absolute top-6 right-6"
              onClick={() => setOpenModal(false)}
            >
              <Close />
            </Button>
          </DialogHeader>
          <div className="pl-3 pr-6 grid grid-cols-12 gap-4">
            <div className="col-start-1 col-span-7">
              <div className="px-3">
                <div className="relative">
                  <Search className="absolute top-1/2 left-2.5 -translate-y-1/2 text-secondary-600" />
                  <Input
                    ref={ref}
                    name="search"
                    type="text"
                    className="pl-[36px] pr-[36px]"
                    placeholder={`Nhập tên ${
                      inviteType === "friend" ? "bạn bè" : "thành viên"
                    }`}
                    onChange={(e) => handleChangeSearch(e.currentTarget.value)}
                    value={keyword}
                  />
                  {keyword && (
                    <CloseFilled
                      onClick={() => {
                        setSearching(false);
                        setKeyword("");
                      }}
                      className="absolute top-1/2 right-3 -translate-y-1/2 text-secondary-600"
                    />
                  )}
                </div>
              </div>
              {searching ? (
                searchResult.length > 0 ? (
                  searchResult.map((user) => {
                    return (
                      <div
                        key={user.id}
                        className="px-3 py-2 flex gap-3 items-center rounded hover:bg-secondary-50 cursor-pointer"
                        onClick={() => handleSelectUser(user)}
                      >
                        <Checkbox
                          checked={selectedUser.some((e) => e.id === user.id)}
                        />
                        <div className="flex gap-2">
                          <TGNAvatar
                            src={user.photoUrl}
                            userName={
                              user.name || extractUsername(user.email || "")
                            }
                            userPhoto={user.photoUrl}
                            size="40"
                          />
                          <div>
                            <p className="text-sm font-medium text-secondary-900">
                              {user.name || extractUsername(user.email || "")}
                            </p>
                            <p className="text-xs text-secondary-600">
                              {user.userId}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex-none min-w-full px-0 overflow-hidden lg:overflow-auto h-[400px]">
                    <p className="text-center text-sm text-secondary-900 mt-4">
                      Không tìm thấy kết quả phù hợp
                    </p>
                  </div>
                )
              ) : (
                <div className="flex-none min-w-full px-0 overflow-hidden lg:overflow-auto h-[400px]">
                  <p className="text-secondary-600 font-medium text-xs px-3 pt-4 pb-2 flex items-center">
                    {inviteType === "friend" && `Bạn bè`}
                    {inviteType === "root" && "Gợi ý"}
                    <DotMark
                      size={5}
                      className="mx-1"
                    />
                    <span className="text-xs font-semibold">
                      ({totalElement})
                    </span>
                  </p>
                  <InfiniteScroll
                    dataLength={listFriends.length}
                    next={() => {
                      setPage((prev) => prev + 1);
                      fetchFriendScroll(page + 1);
                    }}
                    hasMore={hasMore}
                    loader={
                      <div className="flex justify-center items-center p-2">
                        <TGNLoading size="small" />
                      </div>
                    }
                  >
                    {listFriends.map((user) => {
                      return (
                        <div
                          key={user.id}
                          className="px-3 py-2 flex gap-3 items-center rounded hover:bg-secondary-50 cursor-pointer"
                          onClick={() => handleSelectUser(user)}
                        >
                          <Checkbox
                            checked={selectedUser.some((e) => e.id === user.id)}
                          />
                          <div className="flex gap-2">
                            <TGNAvatar
                              userName={
                                user.name || extractUsername(user.email || "")
                              }
                              userPhoto={user.photoUrl}
                              size="40"
                            />
                            <div>
                              <p className="text-sm font-medium text-secondary-900">
                                {user.name || extractUsername(user.email || "")}
                              </p>
                              <p className="text-xs text-secondary-600">
                                {user.userId}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              )}
            </div>
            <div className="relative col-start-8 col-span-5 rounded px-2 py-4 border border-secondary-200 bg-secondary-50">
              <p className="text-secondary-600 font-medium text-sm px-2">
                Danh sách chọn:{" "}
                <span className="font-semibold">{selectedUser.length}</span>
              </p>
              <div className="flex-col justify-start items-start inline-flex min-w-full px-0 overflow-hidden lg:overflow-auto w-[280px]  h-[400px]">
                {selectedUser.map((user) => {
                  return (
                    <div
                      className="self-stretch p-2 rounded justify-between items-center inline-flex w-full text-ellipsis overflow-hidden"
                      key={user.id}
                    >
                      <div className="h-10 justify-start items-center gap-2 flex overflow-hidden ">
                        <TGNAvatar
                          userName={
                            user.name || extractUsername(user.email || "")
                          }
                          userPhoto={user.photoUrl}
                          size="40"
                        />
                        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex w-full">
                          <p className="self-stretch text-secondary-900 text-sm font-medium w-full">
                            {user.name || extractUsername(user.email || "")}
                          </p>
                          <p className="self-stretch text-secondary-600 text-xs font-normal text-ellipsis w-full">
                            {user.userId}
                          </p>
                        </div>
                      </div>
                      <Button
                        type="button"
                        variant="text"
                        size="sm"
                        onClick={() => handleSelectUser(user)}
                      >
                        <Close />
                      </Button>
                    </div>
                    //
                  );
                })}
              </div>
            </div>
          </div>
          <DialogFooter className="p-6 flex justify-end border-t border-secondary-100">
            <Button
              variant="outline"
              size="sm"
              disabled={submitting}
              onClick={() =>
                selectedUser.length > 0
                  ? setOpenModalExit(true)
                  : setOpenModal(false)
              }
            >
              Huỷ bỏ
            </Button>
            <Button
              disabled={selectedUser.length === 0}
              size="sm"
              onClick={handleSendInvite}
              loading={submitting}
            >
              Gửi lời mời
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={openModalExit}>
        <DialogContent className="gap-10">
          <DialogHeader className="flex flex-row gap-4">
            <div>
              <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full block">
                <InformationFilled size={20} />
              </span>
            </div>
            <div className="flex-1">
              <DialogTitle className="mb-2">
                {inviteType === "friend" && "Huỷ mời bạn bè?"}
                {inviteType === "root" && "Huỷ mời Thành viên Tổ chức?"}
              </DialogTitle>
              <DialogDescription>
                Hệ thống sẽ không lưu lại các lựa chọn của bạn.
              </DialogDescription>
            </div>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                size="sm"
                variant={"outline"}
                type="button"
                onClick={() => (
                  setOpenModal(false),
                  setOpenModalExit(false),
                  setSelectedUser([])
                )}
              >
                Thoát
              </Button>
            </DialogClose>

            <Button
              size="sm"
              onClick={() => setOpenModalExit(false)}
            >
              Tiếp tục chỉnh sửa
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(UserInviteModal);
