import React, { useEffect, useState } from "react";

import { CaretDown, Close, Locked } from "@carbon/icons-react";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { getCustomMessage, getMessage } from "@/utils/message";

import { ComboboxMembers, IComboboxMemberItem } from "./ComboboxMembers";
import {
  getFriend,
  getSharedUsers,
  removeMemberReference,
  shareContent,
} from "../api/lib.api";
// eslint-disable-next-line import/namespace
import { IContent, ICreator } from "../types/lib.types";

const enum ManageContentAccessModalNavAction {
  INVITATION = 0,
  MEMBER = 1,
}

const ManageContentAccessModalNav = ({
  selected,
  onChangeTab,
}: {
  selected: ManageContentAccessModalNavAction;
  onChangeTab: (tab: ManageContentAccessModalNavAction) => void;
}) => {
  return (
    <div className="flex flex-row items-center border-b-[1px] border-[#F3F4F6] px-6">
      <div className="flex flex-row font-medium text-tg-text-secondary">
        <div
          className={cn(
            "p-4 cursor-pointer hover:text-primary",
            selected === ManageContentAccessModalNavAction.INVITATION
              ? "text-primary border-b-[2px] border-primary"
              : ""
          )}
          onClick={() =>
            onChangeTab(ManageContentAccessModalNavAction.INVITATION)
          }
        >
          Lời mời
        </div>
        <div
          className={cn(
            "p-4 cursor-pointer hover:text-primary",
            selected === ManageContentAccessModalNavAction.MEMBER
              ? "text-primary border-b-[2px] border-primary"
              : ""
          )}
          onClick={() => onChangeTab(ManageContentAccessModalNavAction.MEMBER)}
        >
          Thành viên
        </div>
      </div>
    </div>
  );
};

const ManageContentAccessModalInvitationTab = ({
  content,
  onSubmitSuccess,
}: {
  content: IContent | undefined;
  onSubmitSuccess?: () => void;
}) => {
  const [selected, setSelected] = useState<ICreator[]>([]);
  const [members, setMembers] = useState<IComboboxMemberItem[]>([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        if (!content) return;
        const resMember = await getFriend();
        const sharedContent = await getSharedUsers(content.id);
        const memberInContent = sharedContent.data || [];
        if (resMember && resMember.data) {
          const tmpMembers = [...resMember.data].map((m) => ({
            ...m,
            shared: memberInContent.some(
              (mic: any) => mic.user.email === m.email
            ),
          }));
          setMembers(tmpMembers);
        } else {
          setMembers([]);
        }
      } catch {
        console.log("error");
      }
    };
    fetchMembers();
  }, []);

  const onSelectMemberChange = (val: ICreator[]) => {
    setSelected(val);
  };

  const onAcceptContent = async () => {
    try {
      if (!content || !selected || !selected.length) return;
      await shareContent(
        content?.id,
        selected.map((s) => s.id)
      );
      toast({
        title: "Thành công!",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: getCustomMessage("MSGTV35", content.name),
            }}
          ></div>
        ),
      });
      //close modal
      onSubmitSuccess && onSubmitSuccess();
    } catch (e: any) {
      toast({
        title: "Thất bại!",
        description: getMessage("MSG_ERROR"),
      });
    }
  };

  return (
    <div className="flex mt-4 space-x-2 px-6 pb-6">
      <ComboboxMembers
        placeholder={"Nhập tên bạn bè"}
        onSelected={onSelectMemberChange}
        options={members}
      />
      <Button
        disabled={!selected || !selected.length}
        className="w-auto min-w-[103px]"
        onClick={onAcceptContent}
      >
        Gửi lời mời
      </Button>
    </div>
  );
};

const ManageContentAccessModalMemberTab = ({
  content,
}: {
  content: IContent | undefined;
}) => {
  const [sharedUsers, setSharedUsers] = useState<ICreator[]>([]);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const fetchSharedUsers = async () => {
    try {
      if (!content) return;
      const result = await getSharedUsers(content?.id);
      if (result.data) {
        setSharedUsers(result.data.map((item) => item.user));
      }
    } catch {
      console.log("error");
    }
  };
  useEffect(() => {
    fetchSharedUsers();
  }, []);
  const onRemove = async (memberId: number) => {
    try {
      if (!content) return;
      await removeMemberReference(content.id, memberId);
      fetchSharedUsers();
    } catch (e: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(e.data?.errors[0]),
      });
    }
  };
  return (
    <>
      <div className="max-h-[500px] min-h-[400px] space-y-2 overflow-auto">
        <div className="mx-3">
          <div className="font-medium px-3 ">
            Thành viên ({sharedUsers.length})
          </div>

          {sharedUsers.map((member, index) => (
            <div
              key={member.id}
              className={cn(
                "flex flex-row items-center gap-2 py-2 px-3 hover:bg-secondary-100 cursor-pointer rounded-md [&:hover>button]:visible",
                index > 4 && !isShowMore ? "hidden" : ""
              )}
            >
              <TGNAvatar
                size="32"
                src={member.photo || ""}
                userName={member.name ?? member.email}
              />
              <div className="flex flex-col">
                <div className="flex flex-row gap-2">
                  <div className="font-medium text-sm">
                    {member.username ||
                      member.name ||
                      member.email ||
                      "Taga User"}
                  </div>
                </div>
                <div className="text-xs text-tg-text-secondary">
                  {member.email}
                </div>
              </div>
              <Button
                onClick={() => onRemove && onRemove(member.id)}
                variant="text"
                className=" ml-auto invisible "
              >
                <div className="flex items-center">
                  <Close /> <span>Gỡ</span>
                </div>
              </Button>
            </div>
          ))}
        </div>
        {sharedUsers.length > 5 && !isShowMore && (
          <Button
            variant="text"
            size="sm"
            className="text-tg-text-secondary text-sm ml-2 mt-2"
            onClick={() => setIsShowMore(true)}
          >
            <CaretDown className="mr-2" /> Xem thêm
          </Button>
        )}
      </div>
    </>
  );
};

interface IManageContentAccessModalProps {
  isShow: boolean;
  content: IContent | undefined;
  onSubmitSuccess?: () => void;
  onOpenChange?: (val: boolean) => void;
}

export default function ManageContentAccessModal({
  content,
  isShow,
  onSubmitSuccess,
  onOpenChange,
}: IManageContentAccessModalProps) {
  const [selected, setSelected] = useState(
    ManageContentAccessModalNavAction.INVITATION
  );

  const onChangeTab = (tab: ManageContentAccessModalNavAction) => {
    setSelected(tab);
  };

  return (
    <Dialog
      open={isShow}
      onOpenChange={onOpenChange}
    >
      <DialogContent className="w-[700px] max-w-full p-0 transition-transform">
        <DialogHeader className="px-6 mt-6">
          <div className="flex items-center">
            <div className="font-semibold text-lg">Quản lý truy cập</div>
          </div>
        </DialogHeader>
        <span
          className="absolute top-4 right-4 cursor-pointer "
          onClick={() => onOpenChange && onOpenChange(false)}
        >
          <Close />
        </span>
        <div className="flex flex-row items-center px-6">
          <div className="w-100 h-10 justify-start items-start gap-3 inline-flex">
            <div className="w-[40px] h-[40px] p-3 rounded-full bg-secondary-50 text-secondary-600">
              <Locked />
            </div>
            <div className="flex-col justify-start items-start gap-1 inline-flex">
              <div className="justify-start items-start gap-1 inline-flex">
                <div className="text-secondary-900 text-sm font-medium ">
                  Hạn chế
                </div>
              </div>
              <div className="text-secondary-600 text-xs">
                Chỉ có sẵn với một số người dùng được cấp quyền truy cập.{" "}
              </div>
            </div>
          </div>
        </div>

        <ManageContentAccessModalNav
          selected={selected}
          onChangeTab={onChangeTab}
        />
        {selected === ManageContentAccessModalNavAction.INVITATION && (
          <ManageContentAccessModalInvitationTab
            content={content}
            onSubmitSuccess={onSubmitSuccess}
          />
        )}
        {selected === ManageContentAccessModalNavAction.MEMBER && (
          <ManageContentAccessModalMemberTab content={content} />
        )}
      </DialogContent>
    </Dialog>
  );
}
