// import { UI_ROUTES } from "@/_taga/ui/routes/ui.routes";
import { ACCOUNT_ROUTES } from "@/features/account/routes/account.routes";
import { AUTH_ROUTES } from "@/features/auth/routes/auth.routes";
import { FRIENDS_ROUTES } from "@/features/friend/routes/lib.routes";
import { GROUP_ROUTES } from "@/features/groups/routes/group.routes";
import { CLOUD_ROUTES } from "@/features/lib/routes/lib.routes";
import { MINDMAP_ROUTES } from "@/features/mindmap/routes/mindmap.routes";
import { NOTIFICATION_ROUTES } from "@/features/notification/routes/notification.routes";

export const ROUTE_LIST = [
  ...MINDMAP_ROUTES,
  ...AUTH_ROUTES,
  ...GROUP_ROUTES,
  ...ACCOUNT_ROUTES,
  ...CLOUD_ROUTES,
  ...FRIENDS_ROUTES,
  ...NOTIFICATION_ROUTES,
];
