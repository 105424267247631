import React, { memo } from "react";

import { Close } from "@carbon/icons-react";

import { useAppDispatch, useAppSelector } from "@/redux/store";

import FileIcon from "./FileIcon";
import {
  ContentTypeEnums,
  DownloadContentStatusEnums,
} from "../constants/lib.constants";
import { libraryActions } from "../redux/lib.slice";

const DownloadPopup = () => {
  const { downloadContents, showDownloadPopup } = useAppSelector(
    (state) => state.library
  );
  const dispatch = useAppDispatch();
  if (!showDownloadPopup) {
    return null;
  }
  return (
    <div className="transition-opacity ease-in duration-700 opacity-100 shadow-lg rounded p-4 fixed bottom-4 right-4 w-[344px] overflow-y-auto h-[400px] bg-white border-secondary-200 border border-solid">
      <div className="flex justify-between items-center mb-4 sticky top-0 bg-white">
        <span className="text-sm font-medium">Danh sách tải xuống</span>
        <Close
          className="cursor-pointer"
          size={20}
          onClick={() => dispatch(libraryActions.setShowDownloadPopup(false))}
        />
      </div>
      {downloadContents.map((item) => (
        <div
          key={item.content.id}
          className="py-2"
        >
          <div className="flex gap-2">
            <FileIcon
              extension={item.content.extension}
              size={20}
            />
            <div className="text-sm text-secondary-600">
              <p className="font-medium">{item.content.name}</p>
              <span className="text-xs">
                {item.status === DownloadContentStatusEnums.COMPLETED &&
                  "Đã tải xuống"}
                {item.status === DownloadContentStatusEnums.ERROR &&
                  "Đã xảy ra lỗi"}
                {item.status === DownloadContentStatusEnums.PENDING
                  ? item.content.type === ContentTypeEnums.FOLDER
                    ? "Đang nén ..."
                    : "Đang tải xuống ..."
                  : ""}
              </span>
            </div>
          </div>
          {item.content.type === ContentTypeEnums.FOLDER &&
            item.status === DownloadContentStatusEnums.PENDING && (
              <div className="h-1 bg-secondary-100 relative mt-4 overflow-hidden">
                <div className="h-1 w-[200px] absolute top-0 left-0 animate-uploading bg-gradient-to-r from-transparent via-blue-500 to-transparent rounded-[100px]"></div>
              </div>
            )}
        </div>
      ))}
    </div>
  );
};

export default memo(DownloadPopup);
