import React, { memo, ReactNode, FC, useState, useRef } from "react";

import { Overlay } from "react-bootstrap";

import styles from "./MemberOverlay.module.scss";
import MemberPopup from "../Overview/NodeManagement/MemberPopup/MemberPopup";
import AssigneePopup from "../Popup/AssigneePopup/AssigneePopup";

type MemberOverlayProps = {
  children: ReactNode;
  isChildMember?: boolean;
  nodeId?: string;
};

const MemberOverlay: FC<MemberOverlayProps> = ({
  children,
  isChildMember = false,
  nodeId,
}) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <div
        role="presentation"
        className="d-inline-flex cursor-pointer"
        ref={target}
        onClick={() => setShow(!show)}
      >
        {children}
      </div>
      <Overlay
        target={target.current}
        show={show}
        rootClose
        placement="bottom-start"
        onHide={() => setShow(false)}
      >
        {({
          /* eslint-disable */
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
          /* eslint-enable */
        }) => (
          <div
            {...props}
            className={styles.overlay}
          >
            {isChildMember ? (
              <MemberPopup nodeId={nodeId || ""} />
            ) : (
              <AssigneePopup stopEvent />
            )}
          </div>
        )}
      </Overlay>
    </>
  );
};

export default memo(MemberOverlay);
