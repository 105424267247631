import React, { FC, memo } from "react";

import { TrashCan } from "@carbon/icons-react";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import { formatDistance } from "@/utils/date";

import RestoreGroupModal from "../../screens/RootGroupDeletedScreen/RestoreGroupModal";
import { RestoreGroup } from "../../types/group.type";

interface GroupCardProps {
  group: RestoreGroup;
}

const GroupCard: FC<GroupCardProps> = ({ group }) => {
  return (
    <div className="relative rounded-lg border border-secondary-200 hover:shadow-lg overflow-hidden">
      <img
        className="object-cover h-[140px] w-full"
        src={group.photoUrl ? group.photoUrl : GroupCover}
        alt=""
      />
      <div className="p-4">
        <p className="text-xs text-secondary-600 font-medium uppercase mb-2 truncate">
          Tổ chức
        </p>
        <div className="min-h-[76px]">
          <p className="font-semibold text-base leading-6 text-secondary-900 mb-2 line-clamp-2 inline">
            {group.name}
          </p>
          <p className="flex items-center">
            <span className="text-sm text-secondary-600 flex items-center leading-4 mt-2">
              <TrashCan className="me-1" />
              Đã xóa {formatDistance(group.deletedAt)}
            </span>
          </p>
        </div>

        <div className="mt-3">
          <RestoreGroupModal group={group} />
        </div>
      </div>
    </div>
  );
};

export default memo(GroupCard);
