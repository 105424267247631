import React, { Fragment, useEffect, useMemo, useState } from "react";

import { Add } from "@carbon/icons-react";
import { isAxiosError } from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useSearchParams } from "react-router-dom";

import TGNLoading from "@/components/TGNLoading/TGNLoading";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from "@/constants/app.constants";
import usePagination from "@/hooks/usePagination";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import DialogConfirmMove from "./DialogConfirmMove";
import DialogConfirmMoveDuplicateName from "./DialogConfirmMoveDuplicateName";
import {
  checkDuplicateContent,
  getChildContent,
  getContentDetail,
  moveContent,
} from "../../api/lib.api";
import {
  ContentTypeEnums,
  FolderMenuActions,
} from "../../constants/lib.constants";
import { LibPathsEnum } from "../../constants/lib.paths";
import { libraryActions } from "../../redux/lib.slice";
import { IContent } from "../../types/lib.types";
import ContentItem from "../ContentItem";

export interface IFolderListModalProps {
  isShow: boolean;
  sourceContent: IContent | undefined;
  onOpenChange: () => void;
}

export default function FolderListModal({
  isShow,
  sourceContent,
  onOpenChange,
}: IFolderListModalProps) {
  const dispatch = useAppDispatch();
  const { libraryId } = useAppSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [contents, setContents] = useState<IContent[]>([]);
  const [contentSelected, setContentSelected] = useState<IContent>();
  const [currentContent, setCurrentContent] = useState<IContent>();
  const { pagination, setPagination } = usePagination();
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [isShowDialogConfirmMove, setIsShowDialogConfirmMove] =
    useState<boolean>(false);
  const [
    isShowDialogConfirmDuplicateName,
    setIsShowDialogConfirmDuplicateName,
  ] = useState<boolean>(false);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [newNameForDuplicate, setNewNameForDuplicate] = useState<string>("");

  const { toast } = useToast();

  useEffect(() => {
    if (!libraryId || !isShow) return;
    fetchContents(libraryId, pagination.current);
  }, [libraryId, isShow]);

  const fetchContents = async (contentId: number, page: number) => {
    try {
      setLoading(true);
      const contentDetail = await getContentDetail(contentId || libraryId);
      setCurrentContent(contentDetail.data);
      const result = await getChildContent({
        id: contentId,
        pageNumber: page,
        pageSize: pagination.size,
      });
      setPagination({
        current: result.pageIndex,
        total: result.totalPage,
        size: PAGE_SIZE_DEFAULT,
      });
      if (result.pageIndex === PAGE_DEFAULT) {
        setContents(result.data);
      } else if (result.pageIndex <= result.totalPage) {
        setIsLoadMore(true);
        setContents((prev) => [...prev, ...result.data]);
      }
      if (result.pageIndex >= result.totalPage) {
        setIsLoadMore(false);
      }
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const checkDuplicateName = async () => {
    if (!sourceContent || !contentSelected) {
      return;
    }
    try {
      setLoadingAction(true);
      const result = await checkDuplicateContent({
        sourceId: sourceContent.id,
        targetId: contentSelected.id,
      });
      if (result.data.duplicate) {
        setIsShowDialogConfirmDuplicateName(true);
        setNewNameForDuplicate(result.data.new_name);
      } else {
        setIsShowDialogConfirmMove(true);
      }
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    } finally {
      setLoadingAction(false);
    }
  };

  const openFolder = (contentId: number, action: FolderMenuActions) => {
    if (action === FolderMenuActions.Open) {
      setContents([]);
      fetchContents(contentId, PAGE_DEFAULT);
    }
  };
  const handleRemove = async () => {
    if (!sourceContent || !contentSelected) {
      return;
    }
    try {
      setLoadingAction(true);
      const result = await moveContent({
        sourceId: sourceContent.id,
        targetId: contentSelected.id,
      });
      if (result) {
        toast({
          title: "Thành công!",
          variant: "success",
          description: getMessage(
            sourceContent.type === ContentTypeEnums.FILE ? "MSG32" : "MSG31"
          ),
        });
        setIsShowDialogConfirmDuplicateName(false);
        setIsShowDialogConfirmMove(false);
        onOpenChange();
        searchParams.set("content_id", contentSelected.id.toString());
        searchParams.delete("keyword");
        navigate(LibPathsEnum.CLOUD + "?" + searchParams.toString());
      }
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    } finally {
      setLoadingAction(false);
    }
  };
  const getParentId = (content: IContent) => {
    const materialPaths = content.material_path.split(".");
    if (materialPaths[materialPaths.length - 2]) {
      return Number(materialPaths[materialPaths.length - 2]);
    }
  };
  const disableContent = (content: IContent) => {
    if (!sourceContent || content.type === ContentTypeEnums.FILE) {
      return true;
    }
    return content.id === sourceContent.id;
  };

  return (
    <>
      <Dialog
        open={isShow}
        onOpenChange={onOpenChange}
      >
        <DialogContent className="flex flex-col max-w-[830px] h-[80vh] bg-white">
          <DialogHeader className="flex h-fit">
            <div className="flex items-center">
              <div className="font-semibold text-lg">
                Di chuyển "{sourceContent?.name}" tới ...
              </div>
            </div>
          </DialogHeader>
          <div className="p-4 border border-solid border-secondary-200 rounded">
            <Breadcrumb className="mb-7">
              <BreadcrumbList className="text-sm font-semibold text-secondary-600">
                <BreadcrumbItem
                  className="cursor-pointer"
                  onClick={() => openFolder(libraryId, FolderMenuActions.Open)}
                >
                  Tất cả
                </BreadcrumbItem>
                {currentContent &&
                  currentContent.deep_parent.map((item) => {
                    if (item.type === ContentTypeEnums.LIBRARY) {
                      return null;
                    }
                    return (
                      <Fragment key={item.id}>
                        <BreadcrumbSeparator>/</BreadcrumbSeparator>
                        <BreadcrumbItem
                          className="cursor-pointer"
                          onClick={() =>
                            openFolder(item.id, FolderMenuActions.Open)
                          }
                        >
                          {item.name}
                        </BreadcrumbItem>
                      </Fragment>
                    );
                  })}
                {currentContent &&
                  currentContent.type !== ContentTypeEnums.LIBRARY && (
                    <>
                      <BreadcrumbSeparator>/</BreadcrumbSeparator>
                      <BreadcrumbItem
                        className="cursor-pointer"
                        onClick={() =>
                          openFolder(currentContent.id, FolderMenuActions.Open)
                        }
                      >
                        {currentContent.name}
                      </BreadcrumbItem>
                    </>
                  )}
              </BreadcrumbList>
            </Breadcrumb>
            <InfiniteScroll
              className="px-0"
              dataLength={contents.length}
              next={() => {
                fetchContents(
                  currentContent?.id || libraryId,
                  pagination.current + 1
                );
              }}
              height="calc(80vh - 220px)"
              hasMore={isLoadMore}
              loader={
                <div className="flex items-center justify-center mt-4">
                  <TGNLoading size="medium" />
                </div>
              }
            >
              <div className="bg-white flex flex-wrap gap-x-2 gap-y-9">
                {contents.map((content) => {
                  return (
                    <ContentItem
                      key={content.id}
                      disable={disableContent(content)}
                      content={content}
                      disableRightClick
                      active={contentSelected?.id === content.id}
                      onMenuClick={(action) => openFolder(content.id, action)}
                      onClick={() => setContentSelected(content)}
                    />
                  );
                })}
              </div>
            </InfiniteScroll>
            <div className="flex justify-between bg-white absolute w-full p-4 bottom-0 left-0 border-t border-t-[#F3F4F6] rounded-b-lg shadow-[rgba(17,24,39,0.05)_0px_-1px_2px_0px]">
              <Button
                variant="ghost"
                onClick={() => {
                  dispatch(libraryActions.showCreateModal());
                }}
                iconBefore={<Add />}
                className=""
              >
                Tạo thư mục
              </Button>
              <div className="space-x-2">
                <Button
                  variant="outline"
                  onClick={onOpenChange}
                >
                  Hủy
                </Button>
                <Button
                  disabled={
                    !contentSelected ||
                    (sourceContent &&
                      getParentId(sourceContent) === contentSelected.id)
                  }
                  onClick={checkDuplicateName}
                  loading={loadingAction}
                >
                  Di chuyển
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <DialogConfirmMove
        isShow={isShowDialogConfirmMove}
        sourceContent={sourceContent}
        targetContent={contentSelected}
        loading={loadingAction}
        onAcceptContent={handleRemove}
        onRejectContent={() => setIsShowDialogConfirmMove(false)}
      />
      <DialogConfirmMoveDuplicateName
        isShow={isShowDialogConfirmDuplicateName}
        sourceContent={sourceContent}
        newName={newNameForDuplicate}
        loading={loadingAction}
        onAcceptContent={handleRemove}
        onRejectContent={() => setIsShowDialogConfirmDuplicateName(false)}
      />
    </>
  );
}
