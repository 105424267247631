import React, { memo } from "react";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import Typography from "@/components/Typography/Typography";

import styles from "./ActivityLog.module.scss";

const ActivityLog = () => {
  return (
    <div className="flex-1 pt-12 ps-12 position-relative">
      <div className="d-flex justify-content-between">
        <Typography
          color="color-black"
          className="fs-16 fw-semibold"
        >
          Activity log
        </Typography>
        <div className="d-flex align-items-center gap-8">
          <i className="tgn-outline-search-01 fs-16"></i>
          <i className="tgn-solid-more-vertical"></i>
        </div>
      </div>

      <div className={styles["mt-48"]}>
        <Typography className="fs-12 text-tertiary-color-gray-5 text-center mt-12 mb-8">
          5 new updates
        </Typography>
        <div>
          <div className={styles["update-line"]}>
            <div>
              <TGNAvatar
                name="Nguyen Son"
                status="active"
                size="24"
                round
              />
            </div>
            <div>
              <span className="text-tertiary-color-gray-5 fs-12">
                <span className="text-primary-color-gray-10 fw-semibold">
                  @kawalee.taga
                </span>{" "}
                created this task by copying Update Taga Design System ver 1.0
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ActivityLog);
