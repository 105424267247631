import React, { useEffect, useState } from "react";

import {
  Checkmark,
  CheckmarkFilled,
  NotificationFilled,
  NotificationOff,
  OverflowMenuHorizontal,
  Settings,
  TrashCan,
} from "@carbon/icons-react";
import clsx from "clsx";
import { Loader2 } from "lucide-react";
import InfiniteScroll from "react-infinite-scroll-component";

import { NotificationDot } from "@/assets/icons";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import { PAGE_SIZE_DEFAULT } from "@/constants/app.constants";
import {
  acceptGroupInvitation,
  updateGroupDeviceStatus,
  updateStatusJoinRequest,
} from "@/features/groups/api/group.api";
import { rejectAccessRequest } from "@/features/lib/api/lib.api";
import usePagination from "@/hooks/usePagination";
import useToastError from "@/hooks/useToastError";
import { cn } from "@/lib/utils";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";
import { getMessage } from "@/utils/message";

import {
  deleteNotification,
  getNotification,
  markAllAsRead,
  markNotification,
} from "../../api/notification.api";
import {
  NOTIFICATION_ACTION_TYPES,
  NotificationStatus,
} from "../../constants/notification.constant";
import { notificationAction } from "../../redux/notification.slice";
import { INotification } from "../../types/notification.type";
import { getNotificationText } from "../../utils/notificationText";

const Notification = () => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.notification);
  const [tab, setTab] = useState("all");
  const { showToastError } = useToastError();
  const { pagination, setPagination } = usePagination();
  const [isLoadMore, setIsLoadMore] = useState(false);

  const fetchNotification = async (page: number, type: string) => {
    try {
      const result = await getNotification({
        pageNumber: page,
        pageSize: PAGE_SIZE_DEFAULT,
        notificationStatusFilter:
          type === "unread" ? NotificationStatus.UNREAD : undefined,
      });
      setPagination({
        current: page,
        size: pagination.size,
        total: result.totalPage,
      });
      setIsLoadMore(page < result.totalPage);
      if (page === 1) {
        dispatch(notificationAction.setNotification(result.data));
      } else {
        dispatch(
          notificationAction.setNotification([...notifications, ...result.data])
        );
      }
    } catch (error) {
      showToastError(error);
    }
  };

  const handleDeleteNotification = async (id: number) => {
    try {
      await deleteNotification(id);
      toast({
        title: "Thành công",
        variant: "success",
        description: getMessage("MSG_TB23"),
      });
      fetchNotification(1, tab);
    } catch (error) {
      showToastError(error);
    }
  };

  const handleMarkNotification = async (
    id: number,
    status: NotificationStatus
  ) => {
    try {
      await markNotification(id, status);
      fetchNotification(1, tab);
    } catch (error) {
      showToastError(error);
    }
  };

  const handleMarkAllNotificationAsRead = async () => {
    try {
      await markAllAsRead();
      fetchNotification(1, tab);
    } catch (error) {
      showToastError(error);
    }
  };
  useEffect(() => {
    fetchNotification(1, tab);
  }, [tab]);
  const handleClickAction = async (item: INotification, accept: boolean) => {
    try {
      switch (item.notificationType) {
        case "NT_1":
          await acceptGroupInvitation(accept, Number(item.inviteInfo.inviteId));
          toast({
            title: "Thành công",
            variant: "success",
            description:
              (accept ? "Chấp nhận" : "Từ chối") +
              " yêu cầu tham gia nhóm thành công",
          });
          break;
        case "NT_11":
          await updateStatusJoinRequest({
            id: Number(item.inviteInfo.inviteId),
            accept: accept,
            groupId: item.groupInfo.groupId,
          });
          toast({
            title: "Thành công",
            variant: "success",
            description:
              (accept ? "Chấp nhận" : "Từ chối") +
              " yêu cầu tham gia nhóm thành công",
          });
          break;
        case "NT_13":
          await updateGroupDeviceStatus({
            groupId: item.groupInfo.groupId,
            userId: item.sender.id,
            deviceUniqueId: item.deviceInfo.deviceUniqueId,
            accept,
          });
          toast({
            title: "Thành công!",
            variant: "success",
            description: (
              <p>
                Đã {accept ? "phê duyệt" : "từ chối"} thiết bị{" "}
                <span className="font-semibold">
                  {item.deviceInfo.deviceUniqueId}
                </span>
              </p>
            ),
          });
          break;
        case "NT_19":
          await rejectAccessRequest(
            item.contentInfo.contentAttributeId,
            accept
          );
          toast({
            title: "Thành công!",
            description: getMessage(
              accept ? "MSG74" : "MSG75",
              item.sender.name || "Taga User"
            ),
          });
          break;
        default:
          break;
      }
      fetchNotification(1, tab);
    } catch (err) {
      showToastError(err);
    }
  };

  return (
    <div className="pt-[100px] bg-secondary-100 min-h-screen">
      <div className="max-w-[900px] bg-white rounded-xl shadow-xl mx-auto p-10">
        <div className=" pt-4 flex justify-between">
          <p className="text-lg font-semibold">Thông báo</p>
          <div className="flex gap-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  size={"sm"}
                  variant="ghost"
                >
                  <OverflowMenuHorizontal />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuItem
                  className="hover:bg-secondary-100 hover:cursor-pointer"
                  onClick={() => {
                    handleMarkAllNotificationAsRead();
                  }}
                >
                  <Checkmark className="mr-2" />
                  Đánh dấu tất cả là đã đọc
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Button
              size={"sm"}
              variant="ghost"
            >
              <Settings />
            </Button>
          </div>
        </div>
        <div>
          <>
            <div className="container flex gap-2">
              <span
                className={clsx(
                  "text-xs text-secondary-600 border border-secondary-200 rounded px-3 py-1 hover:cursor-pointer",
                  tab === "all" ? "text-secondary-900 bg-secondary-50" : ""
                )}
                onClick={() => setTab("all")}
              >
                Tất cả
              </span>
              <span
                className={clsx(
                  "text-xs text-secondary-600 border border-secondary-200 rounded px-3 py-1 hover:cursor-pointer",
                  tab === "unread" ? "text-secondary-900 bg-secondary-50" : ""
                )}
                onClick={() => setTab("unread")}
              >
                Chưa đọc
              </span>
            </div>
            {notifications.length > 0 ? (
              <>
                <div className="container mt-6">
                  <InfiniteScroll
                    style={{
                      height: "unset",
                      overflow: "unset",
                    }}
                    className="px-0"
                    dataLength={notifications.length}
                    next={() => fetchNotification(pagination.current + 1, tab)}
                    hasMore={isLoadMore}
                    loader={<Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  >
                    <div>
                      {notifications.map((item) => (
                        <div
                          key={item.id}
                          className={cn(
                            "gap-2 border-b border-secondary-100 p-2 relative hover:bg-secondary-50 last-of-type:border-none group cursor-pointer"
                          )}
                        >
                          <div className="flex gap-2 p-2">
                            {item.photoUrl ? (
                              <img
                                src={item.photoUrl}
                                alt=""
                                className="w-10 h-10 object-cover"
                              />
                            ) : (
                              <TGNAvatar
                                size="40"
                                name={item.sender.name}
                              />
                            )}
                            <div>
                              <div>
                                <p
                                  className="text-sm mb-1"
                                  dangerouslySetInnerHTML={{
                                    __html: getNotificationText(item),
                                  }}
                                ></p>
                                <p className="text-xs text-secondary-600">
                                  {formatDistance(item.createdAt)} trước
                                </p>
                              </div>
                              {NOTIFICATION_ACTION_TYPES.includes(
                                item.notificationType
                              ) && (
                                <div className="mt-3">
                                  <Button
                                    className="w-[328px]"
                                    size="sm"
                                    onClick={() =>
                                      handleClickAction(item, true)
                                    }
                                  >
                                    Chấp nhận
                                  </Button>
                                  <Button
                                    className="w-[328px] ml-3"
                                    size="sm"
                                    variant="ghost"
                                    onClick={() =>
                                      handleClickAction(item, false)
                                    }
                                  >
                                    Từ chối
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                          {item.notificationStatus ===
                            NotificationStatus.UNREAD && (
                            <NotificationDot className="absolute top-0 right-0" />
                          )}
                          <DropdownMenu>
                            <DropdownMenuTrigger
                              className="opacity-0 group-hover:opacity-100 absolute top-4 right-4"
                              asChild
                            >
                              <Button
                                size="sm"
                                variant="ghost"
                              >
                                <OverflowMenuHorizontal />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <DropdownMenuItem
                                className="text-secondary-600 py-2.5 hover:cursor-pointer hover:bg-secondary-100"
                                onClick={() => {
                                  handleMarkNotification(
                                    item.id,
                                    item.notificationStatus ===
                                      NotificationStatus.UNREAD
                                      ? NotificationStatus.READ
                                      : NotificationStatus.UNREAD
                                  );
                                }}
                              >
                                <Checkmark className="mr-2" />
                                {item.notificationStatus ===
                                NotificationStatus.UNREAD
                                  ? "Đánh dấu đã đọc"
                                  : "Đánh dấu chưa đọc"}
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                className="text-secondary-600 py-2.5 hover:cursor-pointer hover:bg-secondary-100"
                                onClick={() =>
                                  handleDeleteNotification(item.id)
                                }
                              >
                                <TrashCan className="mr-2" />
                                Xoá thông báo
                              </DropdownMenuItem>
                              <DropdownMenuItem className="text-secondary-600 py-2.5 hover:cursor-pointer hover:bg-secondary-100">
                                <NotificationOff className="mr-2" />
                                Tắt nhận thông báo từ người này
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                      ))}
                    </div>
                  </InfiniteScroll>
                </div>
              </>
            ) : (
              <div className="h-[calc(100vh-300px)] flex justify-center flex-col items-center">
                <div className="">
                  <div className="border border-secondary-100 rounded-full inline-block p-[60px] mb-9">
                    <div className="border border-secondary-100 rounded-full inline-block p-10">
                      <div className="p-4 border border-secondary-200 shadow-notification rounded-full inline-block">
                        {tab === "all" ? (
                          <NotificationFilled
                            className="inline-block"
                            size={32}
                          />
                        ) : (
                          <CheckmarkFilled
                            color="#5ACE86"
                            className="inline-block"
                            size={32}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-lg font-semibold mb-2 text-center">
                  {tab === "all"
                    ? "Oops! Chưa có thông báo mới"
                    : "Bạn đã đọc hết thông báo!"}
                </p>
                <p className="text-xs text-center text-secondary-600">
                  {tab === "all"
                    ? "Thông báo về hoạt động mới trên TAGA sẽ được hiển thị tại đây"
                    : "Thông báo mới sẽ được hiển thị tại đây"}
                </p>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default Notification;
