import React, { FC, ReactNode } from "react";

import cx from "classnames";

type TypographyProps = {
  className?: string;
  color?: string;
  children: ReactNode;
} & React.HTMLAttributes<HTMLParagraphElement>;

const Typography: FC<TypographyProps> = ({
  className,
  color,
  children,
  ...props
}) => {
  return (
    <p
      {...props}
      className={cx(className, color ? `text-${color}` : "", "mb-0")}
    >
      {children}
    </p>
  );
};

export default Typography;
