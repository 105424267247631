import api from "@/api/api_instance";
import { STORAGE_KEY } from "@/constants/app.constants";
import { AppResponse } from "@/types/app.types";

import { AuthEndpointsEnum } from "../constants/auth.endpoints";
import {
  GetOtpResponse,
  GetRelationUserParams,
  GetWebKeyResponse,
  UserDef,
} from "../types/auth.types";

export const fetchWebKey = (
  fingerprint: string
): Promise<GetWebKeyResponse> => {
  return api.get(AuthEndpointsEnum.WEB_KEY, {
    params: {
      fingerprint,
    },
  });
};

export const getCurrentUsername = (): Promise<UserDef> => {
  return api.get(AuthEndpointsEnum.PROFILE.replace(":id", "-1"));
};

export const getRelationUser = (
  params: GetRelationUserParams,
  signal: AbortSignal
): Promise<UserDef[]> => {
  return api.get(AuthEndpointsEnum.RELATIONSHIP, {
    params,
    signal,
  });
};

export const saveTokenToStorage = (val: string): void => {
  sessionStorage.setItem(STORAGE_KEY.LOGIN_AUTH, val);
};

export const getTokenFromStorage = (): string | null => {
  return sessionStorage.getItem(STORAGE_KEY.LOGIN_AUTH);
};

export const removeTokenFromStorage = (): void => {
  sessionStorage.removeItem(STORAGE_KEY.LOGIN_AUTH);
};

export const getOtp = (): Promise<GetOtpResponse> => {
  return api.get(AuthEndpointsEnum.OTP);
};

export const getLibraryId = (): Promise<AppResponse<number>> => {
  return api.get(AuthEndpointsEnum.LIBRARY_ID);
};
