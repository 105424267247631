import React, { memo, useMemo } from "react";

import cx from "classnames";
import { Tab, Tabs } from "react-bootstrap";

import Typography from "@/components/Typography/Typography";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import FileManagement from "./FileManagement/FileManagement";
import MemberManagement from "./MemberManagement/MemberManagement";
import NodeManagement from "./NodeManagement/NodeManagement";
import styles from "./Overview.module.scss";
import OverviewTab from "./OverviewTab/OverviewTab";
import { UpdateDescriptionType, updateStyleNode } from "../../mindmap";

const Overview = () => {
  const { currentSheet } = useAppSelector((state) => state.mindmap);
  const dispatch = useAppDispatch();
  const tabs = useMemo(() => {
    return [
      {
        eventKey: "overview",
        title: "Thảo luận",
        component: <OverviewTab />,
      },
      {
        eventKey: "manana_node",
        title: "Quản lý Node",
        component: <NodeManagement />,
      },
      {
        eventKey: "attachments",
        title: "Lưu trữ",
        component: <FileManagement />,
      },
      {
        eventKey: "members",
        title: "Thành viên",
        component: <MemberManagement />,
      },
      {
        eventKey: "documents",
        title: "Tài liệu",
        component: <OverviewTab />,
      },
    ];
  }, []);
  const onUpdateName = (name: string) => {
    if (!name.trim()) {
      return;
    }
    dispatch(
      updateStyleNode({
        type: UpdateDescriptionType.NAME,
        name: name.trim(),
      })
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className="d-flex gap-4 align-items-center">
        <div
          className={cx("fs-20 fw-600 flex-1", styles.name)}
          contentEditable
          dangerouslySetInnerHTML={{
            __html: currentSheet.selectedNode?.data.name || "",
          }}
          onBlur={(e) => onUpdateName(e.currentTarget.innerHTML)}
        ></div>
        <i className="tgn-outline-more-horizontal fs-20 text-primary-color-gray-10"></i>
      </div>
      <Typography className="fs-14 text-tertiary-color-gray-5 mt-4">
        ID: {currentSheet.selectedNode?.data?.id}
      </Typography>

      <div>
        <div className="position-relative">
          <Tabs
            defaultActiveKey="overview"
            id="uncontrolled-tab-example"
            className={styles.tabs}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.eventKey}
                className={styles.tab}
                eventKey={tab.eventKey}
                title={tab.title}
              >
                {tab.component}
              </Tab>
            ))}
          </Tabs>
          <div className={styles.setting}>
            <i className="tgn-outline-search-01 fs-16 text-secondary-color-gray-7 cursor-pointer"></i>
            <i className="tgn-outline-more-horizontal fs-16 text-secondary-color-gray-7 cursor-pointer"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Overview);
