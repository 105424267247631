import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAppSelector } from "@/redux/store";

import Approved from "./components/Approved";
import Requested from "./components/Requested";
import { GroupUserRoleEnums } from "../../constants/group.constant";
import { useCheckRoleAndDevice } from "../../hooks/useCheckRoleAndDevice";

const GroupDeviceScreen = () => {
  useCheckRoleAndDevice(
    [GroupUserRoleEnums.OWNER, GroupUserRoleEnums.ADMIN],
    true
  );
  const navigate = useNavigate();
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const urlParams = new URLSearchParams(window.location.search);
  const [tabActive, setTabActive] = useState(
    urlParams.get("tab") || "approved"
  );
  const changeTab = (tab: string) => {
    urlParams.set("tab", tab),
      navigate(`${window.location.pathname}?${urlParams.toString()}`);
  };

  return (
    <>
      {groupInfo && (
        <div className="pb-6">
          <div className="bg-white pt-5 pb-6 z-10 sticky top-0">
            <div className="container">
              <p className="text-lg font-semibold text-secondary-900">
                Quản lý thiết bị
              </p>
              <p className="text-xs text-secondary-600">
                Quản lý những thiết bị truy cập bởi thành viên Tổ chức của bạn.
              </p>
            </div>
          </div>
          <Tabs defaultValue={tabActive || "approved"}>
            <div className="bg-white z-10 sticky top-[88px] border-secondary-100 border-b">
              <div className="container">
                <TabsList className="p-0 flex justify-start">
                  <TabsTrigger
                    value="approved"
                    onClick={() => changeTab("approved")}
                  >
                    Danh sách thiết bị
                  </TabsTrigger>
                  <TabsTrigger
                    value="waiting"
                    onClick={() => changeTab("waiting")}
                  >
                    Chờ phê duyệt
                  </TabsTrigger>
                </TabsList>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-start-3 col-end-11">
                <TabsContent value="approved">
                  <Approved />
                </TabsContent>
                <TabsContent value="waiting">
                  <Requested />
                </TabsContent>
              </div>
            </div>
          </Tabs>
        </div>
      )}
    </>
  );
};

export default GroupDeviceScreen;
