import React, { useEffect, memo, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { LoadingIcon } from "@/assets/icons";
import { Toaster } from "@/components/ui/sonner";
import { Toaster as CustomToaster } from "@/components/ui/toaster";
import { AppPathsEnum } from "@/constants/app.constants";
import {
  getCurrentUsername,
  removeTokenFromStorage,
} from "@/features/auth/api/auth";
import {
  authAction,
  getLibraryIdThunk,
} from "@/features/auth/redux/auth.slice";
import { notificationAction } from "@/features/notification/redux/notification.slice";
import { INotification } from "@/features/notification/types/notification.type";
import { getNotificationText } from "@/features/notification/utils/notificationText";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import RoutesWrapper from "@/routes/RoutesWrapper";
import { formatDistance } from "@/utils/date";

import InsideLayout from "./InsideLayout";
import Header from "./Nav/Header";
import Nav from "./Nav/Nav";
import TGNAvatar from "../TGNAvatar/TGNAvatar";
import TGNLoading from "../TGNLoading/TGNLoading";
import { Dialog, DialogContent } from "../ui/dialog";
import { useToast } from "../ui/use-toast";

const Default = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.notification);
  const loadingGlobal = useAppSelector((state) => state.common.loading);
  const location = useLocation();
  const { user } = useAppSelector((state) => state.auth);
  const { toast } = useToast();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const userInfo = await getCurrentUsername();
        if (!userInfo) {
          removeTokenFromStorage();
          navigate(AppPathsEnum.LOGIN);
        } else {
          if (location.pathname === AppPathsEnum.LOGIN) {
            navigate("/");
          }
          dispatch(authAction.setUserData(userInfo));
          dispatch(getLibraryIdThunk());
        }
      } catch (error) {
        removeTokenFromStorage();
        navigate(AppPathsEnum.LOGIN);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    const urlEndpoint =
      "https://dev.taganow.vn/api/v1/notification/event?userId=" + user?.id;
    const eventSource = new EventSource(urlEndpoint);
    function listenEvent(event: MessageEvent) {
      const decode = event.data.replace(/\+/g, " ");
      // Decode the URI component
      const decodedText = decodeURIComponent(decode);
      const item = JSON.parse(decodedText) as INotification;
      toast({
        variant: "default",
        description: (
          <div>
            {item.photoUrl ? (
              <img
                src={item.photoUrl}
                alt=""
                className="w-10 h-10 object-cover"
              />
            ) : (
              <TGNAvatar
                size="40"
                name={item.sender.name}
              />
            )}
            <div>
              <div
                className="text-sm mb-1"
                dangerouslySetInnerHTML={{
                  __html: getNotificationText(item),
                }}
              ></div>
              <p className="text-xs text-secondary-600">
                {formatDistance(item.createdAt)} trước
              </p>
            </div>
          </div>
        ),
      });
      if (!notifications.find((notify) => notify.id === item.id)) {
        dispatch(notificationAction.addNotification(item));
      }
    }
    eventSource.addEventListener("notificationEvent", listenEvent);
    eventSource.addEventListener("error", () => {
      eventSource.close();
    });
    return () => {
      eventSource.removeEventListener("notificationEvent", listenEvent);
      eventSource.removeEventListener("error", () => {
        eventSource.close();
      });
      eventSource.close();
    };
  }, [user?.id, notifications, dispatch]);

  return (
    <>
      {loading ? (
        <TGNLoading
          isFullScreen={true}
          size="large"
        />
      ) : (
        <div className="app min-h-screen">
          <Dialog open={loadingGlobal.show}>
            <DialogContent className="p-6 text-center max-w-[400px] gap-2">
              <LoadingIcon className="animate-spin duration-[3000ms] mx-auto" />
              <p className="text-secondary-900 text-lg font-semibold mt-2">
                {loadingGlobal.title}
              </p>

              <p className="text-secondary-600 text-sm">
                {loadingGlobal.description}
              </p>
            </DialogContent>
          </Dialog>
          {/* <Nav /> */}
          <Toaster />
          <CustomToaster />
          {/* <Toaster
            position="top-right"
            reverseOrder={false}
            gutter={8}
            toastOptions={{
              className: "p-4 rounded border-t-2 min-w-[332px] shadow-lg",
              success: {
                className:
                  "p-4 rounded border-t-2 min-w-[332px] shadow-lg border-success-600",
                icon: (
                  <div className="p-1 bg-success-100 border-[6px] rounded-full border-success-25 box-border">
                    <CheckmarkFilled
                      size={20}
                      className="text-success-500"
                    />
                  </div>
                ),
              },
              error: {
                className:
                  "p-4 rounded border-t-2 min-w-[332px] shadow-lg border-error-600",
                icon: (
                  <div className="p-1 bg-error-100 border-[6px] rounded-full border-error-25 box-border">
                    <CloseFilled
                      size={20}
                      className="text-error-600"
                    />
                  </div>
                ),
              },
              loading: {
                className: "p-4 rounded border-t-2 min-w-[332px] shadow-lg",
              },
            }}
          >
            {(t) => (
              <ToastBar toast={t}>
                {({ icon, message }) => (
                  <>
                    {icon}
                    {message}
                    {t.type !== "loading" && t.type !== "success" && (
                      <Close onClick={() => toast.dismiss(t.id)} />
                    )}
                  </>
                )}
              </ToastBar>
            )}
          </Toaster> */}
          <InsideLayout>
            <RoutesWrapper />
          </InsideLayout>
        </div>
      )}
    </>
  );
};

export default memo(Default);
