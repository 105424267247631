import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, error, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex w-full text-secondary-900 rounded border border-input bg-background px-3 py-[9px] shadow-sm text-sm leading-5 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-secondary-400 hover:border-primary-600 focus:shadow-active focus:border-primary-600 focus-visible:outline-none disabled:bg-secondary-100 disabled:text-secondary-300 disabled:hover:border-secondary-200 disabled:cursor-not-allowed read-only:bg-secondary-100 read-only:cursor-not-allowed read-only:hover:border-input read-only:focus:shadow-none read-only:focus:border-input",
          error
            ? "border-error-600 hover:border-error-600 focus:border-error-600 focus:shadow-error"
            : "",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
