import React, { memo } from "react";

import { useAppDispatch } from "@/redux/store";

import styles from "./Menubar.module.scss";
import { addNode, addSubnode, removeNode } from "../../mindmap";
import OverlayAction from "../OverlayAction/OverlayAction";
import AssigneePopup from "../Popup/AssigneePopup/AssigneePopup";
import DatePopup from "../Popup/DatePopup/DatePopup";
import HastagPopup from "../Popup/HastagPopup/HastagPopup";

const MenuBar = () => {
  const dispatch = useAppDispatch();
  const actionList = [
    {
      icon: "tgn-outline-task-add",
      tooltip: "Add Task",
      shorthand: "P",
      onClick: () => dispatch(addNode()),
    },
    {
      icon: "tgn-outline-mail-plus",
      tooltip: "Add Child Task",
      shorthand: "P",
      onClick: () => dispatch(addSubnode()),
    },
    {
      icon: "tgn-outline-user-add",
      content: <AssigneePopup />,
      tooltip: "Add Assignees",
      shorthand: "P",
    },
    {
      icon: "tgn-outline-calendar-check",
      content: <DatePopup />,
      tooltip: "Setup Date",
      shorthand: "P",
    },
    {
      icon: "tgn-outline-tag-01",
      content: <HastagPopup />,
      tooltip: "Add Hashtag",
      shorthand: "P",
    },
    {
      icon: "tgn-outline-copy",
      tooltip: "Duplicate",
      shorthand: "P",
    },
    {
      icon: "tgn-outline-trash",
      tooltip: "Delete",
      shorthand: "P",
      onClick: () => dispatch(removeNode()),
    },
  ];
  return (
    <div className={styles.menuBar}>
      <div className={styles.actions}>
        {actionList.map((item, index) => {
          return (
            <div key={index}>
              <OverlayAction
                content={item.content}
                icon={item.icon}
                tooltip={item.tooltip}
                shorthand={item.shorthand}
                onClick={item.onClick}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(MenuBar);
