import React, { FC, Fragment, memo, useMemo, useState } from "react";

import { Information, Renew, RowDelete, TrashCan } from "@carbon/icons-react";
import clsx from "clsx";

import Divider from "@/components/Divider/Divider";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { formatBytes, getContentName } from "@/utils/file";

import FileIcon from "../../components/FileIcon";
import {
  ContentTypeEnums,
  FolderTrashMenuActions,
} from "../../constants/lib.constants";
import { IContent } from "../../types/lib.types";

type ContentItemProps = {
  content: Partial<IContent>;
  active: boolean;
  onMenuClick: (action: FolderTrashMenuActions) => void;
};

const ContentItem: FC<ContentItemProps> = ({
  content,
  active,
  onMenuClick,
}) => {
  const [open, setOpen] = useState(false);
  const actionBarList = useMemo(() => {
    return [
      {
        label: "Khôi phục",
        icon: <Renew />,
        action: FolderTrashMenuActions.Restore,
      },
      {
        label: "Thông tin",
        icon: <Information />,
        action: FolderTrashMenuActions.Detail,
      },
      {
        label: "Xóa vĩnh viễn",
        icon: <RowDelete />,
        action: FolderTrashMenuActions.Delete,
      },
      {
        label: "Xóa tất cả",
        icon: <TrashCan />,
        action: FolderTrashMenuActions.DeleteAll,
      },
    ];
  }, []);

  return (
    <ContextMenu onOpenChange={(o) => setOpen(o)}>
      <ContextMenuTrigger>
        <div
          className={clsx(
            "w-[180px] cursor-pointer p-2 rounded h-full",
            open || active ? "bg-secondary-100" : ""
          )}
        >
          <FileIcon
            extension={content.extension}
            size={64}
          />
          <p className="text-xs font-semibold mt-2 break-words">
            {getContentName(content.name || "")}
          </p>
          {content.type === ContentTypeEnums.FILE && (
            <p className="text-xs text-secondary-600">
              {formatBytes(content.size || 0)}
            </p>
          )}
        </div>
      </ContextMenuTrigger>
      <ContextMenuContent className="bg-white p-1">
        {actionBarList.map((item) => (
          <Fragment key={item.label}>
            <ContextMenuItem className="p-0 min-w-[240px]">
              <div
                className="flex gap-1 items-center hover:bg-secondary-100 w-full px-2 py-[10px] rounded-sm cursor-pointer"
                onClick={() => onMenuClick(item.action)}
              >
                <div className="mr-1">{item.icon}</div>
                {item.label}
              </div>
            </ContextMenuItem>
            {["Khôi phục", "Thông tin"].includes(item.label) && (
              <Divider
                className="my-1 !w-[calc(100%-16px)] mx-auto"
                height={1}
              />
            )}
          </Fragment>
        ))}
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default memo(ContentItem);
