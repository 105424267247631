import React, { useState, useEffect } from "react";

import { ChevronDown, InformationFilled, MailReply } from "@carbon/icons-react";

import { Empty } from "@/assets/icons";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";
import { extractUsername } from "@/utils/extractUsername";
import { COMMON_MESSAGES, getMessage } from "@/utils/message";

import { cancelGroupInvited, getListGroupInvited } from "../../api/group.api";
import {
  GroupUserRoleEnums,
  GroupUserStatusEnum,
  GroupUserTypeEnum,
} from "../../constants/group.constant";
import { useCheckRoleAndDevice } from "../../hooks/useCheckRoleAndDevice";
import { IInvitation } from "../../types/group.type";

const InviteRequestedScreen = () => {
  useCheckRoleAndDevice(
    [GroupUserRoleEnums.OWNER, GroupUserRoleEnums.ADMIN],
    true
  );

  const dispatch = useAppDispatch();
  const { data: groupInfo, loading } = useAppSelector((state) => state.group);
  const { user } = useAppSelector((state) => state.auth);
  const { toast } = useToast();
  const [totalPage, setTotalPage] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inviteRequests, setInviteRequests] = useState<IInvitation[] | []>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedInvite, setSelectedInvite] = useState<IInvitation | null>(
    null
  );

  const fetchInviteRequest = async (reload?: boolean) => {
    try {
      if (groupInfo) {
        const result = await getListGroupInvited({
          groupId: groupInfo.id,
          pageNumber: currentPage,
          pageSize: 5,
        });
        if (reload) {
          setInviteRequests(result.data);
        } else {
          setInviteRequests([...inviteRequests, ...result.data]);
        }
        setTotalPage(result.totalPage);
      }
    } catch (error: any) {
      if (error.response?.data.errors[0]) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    }
  };
  useEffect(() => {
    fetchInviteRequest();
  }, [currentPage, loading]);

  const handleCancel = async (item: IInvitation) => {
    setIsSubmitting(true);
    try {
      if (item) {
        if (selectedInvite) {
          await cancelGroupInvited(selectedInvite.id);
        }
        await fetchInviteRequest(true);
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: COMMON_MESSAGES.MSG79.replaceAll(
                  "${0}",
                  `<span class='font-semibold'>${
                    selectedInvite?.receiver.name ||
                    selectedInvite?.receiver.email
                  }</span>`
                ),
              }}
            ></div>
          ),
        });
        setSelectedInvite(null);
        setOpenConfirmModal(false);
      }
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="pb-6">
      {groupInfo && (
        <>
          <div className="bg-white pt-5 pb-6 z-10 sticky top-0 border-b border-secondary-100">
            <div className="container">
              <p className="text-lg font-semibold text-secondary-900">
                Lời mời đã gửi
              </p>
              <p className="text-xs text-secondary-600">
                Danh sách lời mời đã gửi tham gia{" "}
                {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} của bạn
              </p>
            </div>
          </div>
          {inviteRequests.length > 0 ? (
            <div className="grid grid-cols-12">
              <div className="col-start-3 col-end-11">
                <div className="mt-6">
                  <p className="text-base font-semibold text-secondary-900">
                    Đã mời
                  </p>
                  <p className="text-xs text-secondary-600">
                    Người dùng nhận được lời mời tham gia có quyền xem trước
                    thông tin của {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}.
                  </p>
                  <div className="p-3 pb-6 rounded-lg border border-secondary-200 bg-white mt-6 grid gap-3">
                    {inviteRequests.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="p-3 flex gap-3 items-center justify-between"
                        >
                          {item.groupUserType ===
                          GroupUserTypeEnum.INVITE_EMAIL ? (
                            <div
                              className={`w-[64px] h-[64px] bg-secondary-50 rounded-full flex justify-center items-center ${
                                item.groupUserStatus ===
                                GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
                                  ? "opacity-60"
                                  : ""
                              }`}
                            >
                              <MailReply size={24} />
                            </div>
                          ) : (
                            <TGNAvatar
                              className={`${
                                item.groupUserStatus ===
                                GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
                                  ? "opacity-60"
                                  : ""
                              }`}
                              userName={
                                item.receiver.name ||
                                extractUsername(item.receiver.email || "")
                              }
                              src={item.receiver.photoUrl}
                            />
                          )}
                          <div className="flex-1">
                            <div className="flex items-center">
                              <p
                                className={`text-sm text-secondary-900 font-medium ${
                                  item.groupUserStatus ===
                                  GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
                                    ? "opacity-60"
                                    : ""
                                }`}
                              >
                                {item.groupUserType ===
                                GroupUserTypeEnum.INVITE_EMAIL
                                  ? item.receiver.email
                                  : item.receiver.name ||
                                    extractUsername(item.receiver.email || "")}
                              </p>
                              <span>
                                {item.groupUserStatus ===
                                  GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE && (
                                  <TooltipProvider>
                                    <Tooltip>
                                      <TooltipTrigger>
                                        <Badge
                                          variant={"orange"}
                                          className="rounded uppercase pl-2 py-0.5 ml-1 opacity-100"
                                        >
                                          Đang xử lý
                                        </Badge>
                                      </TooltipTrigger>
                                      <TooltipContent className="opacity-100">
                                        <p>
                                          Lời mời này đã được chuyển tiếp lên Tổ
                                          chức và đang chờ phê duyệt.
                                        </p>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                )}
                              </span>
                            </div>
                            <p
                              className={`text-xs text-secondary-600 ${
                                item.groupUserStatus ===
                                GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
                                  ? "opacity-60"
                                  : ""
                              }`}
                            >
                              {item.groupUserType === GroupUserTypeEnum.INVITE
                                ? item.receiver.email || item.receiver.phone
                                : ""}
                            </p>
                            <p
                              className={`text-xs text-secondary-600 mt-2 ${
                                item.groupUserStatus ===
                                GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
                                  ? "opacity-60"
                                  : ""
                              }`}
                            >
                              Được mời bởi{" "}
                              <span className="font-medium">
                                {item.sender.name}{" "}
                                {user?.id === item.sender.id ? "(Bạn)" : ""}
                              </span>{" "}
                              {formatDistance(item.invitedAt)} trước
                            </p>
                          </div>
                          {item.groupUserStatus !==
                            GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE && (
                            <>
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <div>
                                      <Button
                                        className="ml-auto"
                                        variant="ghost"
                                        type={"button"}
                                        disabled={user?.id !== item.sender.id}
                                        onClick={() => {
                                          if (user?.id !== item.sender.id) {
                                            return;
                                          }
                                          setSelectedInvite(item);
                                          setOpenConfirmModal(true);
                                        }}
                                      >
                                        Huỷ lời mời
                                      </Button>
                                    </div>
                                  </TooltipTrigger>
                                  {user?.id !== item.sender.id && (
                                    <TooltipContent>
                                      <p className="w-[300px]">
                                        Bạn không thể huỷ lời mời do Chủ sở
                                        hữu/Quản trị viên khác tạo
                                      </p>
                                    </TooltipContent>
                                  )}
                                </Tooltip>
                              </TooltipProvider>
                              <Dialog open={openConfirmModal}>
                                <DialogContent className="gap-10">
                                  <DialogHeader className="flex flex-row gap-4">
                                    <div>
                                      <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full block">
                                        <InformationFilled size={20} />
                                      </span>
                                    </div>
                                    <div className="flex-1">
                                      <DialogTitle className="mb-2">
                                        Gỡ lời mời
                                      </DialogTitle>
                                      <DialogDescription>
                                        <p className="text-secondary-600 text-sm"></p>
                                        Bạn chắc chắn muốn huỷ lời mời tới{" "}
                                        {selectedInvite && (
                                          <span className="font-medium">
                                            {selectedInvite.receiver.name ||
                                              extractUsername(
                                                selectedInvite.receiver.email ||
                                                  ""
                                              )}
                                          </span>
                                        )}
                                        ? Người này sẽ không còn được mời tham
                                        gia{" "}
                                        {groupInfo.rootGroupId
                                          ? "Nhóm "
                                          : "Tổ chức "}
                                        của bạn nữa.
                                      </DialogDescription>
                                    </div>
                                  </DialogHeader>
                                  <DialogFooter>
                                    <Button
                                      onClick={() => setOpenConfirmModal(false)}
                                      size="sm"
                                      variant={"outline"}
                                      type="button"
                                    >
                                      Huỷ bỏ
                                    </Button>
                                    <Button
                                      loading={isSubmitting}
                                      onClick={() => handleCancel(item)}
                                      type="button"
                                      variant="secondary"
                                      size="sm"
                                    >
                                      Xác nhận
                                    </Button>
                                  </DialogFooter>
                                </DialogContent>
                              </Dialog>
                            </>
                          )}
                        </div>
                      );
                    })}
                    {currentPage < totalPage && (
                      <div className="flex justify-center">
                        <Button
                          variant="text"
                          iconAfter={<ChevronDown />}
                          type="button"
                          onClick={() => setCurrentPage(currentPage + 1)}
                        >
                          Xem thêm
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col h-[calc(100vh-131px)] -mt-10 items-center justify-center flex-1">
              <div className="flex flex-col items-center justify-center max-w-[229px] text-center">
                <Empty />
                <span className="text-secondary-600 text-xs mt-4 font-semibold">
                  Danh sách lời mời đã gửi trống!
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InviteRequestedScreen;
