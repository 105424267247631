import React, { memo, useEffect, useState } from "react";

import { ChevronRight, TwoFactorAuthentication } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

import { useToast } from "@/components/ui/use-toast";
import { AppPathsEnum } from "@/constants/app.constants";
import { removeTokenFromStorage } from "@/features/auth/api/auth";
import { authAction, getProfileThunk } from "@/features/auth/redux/auth.slice";
import useToastError from "@/hooks/useToastError";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import ChangePasswordDialog from "./ChangePasswordDialog";
import EnableTwoFactorAuthenticationDialog from "./EnableTwoFactorAuthenticationDialog";
import UpdateEmailModal from "./UpdateEmailModal";
import UpdateEmailSuccess from "./UpdateEmailSuccess";
import UpdatePhoneNumberModal from "./UpdatePhoneNumberModal";
import {
  getUserMFAListThunk,
  UpdateEmailOrPhoneNumberParams,
  UserMFAEnums,
} from "../account";
import {
  changePassword,
  removeEmailOrPhoneNumber,
  updateEmailOrPhoneNumberApi,
  updateUserMFAType,
} from "../api/account.api";

const AccountAndSecurity = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { showToastError } = useToastError();
  const [newEmail, setNewEmail] = useState("");
  const [openUpdateEmailModal, setOpenUpdateEmailModal] = useState(false);
  const [openUpdateEmailSuccessDialog, setOpenUpdateEmailSuccessDialog] =
    useState(false);
  const [openUpdatePhoneNumberModal, setOpenUpdatePhoneNumberModal] =
    useState(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [
    openEnableTwoFactorAuthenticationDialog,
    setOpenEnableTwoFactorAuthenticationDialog,
  ] = useState(false);
  const handleSubmitMFA = async (type: UserMFAEnums) => {
    try {
      setLoading(true);
      await updateUserMFAType(type);
      setOpenEnableTwoFactorAuthenticationDialog(false);
      toast({
        title: "Thành công!",
        variant: "success",
        description:
          type === UserMFAEnums.OFF
            ? getMessage("MSG_TK32")
            : getMessage("MSG_TK13"),
      });
      await dispatch(getProfileThunk());
    } catch (error) {
      showToastError(error);
    } finally {
      setLoading(false);
    }
  };
  const handleChangePassword = async (values: {
    currentPwd: string;
    pwd: string;
    otp: string;
    uid: string;
  }) => {
    const { currentPwd, pwd, otp, uid } = values;
    try {
      setLoading(true);
      await changePassword({
        currentPassword: currentPwd,
        newPassword: pwd,
        otp,
        uid,
      });
      toast({
        title: "Thành công!",
        variant: "success",
        description: getMessage("MSG_TK19"),
      });
      setOpenChangePasswordDialog(false);
    } catch (error) {
      showToastError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(getUserMFAListThunk());
  }, [dispatch]);
  const updateEmailOrPhoneNumber = async (
    params: UpdateEmailOrPhoneNumberParams
  ) => {
    try {
      setLoading(true);
      await updateEmailOrPhoneNumberApi(params);
      setNewEmail(params.email || "");
      setOpenEnableTwoFactorAuthenticationDialog(false);
      toast({
        title: "Thành công!",
        variant: "success",
        description: params.email
          ? getMessage("MSG_TK30")
          : getMessage("MSG_TK24"),
      });
      await dispatch(getProfileThunk());
      setOpenUpdateEmailModal(false);
      setOpenUpdatePhoneNumberModal(false);
      if (params.email) {
        setOpenUpdateEmailSuccessDialog(true);
      }
    } catch (error) {
      showToastError(error);
    } finally {
      setLoading(false);
    }
  };
  const logout = () => {
    setOpenUpdateEmailSuccessDialog(false);
    dispatch(authAction.setUserData(null));
    removeTokenFromStorage();
    navigate(AppPathsEnum.LOGIN);
  };
  const handleRemoveEmail = async () => {
    setOpenUpdateEmailModal(false);
    try {
      await removeEmailOrPhoneNumber({ email: true });
      toast({
        title: "Thành công!",
        variant: "success",
        description: getMessage("MSG_TK25"),
      });
      await dispatch(getProfileThunk());
    } catch (error) {
      showToastError(error);
    }
  };
  const handleRemovePhoneNumber = async () => {
    setOpenUpdatePhoneNumberModal(false);
    try {
      await removeEmailOrPhoneNumber({ phoneNumber: true });
      toast({
        title: "Thành công!",
        variant: "success",
        description: getMessage("MSG_TK26"),
      });
      await dispatch(getProfileThunk());
    } catch (error) {
      showToastError(error);
    }
  };

  return (
    <div className="w-full">
      <div className="mt-6 max-w-[696px] text-sm mx-auto">
        <p className="text-base font-semibold text-secondary-900 mb-1">
          Tài khoản & Bảo mật
        </p>
        <p className="text-xs text-secondary-600">
          Quản lý và thiết lập tài khoản và trải nghiệm kết nối trên nền tảng
          Mạng xã hội Taga.{" "}
          <span className="text-secondary-900 font-semibold">
            Tìm hiểu thêm ↗
          </span>
        </p>

        <p className="text-secondary-600 font-medium uppercase text-xs mt-10 mb-4">
          Thông tin đăng nhập
        </p>
        <div className="rounded-[8px] border border-solid border-secondary-200 p-3 shadow-sm text-sm">
          <div className="flex justify-between items-center p-3 rounded transition hover:bg-secondary-100 cursor-pointer">
            <div>
              <p className="mb-1 text-sm text-secondary-900 font-medium">
                Tên người dùng
              </p>
              <p className="text-secondary-600">@{user?.username}</p>
            </div>
            <ChevronRight size={16} />
          </div>
          <div
            className="flex justify-between items-center p-3 rounded transition hover:bg-secondary-100 cursor-pointer"
            onClick={() => setOpenUpdatePhoneNumberModal(true)}
          >
            <div>
              <p className="mb-1 text-sm text-secondary-900 font-medium">
                Số điện thoại
              </p>
              <p className="text-secondary-600">{user?.phoneNumber}</p>
            </div>
            <ChevronRight size={16} />
          </div>
          <div
            className="flex justify-between items-center p-3 rounded transition hover:bg-secondary-100 cursor-pointer"
            onClick={() => setOpenUpdateEmailModal(true)}
          >
            <div>
              <p className="mb-1 text-sm text-secondary-900 font-medium">
                Email
              </p>
              <p className="text-secondary-600">{user?.email}</p>
            </div>
            <ChevronRight size={16} />
          </div>
          <div className="flex justify-between items-center p-3 rounded transition hover:bg-secondary-100 cursor-pointer">
            <div>
              <p className="mb-1 text-sm text-secondary-900 font-medium">
                Xác thực
              </p>
              <p className="text-secondary-600">
                Không.{" "}
                <span className="text-primary font-medium">Tìm hiểu thêm</span>
              </p>
            </div>
            <ChevronRight size={16} />
          </div>
        </div>

        <p className="text-secondary-600 font-medium uppercase text-xs mt-10 mb-4">
          Mật khẩu & bảo mật
        </p>
        <div className="rounded-[8px] border border-solid border-secondary-200 p-3 shadow-sm text-sm">
          <div
            className="flex justify-between items-center p-3 rounded transition hover:bg-secondary-100 cursor-pointer"
            onClick={() => setOpenChangePasswordDialog(true)}
          >
            <div>
              <p className="mb-1 text-sm text-secondary-900 font-medium">
                Thay đổi mật khẩu
              </p>
              <p className="text-secondary-600">**********</p>
            </div>
            <ChevronRight size={16} />
          </div>
          <div
            className="flex justify-between items-center p-3 rounded transition hover:bg-secondary-100 cursor-pointer"
            onClick={() => setOpenEnableTwoFactorAuthenticationDialog(true)}
          >
            <div>
              <p className="mb-1 text-sm text-secondary-900 font-medium">
                Xác thực 2 yếu tố
              </p>
              <p className="text-secondary-600">
                Bảo vệ tài khoản của bạn khỏi bị truy cập lạ bằng cách yêu cầu
                xác thực lớp thứ hai. Bạn có thể chọn phương thức tin nhắn văn
                bản, ứng dụng xác thực hoặc khóa bảo mật.{" "}
                <span className="text-primary font-medium text-xs">
                  Tìm hiểu thêm
                </span>
              </p>
              <div className="flex items-center gap-2 text-secondary-400 mt-2">
                <TwoFactorAuthentication />
                <span className="font-semibold text-sm">
                  {user?.userMFAType ? "Đã bật" : "Chưa kích hoạt"}
                </span>
              </div>
            </div>
            <ChevronRight size={16} />
          </div>
        </div>

        <div className="rounded-[8px] border border-solid border-secondary-200 p-3 shadow-sm text-sm mt-5">
          <div className="flex justify-between items-center p-3 rounded transition hover:bg-secondary-100 cursor-pointer">
            <div>
              <p className="mb-1 text-sm text-secondary-900 font-medium">
                Cảnh báo đăng nhập
              </p>
              <p className="text-secondary-600">
                Kiểm soát thiết bị đăng nhập vào tài khoản.
              </p>
            </div>
            <ChevronRight size={16} />
          </div>
        </div>
      </div>
      <EnableTwoFactorAuthenticationDialog
        open={openEnableTwoFactorAuthenticationDialog}
        onClose={() => setOpenEnableTwoFactorAuthenticationDialog(false)}
        onSubmitMFA={handleSubmitMFA}
        loading={loading}
      />
      <ChangePasswordDialog
        open={openChangePasswordDialog}
        loading={loading}
        onClose={() => {
          setOpenChangePasswordDialog(false);
        }}
        onSubmit={handleChangePassword}
      />
      <UpdateEmailModal
        open={openUpdateEmailModal}
        onClose={() => setOpenUpdateEmailModal(false)}
        onSubmit={updateEmailOrPhoneNumber}
        onDelete={handleRemoveEmail}
      />
      <UpdatePhoneNumberModal
        open={openUpdatePhoneNumberModal}
        onClose={() => setOpenUpdatePhoneNumberModal(false)}
        onSubmit={updateEmailOrPhoneNumber}
        onDelete={handleRemovePhoneNumber}
      />
      <UpdateEmailSuccess
        open={openUpdateEmailSuccessDialog}
        onOK={logout}
        email={newEmail}
      />
    </div>
  );
};

export default memo(AccountAndSecurity);
