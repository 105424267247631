import React, { FC, ReactNode, memo, useState } from "react";

type SubNavProps = {
  children: ReactNode;
};

const SubNav: FC<SubNavProps> = ({ children }) => {
  const [expand] = useState(true);
  // const iconExpandButton = expand ? <ShrinkIcon /> : <ExpandIcon />;
  return (
    <div className="h-[calc(100vh-70px)] fixed w-[280px]  [transition:all_0.2s_ease-in-out] top-[70px] overflow-hidden border-r border-secondary-100">
      {children}
    </div>
  );
};

export default memo(SubNav);
