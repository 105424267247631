import React, { FC, memo, useState } from "react";

import {
  Earth,
  Locked,
  PinFilled,
  UserAvatar,
  OverflowMenuVertical,
  DotMark,
} from "@carbon/icons-react";
import { Link } from "react-router-dom";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import {
  getGroupInvitationForUser,
  userCancelRequestToGroup,
} from "@/features/groups/api/group.api";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { IGroupPersonalRequest } from "@/features/groups/types/group.type";
import { useAppSelector } from "@/redux/store";
import formatNumber from "@/utils/formatNumber";
import { getMessage } from "@/utils/message";

import CancelModal from "./CancelModal";

interface GroupCardRequestProps {
  group: IGroupPersonalRequest;
}

const GroupCardRequest: FC<GroupCardRequestProps> = ({ group }) => {
  const { toast } = useToast();
  const [requesting, setRequesting] = useState(false);
  const [requested, setRequested] = useState(false);

  return (
    <div className="relative rounded-lg border border-secondary-200 hover:shadow-lg overflow-hidden">
      <img
        className="object-cover h-[140px] w-full"
        src={group.photoUrl ? group.photoUrl : GroupCover}
        alt=""
      />
      <div className="p-4">
        <p className="text-xs text-secondary-600 font-medium uppercase mb-2 truncate">
          Tổ chức
          {group.groupId !== group.rootId && (
            <span className="">
              ・
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Link
                      className="text-primary-600 hover:text-primary-800 truncate text-ellipsis"
                      to={GroupPathsEnum.GROUP_DETAIL.replace(
                        ":id",
                        String(group.rootId)
                      )}
                    >
                      {group.rootName}
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent>
                    <span className="text-xs"> {group.rootName}</span>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </span>
          )}
        </p>
        <div className="min-h-[76px]">
          <p>
            <Link
              className="font-semibold text-base leading-6 text-secondary-900 mb-2 line-clamp-2 hover:text-primary-600 inline"
              to={GroupPathsEnum.GROUP_DETAIL.replace(
                ":id",
                String(group.groupId)
              )}
            >
              {group.name}
            </Link>
          </p>
          <div className="flex items-center">
            <span className="text-sm text-secondary-600">
              {formatNumber(group.numberOfMembers)} thành viên
            </span>
          </div>
        </div>

        <div className="mt-3">
          <CancelModal
            inviteId={group.id}
            groupName={group.name}
            rootGroupId={group.rootId}
            groupLock={group.groupLock}
            groupId={group.id}
            button={
              <Button
                loading={requesting}
                type="button"
                variant="ghost"
                className="w-full"
              >
                Huỷ yêu cầu
              </Button>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default memo(GroupCardRequest);
