export const PASSWORD_REGEX =
  // eslint-disable-next-line
  /^(?=.*[A-Z])(?=.*[*@!#%&()^~\[\]{}\\|_\-+=$<>:;"'.]).{8,}$/;

export const MIN_ONE_CHARACTER_UPPERCASE_REGEX = /^(?=.*[A-Z]).{1,}$/;

export const MIN_EIGHT_CHARACTER_REGEX = /^(?=.*[a-zA-Z0-9]).{8,}$/;

export const SPECIAL_CHARACTER_REGEX =
  // eslint-disable-next-line
  /^(?=.*[*@!#%&()^~\[\]{}\\|_\-+=$<>:;"'.]).{1,}$/;
