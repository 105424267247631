import React, { FC, ReactNode, memo } from "react";

import { useAppSelector } from "@/redux/store";

import { GroupUserRoleEnums } from "../constants/group.constant";

type GroupPermissionProps = {
  codes: GroupUserRoleEnums[];
  isVerifyDevice?: boolean;
  children: ReactNode;
};

const GroupPermission: FC<GroupPermissionProps> = ({
  codes,
  children,
  isVerifyDevice = true,
}) => {
  const groupInfo = useAppSelector((state) => state.group.data);
  if (
    !groupInfo ||
    !groupInfo.role ||
    !codes.includes(groupInfo.role?.code) ||
    (isVerifyDevice && !groupInfo.deviceAccepted)
  ) {
    return null;
  }
  return <>{children}</>;
};

export default memo(GroupPermission);
