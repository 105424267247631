import React, { memo, useEffect, useState } from "react";

import { NotificationNew, Forum, Rocket } from "@carbon/icons-react";
import { NavLink } from "react-router-dom";

import TagaLogoColor from "@/assets/images/taganow-logo-color.svg";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import MenuSettingAccountPopup from "@/features/account/screens/MenuSettingAccountPopup";
import { getTokenFromStorage } from "@/features/auth/api/auth";
import { useAppSelector } from "@/redux/store";
import { extractUsername } from "@/utils/extractUsername";

import style from "./Nav.module.scss";

const Header = () => {
  const userInfo = useAppSelector((state) => state.auth.user);
  const [urlStudio, setUrlStudio] = useState("");

  useEffect(() => {
    const authInfo = getTokenFromStorage();
    if (!authInfo) return;
    const params = {
      ...JSON.parse(authInfo),
      callback: "/studio",
    };
    setUrlStudio(
      `https://library.taganow.vn/token/${btoa(JSON.stringify(params))}`
    );
  }, []);

  const navLinks = [
    {
      url: "/",
      end: false,
      icon: "",
      label: "Trang chủ",
    },
    {
      url: "/group",
      end: false,
      icon: "",
      label: "Nhóm",
    },
    {
      url: "/task",
      end: false,
      icon: "",
      label: "Công việc",
    },
    {
      url: urlStudio,
      end: true,
      icon: <Rocket />,
      label: "Studiø",
    },
    {
      url: "/cloud",
      end: false,
      icon: "",
      label: "Thư viện",
    },
  ];
  return (
    <div className="fixed bg-white border-b border-secondary-100 w-full h-[70px]  z-20">
      <div className="flex justify-between py-2 px-4 w-full h-[70px] items-center">
        <a href="/">
          <img
            src={TagaLogoColor}
            alt="logo"
            className="h-[30px] cursor-pointer"
          />
        </a>
        <div className="flex gap-4">
          {navLinks.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.url}
                target={item.end ? "blank" : ""}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "bg-secondary-100 flex items-center py-2.5 px-10 rounded font-medium"
                    : "flex items-center py-2.5 px-10 rounded text-secondary-600 gap-2"
                }
              >
                {item.label}
                {item.icon}
              </NavLink>
            );
          })}
        </div>
        <div className="flex items-center gap-4">
          <a
            className="p-2.5 bg-secondary-100 rounded-full hover:bg-secondary-200"
            href="/notification"
          >
            <NotificationNew
              size="24"
              className="text-secondary-700"
            />
          </a>
          <a
            className="p-2.5 bg-secondary-100 rounded-full hover:bg-secondary-200"
            href="/chats"
          >
            <Forum
              size="24"
              className="text-secondary-700"
            />
          </a>
          {userInfo && (
            <Popover>
              <PopoverTrigger asChild>
                <div className="flex items-center">
                  <TGNAvatar
                    className="cursor-pointer"
                    src={userInfo.photo}
                    name={
                      userInfo.name || extractUsername(userInfo.email || "")
                    }
                    size="40"
                    round
                  />
                </div>
              </PopoverTrigger>
              <PopoverContent className="p-0 border-none shadow-none w-fit h-fit ml-6">
                <MenuSettingAccountPopup />
              </PopoverContent>
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
