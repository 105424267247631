import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAppSelector } from "@/redux/store";

import AdvanceSetting from "./components/AdvanceSetting";
import { GroupUserRoleEnums } from "../../constants/group.constant";
import { useCheckRoleAndDevice } from "../../hooks/useCheckRoleAndDevice";

const SettingUserScreen = () => {
  useCheckRoleAndDevice(
    [
      GroupUserRoleEnums.OWNER,
      GroupUserRoleEnums.ADMIN,
      GroupUserRoleEnums.MEMBER,
    ],
    true
  );
  const navigate = useNavigate();
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const urlParams = new URLSearchParams(window.location.search);
  const [tabActive, setTabActive] = useState(
    urlParams.get("tabActive") || "general"
  );

  return (
    <>
      {groupInfo && (
        <div className="pb-6">
          <div className="bg-white pt-5 pb-6 z-10 sticky top-0">
            <div className="container">
              <p className="text-lg font-semibold text-secondary-900">
                Thiết lập cá nhân
              </p>
              <p className="text-xs text-secondary-600">
                Thiết lập và quản lý{" "}
                {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} của bạn.
              </p>
            </div>
          </div>

          <Tabs defaultValue={tabActive}>
            <div className="bg-white z-10 sticky top-[88px] border-secondary-100 border-b">
              <div className="container">
                <TabsList className="p-0 flex justify-start">
                  <TabsTrigger
                    value="general"
                    onClick={() => (
                      urlParams.set("tabActive", "general"),
                      navigate(
                        `${window.location.pathname}?${urlParams.toString()}`
                      )
                    )}
                  >
                    {" "}
                    Thông tin chung
                  </TabsTrigger>
                  <TabsTrigger
                    value="advance"
                    onClick={() => (
                      urlParams.set("tabActive", "advance"),
                      navigate(
                        `${window.location.pathname}?${urlParams.toString()}`
                      )
                    )}
                  >
                    Thiết lập Nâng cao
                  </TabsTrigger>
                </TabsList>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-start-3 col-end-11">
                <TabsContent value="general">
                  {/* <GeneralSetting /> */}
                  Blank
                </TabsContent>
                <TabsContent value="advance">
                  <AdvanceSetting />
                </TabsContent>
              </div>
            </div>
          </Tabs>
        </div>
      )}
    </>
  );
};

export default SettingUserScreen;
