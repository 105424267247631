import React, { useMemo } from "react";

import { WarningAlt, WarningFilled } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useAppSelector } from "@/redux/store";

import { ContentTypeEnums } from "../../constants/lib.constants";
import { IContent } from "../../types/lib.types";

interface IDialogConfirmMoveProps {
  isShow: boolean;
  sourceContent: IContent | undefined;
  targetContent: IContent | undefined;
  loading: boolean;
  onOpenChange?: (val: boolean) => void;
  onRejectContent?: () => void;
  onAcceptContent?: () => void;
}
export default function DialogConfirmMove({
  isShow,
  sourceContent,
  targetContent,
  loading,
  onOpenChange,
  onRejectContent,
  onAcceptContent,
}: IDialogConfirmMoveProps) {
  const { libraryId } = useAppSelector((state) => state.auth);

  const targetIsRoot = useMemo(
    () => targetContent && targetContent.id === libraryId,
    [targetContent]
  );
  const isFolder = useMemo(
    () => sourceContent?.type === ContentTypeEnums.FOLDER,
    [sourceContent]
  );

  return (
    <Dialog
      open={isShow}
      onOpenChange={onOpenChange}
      onEnterKeyPressWhenOpen={onAcceptContent}
    >
      <DialogContent className="flex flex-col w-[696px] max-w-full h-fit bg-white">
        <button />
        <div className="flex flex-row space-x-4">
          <div className="p-1 rounded-full bg-[#FFFAEB] h-fit">
            <div className="p-1 rounded-full bg-[#FEF0C7]">
              <WarningFilled className="w-6 h-6 text-[#F79009]" />
            </div>
          </div>
          <div>
            <div className="text-lg font-semibold">Di chuyển nội dung</div>
            <div className="mt-2">
              Bạn có chắc muốn di chuyển{" "}
              <span className="font-semibold">"{sourceContent?.name}"</span> tới
              thư mục{" "}
              <span className="font-semibold">
                "{targetIsRoot ? "Thư viện" : targetContent?.name}"
              </span>{" "}
              ?
            </div>
            <div className="flex flex-col bg-[#FFFBFA] text-[#B42318] text-sm px-4 py-2 border-l-2 border-[#D92D20] space-y-2 mt-4">
              <div className="flex flex-row items-center space-x-1">
                <WarningAlt />
                <span className="font-semibold">Cảnh báo</span>
              </div>
              <div>
                Người dùng được cấp quyền với {isFolder ? "thư mục" : "tệp tin"}{" "}
                này sẽ có quyền truy cập và sử dụng nội dung “
                <span className="font-semibold">{sourceContent?.name}</span>”,
                bạn có thể tuỳ chỉnh lại sau.
              </div>
            </div>
            <div className="space-x-2 flex justify-end pt-4">
              <Button
                variant="outline"
                onClick={onRejectContent}
              >
                Hủy
              </Button>
              <Button
                onClick={onAcceptContent}
                loading={loading}
              >
                Đồng ý
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
