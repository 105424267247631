import axios from "axios";

import api from "@/api/api_instance";

import { NotificationEndPointEnum } from "../constants/notification.endpoint";
import { INotificationResponse } from "../types/notification.type";
// eslint-disable-next-line import/order
import { PaginationParams } from "./../../../types/app.types";
// eslint-disable-next-line import/order
import { NotificationStatus } from "./../constants/notification.constant";

export const getNotification = (
  params: { notificationStatusFilter?: NotificationStatus } & PaginationParams
): Promise<INotificationResponse> => {
  return api.get(NotificationEndPointEnum.NOTIFICATION, { params });
};

export const markNotification = (
  notificationId: number,
  notificationStatus: NotificationStatus
) => {
  return api.put(NotificationEndPointEnum.MARK_NOTIFICATION, {
    notificationId,
    notificationStatus,
  });
};

export const markAllAsRead = () => {
  return api.put(NotificationEndPointEnum.MARK_NOTIFICATION_AS_READ);
};

export const deleteNotification = (notificationId: number) => {
  return api.delete(NotificationEndPointEnum.NOTIFICATION, {
    params: {
      notificationId,
    },
  });
};
