import React, { useCallback, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Badge } from "@/components/ui/badge";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { DisplayMode } from "@/constants/app.constants";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import CardUser from "./component/CardUser";
import { UserDetail } from "./component/UserDetail";
import { getGroupMembers } from "../../api/group.api";
import {
  GroupRoleEnum,
  GroupSortEnum,
  GroupTypeEnum,
} from "../../constants/group.constant";
import { getGroupMembersListThunk } from "../../group";
import { IMemberGroup } from "../../types/group.type";

const Member = () => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { data: groupInfo, membersList } = useAppSelector(
    (state) => state.group
  );
  const [page, setPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [userList, setUserList] = useState<IMemberGroup[]>([]);
  const fetchInfiniteScroll = async (pageScroll?: number) => {
    if (groupInfo) {
      const result = await getGroupMembers({
        groupId: groupInfo.id,
        pageNumber: pageScroll,
        pageSize: 9,
      });
      setUserList(() => [...userList, ...result.data]);
    }
  };

  const fetchGroupMembers = useCallback(() => {
    if (groupInfo) {
      dispatch(
        getGroupMembersListThunk({
          pageNumber: 1,
          pageSize: 9,
          groupId: groupInfo.id,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          if (!result.length || result.length < 9) {
            setIsLoadMore(false);
          }
        });
    }
  }, [dispatch, groupInfo]);

  const urlParams = new URLSearchParams(window.location.search);
  const [roleFilter, setRoleFilter] = useState(urlParams.get("role"));
  const [sort, setSort] = useState(urlParams.get("sort"));
  const [typeFilter, setTypeFilter] = useState(urlParams.get("type"));
  const [idUser, setIdUser] = useState<number | null>(
    Number(urlParams.get("idUser"))
  );
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.LIST);

  const handelSetId = (id: number) => {
    setIdUser(id);
    urlParams.set("idUser", String(id));
    navigate(`${window.location.pathname}?${urlParams.toString()}`);
  };

  useEffect(() => {
    fetchGroupMembers();
  }, [fetchGroupMembers]);

  return (
    <div className="min-h-[calc(100vh-280px)] bg-white">
      <div className="z-50 sticky top-[130px]">
        <div className="container">
          <div className="flex justify-between">
            <div className="py-4 flex">
              <p className="font-semibold text-secondary-900 mr-2">
                {groupInfo?.memberCounter} Thành viên
              </p>
            </div>
            <div
              className={"grid gap-4 auto-cols-max grid-flow-col items-center"}
            >
              <div className="text-xs flex items-center">
                <span className="text-xs">Lọc theo:</span>
                <Select
                  defaultValue={roleFilter || "default"}
                  onValueChange={(value) => {
                    if (value === "default") {
                      setRoleFilter(null);
                      urlParams.delete("role");
                    } else {
                      urlParams.set("role", value);
                      setRoleFilter(value);
                      setPage(1);
                    }
                    navigate(
                      `${window.location.pathname}?${urlParams.toString()}`
                    );
                  }}
                >
                  <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium bg-transparent">
                    <SelectValue defaultValue={roleFilter || "default"} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem
                        value="default"
                        className={
                          roleFilter == null
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Tất cả Vai trò
                      </SelectItem>
                      <SelectItem
                        value={GroupRoleEnum.MANAGER}
                        className={
                          roleFilter == GroupRoleEnum.MANAGER
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Quản trị
                      </SelectItem>
                      <SelectItem
                        value={GroupRoleEnum.MEMBER}
                        className={
                          roleFilter == GroupRoleEnum.MEMBER
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Đã tham gia
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              {/* <div className="text-xs flex items-center">
                <Select
                  defaultValue={typeFilter || "default"}
                  onValueChange={(value) => {
                    if (value === "default") {
                      setSort(null);
                      urlParams.delete("type");
                    } else {
                      setSort(value);
                      urlParams.set("type", value);
                      setPage(1);
                    }
                    navigate(
                      `${window.location.pathname}?${urlParams.toString()}`
                    );
                  }}
                >
                  <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium bg-transparent">
                    <SelectValue defaultValue={typeFilter || "default"} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem
                        value="default"
                        className={
                          typeFilter == null
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Tất cả chức danh
                      </SelectItem>
                      <SelectItem
                        value={GroupSortEnum.NAME}
                        className={
                          typeFilter == GroupSortEnum.NAME
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Tên
                      </SelectItem>
                      <SelectItem
                        value={GroupSortEnum.UPDATED_DATE}
                        className={
                          sort == GroupSortEnum.UPDATED_DATE
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Ngày cập nhật
                      </SelectItem>
                      <SelectItem
                        value={GroupSortEnum.CREATED_DATE}
                        className={
                          sort == GroupSortEnum.CREATED_DATE
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Ngày tạo
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div> */}
              <div className="text-xs flex items-center">
                <span className="text-secondary-600">Nhóm theo:</span>
                <Select
                  defaultValue={sort || "default"}
                  onValueChange={(value) => {
                    if (value === "default") {
                      setSort(null);
                      urlParams.delete("sort");
                    } else {
                      setSort(value);
                      urlParams.set("sort", value);
                      setPage(1);
                    }
                    navigate(
                      `${window.location.pathname}?${urlParams.toString()}`
                    );
                  }}
                >
                  <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium bg-transparent">
                    <SelectValue defaultValue={sort || "default"} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem
                        value="default"
                        className={
                          sort == null
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Mặc định
                      </SelectItem>
                      <SelectItem
                        value={GroupSortEnum.NAME}
                        className={
                          sort == GroupSortEnum.NAME
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Tên
                      </SelectItem>
                      <SelectItem
                        value={GroupSortEnum.UPDATED_DATE}
                        className={
                          sort == GroupSortEnum.UPDATED_DATE
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Ngày cập nhật
                      </SelectItem>
                      <SelectItem
                        value={GroupSortEnum.CREATED_DATE}
                        className={
                          sort == GroupSortEnum.CREATED_DATE
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Ngày tạo
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <InfiniteScroll
          style={{
            height: "unset",
            overflow: "unset",
          }}
          className="px-0"
          dataLength={membersList.length}
          next={() => {
            setPage((prev) => prev + 1);
            fetchInfiniteScroll(page);
          }}
          hasMore={isLoadMore}
          loader={
            <div className="flex items-center justify-center mt-6">
              <TGNLoading size="large" />
            </div>
          }
        >
          <div
            className={
              displayMode === DisplayMode.GRID
                ? "grid sm:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-6 gap-4"
                : ""
            }
          >
            {membersList.map((member, index) => {
              return (
                <CardUser
                  member={member}
                  displayMode={displayMode}
                  key={index}
                  groupId={index + 1}
                  setId={(id) => handelSetId(id)}
                />
              );
            })}
          </div>
        </InfiniteScroll>
      </div>
      <UserDetail
        userId={idUser}
        close={() => (
          setIdUser(null),
          urlParams.delete("idUser"),
          navigate(`${window.location.pathname}?${urlParams.toString()}`)
        )}
      />
    </div>
  );
};

export default Member;
