import React, { FC, Fragment, memo, useState } from "react";

import { Close } from "@carbon/icons-react";
import { ChevronDown } from "lucide-react";

import GroupDetailAvatar from "@/assets/images/Group Detail Cover.png";
import Divider from "@/components/Divider/Divider";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { GroupStructureDetailDef } from "@/features/groups/types/group.type";

type GroupStructureDetailProps = {
  open: boolean;
  close: () => void;
  group?: GroupStructureDetailDef;
};
const GroupStructureDetailSheet: FC<GroupStructureDetailProps> = ({
  open,
  close,
  group,
}) => {
  const [showMore, setShowMore] = useState(false);
  if (!group) {
    return null;
  }
  return (
    <Sheet
      open={open}
      onOpenChange={close}
    >
      <SheetContent className="sm:max-w-[500px] px-6 text-sm">
        <div className="flex gap-3 py-6">
          <TGNAvatar
            name="test"
            size="40"
            src={group.photoUrl || GroupDetailAvatar}
            round="12px"
          />
          <div>
            <p className="truncate w-[368px] mb-0 text-sm font-semibold text-secondary-900">
              {group.groupName}
            </p>
            <span className="text-secondary-600 text-xs">
              {group.numberOfMembers} Thành viên
            </span>
          </div>
          <div className="-mt-4">
            <Close
              className="cursor-pointer"
              size={20}
              onClick={close}
            />
          </div>
        </div>
        <p className="font-medium mb-2">Giới thiệu</p>
        <p>
          {group.description}
          {/* <span className="font-medium cursor-pointer"> Ẩn bớt</span> */}
        </p>
        <Divider
          className="-mx-6 my-6"
          height={1}
          width="unset"
        />
        <div className="font-medium mb-5">Thông tin cơ bản</div>
        <div className="grid grid-cols-2">
          {group.groupAttributes
            .slice(0, !showMore ? 3 : group.groupAttributes.length)
            .map((attribute) => (
              <Fragment key={attribute.key}>
                <div className="py-2">{attribute.label}</div>
                <div className="py-2 font-medium">{attribute.value}</div>
              </Fragment>
            ))}
        </div>
        {group.groupAttributes.length > 3 && (
          <Button
            variant="text"
            className="-ml-4"
            size="sm"
            onClick={() => setShowMore(!showMore)}
          >
            <ChevronDown size={16} />
            {showMore ? "Ẩn bớt" : "Xem thêm"}
          </Button>
        )}
      </SheetContent>
    </Sheet>
  );
};

export default memo(GroupStructureDetailSheet);
