import React, { FC, memo, useEffect, useRef, useState } from "react";

import { ArrowLeft, Renew } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { COUNT_DOWN } from "@/constants/app.constants";
import { getSecretEmail } from "@/utils/extractUsername";

type VerifyChangePwdOTPProps = {
  open: boolean;
  loading: boolean;
  reFetchUid: () => void;
  onClose: () => void;
  onSubmit: (otp: string) => void;
};

const VerifyChangePwdOTP: FC<VerifyChangePwdOTPProps> = ({
  open,
  loading,
  reFetchUid,
  onClose,
  onSubmit,
}) => {
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [countDown, setCountDown] = useState(COUNT_DOWN);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1, value.length);
    setActiveOtpIndex(value ? index + 1 : index - 1);
    setOtp(newOtp);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown((seconds) => seconds - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [activeOtpIndex]);
  useEffect(() => {
    setCountDown(COUNT_DOWN);
  }, [open]);
  const reSendOtp = () => {
    if (countDown === 0) {
      setCountDown(COUNT_DOWN);
      reFetchUid();
    }
  };

  return (
    <div className="max-w-[600px] gap-6">
      <div className="flex items-center gap-2 text-secondary-600 mb-4">
        <ArrowLeft />
        <span>Trở lại</span>
      </div>
      <DialogHeader className="relative">
        <DialogTitle className="text-gray-900 text-lg font-semibold">
          Xác minh
        </DialogTitle>
        <p className="text-sm text-secondary-600 font-normal">
          Nhập mã xác nhận bao gồm 6 chữ số chúng tôi vừa gửi đến số điện thoại
          của bạn{" "}
          <span className="font-medium text-secondary-900">
            {getSecretEmail("")}
          </span>
          .
        </p>
      </DialogHeader>
      <div className="mt-4">
        <div className="w-full h-40 px-4 py-12 bg-secondary-50 rounded justify-center items-center gap-2 inline-flex">
          {otp.map((_, index) => {
            return (
              <React.Fragment key={index}>
                <Input
                  ref={index === activeOtpIndex ? inputRef : null}
                  type="number"
                  className="w-16 h-16 p-1 bg-white rounded-lg shadow border border-secondary-200 text-5xl font-medium text-center text-primary spin-button-none active:border-primary-600 placeholder:text-secondary-300"
                  onChange={(e) => handleChange(e, index)}
                  value={otp[index]}
                  onKeyDown={(event) => {
                    if ([event.code, event.key].includes("Backspace")) {
                      event.preventDefault();
                      const newOtp = [...otp];
                      newOtp[index] = "";
                      setOtp(newOtp);
                      setActiveOtpIndex(activeOtpIndex - 1);
                    }
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="0"
                />
                {index === 2 && (
                  <span className="w-2 py-0.5 bg-secondary-400" />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <DialogFooter className="mt-6">
        <div className="h-11 flex-col justify-center items-start gap-1 inline-flex mr-auto">
          <div className="text-gray-900 text-sm font-normal ">
            Không nhận được mã xác nhận?
          </div>

          <span className="flex gap-1 items-center text-primary font-medium text-sm ">
            <span
              onClick={reSendOtp}
              className={
                countDown > 0
                  ? "flex opacity-50 items-center gap-1"
                  : "flex items-center gap-1 cursor-pointer"
              }
            >
              <Renew />
              Gửi lại
            </span>
            {countDown > 0 && <span>sau {countDown}s</span>}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <Button
            size="sm"
            variant={"outline"}
            onClick={() => {
              setOtp(new Array(6).fill(""));
              onClose();
            }}
            type="button"
          >
            Hủy bỏ
          </Button>
          <Button
            disabled={otp.join("").length !== 6 || loading}
            size="sm"
            loading={loading}
            onClick={() => onSubmit(otp.join(""))}
            type="button"
          >
            Xác nhận
          </Button>
        </div>
      </DialogFooter>
    </div>
  );
};

export default memo(VerifyChangePwdOTP);
