import React, { FC, memo, useState } from "react";
import "react-multi-carousel/lib/styles.css";

import { Close, Locked, Unlocked } from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

import { LoadingIcon } from "@/assets/icons";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { settingGroup } from "@/features/groups/api/group.api";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { getGroupInfo } from "@/features/groups/group";
import { useAppDispatch } from "@/redux/store";
import { COMMON_MESSAGES, getMessage } from "@/utils/message";

import { GroupInfoDef } from "../../../types/group.type";

type LockOrgModalProps = {
  groupInfo?: GroupInfoDef;
  disable: boolean;
};

const LockOrgModal: FC<LockOrgModalProps> = ({ groupInfo, disable }) => {
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLock = async () => {
    try {
      setOpenModal(false);
      setLoading(true);
      if (groupInfo) {
        await settingGroup({
          deviceRequireAccept: true,
          groupId: groupInfo.id,
          lock: false,
          mode: groupInfo.mode,
          requireApprove: groupInfo.requireRequestApprove,
        });
        navigate(`/group/${groupInfo.id}`);
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: COMMON_MESSAGES.MSG62.replaceAll(
                  "${0}",
                  `${
                    groupInfo.rootGroupId ? "nhóm" : "tổ chức"
                  } <span class='font-semibold'>${groupInfo.name}</span>`
                ),
              }}
            ></div>
          ),
        });
        dispatch(
          getGroupInfo({
            group_id: groupInfo.id,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
          });
      }
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    } finally {
      setLoading(false);
    }
  };
  const [isHovering, setIsHovered] = useState(false);
  return (
    <>
      {groupInfo && (
        <>
          <Dialog
            open={openModal}
            onOpenChange={setOpenModal}
          >
            <DialogTrigger asChild>
              <Button
                variant="primary"
                disabled={disable}
                onClick={() => setOpenModal(true)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {isHovering ? (
                  <Unlocked className="-ms-2 me-2  " />
                ) : (
                  <Locked className="-ms-2 me-2" />
                )}
                Mở khóa {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}
              </Button>
            </DialogTrigger>
            <DialogContent
              className={"sm:max-w-[600px] bg-white gap-4 text-center"}
            >
              <DialogHeader className="relative">
                <Button
                  variant="ghost"
                  size="sm"
                  className="absolute top-0 right-0 rounded-full w-[36px]"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <Close />
                </Button>
                <DialogTitle className="text-center max-w-[460PX] text-lg mx-auto">
                  Mở khoá {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}{" "}
                  {groupInfo?.name}
                </DialogTitle>
              </DialogHeader>
              <div className="grid gap-4">
                <DialogDescription className="font-medium text-secondary-900">
                  Bạn chắc chắn muốn mở khoá{" "}
                  {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}{" "}
                  <span className="font-semibold">“ {groupInfo?.name}”</span>{" "}
                  {groupInfo.rootGroupId
                    ? "với Tổ chức và người dùng trên TAGA?"
                    : "với người dùng trên TAGA?"}
                </DialogDescription>
              </div>
              <DialogFooter className="mt-6">
                <Button
                  className="w-full"
                  iconBefore={<Unlocked className="" />}
                  onClick={() => {
                    handleLock();
                  }}
                >
                  Mở khoá {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          <Dialog
            open={loading}
            onOpenChange={setLoading}
          >
            <DialogContent className="p-6 text-center max-w-[400px] gap-2">
              <LoadingIcon className="animate-spin duration-[3000ms] mx-auto" />
              <p className="text-secondary-900 text-lg font-semibold mt-2">
                Đang mở khoá {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}
              </p>

              <p className="text-secondary-600 text-sm">
                Vui lòng chờ tới khi thiết lập hoàn tất.
              </p>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default memo(LockOrgModal);
