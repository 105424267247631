import React, { FC, ReactNode } from "react";

import { Close } from "@carbon/icons-react";
import cx from "classnames";

import { Badge } from "@/components/ui/badge";

type TGNChipProps = {
  label: string | ReactNode;
  type?: "outline" | "solid" | "light";
  removable?: boolean;
  size?: "sm" | "md" | "lg";
  className?: string;
  firstIcon?: ReactNode;
  variant?: "default" | "secondary" | "destructive" | "outline";
};

const TGNChip: FC<TGNChipProps> = ({
  label,
  removable,
  size = "sm",
  className = "",
  variant = "default",
  firstIcon,
}) => {
  return (
    <Badge
      size={size}
      variant={variant}
      className={cx(className)}
    >
      {firstIcon && <span className="-ms-1 me-1">{firstIcon}</span>}
      <span>{label}</span>
      {removable && <Close className="-me-1 ms-1 cursor-pointer" />}
    </Badge>
  );
};

export default TGNChip;
