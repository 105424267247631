import React, { FC, memo } from "react";

import { WarningAlt, WarningFilled } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";

import { IContent } from "../types/lib.types";

type RemoveSharedContentDialogProps = {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
  loading: boolean;
  content?: IContent;
};

const RemoveSharedContentDialog: FC<RemoveSharedContentDialogProps> = ({
  open,
  content,
  loading,
  onClose,
  onOk,
}) => {
  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
    >
      <DialogContent className="flex flex-col w-[700px] max-w-full h-fit bg-white">
        <button />
        <div className="flex flex-row space-x-4">
          <div className="p-1 rounded-full bg-[#FFFAEB] h-fit">
            <div className="p-1 rounded-full bg-[#FEF0C7]">
              <WarningFilled className="w-6 h-6 text-[#F79009]" />
            </div>
          </div>
          <div>
            <div className="text-lg font-semibold">Gỡ quyền truy cập</div>
            <div className="mt-2">
              Bạn chắc chắn muốn gỡ quyền truy cập của mình đối với “
              <span className="font-semibold">{content?.name}</span>
              ”? ?
            </div>
            <div className="flex flex-col bg-[#FFFBFA] text-[#B42318] text-sm px-4 py-2 border-l-2 border-[#D92D20] space-y-2 mt-4">
              <div className="flex flex-row items-center space-x-1">
                <WarningAlt />
                <span className="font-semibold">Cảnh báo</span>
              </div>
              <div>
                Nội dung này sẽ bị gỡ khỏi danh sách nội dung được chia sẻ, bạn
                sẽ cần yêu cầu truy cập để truy cập sau này.
              </div>
            </div>
            <div className="space-x-2 flex justify-end pt-4">
              <Button
                variant="outline"
                onClick={onClose}
              >
                Hủy
              </Button>
              <Button
                onClick={onOk}
                loading={loading}
              >
                Đồng ý
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(RemoveSharedContentDialog);
