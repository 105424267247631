import React, { FC, memo, useRef, useState, useEffect } from "react";

import cx from "classnames";

import Divider from "@/components/Divider/Divider";

import styles from "./CommentInput.module.scss";
import { KEY_CODE } from "../../mindmap";

type CommentInputProps = {
  pushMessage: (message: string, files: File[]) => void;
  loading: boolean;
};

const CommentInput: FC<CommentInputProps> = ({ pushMessage, loading }) => {
  const inputRef = useRef<HTMLDivElement | null>(null);
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<File[]>([]);

  const onChangeComment = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!e.shiftKey && e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      if (!e.currentTarget.innerText.trim() && !files.length) {
        if (inputRef.current) {
          inputRef.current.innerHTML = "";
          setFiles([]);
        }
        return;
      }
      pushMessage(e.currentTarget.innerHTML, files);
      if (!files.length) {
        resetInput();
      }
      return;
    }
  };
  useEffect(() => {
    if (!loading) {
      resetInput();
    }
  }, [loading]);

  const resetInput = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.innerHTML = "";
        setFiles([]);
      }
    }, 0);
  };
  const openFile = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };
  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const filesArr: File[] = Array.prototype.slice.call(e.target.files);
    setFiles((prev) => [...prev, ...filesArr]);
    if (fileRef.current) {
      fileRef.current.value = "";
    }
  };
  const removeFile = (index: number) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  return (
    <div className={styles.comment}>
      <div className={cx(styles.box, loading && styles.loading)}>
        {/* {showMention && (
          <Mention
            position={currentIndex}
            onChangeMention={() => {
              console.warn("change");
            }}
          />
        )} */}
        <input
          ref={fileRef}
          type="file"
          hidden
          multiple
          onChange={onChangeFile}
        />
        <div className="d-flex align-items-center flex-wrap gap-8">
          {files.length
            ? files.map((file, index) => (
                <div
                  key={file.lastModified + index}
                  className={styles.preview}
                >
                  <i className="tgn-outline-file-03 fs-18"></i>
                  <span className="fs-12">{file.name}</span>
                  <i
                    className={cx(
                      "tgn-outline-chat-close",
                      styles["close-icon"]
                    )}
                    onClick={() => removeFile(index)}
                  ></i>
                </div>
              ))
            : null}
        </div>
        <div
          ref={inputRef}
          aria-hidden
          className={cx(styles["text-box"], "chat-box", "custom-scrollbar")}
          contentEditable
          aria-placeholder="Add comment or update"
          onKeyDown={onChangeComment}
        ></div>
        <div className="d-flex justify-content-between mt-12 align-items-center">
          <div className="d-flex align-items-center">
            <i
              className={cx(
                "tgn-solid-add-circle me-4 cursor-pointer fs-20",
                styles.icon
              )}
              onClick={openFile}
            ></i>
            <Divider
              width={1}
              height={16}
              color="#e8e8e8"
            />
            <i className={cx("tgn-outline-smile-ellipse", styles.icon)}></i>
            <i className={cx("tgn-solid-link", styles.icon)}></i>
            <i className={cx("tgn-outline-image-rectangle", styles.icon)}></i>
          </div>
          <div>
            <i
              role="presentation"
              className="tgn-solid-sent-fast text-brand-primary fs-22 cursor-pointer"
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CommentInput);
