import React, { FC, memo, useEffect, useRef, useState } from "react";

import { HelpFilled, InformationFilled } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { useToast } from "@/components/ui/use-toast";
import { getGroupById } from "@/features/groups/api/group.api";
import { GroupInfoDef } from "@/features/groups/types/group.type";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import LockOrgModal from "./LockOrgModal";
import UnLockOrgModal from "./UnLockOrgModal ";

interface AccessSettingProps {
  disable: boolean;
}

const AccessSetting: FC<AccessSettingProps> = ({ disable }) => {
  const { toast } = useToast();
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const [loading, setLoading] = useState(false);
  const [rootGroup, setRootGroup] = useState<GroupInfoDef | null>(null);
  useEffect(() => {
    const fetchRootGroup = async () => {
      try {
        setLoading(true);
        if (groupInfo && groupInfo.parentId) {
          const result = await getGroupById(groupInfo.parentId);
          setRootGroup(result.data);
        }
      } catch (error: any) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      } finally {
        setLoading(false);
      }
    };
    fetchRootGroup();
  }, [groupInfo]);

  return (
    <div className="mt-10">
      <p className="text-secondary-600 font-medium text-xs uppercase mb-4 text-medium">
        Quyền riêng tư
      </p>
      {loading ? (
        <div className="flex justify-center items-center">
          <TGNLoading size="medium" />
        </div>
      ) : (
        <>
          {groupInfo && !groupInfo.groupLock && (
            <div
              className={`p-6 bg-primary-25 rounded-lg border border-primary-200 ${
                disable || (rootGroup ? rootGroup.groupLock : false)
                  ? "opacity-60"
                  : ""
              }`}
            >
              <div className="flex gap-4">
                <div>
                  <span className="border-[6px] p-1 text-primary-600 border-primary-50 bg-primary-100 rounded-full block">
                    <InformationFilled size={20} />
                  </span>
                </div>
                <div>
                  <p className="mb-2 text-sm font-medium text-secondary-600">
                    Hành động này sẽ cô lập{" "}
                    {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}{" "}
                    <span className="font-semibold text-primary-600">
                      {groupInfo.name}
                    </span>{" "}
                    {groupInfo.rootGroupId ? (
                      <>
                        {" "}
                        và
                        <span className="font-semibold text-primary">
                          {" "}
                          chuỗi các nhóm trực thuộc
                        </span>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    trên TAGA.
                  </p>
                  <p className="text-xs text-secondary-600 mb-6">
                    {groupInfo.rootGroupId
                      ? "Nhóm và chuỗi các nhóm trực thuộc của bạn sẽ tạm thời ở trạng thái cô lập. Thành viên nhóm không thể gửi lời mời tới tất cả người dùng (bao gồm thành viên Tổ chức), cũng như người dùng trên nền tảng sẽ không thể yêu cầu tham gia vào Nhóm hay chuỗi các nhóm trực thuộc của bạn."
                      : "Hệ thống Tổ chức của bạn sẽ tạm thời ở trạng thái cô lập. Thành viên không thể gửi lời mời tới người dùng bên ngoài Tổ chức, cũng như người dùng trên nền tảng sẽ không thể yêu cầu tham gia vào Tổ chức hay các Nhóm trực thuộc tổ chức của bạn."}
                  </p>
                  <div className="flex items-center gap-3">
                    {groupInfo && (
                      <LockOrgModal
                        groupInfo={groupInfo}
                        disable={
                          disable || (rootGroup ? rootGroup.groupLock : false)
                        }
                      />
                    )}
                    <p className="text-xs text-secondary-600 font-medium flex">
                      <HelpFilled className="mr-1" /> Tìm hiểu thêm
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {groupInfo && groupInfo.groupLock && (
            <div
              className={`p-6 bg-primary-25 rounded-lg border border-primary-200 ${
                disable || (rootGroup ? rootGroup.groupLock : false)
                  ? "opacity-60"
                  : ""
              }`}
            >
              <div className="flex gap-4">
                <div>
                  <span className="border-[6px] p-1 text-primary-600 border-primary-50 bg-primary-100 rounded-full block">
                    <InformationFilled size={20} />
                  </span>
                </div>
                <div>
                  <p className="mb-2 text-sm font-medium">
                    Hành động này sẽ mở khoá{" "}
                    {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}{" "}
                    <span className="font-semibold text-primary-600">
                      {groupInfo.name}
                    </span>{" "}
                    {groupInfo.rootGroupId ? (
                      <>
                        {" "}
                        và
                        <span className="font-semibold text-primary">
                          {" "}
                          chuỗi các nhóm trực thuộc
                        </span>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    trên TAGA.
                  </p>
                  <p className="text-xs text-secondary-600 mb-6">
                    Hệ thống {groupInfo.rootGroupId ? "nhóm" : "tổ chức"} của
                    bạn sẽ trở lại trạng thái hoạt động như thông thường. Những
                    lời mời tham gia/yêu cầu truy cập chưa{" "}
                    <span className="font-medium text-secondary-900">
                      hết hạn 72 giờ
                    </span>{" "}
                    sẽ tiếp tục khả dụng với người dùng nhận được.
                  </p>
                  <div className="flex items-center gap-3">
                    {groupInfo && (
                      <UnLockOrgModal
                        groupInfo={groupInfo}
                        disable={
                          disable || (rootGroup ? rootGroup.groupLock : false)
                        }
                      />
                    )}
                    <p className="text-xs text-secondary-600 font-medium flex">
                      <HelpFilled className="mr-1" /> Tìm hiểu thêm
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(AccessSetting);
