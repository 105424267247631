import React, { FC, memo } from "react";

import {
  AccessibilityColor,
  OverflowMenuVertical,
  Vlan,
} from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

import GroupDetailAvatar from "@/assets/images/Group Detail Cover.png";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { GroupStructureDetailDef } from "@/features/groups/types/group.type";

type GroupItemProps = {
  groupStructure: GroupStructureDetailDef;
  showStructure: (groupStructure: GroupStructureDetailDef) => void;
};

const GroupItem: FC<GroupItemProps> = ({ groupStructure, showStructure }) => {
  const navigate = useNavigate();
  return (
    <div className="py-4 border-secondary-200 border-b last:border-b-0 first:border-t flex justify-between items-center">
      <div className="flex gap-2 items-center">
        <TGNAvatar
          size="40"
          src={groupStructure.photoUrl || GroupDetailAvatar}
          round="12px"
        />
        <div>
          <p className="text-secondary-900 font-medium mb-0 text-sm -mb-1">
            {groupStructure.groupName}
          </p>
          <span className="text-secondary-600 text-xs">
            {groupStructure.numberOfMembers} Thành viên
          </span>
        </div>
      </div>
      <div className="flex gap-2">
        <Button
          variant="ghost"
          onClick={() => showStructure(groupStructure)}
        >
          <Vlan className="mr-2" />
          Xem cơ cấu
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="p-3 cursor-pointer">
              <OverflowMenuVertical />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <Button
              variant="text"
              className="w-full justify-start"
              onClick={() =>
                navigate(
                  GroupPathsEnum.GROUP_DETAIL.replace(
                    /:id/,
                    groupStructure.groupId.toString()
                  )
                )
              }
            >
              <AccessibilityColor className="mr-2" />
              Truy cập nhóm
            </Button>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default memo(GroupItem);
