import React, { FC, memo, useEffect, useState } from "react";

import { Close } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "@/components/ui/dialog";
import { UserMFATypeEnums } from "@/features/auth/types/auth.types";
import { useAppSelector } from "@/redux/store";

import { UserMFAEnums } from "../account";

type EnableTwoFactorAuthenticationDialogProps = {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmitMFA: (MFAType: UserMFAEnums) => void;
};

const EnableTwoFactorAuthenticationDialog: FC<
  EnableTwoFactorAuthenticationDialogProps
> = ({ open, loading, onClose, onSubmitMFA }) => {
  const { user } = useAppSelector((state) => state.auth);
  const [checkEmail, setCheckEmail] = useState(
    user?.userMFAType === UserMFATypeEnums.EMAIL
  );
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(
    user?.userMFAType === UserMFATypeEnums.PHONE_SMS
  );
  const [openOffMFA, setOpenOffMFA] = useState(false);

  const onOK = () => {
    if (!checkEmail && !checkPhoneNumber) {
      setOpenOffMFA(true);
      return;
    }
    if (checkEmail && checkPhoneNumber) {
      onSubmitMFA(UserMFAEnums.COMBINE_EMAIL_AND_PHONE_SMS);
    } else if (checkEmail) {
      onSubmitMFA(UserMFAEnums.EMAIL);
    } else if (checkPhoneNumber) {
      onSubmitMFA(UserMFAEnums.PHONE_SMS);
    }
  };
  useEffect(() => {
    setOpenOffMFA(false);
    setCheckEmail(user?.userMFAType === UserMFATypeEnums.EMAIL);
    setCheckPhoneNumber(user?.userMFAType === UserMFATypeEnums.PHONE_SMS);
  }, [open]);

  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
    >
      <DialogContent className="pt-4 pb-10 rounded-xl border border-solid border-secondary-200 max-w-[600px]">
        <div>
          <Close
            size={20}
            className="absolute top-4 right-4 text-end cursor-pointer"
            onClick={onClose}
          />
        </div>
        <p className="text-lg font-semibold">Kích hoạt xác thực 2 yếu tố</p>
        <p className="text-xs -mt-3">
          Bảo vệ tài khoản của bạn khỏi bị truy cập lạ bằng cách yêu cầu xác
          thực lớp thứ hai. Bạn có thể chọn phương thức tin nhắn văn bản, ứng
          dụng xác thực hoặc khóa bảo mật.{" "}
          <span className="text-primary font-medium">Tìm hiểu thêm</span>
        </p>
        <div className="mt-7">
          <div className="flex items-start gap-[10px] text-sm">
            <Checkbox
              id="phonenumber"
              checked={checkPhoneNumber}
              onCheckedChange={() => setCheckPhoneNumber(!checkPhoneNumber)}
            />
            {/* eslint-disable-next-line */}
            <label
              htmlFor="phonenumber"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              <p>
                <p className="font-medium text-secondary-600 mb-1">
                  Gửi tin nhắn văn bản
                </p>
                <p className="text-xs text-secondary-600">
                  Chúng tôi sẽ gửi 1 đoạn mã xác thực bao gồm các chữ số tới số
                  điện thoại liên kết khi phát hiện đăng nhập lạ vào tài khoản
                  của bạn
                </p>
              </p>
            </label>
          </div>
          <div className="flex items-start gap-[10px] mt-4 text-sm">
            <Checkbox
              id="email"
              checked={checkEmail}
              onCheckedChange={() => setCheckEmail(!checkEmail)}
            />
            {/* eslint-disable-next-line */}
            <label
              htmlFor="email"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              <p>
                <p className="font-medium text-secondary-600 mb-1">
                  Xác nhận bằng email
                </p>
                <p className="text-xs text-secondary-600">
                  Chúng tôi sẽ gửi 1 đoạn mã xác thực bao gồm các chữ số tới địa
                  chỉ email liên kết khi phát hiện đăng nhập lạ vào tài khoản
                  của bạn
                </p>
              </p>
            </label>
          </div>
        </div>
        <div className="mt-10">
          <Button
            className="font-medium text-sm w-full"
            variant="primary"
            onClick={onOK}
            disabled={loading}
            loading={loading}
          >
            Xác nhận
          </Button>
        </div>
        <Dialog
          open={openOffMFA}
          onOpenChange={() => setOpenOffMFA(false)}
        >
          <DialogContent className="max-w-[364px]">
            <div>
              <DialogTitle className="text-secondary-900 text-lg font-semibold mb-1">
                Tắt xác thực 2 yếu tố?
              </DialogTitle>
              <DialogDescription className="text-secondary-600 text-xs">
                Nếu bạn tắt tính năng xác thực 2 yếu tố, tài khoản của bạn sẽ
                không còn lớp bảo vệ tăng cường nữa.
              </DialogDescription>
              <div className="mt-6">
                <Button
                  variant="destructive"
                  className="w-full"
                  disabled={loading}
                  loading={loading}
                  onClick={() => onSubmitMFA(UserMFAEnums.OFF)}
                >
                  Tắt
                </Button>
              </div>
              <div className="mt-3">
                <DialogClose className="w-full">
                  <Button
                    variant="ghost"
                    className="w-full"
                  >
                    Huỷ bỏ
                  </Button>
                </DialogClose>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default memo(EnableTwoFactorAuthenticationDialog);
