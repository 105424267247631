import React, { memo, useRef, useState } from "react";

import { Edit, TrashCan, Upload, Warning } from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import GroupDetailCover from "@/assets/images/Group Detail Cover.png";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import {
  deleteGroupPhoto,
  generatePresignedUrl,
  updateGroup,
  uploadGroupPhoto,
} from "@/features/groups/api/group.api";
import GroupAttributeModal from "@/features/groups/components/GroupAttributeModal/GroupAttributeModal";
import { GroupUserRoleEnums } from "@/features/groups/constants/group.constant";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { getGroupInfo } from "@/features/groups/group";
import { useCheckRoleAndDevice } from "@/features/groups/hooks/useCheckRoleAndDevice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";
const GeneralSetting = () => {
  useCheckRoleAndDevice(
    [GroupUserRoleEnums.OWNER, GroupUserRoleEnums.ADMIN],
    true
  );
  const [editable, setEditable] = useState(false);
  const [existingNameError, setExistingNameError] = useState<null | string>(
    null
  );
  const [deletedImage, setDeletedImage] = useState(false);
  const [urlImage, setUrlImage] = useState<string | null>(null);
  const [fileUpload, setFileUpload] = useState<File | null>(null);
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const [submittingGeneral, setSubmittingGeneral] = useState(false);
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [isChanged, setIsChanged] = useState(false);
  const generalSchema = Yup.object().shape({
    name: Yup.string()
      .max(
        50,
        `Tên ${
          groupInfo?.rootGroupId ? "nhóm" : "tổ chức"
        } không được vượt quá 50 ký tự`
      )
      .trim()
      .required(
        `Tên ${
          groupInfo?.rootGroupId ? "nhóm" : "tổ chức"
        } không được bỏ trống.`
      ),
    description: Yup.string().max(
      400,
      `Mô tả ${
        groupInfo?.rootGroupId ? "nhóm" : "tổ chức"
      } không được vượt quá 400 ký tự`
    ),
  });
  const handleRemoveAttribute = async (key: string) => {
    try {
      setSubmittingGeneral(true);
      if (groupInfo) {
        await updateGroup({
          groupId: groupInfo.id ?? 0,
          groupAttributes: [
            {
              key,
              delete: true,
            },
          ],
          name: groupInfo.name,
          description: groupInfo.description,
        });
        dispatch(
          getGroupInfo({
            group_id: groupInfo.id,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
          });
        toast({
          title: "Thành công!",
          variant: "success",
          description: getMessage("MSG4"),
        });
      } else {
        return null;
      }
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    } finally {
      // setOpenModal(false);
      setSubmittingGeneral(false);
    }
  };

  const onFilesChange = (file: File) => {
    setEditable(true);
    setFileUpload(file);

    if (file) {
      const url = URL.createObjectURL(file);
      setUrlImage(url);
      setIsChanged(true);
      setDeletedImage(false);
    } else {
      setUrlImage(null);
    }
  };

  const handleDeletePhoto = async () => {
    if (urlImage) {
      setFileUpload(null);
      setUrlImage(null);
    }
    if (groupInfo?.photoUrl) {
      setDeletedImage(true);
      setIsChanged(true);
    }
  };
  return (
    <>
      {groupInfo && (
        <div className="mt-6">
          <p className="text-base font-semibold text-secondary-900">
            Thông tin {groupInfo.rootGroupId ? "nhóm" : "Tổ chức"}
          </p>
          <p className="text-xs text-secondary-600">
            Chỉnh sửa thông tin hiển thị{" "}
            {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} của bạn với thành viên
            và người dùng trên TAGA.
          </p>
          {groupInfo && (
            <Formik
              initialValues={{
                groupId: groupInfo.id,
                name: groupInfo.name || "",
                description: groupInfo.description || "",
                photoId: undefined,
              }}
              validationSchema={generalSchema}
              onSubmit={(values, { setSubmitting }) => {
                const updateGroupFn = async () => {
                  try {
                    setSubmittingGeneral(true);
                    if (fileUpload && groupInfo) {
                      const result = await generatePresignedUrl(
                        fileUpload.name,
                        fileUpload.size,
                        groupInfo.id
                      );
                      await uploadGroupPhoto(
                        fileUpload,
                        result.data.url,
                        result.data.type,
                        result.data.len
                      );
                      values.photoId = result.data.id;
                      await updateGroup(values);
                    } else {
                      await updateGroup(values);
                    }
                    if (deletedImage) {
                      await deleteGroupPhoto(groupInfo.id);
                    }
                    dispatch(
                      getGroupInfo({
                        group_id: groupInfo.id,
                      })
                    )
                      .then(unwrapResult)
                      .catch(() => {
                        navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
                      });
                    setIsChanged(false);
                    setEditable(false);
                    setExistingNameError(null);
                    toast({
                      title: "Thành công!",
                      variant: "success",
                      description: getMessage("MSG4"),
                    });
                  } catch (error: any) {
                    if (error.response?.data.errors.includes("MSG2")) {
                      setExistingNameError(
                        "Tên nhóm đã tồn tại, vui lòng điền giá trị khác."
                      );
                    } else {
                      toast({
                        title: "Thất bại!",
                        variant: "error",
                        description: getMessage(error.response?.data.errors[0]),
                      });
                    }
                  } finally {
                    setDeletedImage(false);
                    setSubmittingGeneral(false);
                    setSubmitting(false);
                  }
                };
                updateGroupFn();
              }}
            >
              {({
                enableReinitialize,
                handleChange,
                errors,
                isSubmitting,
                isValid,
              }) => (
                <Form
                  ref={formRef}
                  id="information"
                >
                  <div className="p-6 bg-white rounded border mt-6 relative">
                    {!editable && (
                      <Button
                        className="absolute right-6 top-6"
                        variant="outline"
                        type="button"
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          setUrlImage(null);
                          setFileUpload(null);
                          setEditable(true);
                        }}
                      >
                        Chỉnh sửa
                      </Button>
                    )}
                    <div className="flex items-start gap-2.5 mb-6">
                      <div className="rounded-xl overflow-hidden	">
                        {urlImage ? (
                          <img
                            className="object-cover w-[64px] h-[64px]"
                            src={urlImage}
                            alt=""
                          />
                        ) : deletedImage ? (
                          <img
                            className="object-cover w-[64px] h-[64px]"
                            src={GroupDetailCover}
                            alt=""
                          />
                        ) : (
                          <img
                            className="object-cover w-[64px] h-[64px]"
                            src={groupInfo.photoUrl ?? GroupDetailCover}
                            alt=""
                          />
                        )}
                      </div>
                      <div>
                        <p className="text-sm text-secondary-600 font-medium mb-2">
                          Ảnh đại diện
                        </p>
                        {editable && (
                          <div className="flex items-center gap-2">
                            <Label
                              className="inline-flex items-center py-[7px] pl-2 pr-3 rounded border-secondary-200 hover:shadow-sm border text-sm text-secondary-600 font-medium hover:border-secondary-300 hover:bg-secondary-50 hover:cursor-pointer"
                              htmlFor="avatar"
                            >
                              <Upload className="mr-1" />
                              Tải lên
                              <Input
                                accept="image/png, image/jpeg, image/jpg, image/webp"
                                type="file"
                                id="avatar"
                                hidden
                                className="hidden"
                                onChange={(e) => {
                                  const fileList = e.target.files;
                                  if (fileList) {
                                    const file = fileList[0];
                                    onFilesChange(file);
                                  }
                                }}
                              />
                            </Label>
                            {(groupInfo.photoUrl || urlImage) && (
                              <Button
                                type="button"
                                size="sm"
                                variant="outline"
                                className="font-medium"
                                onClick={handleDeletePhoto}
                              >
                                Xoá ảnh
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="grid gap-4">
                      <div className="grid gap-2">
                        <Label className="text-sm">
                          Tên {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}
                        </Label>
                        <Field
                          readOnly={!editable}
                          name="name"
                          as={Input}
                          placeholder={`Tên ${
                            groupInfo.rootGroupId ? "nhóm" : "tổ chức"
                          }`}
                          onChange={(e: React.ChangeEvent<any>) => {
                            if (e.currentTarget.value === "") {
                              setExistingNameError(null);
                            }
                            setIsChanged(true);
                            handleChange(e);
                          }}
                          error={errors.name || existingNameError}
                        />
                        {errors.name ? (
                          <p className="text-error-400 text-xs flex">
                            <Warning
                              className="text-error-400 mr-1"
                              size={16}
                            />
                            {errors.name}
                          </p>
                        ) : null}
                        {existingNameError && (
                          <p className="text-error-400 text-xs flex">
                            <Warning
                              className="text-error-400 mr-1"
                              size={16}
                            />
                            {existingNameError}
                          </p>
                        )}
                      </div>
                      <div className="grid gap-2">
                        <Label className="text-sm">
                          Mô tả {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}
                        </Label>
                        <Field
                          readOnly={!editable}
                          name="description"
                          as={Textarea}
                          placeholder={`${
                            groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"
                          } của chúng tôi là...`}
                          onChange={(e: React.ChangeEvent<any>) => {
                            setIsChanged(true);
                            handleChange(e);
                          }}
                          error={errors.description}
                        />
                        {errors.description ? (
                          <p className="text-error-400 text-xs flex">
                            <Warning
                              className="text-error-400 mr-1"
                              size={16}
                            />
                            {errors.description}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-6 flex justify-end gap-3">
                      {!isChanged && editable && (
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => {
                            setEditable(false);
                            setExistingNameError(null);
                          }}
                        >
                          Huỷ bỏ
                        </Button>
                      )}
                      {isChanged && (
                        <Dialog>
                          <DialogTrigger>
                            <div>
                              <Button
                                type="button"
                                variant="outline"
                              >
                                Huỷ bỏ
                              </Button>
                            </div>
                          </DialogTrigger>
                          <DialogContent>
                            <DialogHeader>
                              <DialogTitle>Huỷ thay đổi?</DialogTitle>
                              <DialogDescription>
                                Nếu bạn rời khỏi trang này, mọi chỉnh sửa, cập
                                nhật sẽ không được lưu lại.
                              </DialogDescription>
                            </DialogHeader>
                            <DialogFooter>
                              <DialogClose asChild>
                                <div>
                                  <Button variant="ghost">Huỷ bỏ</Button>
                                </div>
                              </DialogClose>
                              <Button
                                onClick={(e) => {
                                  if (formRef.current) {
                                    formRef.current.reset();
                                  }
                                  setUrlImage(null);
                                  setFileUpload(null);
                                  setEditable(false);
                                  setIsChanged(false);
                                }}
                              >
                                Chấp nhận
                              </Button>
                            </DialogFooter>
                          </DialogContent>
                        </Dialog>
                      )}
                      {editable && (
                        <Button
                          disabled={!isChanged || !isValid}
                          type="submit"
                          form="information"
                          loading={submittingGeneral}
                        >
                          Lưu thay đổi
                        </Button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          <div className="mt-10 flex items-center justify-between">
            <div>
              <p className="text-xs text-secondary-600 font-medium uppercase mb-1">
                Thông tin bổ sung
              </p>
              <p className="text-xs text-secondary-600">
                Bổ sung thông tin liên quan của{" "}
                {groupInfo.rootGroupId
                  ? "Bộ phận/Nhóm"
                  : "Doanh nghiệp/Tổ chức"}{" "}
                của bạn
              </p>
            </div>
            <GroupAttributeModal />
          </div>
          <div className="p-6 bg-white rounded border mt-4">
            <Table>
              <TableHeader>
                <TableRow className="text-xs font-medium text-secondary-600">
                  <TableHead className="w-[50px] p-3 relative after:absolute after:h-[16px] after:w-[1px] after:bg-secondary-200 after:right-0 after:top-1/2 after:-translate-y-1/2">
                    STT
                  </TableHead>
                  <TableHead className="p-3 relative after:absolute after:h-[16px] after:w-[1px] after:bg-secondary-200 after:right-0 after:top-1/2 after:-translate-y-1/2">
                    Tiêu đề
                  </TableHead>
                  <TableHead className="p-3 relative after:absolute after:h-[16px] after:w-[1px] after:bg-secondary-200 after:right-0 after:top-1/2 after:-translate-y-1/2">
                    Nội dung
                  </TableHead>
                  <TableHead className="p-3">Hành động</TableHead>
                </TableRow>
              </TableHeader>
              {groupInfo.attributes && groupInfo.attributes?.length > 0 && (
                <TableBody>
                  {groupInfo.attributes.map((attribute, index) => {
                    return (
                      <TableRow
                        key={index}
                        className="text-secondary-600 hover:bg-primary-25"
                      >
                        <TableCell className="py-5 px-3">{index + 1}</TableCell>
                        <TableCell className="py-5 px-3">
                          {attribute.label}
                        </TableCell>
                        <TableCell className="py-5 px-3">
                          {attribute.value}
                        </TableCell>
                        <TableCell className="py-5 px-3">
                          <div className="flex">
                            <Button
                              variant="text"
                              onClick={() =>
                                handleRemoveAttribute(attribute.key)
                              }
                            >
                              <TrashCan />
                            </Button>
                            <GroupAttributeModal
                              edit
                              attribute={attribute}
                              button={
                                <Button variant="text">
                                  <Edit />
                                </Button>
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
            {!groupInfo.attributes ||
              (groupInfo.attributes.length <= 0 && (
                <p className="text-xs text-secondary-600 text-center mt-10 mb-4">
                  Tổ chức chưa có thông tin nào. Bắt đầu bằng cách nhấp vào nút
                  “+ Thêm mới”
                </p>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default memo(GeneralSetting);
