import React, { FC, memo, useRef, useState } from "react";

import cx from "classnames";
import { v4 as uuidv4 } from "uuid";

import TGNButton from "@/components/TGNButton/TGNButton";
import { ChatSocketTypeEnums, SectionDef } from "@/features/mindmap/mindmap";
import { useAppSelector } from "@/redux/store";

import Line from "./Line";
import styles from "./Section.module.scss";
import CreateTaskModal from "../CreateTaskModal/CreateTaskModal";

type SectionProps = {
  section?: SectionDef;
};

const Section: FC<SectionProps> = ({ section }) => {
  const { mindmapIO, currentSheet } = useAppSelector((state) => state.mindmap);
  const [title, setTitle] = useState(section?.title || "");
  const [showDetailSection, setShowDetailSection] = useState(true);
  const lineRef = useRef<HTMLDivElement | null>(null);
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);

  const onBlurTitle = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (currentSheet.isDraft || !mindmapIO || !e.target.value.trim()) {
      return;
    }
    mindmapIO.addChat({
      stepId: currentSheet.selectedNode?.data.id || "",
      value: {
        id: uuidv4(),
        type: ChatSocketTypeEnums.SECTION,
        stepId: currentSheet.id,
        title: e.target.value.trim(),
      },
    });
  };
  const onBlurLine = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    if (
      currentSheet.isDraft ||
      !mindmapIO ||
      !e.target.innerText.trim() ||
      !section
    ) {
      return;
    }
    const lines = e.target.innerText.split("\n");
    lines.forEach((line) => {
      if (line.trim()) {
        const id = uuidv4();
        mindmapIO.addChat({
          stepId: currentSheet.selectedNode?.data.id || "",
          value: {
            id,
            type: ChatSocketTypeEnums.LINE,
            sectionId: section.id,
            content: line.trim(),
          },
        });
      }
    });
    if (lineRef.current) {
      lineRef.current.innerHTML = "";
    }
  };
  return (
    <div>
      <CreateTaskModal
        show={showCreateTaskModal}
        sectionId={section?.id || ""}
        onHide={() => {
          if (section?.id) {
            setShowCreateTaskModal(false);
          }
        }}
      />
      <div className={styles.wrapper}>
        <div className="d-flex justify-content-between gap-16">
          <div className="d-flex flex-1">
            <i
              className={cx(
                "tgn-outline-direction-down-01 fs-20 text-primary-color-gray-10 cursor-pointer",
                styles["btn-down"]
              )}
              style={{
                width: 24,
              }}
              onClick={() => setShowDetailSection(!showDetailSection)}
            ></i>
            <div className="flex-1">
              <div className="d-flex">
                <input
                  placeholder="Đề mục công việc"
                  className={styles.title}
                  onBlur={onBlurTitle}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  disabled={Boolean(section?.title) || false}
                ></input>
                <TGNButton
                  className="ms-4"
                  variant="ghost"
                  height={30}
                  onClick={() => setShowCreateTaskModal(true)}
                  hidden
                >
                  <i className="tgn-outline-plus me-2 fs-20 text-tertiary-color-gray-5"></i>
                  <span className="text-nowrap text-tertiary-color-gray-5">
                    Tạo Tác vụ
                  </span>
                </TGNButton>
              </div>
            </div>
          </div>
          <div>
            <i className="tgn-outline-more-horizontal text-tertiary-color-gray-5 fs-20"></i>
          </div>
        </div>
        {showDetailSection && (
          <div className="ms-4">
            {(section?.lines || []).map((line) => (
              <Line
                key={line.id}
                line={line}
              />
            ))}
            <div
              ref={lineRef}
              aria-placeholder="Thêm mô tả hoặc gõ “/” để hiển thị lệnh liên quan."
              contentEditable
              className={styles.description}
              onBlur={onBlurLine}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Section);
