import React, { useState, useEffect, Fragment, useRef } from "react";

import { isAxiosError } from "axios";
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useSearchParams } from "react-router-dom";

import ListEmpty from "@/assets/images/Search_empty.png";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useToast } from "@/components/ui/use-toast";
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from "@/constants/app.constants";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getFingerprint } from "@/utils/local";
import { getCustomMessage, getMessage } from "@/utils/message";

import {
  deleteMySharedContent,
  downloadFolder,
  softDeleteContent,
} from "../../api/lib.api";
import ContentDetailPopup from "../../components/ContentDetailPopup";
import ContentItem from "../../components/ContentItem";
import RemoveSharedContentDialog from "../../components/RemoveSharedContentDialog";
import {
  ContentTypeEnums,
  DownloadContentStatusEnums,
  FolderMenuActions,
} from "../../constants/lib.constants";
import { LibPathsEnum } from "../../constants/lib.paths";
import {
  downloadFileThunk,
  getMySharedContentThunk,
  getParentContentDetailThunk,
  libraryActions,
} from "../../redux/lib.slice";
import { IContent } from "../../types/lib.types";

const ShareWithMe = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const containerRef = useRef<HTMLDivElement>(null);
  const { libraryId } = useAppSelector((state) => state.auth);
  const {
    contentDetail,
    loading,
    parentContentDetail,
    sharedContents,
    pageIndex,
    totalPage,
  } = useAppSelector((state) => state.library);
  const [searchParams] = useSearchParams();
  // const [keyword, setKeyword] = useState("");
  const [contentId, setContentId] = useState(
    searchParams.get("content_id")
      ? Number(searchParams.get("content_id"))
      : libraryId
  );
  const [openContentDetailPopup, setOpenContentDetailPopup] = useState(false);
  const [openRemoveSharedContentDialog, setOpenRemoveSharedContentDialog] =
    useState(false);

  // share
  const [contentSelected, setContentSelected] = useState<IContent>();
  //end share
  const [loadingAction, setLoadingAction] = useState(false);
  //end move
  //delete
  const onDelete = async (content: IContent) => {
    try {
      if (!content) return;
      await softDeleteContent(content?.id);
      fetchContents(PAGE_DEFAULT);
      toast({
        title: "Thành công!",
        variant: "success",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: getCustomMessage("MSGTV36", content.name),
            }}
          ></div>
        ),
      });
    } catch {
      console.log("error");
    }
  };
  //Check action
  const onMenuClick = async (action: FolderMenuActions, content: IContent) => {
    setContentSelected(content);
    switch (action) {
      case FolderMenuActions.Open:
        handleOpenFolder(content.id);
        break;
      case FolderMenuActions.Detail:
        setContentSelected(content);
        setOpenContentDetailPopup(true);
        dispatch(libraryActions.setContentDetail(content));
        break;
      case FolderMenuActions.RemoveShare:
        setContentSelected(content);
        setOpenRemoveSharedContentDialog(true);
        break;
      case FolderMenuActions.Download:
        try {
          if (content.type === ContentTypeEnums.FILE) {
            dispatch(
              libraryActions.addDownloadContent({
                content,
                status: DownloadContentStatusEnums.PENDING,
              })
            );
            dispatch(libraryActions.setShowDownloadPopup(true));
            await dispatch(
              downloadFileThunk({
                content,
              })
            );
          } else {
            dispatch(
              libraryActions.addDownloadContent({
                content,
                status: DownloadContentStatusEnums.PENDING,
              })
            );
            dispatch(libraryActions.setShowDownloadPopup(true));
            await downloadFolder({
              contentId: content.id,
              fingerprint: getFingerprint(),
            });
          }
        } catch {
          toast({
            variant: "error",
            title: "Thất bại",
            description: getMessage("MSG_ERROR"),
          });
        }
        break;
      default:
        break;
    }
  };
  const fetchContents = async (pageNumber: number) => {
    try {
      await dispatch(
        getMySharedContentThunk({
          contentId,
          pageNumber,
          pageSize: PAGE_SIZE_DEFAULT,
        })
      );
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    }
  };
  const handleOpenFolder = (id: number) => {
    searchParams.set("content_id", id.toString());
    // searchParams.delete("keyword");
    navigate(LibPathsEnum.SHARE_WITH_ME + "?" + searchParams.toString());
  };

  useEffect(() => {
    if (contentId && !loading) {
      dispatch(getParentContentDetailThunk({ contentId }));
      fetchContents(PAGE_DEFAULT);
    }
  }, [contentId]);
  useEffect(() => {
    const content_id = searchParams.get("content_id");
    // const keywordParam = searchParams.get("keyword");
    setContentId(content_id ? Number(content_id) : libraryId);
    // if (keywordParam) {
    //   searchParams.delete("content_id");
    // }
    // setKeyword(searchParams.get("keyword") || "");
  }, [searchParams, libraryId]);

  useEffect(() => {
    if (containerRef.current === null) {
      return;
    }
    const height = containerRef.current.clientHeight;
    const heightScreen = window.innerHeight;
    const HEIGHT_SEARCH_BAR = 64;
    const HEIGHT_BREAD_CUM = 64;
    if (
      !loading &&
      pageIndex < totalPage &&
      height < heightScreen - HEIGHT_BREAD_CUM - HEIGHT_SEARCH_BAR
    ) {
      fetchContents(pageIndex + 1);
    }
  }, [loading]);
  const handleRemoveSharedContent = async () => {
    if (!contentSelected) {
      return;
    }
    try {
      setLoadingAction(true);
      const result = await deleteMySharedContent(contentSelected.id);
      if (result) {
        toast({
          title: "Thành công!",
          variant: "success",
          description: getMessage("MSG37", contentSelected.name),
        });
      }
      setOpenRemoveSharedContentDialog(false);
      fetchContents(PAGE_DEFAULT);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    } finally {
      setLoadingAction(false);
    }
  };
  useEffect(() => {
    return () => {
      dispatch(libraryActions.resetDownloadContents());
    };
  }, []);

  if (!contentId) {
    return <></>;
  }

  return (
    <div className="container mx-auto">
      <div>
        {sharedContents.length === 0 &&
        !loading &&
        parentContentDetail?.id === libraryId ? (
          <div className="flex items-center justify-center h-[calc(100vh-64px)]">
            <div className="flex flex-col items-center justify-center gap-6 max-w-[350px]">
              <img
                className="w-[124px]"
                src={ListEmpty}
                alt=""
              />
              <div className="text-center">
                <p className="text-base font-semibold mb-2">
                  Oops! Danh sách chia sẻ trống.
                </p>
                <p className="text-xs text-secondary-600">
                  Danh sách nội dung được chia sẻ của bạn sẽ hiển thị tại đây.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Breadcrumb className="px-6 mt-7 mb-4">
              <BreadcrumbList>
                {parentContentDetail &&
                parentContentDetail.type === ContentTypeEnums.LIBRARY ? (
                  <BreadcrumbItem
                    className="cursor-pointer"
                    onClick={() => handleOpenFolder(parentContentDetail.id)}
                  >
                    <p className="text-secondary-600 text-sm font-semibold">
                      Được chia sẻ
                    </p>
                  </BreadcrumbItem>
                ) : (
                  (parentContentDetail?.deep_parent || []).length > 0 &&
                  parentContentDetail?.deep_parent.map((item) => (
                    <Fragment key={item.id}>
                      <BreadcrumbItem
                        className="cursor-pointer"
                        onClick={() =>
                          handleOpenFolder(
                            item.type === ContentTypeEnums.LIBRARY
                              ? libraryId
                              : item.id
                          )
                        }
                      >
                        <p className="text-secondary-400 text-sm font-semibold">
                          {item.type === ContentTypeEnums.LIBRARY
                            ? "Được chia sẻ"
                            : item.name}
                        </p>
                      </BreadcrumbItem>
                      <BreadcrumbSeparator>/</BreadcrumbSeparator>
                    </Fragment>
                  ))
                )}
                {parentContentDetail &&
                  parentContentDetail.type !== ContentTypeEnums.LIBRARY && (
                    <>
                      <BreadcrumbItem
                        className="cursor-pointer"
                        onClick={() => handleOpenFolder(parentContentDetail.id)}
                      >
                        <p className="text-secondary-600 text-sm font-semibold">
                          {parentContentDetail.name}
                        </p>
                      </BreadcrumbItem>
                    </>
                  )}
              </BreadcrumbList>
            </Breadcrumb>
            <div ref={containerRef}>
              {loading ? (
                <div className="flex items-center justify-center mt-6">
                  <TGNLoading size="medium" />
                </div>
              ) : (
                <InfiniteScroll
                  style={{
                    height: "unset",
                    overflow: "unset",
                  }}
                  className="px-0"
                  dataLength={sharedContents.length}
                  next={() => {
                    fetchContents(pageIndex + 1);
                  }}
                  hasMore={pageIndex < totalPage}
                  loader={null}
                >
                  <div
                    className={clsx(
                      "flex px-4 flex-wrap gap-x-2 gap-y-9",
                      openContentDetailPopup ? "w-[calc(100%-344px)]" : ""
                    )}
                  >
                    {sharedContents.map((item) => (
                      <ContentItem
                        key={item.content.id}
                        content={item.content}
                        active={contentSelected?.id === item.content.id}
                        isShare
                        onMenuClick={(action) =>
                          onMenuClick(action, item.content)
                        }
                      />
                    ))}
                  </div>
                </InfiniteScroll>
              )}
            </div>
          </div>
        )}
      </div>
      {contentDetail && (
        <ContentDetailPopup
          open={openContentDetailPopup}
          content={contentDetail}
          onClose={() => setOpenContentDetailPopup(false)}
          isShare
        />
      )}
      <RemoveSharedContentDialog
        open={openRemoveSharedContentDialog}
        onClose={() => setOpenRemoveSharedContentDialog(false)}
        onOk={handleRemoveSharedContent}
        content={contentSelected}
        loading={loadingAction}
      />
    </div>
  );
};

export default ShareWithMe;
