import React, { FC, ReactNode, memo, useState } from "react";

import {
  ArrowUpRight,
  CheckmarkFilled,
  Close,
  WarningFilled,
} from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import {
  acceptGroupInvitation,
  getGroupInvitationForUser,
} from "@/features/groups/api/group.api";
import { GroupUserStatusEnum } from "@/features/groups/constants/group.constant";
import {
  getGroupInfo,
  getGroupInvitationForUserThunk,
  groupActions,
} from "@/features/groups/group";
import { IGroupInvitation } from "@/features/groups/types/group.type";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch } from "@/redux/store";
import { COMMON_MESSAGES, getCustomMessage, getMessage } from "@/utils/message";

import ListQuickAction from "./ListQuickAction";
import { GroupPathsEnum } from "../../../constants/group.paths";

type AcceptModalProps = {
  // groupInfo: GroupInfoDef | IGroupInvitation;
  button: ReactNode;
  isDetail?: boolean;
  groupId: number;
  inviteId: number;
  groupName: string;
  rootGroupId?: number;
  groupLock: boolean;
};

const AcceptModal: FC<AcceptModalProps> = ({
  button,
  isDetail,
  rootGroupId,
  groupId,
  inviteId,
  groupName,
  groupLock,
}) => {
  const { toast } = useToast();
  const [haveInvites, setHaveInvites] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [invites, setInvites] = useState<IGroupInvitation[] | []>([]);
  const [openSuggest, setOpenSuggest] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleAccept = async () => {
    if (groupLock) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG80,
      });
    } else {
      try {
        dispatch(groupActions.setFlagLoading(true));
        await acceptGroupInvitation(true, inviteId);
        if (isDetail) {
          toast({
            title: "Thành công",
            variant: "success",
            description: (
              <div
                dangerouslySetInnerHTML={{
                  __html: getCustomMessage("MSG63", String(groupName)),
                }}
              ></div>
            ),
          });
          const childInvites = await getGroupInvitationForUser({
            rootGroupId: rootGroupId,
            pageNumber: 1,
            pageSize: 10,
          });

          const childInviteAvailable = childInvites.data.filter(
            (item) =>
              item.status !== GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
          );

          if (childInviteAvailable.length > 0) {
            setOpenSuggest(true);
            setInvites(childInviteAvailable);
          } else
            dispatch(
              getGroupInfo({
                group_id: groupId,
              })
            )
              .then(unwrapResult)
              .catch(() => {
                navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
              });
        } else {
          toast({
            title: "Thành công",
            variant: "success",
            description: (
              <div
                dangerouslySetInnerHTML={{
                  __html: getCustomMessage("MSG63", String(groupName)),
                }}
              ></div>
            ),
          });

          dispatch(
            getGroupInvitationForUserThunk({
              pageNumber: 1,
              pageSize: 9,
            })
          );
          const childInvites = await getGroupInvitationForUser({
            rootGroupId: rootGroupId,
            pageNumber: 1,
            pageSize: 10,
          });

          const childInviteAvailable = childInvites.data.filter(
            (item) =>
              item.status !== GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
          );
          setIsSuccess(true);
          if (childInviteAvailable.length > 0) {
            setInvites(childInviteAvailable);
            setHaveInvites(true);
          }
        }
      } catch (error: any) {
        if (error) {
          toast({
            title: "Thất bại!",
            variant: "error",
            description: getMessage(error.response?.data.errors[0]),
          });
        } else {
          toast({
            title: "Thành công!",
            variant: "error",
            description: getMessage("MSG12"),
          });
        }
      } finally {
        // dispatch(groupActions.setFlagLoading(false));
        dispatch(loadingAction.hide);
      }
    }
  };

  return (
    <>
      <div
        className="w-full"
        onClick={handleAccept}
      >
        {button}
      </div>

      <Dialog open={isSuccess}>
        <DialogContent className="max-w-[464px]">
          <DialogHeader>
            <span className="flex items-center justify-center border-[6px] p-1 text-success-600 border-success-50 bg-success-100 rounded-full mx-auto">
              <CheckmarkFilled
                size={20}
                className="text-success-500"
              />
            </span>
          </DialogHeader>
          <div className="text-center gap-2 grid ">
            <DialogTitle className="text-center text-lg">
              Thành công!
            </DialogTitle>
            <DialogDescription className="text-center text-secondary-600">
              Bạn đã là thành viên của Tổ chức{" "}
              <span className="font-semibold"> "{groupName}"</span>. Giờ đây,
              bạn đã có thể truy cập và tham gia vào các hoạt động của tổ chức
              này.
            </DialogDescription>
            <div className="">
              <Link
                className="px-2 py-3 text-sm font-medium text-primary-600 flex items-center mx-auto justify-center"
                to={GroupPathsEnum.GROUP_DETAIL.replace(":id", String(groupId))}
              >
                Truy cập tổ chức
              </Link>
            </div>
          </div>
          <DialogFooter className="sm:justify-center">
            <Button
              variant="text"
              onClick={() => {
                setIsSuccess(false);
                haveInvites
                  ? setOpenSuggest(true)
                  : dispatch(groupActions.setFlagLoading(true));
              }}
            >
              Để sau
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={openSuggest}>
        <DialogContent className={"sm:max-w-[600px] bg-white"}>
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-0 right-0 rounded-full w-[36px]"
              onClick={() => {
                setOpenSuggest(false);
              }}
            >
              <Close />
            </Button>
            <DialogTitle className="text-center max-w-[460PX] text-lg mx-auto">
              Lời mời tham gia Nhóm
            </DialogTitle>
          </DialogHeader>
          <div className="flex gap-2  grid-cols-2 bg-primary-25 p-4 rounded-md">
            <div>
              <span className="block border-[6px] p-1 text-primary-600 border-primary-50 bg-primary-100 rounded-full">
                <WarningFilled size={24} />
              </span>
            </div>
            <span className="w-[calc(100%_-_48px)] text-secondary-900 text-sm">
              Trước đó, bạn đã nhận được một số lời mời từ các nhóm thuộc Tổ
              chức này. Chúng tôi đề xuất bạn thực hiện xử lý các lời mời này để
              tránh bỏ lỡ.
              <span className="font-medium inline-flex items-center">
                Tìm hiểu thêm <ArrowUpRight size={14} />
              </span>
            </span>
          </div>
          {invites && invites.length > 0 && rootGroupId && (
            <ListQuickAction rootGroupId={rootGroupId} />
          )}
          <DialogFooter className="sm:justify-center">
            <div className="flex justify-center">
              <Button
                variant="text"
                onClick={() => {
                  setOpenSuggest(false);
                  isDetail
                    ? dispatch(
                        getGroupInfo({
                          group_id: groupId,
                        })
                      )
                    : dispatch(groupActions.setFlagLoading(true));
                }}
              >
                Để sau
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(AcceptModal);
