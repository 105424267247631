import React, { useState } from "react";

import { Warning } from "@carbon/icons-react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import AvataDefault from "@/assets/images/avatar.png";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAppSelector } from "@/redux/store";

const ProfileSetting = () => {
  const user = useAppSelector((state) => state.auth.user);
  const generalSchema = Yup.object().shape({
    name: Yup.string(),
  });
  const [existingNameError, setExistingNameError] = useState<null | string>(
    null
  );
  const [deletedImage, setDeletedImage] = useState(false);
  const [urlImage, setUrlImage] = useState<string | null>(null);
  const [fileUpload, setFileUpload] = useState<File | null>(null);
  return (
    <div>
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-start-3 col-end-11">
            <p className="text-lg font-semibold text-secondary-900">
              Hồ sơ & Hiển thị
            </p>
            <p className="text-xs text-secondary-600">
              Quản lý thông tin cá nhân của bạn, đồng thời kiểm soát thông tin
              nào người khác xem được và ứng dụng nào có thể truy cập.
            </p>
            <div className="mt-6">
              <Formik
                initialValues={{
                  groupId: user?.id,
                  name: user?.name || "",
                  username: user?.username || "",
                  photoId: undefined,
                }}
                validationSchema={generalSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const updateGroupFn = async () => {
                    console.log("submit");
                  };
                  updateGroupFn();
                }}
              >
                {({ errors }) => (
                  <Form id="information">
                    <div className="p-6 bg-white rounded  rounded-lg border border-secondary-200 mt-6 relative">
                      <div className="flex items-start gap-2.5 mb-6">
                        <div className="rounded-xl overflow-hidden	">
                          {urlImage ? (
                            <img
                              className="object-cover w-[64px] h-[64px]"
                              src={urlImage}
                              alt=""
                            />
                          ) : deletedImage ? (
                            <img
                              className="object-cover w-[64px] h-[64px]"
                              src={AvataDefault}
                              alt=""
                            />
                          ) : (
                            <img
                              className="object-cover w-[64px] h-[64px]"
                              src={user?.photo ?? AvataDefault}
                              alt=""
                            />
                          )}
                        </div>
                        <div>
                          <p className="text-sm text-secondary-600 font-medium mb-2">
                            Ảnh đại diện
                          </p>
                        </div>
                      </div>
                      <div className="grid gap-4">
                        <div className="grid gap-2">
                          <Label className="text-sm">Tên</Label>
                          <Field
                            name="name"
                            as={Input}
                            placeholder={`Tên `}
                            error={errors.name}
                          />
                          {errors.name ? (
                            <p className="text-error-400 text-xs flex">
                              <Warning
                                className="text-error-400 mr-1"
                                size={16}
                              />
                              {errors.name}
                            </p>
                          ) : null}
                          {existingNameError && (
                            <p className="text-error-400 text-xs flex">
                              <Warning
                                className="text-error-400 mr-1"
                                size={16}
                              />
                              {existingNameError}
                            </p>
                          )}
                        </div>
                        <div className="grid gap-2">
                          <Label className="text-sm">Tên người dùng</Label>
                          <Field
                            name="username"
                            as={Input}
                            placeholder={`Tên người dùng`}
                            error={errors.username}
                          />
                        </div>
                        <div className="grid gap-2">
                          <Label className="text-sm">Ngày sinh</Label>
                          <Field
                            name="username"
                            as={Input}
                            placeholder={`Ngày sinh`}
                            error={errors.username}
                          />
                        </div>
                        <div className="grid gap-2">
                          <Label className="text-sm">Địa chỉ</Label>
                          <Field
                            name="username"
                            as={Input}
                            placeholder={`Ngày sinh`}
                            error={errors.username}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="flex p-6 justify-end">
                <Button
                  type="submit"
                  form="information"
                >
                  Lưu thay đổi
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetting;
