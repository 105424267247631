import React, { useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";

import Empty from "@/assets/images/List-none.png";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Badge } from "@/components/ui/badge";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import GroupCardRequest from "./components/GroupCardRequest";
import {
  getGroupRequestRootForUser,
  getMyGroups,
  getUserRequests,
} from "../../api/group.api";
import SearchModal from "../../components/SearchModal/SearchModal";
import {
  GroupSortDateEnum,
  GroupSortEnum,
} from "../../constants/group.constant";
import { GroupPathsEnum } from "../../constants/group.paths";
import { groupActions } from "../../group";
import { IGroupPersonalRequest } from "../../types/group.type";

const GroupInviteScreen = () => {
  const loading = useAppSelector((state) => state.group.loading);
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const [myGroups, setMyGroups] = useState<IGroupPersonalRequest[]>([]);
  const [rootGroups, setRootGroups] = useState<IGroupPersonalRequest[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [totalElement, setTotalElement] = useState(0);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const [rootGroupId, setRootGroupId] = useState(
    Number(urlParams.get("rootGroupId")) || undefined
  );
  const [sort, setSort] = useState(urlParams.get("sort"));

  let isFiltering = false;
  if (rootGroupId || sort) {
    isFiltering = true;
  }
  const fetchMyGroupsInfiniteScroll = async (pageScroll: number) => {
    try {
      setIsLoadMore(true);
      const result = await getUserRequests({
        pageNumber: pageScroll,
        pageSize: pageSize,
        rootGroupId: rootGroupId,
        sort: sort as GroupSortEnum,
      });
      if (!result.data.length || result.data.length < pageSize) {
        setIsLoadMore(false);
      }
      setMyGroups(() => [...myGroups, ...result.data]);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    }
  };
  const fetchMyGroups = async (pageNumber: number) => {
    try {
      const result = await getUserRequests({
        pageNumber: pageNumber,
        pageSize: pageSize,
        rootGroupId: rootGroupId,
        sort: sort as GroupSortEnum,
      });
      setTotalElement(result.totalElements);
      if (!result.data.length || result.data.length < pageSize) {
        setIsLoadMore(false);
      }
      setMyGroups(result.data);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    }
  };
  const fetchRootGroups = async () => {
    try {
      const result = await getGroupRequestRootForUser({});
      setRootGroups(result.data);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    }
  };

  useEffect(() => {
    fetchMyGroups(page);
    fetchRootGroups();
  }, [rootGroupId, sort]);
  useEffect(() => {
    if (loading) {
      fetchMyGroups(page);
      fetchRootGroups();
    }
  }, [loading]);
  useEffect(() => {
    fetchMyGroups(page);
  }, []);
  const handleReload = () => {
    setIsLoadMore(true);
    setPage(1);
    fetchMyGroups(1);
  };
  return (
    <>
      <div className="container  bg-white z-50">
        <div className="py-3 flex items-center justify-between">
          <div className="text-xs font-medium text-secondary-600">
            <span className="text-secondary-400">Nhóm</span> / Yêu cầu đã gửi
          </div>
          <SearchModal />
        </div>
      </div>
      <div className="h-px w-full z-50 bg-secondary-100 sticky top-[64px]"></div>
      <div className=" z-50 bg-white sticky top-[70px]">
        <div className="container">
          <div className="flex justify-between">
            <div className="py-6 flex ">
              <div className="font-semibold text-secondary-900 mr-2">
                Yêu cầu đã gửi
              </div>
              <Badge>{totalElement} Nhóm</Badge>
            </div>
            {myGroups.length > 0 && (
              <div
                className={
                  "grid gap-4 auto-cols-max grid-flow-col items-center"
                }
              >
                <div className="text-xs flex items-center">
                  <span className="text-secondary-600">Lọc theo:</span>
                  {rootGroups.length > 0 && (
                    <div className="text-xs">
                      <Select
                        defaultValue={
                          rootGroupId ? String(rootGroupId) : "default"
                        }
                        onValueChange={(value) => {
                          if (value === "default") {
                            setRootGroupId(undefined);
                            urlParams.delete("rootGroupId");
                          } else {
                            urlParams.set("rootGroupId", value);
                            setRootGroupId(Number(value));
                            setPage(1);
                          }
                          navigate(
                            `${
                              window.location.pathname
                            }?${urlParams.toString()}`
                          );
                        }}
                      >
                        <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                          <SelectValue
                            defaultValue={
                              rootGroupId ? String(rootGroupId) : "default"
                            }
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem
                              value="default"
                              className={
                                rootGroupId == undefined
                                  ? "text-secondary-900 font-medium"
                                  : "text-secondary-600  "
                              }
                            >
                              Tất cả Tổ chức
                            </SelectItem>
                            {rootGroups.length > 0 &&
                              rootGroups.map((group) => {
                                return (
                                  <SelectItem
                                    key={group.id}
                                    value={String(group.id)}
                                    className={
                                      rootGroupId == group.id
                                        ? "text-secondary-900 font-medium"
                                        : "text-secondary-600  "
                                    }
                                  >
                                    {group.name}
                                  </SelectItem>
                                );
                              })}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  )}
                </div>

                <div className="text-xs flex items-center">
                  <span className="text-secondary-600">Sắp xếp theo:</span>
                  <Select
                    defaultValue={sort || GroupSortDateEnum.NEWEST}
                    onValueChange={(value) => {
                      if (value === GroupSortDateEnum.NEWEST) {
                        setSort(null);
                        urlParams.delete("sort");
                      } else {
                        urlParams.set("sort", value);
                        setPage(1);
                        setSort(value);
                      }
                      navigate(
                        `${window.location.pathname}?${urlParams.toString()}`
                      );
                    }}
                  >
                    <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                      <SelectValue
                        defaultValue={sort || GroupSortDateEnum.NEWEST}
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem
                          value={GroupSortDateEnum.NEWEST}
                          className={
                            sort == null
                              ? "text-secondary-900 font-medium"
                              : "text-secondary-600  "
                          }
                        >
                          Mới nhất
                        </SelectItem>
                        <SelectItem
                          value={GroupSortDateEnum.OLDEST}
                          className={
                            sort == GroupSortDateEnum.OLDEST
                              ? "text-secondary-900 font-medium"
                              : "text-secondary-600  "
                          }
                        >
                          Cũ nhất
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container pb-6">
        <InfiniteScroll
          style={{
            height: "unset",
            overflow: "unset",
          }}
          className="px-0"
          dataLength={myGroups.length}
          next={() => {
            setPage((prev) => prev + 1);
            fetchMyGroupsInfiniteScroll(page + 1);
          }}
          hasMore={isLoadMore}
          loader={
            <div className="flex items-center justify-center mt-6">
              <TGNLoading size="large" />
            </div>
          }
        >
          {myGroups.length === 0 ? (
            <div className="flex flex-col h-[calc(100vh-140px)] -mt-10 items-center justify-center flex-1">
              <div className="flex flex-col items-center justify-center max-w-[229px] text-center">
                <img
                  src={Empty}
                  alt=""
                  className="w-[100px] h-[100px] "
                />
                <span className="text-secondary-600 text-xs mt-4 font-semibold">
                  {isFiltering
                    ? "0 kết quả phù hợp"
                    : "Danh sách yêu cầu đã gửi trống!"}
                </span>
                <Link
                  to={GroupPathsEnum.GROUP_DISCOVER}
                  className="px-4 py-2.5 text-sm border border-primary-600 rounded text-primary-600 mt-6"
                >
                  Khám phá
                </Link>
              </div>
            </div>
          ) : (
            <div className="grid gap-4">
              <div className="grid gap-4 grid-cols-3">
                {myGroups.map((group) => {
                  return (
                    <GroupCardRequest
                      key={group.groupId}
                      group={group}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default GroupInviteScreen;
