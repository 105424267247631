import React, { FC, memo, useState } from "react";

import {
  Add,
  Close,
  MailAll,
  OverflowMenuVertical,
  ReplyAll,
  TrashCan,
  UserAdmin,
} from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useNavigate } from "react-router-dom";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";
import { COMMON_MESSAGES, getCustomMessage, getMessage } from "@/utils/message";

import {
  acceptGroupInvitation,
  getGroupInvitationForUser,
  userCancelRequestToGroup,
  userRequestToGroup,
} from "../../api/group.api";
import CreateGroupModal from "../../components/CreateGroupModal/CreateGroupModal";
import GroupPermission from "../../components/GroupPermission";
import UserInviteModal from "../../components/UserInviteModal/InviteModal";
import {
  GroupUserRoleEnums,
  GroupUserStatusEnum,
  GroupUserTypeEnum,
} from "../../constants/group.constant";
import { GroupPathsEnum } from "../../constants/group.paths";
import { getGroupInfo } from "../../group";
import SuggestModal from "../../screens/GroupInvitesScreen/component/SuggestModal";
import CancelModal from "../../screens/GroupMyRequestsScreen/components/CancelModal";
import { GroupInfoDef, IGroupInvitation } from "../../types/group.type";

interface Props {
  status?: string;
  group: GroupInfoDef;
}

const ActionButton: FC<Props> = ({ group }) => {
  const userData = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [requested, setRequested] = useState(false);
  const [openSuggest, setOpenSuggest] = useState(false);
  const [rootInvite, setRootInvite] = useState<IGroupInvitation>();

  const handleAcceptOrg = async (root: IGroupInvitation) => {
    try {
      await acceptGroupInvitation(true, root.id);
      toast({
        title: "Thành công",
        variant: "success",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: getCustomMessage("MSG63", String(root.name)),
            }}
          ></div>
        ),
      });
      setOpenModal(false);
      handleSendRequest(group);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG12,
      });
    } finally {
      // onReLoad();
    }
  };
  const handleSendRequest = async (group: GroupInfoDef) => {
    setRequesting(true);
    try {
      const childInvites = await getGroupInvitationForUser({
        rootGroupId: group.rootGroupId || group.id,
        pageNumber: 1,
        pageSize: 10,
      });

      const index = childInvites.data.findIndex(
        (item) => item.groupId === group.rootGroupId
      );
      childInvites.data.map((item) => {
        if (item.groupId === group.rootGroupId) {
          setRootInvite(item);
        }
      });

      if (index !== -1) {
        setOpenModal(true);
      } else {
        const res = await userRequestToGroup(group.id);

        switch (res.data.status) {
          case GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE:
            toast({
              title: "Thành công!",
              variant: "success",
              description: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getCustomMessage("MSG70", group.name),
                  }}
                ></div>
              ),
            });
            break;
          case GroupUserStatusEnum.APPROVED:
            toast({
              title: "Thành công!",
              variant: "success",
              description: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getCustomMessage("MSG63", group.name),
                  }}
                ></div>
              ),
            });
            break;
          case GroupUserStatusEnum.REQUESTED:
            toast({
              title: "Thành công!",
              variant: "success",
              description: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getCustomMessage("MSG70", group.name),
                  }}
                ></div>
              ),
            });
            break;
          default:
            break;
        }
        setRequested(true);
        dispatch(
          getGroupInfo({
            group_id: group.id,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
          });

        if (
          childInvites.data.filter(
            (item) =>
              !(
                item.status === GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE ||
                item.groupId === group.id
              )
          ).length > 0
        ) {
          setOpenSuggest(true);
        }
      }
    } catch (error: any) {
      if (error.response?.data.errors[0]) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    } finally {
      setRequesting(false);
    }
  };
  const handleCancelRequest = async (group: GroupInfoDef) => {
    setRequesting(true);
    try {
      await userCancelRequestToGroup(group.userGroupRelation.id);

      toast({
        title: "Thành công!",
        variant: "success",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: getCustomMessage("MSG99", group.name),
            }}
          ></div>
        ),
      });
      dispatch(
        getGroupInfo({
          group_id: group.id,
        })
      );
      const childInvites = await getGroupInvitationForUser({
        rootGroupId: group.rootGroupId || group.id,
        pageNumber: 1,
        pageSize: 10,
      });
      if (childInvites.data.length > 0) {
        setOpenSuggest(true);
      }
    } catch (error: any) {
      if (error.response?.data.errors[0]) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <GroupPermission
        isVerifyDevice
        codes={[GroupUserRoleEnums.OWNER, GroupUserRoleEnums.ADMIN]}
      >
        <div className="flex">
          <DropdownMenu
            open={openDropdown}
            onOpenChange={setOpenDropdown}
          >
            <DropdownMenuTrigger asChild>
              <Button
                className="w-full cursor-pointer"
                variant="brand"
                iconBefore={<ReplyAll className="-scale-x-100" />}
                type="button"
                onClick={() => setOpenDropdown(true)}
                disabled={group.groupLock}
              >
                Mời thành viên
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="p-1 rounded border border-secondary-200 shadow-xl">
              <UserInviteModal inviteType="friend" />
              {group.rootGroupId && <UserInviteModal inviteType="root" />}
              <DropdownMenuItem className="p-0">
                <Link
                  to={GroupPathsEnum.INVITE_EMAIL.replace(
                    ":id",
                    String(group.id)
                  )}
                  className="flex items-center gap-2 rounded px-2.5 py-2 hover:bg-secondary-50 w-full"
                >
                  <MailAll className="t" />
                  <div>
                    <p className="text-sm font-medium text-secondary-900">
                      Mời tham gia qua Email
                    </p>
                    <p className="text-sm text-secondary-600">
                      Gửi lời mời tham gia thông qua Email
                    </p>
                  </div>
                </Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <CreateGroupModal
            rootMode={group.mode}
            rootId={group.id}
            rootGroup={group}
            haveSubGroup={group.initSetupFlag?.haveSubGroup}
            button={
              <Button className="bg-brand-50 px-3 py-2.5 block hover:bg-brand-200 text-brand-600 rounded flex-1 ml-3 text-center">
                <Add />
              </Button>
            }
          />
        </div>
      </GroupPermission>

      {!group.deviceAccepted && (group.manager || group.member) && (
        <div className="rounded py-2.5 px-3 flex justify-center items-center bg-secondary-100 text-sm font-medium">
          <UserAdmin className="mr-2" />
          Đã tham gia
        </div>
      )}
      {group.deviceAccepted && group.member && (
        <div className="rounded py-2.5 px-3 flex justify-center items-center bg-secondary-100 text-sm font-medium">
          <UserAdmin className="mr-2" />
          Đã tham gia
        </div>
      )}
      {(!group.role && !group.userGroupRelation) ||
      (group.userGroupRelation &&
        group.userGroupRelation.sender.id !== userData?.id &&
        group.userGroupRelation.groupUserType === GroupUserTypeEnum.REQUEST) ? (
        <Button
          loading={requesting}
          onClick={() => handleSendRequest(group)}
          type="button"
          iconBefore={<ReplyAll className="-scale-x-100" />}
          className="w-full"
          disabled={group.groupLock}
        >
          Tham gia nhóm
        </Button>
      ) : (
        group.userGroupRelation &&
        group.userGroupRelation.groupUserType === GroupUserTypeEnum.REQUEST && (
          <>
            {group.userGroupRelation.groupUserStatus ===
            GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE ? (
              <div className="flex gap-3">
                <Button
                  loading={requesting}
                  type="button"
                  className="w-full"
                  disabled
                >
                  Chờ phê duyệt
                </Button>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      className="px-3 py-2"
                      variant={"text"}
                    >
                      <OverflowMenuVertical />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <Button
                      loading={requesting}
                      type="button"
                      className="w-full"
                      variant={"ghost"}
                      onClick={() => handleCancelRequest(group)}
                    >
                      <TrashCan className="me-1" />
                      Hủy tham gia
                    </Button>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            ) : (
              <CancelModal
                inviteId={group.userGroupRelation.id}
                groupName={group.name}
                groupLock={group.groupLock}
                rootGroupId={group.rootGroupId || group.id}
                isDetail={true}
                groupId={group.id}
                button={
                  <Button
                    loading={requesting}
                    type="button"
                    variant={"ghost"}
                    className="w-full"
                    disabled={group.groupLock}
                  >
                    Hủy yêu cầu
                  </Button>
                }
              />
            )}
          </>
        )
      )}

      <Dialog open={openModal}>
        <DialogContent className="max-w-[600px] gap-6">
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-0 right-0 rounded-full w-[36px]"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <Close />
            </Button>
            <DialogTitle className="text-center max-w-[460px] text-lg mx-auto">
              Lời mời tham gia Tổ chức
              <p className="text-xs text-secondary-600 font-normal">
                Trước đó, bạn đã nhận được lời mời tham gia Tổ chức mà nhóm này
                trực thuộc. Vui lòng chấp nhận lời mời để trở thành thành viên
                của tổ chức, trước khi xử lý lời mời của nhóm.
              </p>
            </DialogTitle>
          </DialogHeader>
          <div className="mx-auto">
            <div className="justify-start items-center gap-2 flex">
              <img
                className="w-10 h-10 rounded-xl"
                src={rootInvite?.photoUrl ? rootInvite?.photoUrl : GroupCover}
                alt=""
              />
              <div className="flex-col justify-start items-start inline-flex">
                <div className="self-stretch text-secondary-900 text-sm font-medium ">
                  {rootInvite?.name}
                </div>
                <div className="justify-start items-start gap-1 inline-flex">
                  <div className="text-secondary-600 text-xs font-normal leading-none">
                    Được mời bởi
                  </div>
                  <div className="text-secondary-600 text-xs font-medium underline leading-none">
                    {rootInvite?.sender.name || rootInvite?.sender.email}
                  </div>
                  <div className="text-secondary-600 text-xs font-normal leading-none">
                    {rootInvite && formatDistance(rootInvite?.invitedAt)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DialogFooter className="sm:justify-center mb-4 -mt-1">
            <Button
              size="sm"
              onClick={() => rootInvite && handleAcceptOrg(rootInvite)}
            >
              Chấp nhận
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <SuggestModal
        openModal={openSuggest}
        handelModal={() => setOpenSuggest(false)}
        groupInfo={group.rootGroupId || Number(group.id)}
        root={group.rootGroupId != null ? false : true}
      />
    </>
  );
};

export default memo(ActionButton);
