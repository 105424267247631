import React, { FC } from "react";

import { Close } from "@carbon/icons-react";

import { Badge } from "@/components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { formatBytes } from "@/utils/file";

import { CapacityDetail } from "../types/lib.types";

interface CapacityDetailsProps {
  open: boolean;
  onClose: () => void;
  capacityDetails: CapacityDetail;
}

const CapacityDetails: FC<CapacityDetailsProps> = ({
  open,
  onClose,
  capacityDetails,
}) => {
  const color = [
    "#63b0f4",
    "#f97066",
    "#fdb022",
    "#32d583",
    "#949494",
    "#24f996",
    "#f9cf24",
    "#f95d24",
    "#24ebf9",
    "#2496f9",
    "#2441f9",
    "#7924f9",
    "#f92496",
    "#f92441",
  ];
  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogHeader className="relative">
          <Close
            className="cursor-pointer absolute -top-2 -right-2"
            size={20}
            onClick={onClose}
          />
          <DialogTitle className="text-lg font-semibold">
            Dung lượng lưu trữ
          </DialogTitle>
          <div className="relative">
            <div className="h-2 my-10 bg-secondary-100 rounded-[384px]"></div>
            <div className="h-2 absolute top-10 left-0 w-full">
              {capacityDetails.data.groupsCapacities.map((group, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      backgroundColor: index < 13 ? color[index] : color[12],
                      width: `${
                        (group.capacity / capacityDetails.data.maxCapacity) *
                        100
                      }%`,
                    }}
                    className="rounded-full h-2 float-left"
                  ></div>
                );
              })}
              <div
                style={{
                  backgroundColor: "#E2E4E2",
                  width: `${
                    (capacityDetails.data.personalLibraryCapacity /
                      capacityDetails.data.maxCapacity) *
                    100
                  }%`,
                }}
                className="rounded-full h-2"
              ></div>
            </div>
          </div>
          <DialogDescription>
            <div className="p-4 bg-secondary-50 rounded-lg">
              <p className="uppercase text-xs font-medium mb-3">Chi tiết</p>
              {capacityDetails.data.groupsCapacities.map((group, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center"
                  >
                    <div className="p-1">
                      <div
                        style={{
                          backgroundColor:
                            index < 13 ? color[index] : color[12],
                        }}
                        className="w-2 h-2 rounded-full mr-2"
                      ></div>
                    </div>
                    <p className="text-sm">{group.groupName}</p>
                    <p className="ml-auto text-sm">
                      {formatBytes(group.capacity)}
                    </p>
                  </div>
                );
              })}
              <div className="flex items-center">
                <div className="p-1">
                  <div
                    style={{
                      backgroundColor: "#E2E4E2",
                    }}
                    className="w-2 h-2 rounded-full mr-2"
                  ></div>
                </div>
                <p className="text-sm">Dữ liệu cá nhân</p>
                <p className="ml-auto text-sm">
                  {formatBytes(capacityDetails.data.personalLibraryCapacity)}
                </p>
              </div>
            </div>
            <div className="flex justify-between mt-10">
              <p>Dung lượng đã sử dụng</p>
              <p>
                {formatBytes(
                  capacityDetails.data.groupsCapacities.reduce(
                    (accumulator: number, item: any) => {
                      return accumulator + item.capacity;
                    },
                    0
                  ) + capacityDetails.data.personalLibraryCapacity
                )}
              </p>
            </div>
          </DialogDescription>
        </DialogHeader>
        <div className="h-px bg-secondary-200"></div>
        <DialogFooter className="sm:justify-between justify-between">
          <p className="text-sm font-semibold">
            Gói đang sử dụng <Badge className="rounded">Miễn phí</Badge>
          </p>
          <p className="text-sm font-semibold">
            {formatBytes(capacityDetails.data.maxCapacity)}
          </p>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CapacityDetails;
