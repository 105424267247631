import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Home,
  Devices,
  ListChecked,
  ReplyAll,
  Rule,
  Archive,
  DataStructured,
  NextOutline,
  SettingsServices,
  RuleDraft,
  ColumnDelete,
  Wikis,
  Locked,
  UserSettings,
  ChevronUp,
  DotMark,
} from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import cx from "classnames";
import {
  NavLink,
  useParams,
  useNavigate,
  Outlet,
  Link,
} from "react-router-dom";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import SubNav from "@/components/layout/SubNav/SubNav";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import formatNumber from "@/utils/formatNumber";

import ActionButton from "./DetailComponent/ActionButton";
import Slide from "./DetailComponent/Slide";
import {
  GroupDeviceStatus,
  GroupRoleEnum,
  GroupUserRoleEnums,
} from "../constants/group.constant";
import { GroupPathsEnum } from "../constants/group.paths";
import { getGroupInfo, groupActions } from "../redux/group.slice";

const GroupDetail = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const groupInfo = useAppSelector((state) => state.group.data);
  const user = useAppSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  const [userRole, setUserRole] = useState<GroupUserRoleEnums | null>();

  useEffect(() => {
    if (!groupInfo) {
      return;
    }
    setUserRole(groupInfo.role?.code ?? null);
  }, [groupInfo, user]);
  useEffect(() => {
    getGroupInfoFn();
  }, [id]);

  const getGroupInfoFn = useCallback(() => {
    setLoading(true);
    dispatch(groupActions.setGroup(null));
    dispatch(
      getGroupInfo({
        group_id: Number(id),
      })
    )
      .then(unwrapResult)
      .catch(() => {
        navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const navLinksDefault: {
    title: string;
    role: (GroupUserRoleEnums | null)[];
    isVerifyDevice?: boolean;
    isRootGroup?: boolean;
    children: {
      end: boolean;
      url: string;
      icon: React.JSX.Element;
      label: string;
      role?: GroupUserRoleEnums[];
      isVerifyDevice?: boolean;
      subChildren?: {
        url: string;
        label: string;
        end: boolean;
      }[];
      isRootGroup?: boolean;
    }[];
  }[] = useMemo(() => {
    return [
      {
        title: "",
        role: [
          GroupUserRoleEnums.OWNER,
          GroupUserRoleEnums.ADMIN,
          GroupUserRoleEnums.MEMBER,
          null,
        ],
        children: [
          {
            end: true,
            url: GroupPathsEnum.GROUP_DETAIL.replace(":id", id),
            icon: <Home className="me-5" />,
            label: `Trang chủ ${groupInfo?.rootGroupId ? "Nhóm" : "Tổ chức"}`,
            // role: [
            //   GroupUserRoleEnums.OWNER,
            //   GroupUserRoleEnums.ADMIN,
            //   GroupUserRoleEnums.MEMBER,
            //   null,
            // ],
          },
          {
            end: true,
            url: GroupPathsEnum.GROUP_LIBRARY.replace(":id", id),
            icon: <Archive className="me-5" />,
            label: `Thư viện ${groupInfo?.rootGroupId ? "Nhóm" : "Tổ chức"}`,
            role: [
              GroupUserRoleEnums.OWNER,
              GroupUserRoleEnums.ADMIN,
              GroupUserRoleEnums.MEMBER,
            ],
            isVerifyDevice: true,
            subChildren: [
              {
                end: true,
                url: GroupPathsEnum.GROUP_LIBRARY_SHARE_WITH_ME.replace(
                  ":id",
                  id
                ),
                label: `Chia sẻ với tôi`,
              },
            ],
          },
          {
            end: true,
            url: GroupPathsEnum.GROUP_DEPARTMENT_USER.replace(":id", id),
            icon: <DataStructured className="me-5" />,
            label: "Thành viên & Cơ cấu tổ chức",
            // role: [
            //   GroupUserRoleEnums.OWNER,
            //   GroupUserRoleEnums.ADMIN,
            //   GroupUserRoleEnums.MEMBER,
            //   null,
            // ],
          },
        ],
      },
      {
        title: `Quản lý ${groupInfo?.rootGroupId ? "Nhóm" : "Tổ chức"}`,
        role: [GroupUserRoleEnums.OWNER, GroupUserRoleEnums.ADMIN],
        isVerifyDevice: true,
        children: [
          {
            url: GroupPathsEnum.GROUP_TITLE,
            end: true,
            icon: <Rule className="me-5" />,
            isVerifyDevice: true,
            label: `Quản lý Chức danh`,
            // role: [GroupUserRoleEnums.OWNER, GroupUserRoleEnums.ADMIN],
          },
          {
            url: GroupPathsEnum.INVITE_REQUESTED,
            end: true,
            icon: <ListChecked className="me-5" />,
            label: "Lời mời đã gửi",
            // role: [GroupUserRoleEnums.OWNER, GroupUserRoleEnums.ADMIN],
            isVerifyDevice: true,
          },
          {
            url: GroupPathsEnum.GROUP_JOIN_REQUEST,
            end: true,
            icon: <ReplyAll className="me-5" />,
            label: "Yêu cầu tham gia",
            // role: [GroupUserRoleEnums.OWNER],
            isVerifyDevice: true,
          },
          {
            url: GroupPathsEnum.LEAVE_REQUEST,
            end: true,
            icon: <NextOutline className="me-5" />,
            label: `Yêu cầu rời ${groupInfo?.rootGroupId ? "Nhóm" : "Tổ chức"}`,
            role: [GroupUserRoleEnums.OWNER],
            isVerifyDevice: true,
          },
          {
            url: GroupPathsEnum.GROUP_DEVICE,
            end: true,
            icon: <Devices className="me-5" />,
            label: "Quản lý thiết bị",
            // role: [GroupUserRoleEnums.OWNER],
            isVerifyDevice: true,
            isRootGroup: true,
          },
        ],
      },
      {
        title: "Cài đặt",
        role: [GroupUserRoleEnums.OWNER, GroupUserRoleEnums.ADMIN],
        isVerifyDevice: true,
        children: [
          {
            url: GroupPathsEnum.GROUP_SETTING.replace(":id", `${id}`),
            end: true,
            icon: <SettingsServices className="me-5" />,
            label: "Cài đặt chung",
            // role: [GroupUserRoleEnums.OWNER],
            isVerifyDevice: true,
          },
          {
            url: GroupPathsEnum.GROUP_ROLE.replace(":id", `${id}`),
            end: true,
            icon: <RuleDraft className="me-5" />,
            label: "Quyền của quản trị viên",
            role: [GroupUserRoleEnums.OWNER],
            isVerifyDevice: true,
          },

          {
            url: GroupPathsEnum.GROUPS_DELETED.replace(":id", `${id}`),
            end: true,
            icon: <ColumnDelete className="me-5" />,
            label: "Lưu trữ",
            role: [GroupUserRoleEnums.OWNER],
            isVerifyDevice: true,
            isRootGroup: true,
          },
        ],
      },
      {
        title: "Cá nhân",
        role: [
          GroupUserRoleEnums.OWNER,
          GroupUserRoleEnums.ADMIN,
          GroupUserRoleEnums.MEMBER,
        ],
        isVerifyDevice: true,
        children: [
          {
            url: GroupPathsEnum.GROUP_SETTING_USER.replace(":id", `${id}`),
            end: true,
            icon: <UserSettings className="me-5" />,
            label: "Thiết lập cá nhân",
            // role: [GroupUserRoleEnums.OWNER],
            isVerifyDevice: true,
          },
        ],
      },
    ];
  }, [groupInfo, id]);

  return (
    <>
      {loading && (
        <TGNLoading
          isFullScreen={true}
          size="large"
        />
      )}
      {groupInfo && (
        <div className="w-full min-h-screen">
          <SubNav>
            <div className="bg-white absolute left-0 top-0 h-full w-full flex flex-col overflow-hidden select-none">
              <div className="border-bottom border-gray-200 bg-white p-4 pt-5">
                <p className="text-xs text-secondary-600 uppercase mb-2 truncate">
                  Tổ chức
                  {groupInfo.rootGroupName && (
                    <span className="text-primary-600">
                      ・{" "}
                      <Link
                        className="text-primary-600 hover:text-primary-800 truncate text-ellipsis"
                        to={GroupPathsEnum.GROUP_DETAIL.replace(
                          ":id",
                          String(groupInfo.rootGroupId)
                        )}
                      >
                        {groupInfo.rootGroupName}
                      </Link>
                    </span>
                  )}
                </p>
                <div className="flex">
                  <img
                    className="w-[60px] h-[60px] object-cover rounded-xl mr-2"
                    src={groupInfo.photoUrl ? groupInfo.photoUrl : GroupCover}
                    alt=""
                  />
                  <div>
                    <p className="text-secondary-900 text-sm font-semibold line-clamp-2 leading-5  mb-1">
                      {groupInfo.name}
                    </p>
                    <p className="text-xs text-secondary-600 flex">
                      {groupInfo.mode ? (
                        <Locked
                          size={14}
                          className="mr-1"
                        />
                      ) : (
                        <Wikis
                          size={14}
                          className="mr-1"
                        />
                      )}
                      {groupInfo.mode ? "Riêng tư" : "Công khai"} ∙{" "}
                      {formatNumber(groupInfo.memberCounter, 1)} thành viên
                    </p>
                  </div>
                </div>
                <div className="mt-6">
                  <ActionButton group={groupInfo} />
                </div>
              </div>
              <div className="w-full bg-secondary-100 h-px my-1"></div>
              <div className="py-5 px-2 relative flex-1 overflow-x-hidden [transition:width_0.2s]">
                {navLinksDefault.map((navLink, index) => {
                  if (
                    navLink.isRootGroup &&
                    groupInfo.id !== groupInfo.rootGroupId
                  ) {
                    return null;
                  }
                  if (navLink.role.includes(userRole || null)) {
                    if (
                      (navLink.isVerifyDevice &&
                        groupInfo.deviceStatus ===
                          GroupDeviceStatus.APPROVED) ||
                      !navLink.isVerifyDevice
                    ) {
                      return (
                        <div key={index}>
                          {navLink.title && (
                            <p className="text-xs font-medium text-secondary-600 pt-4 pb-2">
                              {navLink.title}
                            </p>
                          )}
                          {navLink.children.map((child, childIndex) => {
                            if (
                              (child.isRootGroup &&
                                groupInfo.rootGroupId &&
                                groupInfo.id !== groupInfo.rootGroupId) ||
                              (child.role &&
                                userRole &&
                                !child.role.includes(userRole))
                            ) {
                              return null;
                            }
                            return child.subChildren ? (
                              <Collapsible
                                key={`${child.url}_${childIndex}`}
                                open={true}
                                className=" "
                              >
                                <NavLink
                                  to={child.url.replace(/:id/, id)}
                                  end={child.end}
                                >
                                  {({ isActive }) => (
                                    <>
                                      <div
                                        className={cx(
                                          "flex items-center justify-between px-2 py-2.5 rounded hover:bg-secondary-100",
                                          isActive ? "bg-secondary-100" : ""
                                        )}
                                      >
                                        <p
                                          className={cx(
                                            isActive
                                              ? "text-secondary-900"
                                              : "text-secondary-600",
                                            "text-sm font-medium flex items-center mb-0"
                                          )}
                                        >
                                          {child.icon}
                                          {child.label}
                                        </p>
                                        <CollapsibleTrigger asChild>
                                          <ChevronUp />
                                        </CollapsibleTrigger>
                                      </div>
                                    </>
                                  )}
                                </NavLink>
                                <CollapsibleContent className="mt-0">
                                  {child.subChildren?.map(
                                    (subChild, subChildIndex) => (
                                      <div
                                        key={`${subChild.url}_${subChildIndex}`}
                                        className="px-2 ps-4 text-sm text-secondary-600  rounded"
                                      >
                                        <NavLink
                                          caseSensitive={true}
                                          end={subChild.end}
                                          to={subChild.url}
                                          state={{ type: "share" }}
                                        >
                                          {({ isActive: isSubActive }) => (
                                            <div
                                              className={cx(
                                                "flex items-center px-2 py-2.5 rounded hover:bg-secondary-100",
                                                isSubActive
                                                  ? "bg-secondary-100"
                                                  : ""
                                              )}
                                            >
                                              <DotMark
                                                size={5}
                                                className="mr-2 text-orange-500"
                                              ></DotMark>
                                              {subChild.label} {isSubActive}
                                            </div>
                                          )}
                                        </NavLink>
                                      </div>
                                    )
                                  )}
                                </CollapsibleContent>
                              </Collapsible>
                            ) : (
                              <NavLink
                                key={childIndex}
                                to={child.url.replace(/:id/, id)}
                                end={child.end}
                                className={({ isActive, isPending }) =>
                                  isPending
                                    ? `text-secondary-900 text-sm`
                                    : isActive
                                    ? `text-secondary-900 text-sm font-medium bg-secondary-100 hover:bg-secondary-100 py-2.5 px-2 flex items-center rounded`
                                    : "text-secondary-600 text-sm hover:bg-secondary-100 py-2.5 px-2 flex items-center rounded"
                                }
                              >
                                {child.icon}
                                {child.label}
                              </NavLink>
                            );
                          })}
                        </div>
                      );
                    }
                  }
                })}
              </div>
              <Slide />
              {/* {(groupInfo.member || groupInfo.manager) && (
                <DeviceAuth groupInfo={groupInfo} />
              )} */}
            </div>
          </SubNav>
          <div className="pl-[280px] pt-[70px] w-full h-full min-h-screen bg-secondary-50">
            <Outlet context={groupInfo} />
          </div>
        </div>
      )}
    </>
  );
};

export default GroupDetail;
