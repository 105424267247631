import React, { useState } from "react";

import { CloseFilled, Settings } from "@carbon/icons-react";

import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { authAction } from "@/features/auth/redux/auth.slice";
import { cn } from "@/lib/utils";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import AccountAndSecurity from "../component/AccountAndSecurity";
import Profile from "../component/Profile";

const TABS = [
  {
    label: "Hồ sơ",
    value: "profile",
    content: <Profile />,
  },
  {
    label: "Tài khoản & Bảo mật",
    value: "security",
    content: <AccountAndSecurity />,
  },
  {
    label: "Thông báo",
    value: "notify",
  },
  {
    label: "Dung lượng & Thanh toán",
    value: "capacity",
  },
  {
    label: "Phương thức xác thực",
    value: "verify",
  },
  {
    label: "Giao diện & Ngôn ngữ",
    value: "language",
  },
];

const SettingSheet = () => {
  const [tab, setTab] = useState("profile");
  const openSetting = useAppSelector((state) => state.auth.openSetting);
  const dispatch = useAppDispatch();
  return (
    <Sheet open={openSetting}>
      <SheetTrigger asChild>
        <div
          className="border-b border-solid border-secondary-100 py-3 flex gap-2 items-center cursor-pointer"
          onClick={() => dispatch(authAction.showSetting())}
        >
          <Settings />
          <span>Cài đặt</span>
        </div>
      </SheetTrigger>
      <SheetContent
        side="bottom"
        className="h-full bg-transparent p-0"
      >
        <div className="text-sm">
          <div className="text-end flex justify-end items-center py-2 px-4">
            <span
              className="mr-2 text-xs font-medium text-white cursor-pointer"
              onClick={() => dispatch(authAction.hiddenSetting())}
            >
              Đóng
            </span>
            <CloseFilled
              className="text-white cursor-pointer"
              onClick={() => dispatch(authAction.hiddenSetting())}
            />
          </div>
          <div className="bg-white h-[calc(100vh-24px)] overflow-auto rounded-tr-2xl rounded-tl-2xl relative">
            <p className="font-semibold text-secondary-900 absolute top-4 left-4">
              Tài khoản
            </p>
            <div>
              <div className="absolute left-0 top-14 h-[1px] w-full bg-secondary-100"></div>
              <div className="relative">
                <Tabs defaultValue="profile">
                  <TabsList className="w-full flex items-center mt-1 pb-3">
                    {TABS.map((item) => (
                      <TabsTrigger
                        key={item.value}
                        value={item.value}
                        className={cn(
                          "py-2 px-3 border-none",
                          item.value === tab &&
                            "bg-secondary-100 text-secondary-900 rounded-[8px]"
                        )}
                        onClick={() => setTab(item.value)}
                      >
                        <span
                          className={cn(
                            item.value === tab
                              ? " text-secondary-900 font-medium"
                              : "text-secondary-600 font-normal"
                          )}
                        >
                          {item.label}
                        </span>
                      </TabsTrigger>
                    ))}
                  </TabsList>
                  <div className={cn("overflow-auto", "h-[calc(100vh-80px)]")}>
                    {TABS.map((item) => (
                      <TabsContent
                        key={item.value}
                        value={item.value}
                      >
                        <div>{item.content}</div>
                      </TabsContent>
                    ))}
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default SettingSheet;
