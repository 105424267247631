import React, { FC, memo } from "react";

import { OverflowMenuVertical } from "@carbon/icons-react";
import { Link } from "react-router-dom";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import JoinButton from "@/features/groups/components/GroupCard/JoinButton";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { useAppDispatch } from "@/redux/store";
import formatNumber from "@/utils/formatNumber";

import { GroupDef } from "../../../types/group.type";
interface GroupCardProps {
  group: GroupDef;
}

const CardItem: FC<GroupCardProps> = ({ group }) => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  return (
    <div
      key={group.groupId}
      className="rounded-lg flex border border-secondary-200 hover:shadow-lg  overflow-hidden"
    >
      <img
        src={group.photoUrl ? group.photoUrl : GroupCover}
        alt=""
        className="w-[254px] h-[148px] object-cover "
      />
      <div className="p-4 w-[calc(100%_-_254px)]">
        <p className="text-xs text-secondary-600 font-medium uppercase mb-1 truncate">
          Tổ chức
          {!group.isRootGroup && (
            <span className="">
              ・
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Link
                      className="text-primary-600 hover:text-primary-800 truncate text-ellipsis"
                      to={GroupPathsEnum.GROUP_DETAIL.replace(
                        ":id",
                        String(group.rootId)
                      )}
                    >
                      {group.rootName}
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent>
                    <span className="text-xs"> {group.rootName}</span>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </span>
          )}
        </p>
        <p className="font-semibold text-base leading-6 text-secondary-900 mb-2 line-clamp-2 min-h-[48px]">
          <Link
            className="font-semibold text-base leading-6 text-secondary-900 hover:text-primary-800  "
            to={GroupPathsEnum.GROUP_DETAIL.replace(
              ":id",
              String(group.groupId)
            )}
          >
            {group.name}
          </Link>
        </p>
        <div className="flex justify-between items-center">
          <span className="text-sm text-secondary-600">
            {formatNumber(group.numberOfMembers)} thành viên
          </span>
          <div>
            {group.inviteRequest ? (
              <>
                {/* {group.inviteRequest.groupUserStatus ==
                  GroupUserStatusEnum.REQUESTED &&
                  group.inviteRequest.groupUserType ==
                    GroupUserTypeEnum.INVITE && (
                    <div className="flex">
                      <AcceptGroupModal
                        isDetail={false}
                        groupId={group.groupId}
                        groupName={group.name}
                        inviteId={group.inviteRequest.id}
                        button={<Button className="w-full">Chấp nhận</Button>}
                      />

                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            className="px-3 py-2"
                            variant={"text"}
                          >
                            <OverflowMenuVertical />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          Từ chối lời mời
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  )}
                {group.inviteRequest.groupUserStatus ==
                  GroupUserStatusEnum.REQUESTED &&
                  group.inviteRequest.groupUserType ==
                    GroupUserTypeEnum.REQUEST && (
                    <Button
                      type="button"
                      variant="ghost"
                      className="w-full"
                    >
                      Huỷ yêu cầu
                    </Button>
                  )}
                {group.inviteRequest.groupUserStatus ==
                  GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE && (
                  <>
                    {group.inviteRequest.groupUserType ==
                      GroupUserTypeEnum.REQUEST && (
                      <div className="flex gap-3">
                        <Button
                          type="button"
                          variant="ghost"
                          className="w-full"
                        >
                          Huỷ yêu cầu
                        </Button>
                      </div>
                    )}
                    {group.inviteRequest.groupUserType ==
                      GroupUserTypeEnum.INVITE && (
                      <div className="flex gap-3">
                        <Button
                          type="button"
                          className="w-full"
                          disabled
                        >
                          Đang xử lý
                        </Button>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              className="px-3 py-2"
                              variant={"text"}
                            >
                              <OverflowMenuVertical />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <Button variant={"ghost"}>
                              <TrashCan className="me-1" />
                              Huỷ tham gia
                            </Button>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    )}
                  </>
                )} */}
                <JoinButton group={group} />
              </>
            ) : (
              <>
                {group.role ? (
                  <div className="flex items-center">
                    <Link
                      className="bg-primary-25 px-4 py-2.5 block hover:bg-primary-50 text-primary-600 rounded flex-1 mr-3 text-center text-sm"
                      to={GroupPathsEnum.GROUP_DETAIL.replace(
                        ":id",
                        String(group.groupId)
                      )}
                    >
                      Xem chi tiết
                    </Link>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          className="px-3 py-2"
                          variant={"text"}
                        >
                          <OverflowMenuVertical />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem>Ghim nhóm</DropdownMenuItem>
                        <DropdownMenuItem>Bỏ ghim nhóm</DropdownMenuItem>
                        <DropdownMenuItem>Chia sẻ nhóm</DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                ) : (
                  <JoinButton group={group} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CardItem);
