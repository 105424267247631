import React, { memo, useEffect, useState } from "react";

import cx from "classnames";
import { Row, Col } from "react-bootstrap";

import Divider from "@/components/Divider/Divider";
import TGNButton from "@/components/TGNButton/TGNButton";
import Typography from "@/components/Typography/Typography";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import ColorSetting from "./ColorSetting/ColorSetting";
import styles from "./StyleSetting.module.scss";
import TextSetting from "./TextSetting/TextSetting";
import {
  DecorationEnums,
  TEXT_SETTINGS,
  UpdateDescriptionType,
  updateStyleNode,
} from "../../mindmap";

type SettingType = "" | "text" | "font" | "color" | "fill" | "connector";

const StyleSetting = () => {
  const { selectedNode } = useAppSelector(
    (state) => state.mindmap.currentSheet
  );
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [type, setType] = useState<SettingType>("text");
  const [showSettingType, setShowSettingType] = useState(false);
  const [fontSizeName, setFontSizeName] = useState(
    TEXT_SETTINGS.find(
      (item) => item.fontSize === selectedNode?.data.description.fontSize
    )?.label || ""
  );
  const [decoration, setDecoration] = useState(
    selectedNode?.data.description.decoration || DecorationEnums.INITIAL
  );
  useEffect(() => {
    if (!selectedNode) {
      setShow(false);
      setShowSettingType(false);
    } else {
      setFontSizeName(
        TEXT_SETTINGS.find(
          (item) => item.fontSize === selectedNode?.data.description.fontSize
        )?.label || ""
      );
    }
    setDecoration(
      selectedNode?.data.description.decoration || DecorationEnums.INITIAL
    );
  }, [selectedNode]);
  useEffect(() => {
    if (!showSettingType) {
      setType("");
    }
  }, [showSettingType]);

  const onChangeText = (fontSize: number) => {
    dispatch(
      updateStyleNode({
        type: UpdateDescriptionType.TEXT,
        fontSize,
      })
    );
    setFontSizeName(
      TEXT_SETTINGS.find((item) => item.fontSize === fontSize)?.label || ""
    );
  };

  const renderAction = () => {
    if (!selectedNode) {
      return null;
    }
    if (type == "text") {
      return (
        <TextSetting
          close={() => setShowSettingType(false)}
          onChangeText={onChangeText}
        />
      );
    }
    const { lineColor, color, fill } = selectedNode.data.description;
    if (type === "color" || type === "fill" || type === "connector") {
      return (
        <ColorSetting
          value={type === "color" ? color : type === "fill" ? fill : lineColor}
          onChangeCompleteColor={onChangeColor}
        />
      );
    }
  };
  const onChangeColor = (color: string) => {
    let updateType = UpdateDescriptionType.COLOR;
    switch (type) {
      case "color":
        updateType = UpdateDescriptionType.COLOR;
        break;
      case "connector":
        updateType = UpdateDescriptionType.CONNECTOR_COLOR;
        break;
      case "fill":
        updateType = UpdateDescriptionType.FILL;
        break;
      default:
        break;
    }
    dispatch(
      updateStyleNode({
        type: updateType,
        color,
      })
    );
  };
  const showSetting = (newType: SettingType) => {
    if (type === newType) {
      setShowSettingType(false);
      setType("");
      return;
    }
    setType(newType);
    setShowSettingType(true);
  };
  const handleChooseDecoration = (type: DecorationEnums) => {
    setDecoration(type);
    dispatch(
      updateStyleNode({
        type: UpdateDescriptionType.DECORATION,
        decoration: type,
      })
    );
  };

  return (
    <div
      className={styles.setting}
      onClick={() => {
        setShowSettingType(false);
      }}
    >
      <TGNButton
        width={40}
        height={40}
        onClick={() => setShow(!show)}
        disabled={!selectedNode}
        rounded
        className="bg-black"
      >
        <i className="tgn-outline-pencil text-white fs-20"></i>
      </TGNButton>
      <div className={cx(styles["setting-sidebar"], show && styles.show)}>
        {showSettingType && show && (
          <div className={styles.action}>{renderAction()}</div>
        )}
        <div className="d-flex">
          <Typography className="fs-14 fw-600 flex-1">Styles</Typography>
          <i
            className="tgn-outline-chat-close fs-20 cursor-pointer"
            onClick={() => setShow(false)}
          ></i>
        </div>
        <div className="mt-8">
          <Typography className="fs-14 fw-semibold">Text</Typography>
        </div>
        <Row className="ps-8">
          <Col
            xs={6}
            className="d-flex align-items-center mb-4"
          >
            <span className="fs-12 fw-semibold">Style</span>
          </Col>
          <Col className="mb-4">
            <TGNButton
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                showSetting("text");
              }}
            >
              <span className="fs-14">{fontSizeName}</span>
              <i className="tgn-outline-direction-down-01"></i>
            </TGNButton>
          </Col>
          <Col
            xs={6}
            className="d-flex align-items-center mb-4"
          >
            <span className="fs-12 fw-semibold">Font</span>
          </Col>
          <Col className="mb-4">
            <TGNButton variant="outline">
              <span className="fs-14">Inter</span>
              <i className="tgn-outline-direction-down-01"></i>
            </TGNButton>
          </Col>
          <Col
            xs={6}
            className="d-flex align-items-center mb-4"
          >
            <span className="fs-12 fw-semibold">Color</span>
          </Col>
          <Col className="mb-4">
            <TGNButton
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                showSetting("color");
              }}
            >
              <span
                className={styles.color}
                style={{
                  background: selectedNode?.data.description.color,
                  boxShadow:
                    selectedNode?.data.description.color === "#fff" ||
                    selectedNode?.data.description.color === "#ffffff"
                      ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                      : "",
                }}
              ></span>
              <span className="fs-14">
                {selectedNode?.data.description.color}
              </span>
            </TGNButton>
          </Col>
          <Col
            xs={6}
            className="d-flex align-items-center mb-4"
          >
            <span className="fs-12 fw-semibold">Decoration</span>
          </Col>
          <Col className="mb-4">
            <div className={styles["option-decoration"]}>
              <div
                className={
                  decoration === DecorationEnums.INITIAL && styles.active
                }
                onClick={() => handleChooseDecoration(DecorationEnums.INITIAL)}
              >
                Aa
              </div>
              <div
                className={cx(
                  "text-decoration-underline",
                  decoration === DecorationEnums.UNDERLINE && styles.active
                )}
                onClick={() =>
                  handleChooseDecoration(DecorationEnums.UNDERLINE)
                }
              >
                Aa
              </div>
              <div
                className={cx(
                  "text-decoration-line-through",
                  decoration === DecorationEnums.LINE_THROUGH && styles.active
                )}
                onClick={() =>
                  handleChooseDecoration(DecorationEnums.LINE_THROUGH)
                }
              >
                Aa
              </div>
            </div>
          </Col>
        </Row>
        <Divider
          className="my-2"
          width="100%"
          height={1}
        />
        <div className="mt-8">
          <Typography className="fs-14 fw-semibold">Color</Typography>
        </div>
        <Row className="ps-8">
          <Col
            xs={6}
            className="d-flex align-items-center mb-4"
          >
            <span className="fs-12 fw-semibold">Fill</span>
          </Col>
          <Col className="mb-4">
            <TGNButton
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                showSetting("fill");
              }}
            >
              <span
                className={styles.color}
                style={{
                  background: selectedNode?.data.description.fill,
                  boxShadow:
                    selectedNode?.data.description.fill === "#fff" ||
                    selectedNode?.data.description.fill === "#ffffff"
                      ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      : "",
                }}
              ></span>
              <span className="fs-14">
                {selectedNode?.data.description.fill}
              </span>
            </TGNButton>
          </Col>
          <Col
            xs={6}
            className="d-flex align-items-center mb-4"
          >
            <span className="fs-12 fw-semibold">Connector</span>
          </Col>
          <Col className="mb-4">
            <TGNButton
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                showSetting("connector");
              }}
            >
              <span
                className={styles.color}
                style={{
                  background: selectedNode?.data.description.lineColor,
                  boxShadow:
                    selectedNode?.data.description.lineColor === "#fff" ||
                    selectedNode?.data.description.lineColor === "#ffffff"
                      ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                      : "",
                }}
              ></span>
              <span className="fs-14">
                {selectedNode?.data.description.lineColor}
              </span>
            </TGNButton>
          </Col>
        </Row>
        <Divider
          className="my-2"
          width="100%"
          height={1}
        />
        <div className="mt-8">
          <Typography className="fs-14 fw-semibold">Theme</Typography>
        </div>
        <div className="ps-8 mt-8">
          <div className="fs-12 fw-semibold">
            <Typography>Vanilla</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(StyleSetting);
