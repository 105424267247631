import * as React from "react";

import { Checkmark } from "@carbon/icons-react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { cn } from "@/lib/utils";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "h-4 w-4 text-white shrink-0 rounded-sm border border-secondary-200 ring-offset-background hover:border-primary-600 hover:bg-primary-50 focus:border-primary-600 focus:bg-white focus:shadow-active disabled:border-secondary-100 disabled:bg-secondary-25 disabled:text-secondary-300 data-[state=checked]:border-primary data-[state=checked]:bg-primary data-[state=checked]:disabled:bg-secondary-25 data-[state=checked]:disabled:border-secondary-100"
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <Checkmark className="w-3 h-3 " />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
