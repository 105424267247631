import React, { useEffect, useState } from "react";

import { isAxiosError } from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { useToast } from "@/components/ui/use-toast";
import usePagination from "@/hooks/usePagination";
import { useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import GroupItem from "./component/GroupItem";
import GroupStructureDetailSheet from "./component/GroupStructureDetailSheet";
import {
  getGroupStructureDetail,
  getGroupStructureList,
} from "../../api/group.api";
import { GroupStructureDetailDef } from "../../types/group.type";

const Department = () => {
  const groupInfo = useAppSelector((state) => state.group.data);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const { pagination, setPagination } = usePagination();
  const [totalGroup, setTotalGroup] = useState(0);
  const [openGroupStructureDetailSheet, setOpenGroupStructureDetailSheet] =
    useState(false);
  const { toast } = useToast();
  const [groupStructures, setGroupStructures] = useState<
    GroupStructureDetailDef[]
  >([]);
  const [groupStructureDetail, setGroupStructureDetail] =
    useState<GroupStructureDetailDef>();
  const fetchInfiniteScroll = async (page: number) => {
    try {
      if (groupInfo) {
        const result = await getGroupStructureList({
          pageNumber: page,
          pageSize: pagination.size,
          groupId: groupInfo.id,
        });
        setGroupStructures(() => [...groupStructures, ...result.data]);
        setPagination({
          current: page,
          size: pagination.size,
          total: result.totalPage,
        });
        if (result.totalPage <= page) {
          setIsLoadMore(false);
        }
        setTotalGroup(result.totalElements);
      }
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    }
  };
  useEffect(() => {
    fetchInfiniteScroll(pagination.current);
  }, []);
  const handleOpenGroupStructureDetailSheet = async (
    groupStructure: GroupStructureDetailDef
  ) => {
    try {
      const result = await getGroupStructureDetail(groupStructure.groupId);
      setGroupStructureDetail(result.data);
      setOpenGroupStructureDetailSheet(true);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    }
  };

  return (
    <div className="min-h-[calc(100vh-280px)] bg-white">
      <div>
        <div className="container">
          <div className="flex justify-between">
            <div className="py-4 flex">
              <p className="font-semibold text-secondary-900 mr-2">
                {totalGroup} Nhóm
              </p>
            </div>
            <div
              className={"grid gap-4 auto-cols-max grid-flow-col items-center"}
            ></div>
          </div>
        </div>
      </div>
      <div className="container">
        <InfiniteScroll
          style={{
            height: "unset",
            overflow: "unset",
          }}
          className="px-0"
          dataLength={groupStructures.length}
          next={() => fetchInfiniteScroll(pagination.current + 1)}
          hasMore={isLoadMore}
          loader={
            <div className="flex items-center justify-center mt-6">
              <TGNLoading size="medium" />
            </div>
          }
        >
          <div>
            {groupStructures.map((item) => (
              <GroupItem
                key={item.groupId}
                groupStructure={item}
                showStructure={handleOpenGroupStructureDetailSheet}
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>
      <GroupStructureDetailSheet
        open={openGroupStructureDetailSheet}
        close={() => setOpenGroupStructureDetailSheet(false)}
        group={groupStructureDetail}
      />
    </div>
  );
};

export default Department;
