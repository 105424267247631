import React, { memo, useState, useRef, FC } from "react";

import { Overlay } from "react-bootstrap";

import Divider from "@/components/Divider/Divider";

import styles from "./AttachFilePopup.module.scss";

type AttachFilePopupProps = {
  onSelectFile: () => void;
};

const AttachFilePopup: FC<AttachFilePopupProps> = ({ onSelectFile }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const onHide = () => {
    setShow(false);
  };
  return (
    <>
      <div
        ref={target}
        className={styles["btn-add"]}
        onClick={() => setShow(!show)}
      >
        <i className="tgn-outline-plus fs-16"></i>
      </div>
      <Overlay
        target={target.current}
        show={show}
        rootClose
        placement="bottom-end"
        onHide={onHide}
      >
        {({
          /* eslint-disable */
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
          /* eslint-enable */
        }) => (
          <div
            {...props}
            className={styles.popup}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.item}>
              <i className="tgn-outline-focus fs-16 text-tertiary-color-gray-5"></i>
              <span className="text-primary-color-gray-10 fs-14">
                New Folder
              </span>
            </div>
            <Divider
              className="my-2"
              height={1}
              width="100%"
            />
            <div
              className={styles.item}
              onClick={onSelectFile}
            >
              <i className="tgn-outline-upload fs-16 text-tertiary-color-gray-5"></i>
              <span className="text-primary-color-gray-10 fs-14">
                Upload File(s)
              </span>
            </div>
            <div className={styles.item}>
              <i className="tgn-outline-notes-upload fs-16 text-tertiary-color-gray-5"></i>
              <span className="text-primary-color-gray-10 fs-14">
                Upload Folder(s)
              </span>
            </div>
            <Divider
              className="my-2"
              height={1}
              width="100%"
            />
            <div className={styles.item}>
              <i className="tgn-outline-pills fs-16 text-tertiary-color-gray-5"></i>
              <span className="text-primary-color-gray-10 fs-14">
                Add Blockly
              </span>
            </div>
            <div className={styles.item}>
              <i className="tgn-outline-diamond fs-16 text-tertiary-color-gray-5"></i>
              <span className="text-primary-color-gray-10 fs-14">
                Add Diagram
              </span>
            </div>
            <Divider
              className="my-2"
              height={1}
              width="100%"
            />
            <div className={styles.item}>
              <i className="tgn-outline-diamond fs-16 text-tertiary-color-gray-5"></i>
              <span className="text-primary-color-gray-10 fs-14">
                Import from TAGA Cloud
              </span>
            </div>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default memo(AttachFilePopup);
