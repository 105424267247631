import { HierarchyPointNode } from "d3-hierarchy";
import { select } from "d3-selection";

import Avatar from "@/assets/images/avatar.png";
import { UserDef } from "@/features/auth/types/auth.types";
import {
  BaseTypeNode,
  FlatNodeSearch,
  MindmapItemDef,
  MindmapNodeDef,
  Point,
  ZoomTransform,
} from "@/features/mindmap/types/mindmap.type";
import { getUrlImage } from "@/utils/image";

import {
  DEFAULT_COLOR_NODE,
  DEFAULT_CONNECTOR_NODE,
  DEFAULT_DECORATION_NODE,
  DEFAULT_FILL_NODE,
  DEFAULT_FONT_SIZE_NODE,
  DEFAULT_NAME_NODE,
  DecorationEnums,
  TASK_STATUS_COLOR,
  TaskStatus,
} from "../mindmap";
// eslint-disable-next-line import/order
import styles from "./../screens/mindmap/MindmapScreen.module.scss";

export function diagonal(s: Point, d: Point, scale = 1) {
  const _widthNode = getWidthNode(`#g${s.data?.id}`) || 214;
  const widthNode = _widthNode / scale;
  const distanceX = 60 / scale;
  const distanceY = 30 / scale;
  // s.y: truc hoanh
  // s.x: truc tung
  if (s.x === d.x) {
    return `M ${s.y},${s.x} ${d.y},${d.x}`;
  } else if (s.x > d.x) {
    return `M ${s.y},${s.x} ${s.y + widthNode + distanceY},${s.x} Q${
      s.y + widthNode + distanceX
    },${s.x} ${s.y + widthNode + distanceX},${s.x - distanceX}  Q${
      s.y + widthNode + distanceX
    },${d.x} ${d.y},${d.x} ${d.y - 2},${d.x} ${d.y},${d.x} `;
  } else {
    return `M ${s.y},${s.x} ${s.y + widthNode + distanceY},${s.x} Q${
      s.y + widthNode + distanceX
    },${s.x} ${s.y + widthNode + distanceX},${s.x + distanceX} Q${
      s.y + widthNode + distanceX
    },${d.x} ${d.y},${d.x} ${d.y - 2},${d.x} ${d.y},${d.x} `;
  }
}
// eslint-disable-next-line
export function getWidthNode(el: any) {
  const width = (
    select(el).select(".text-node").node() as BaseTypeNode
  )?.getBoundingClientRect()?.width;
  return width;
}
// eslint-disable-next-line
export function getHeightNode(el: any) {
  const height = (
    select(el).select(".text-node").node() as BaseTypeNode
  )?.getBoundingClientRect()?.height;
  return height;
}

export function findNearestNode(point: Point, coordinates: MindmapNodeDef[]) {
  if (coordinates.length === 0) {
    return null;
  }

  let nearestCoord = coordinates[0];
  let nearestDistance = getDistance(point, nearestCoord);

  for (let i = 1; i < coordinates.length; i++) {
    const distance = getDistance(point, coordinates[i]);
    if (distance < nearestDistance) {
      nearestCoord = coordinates[i];
      nearestDistance = distance;
    }
  }

  return nearestCoord;
}

export function getDistance(point1: Point, point2: Point) {
  const xDistance = point2.x - point1.x;
  const yDistance = point2.y - point1.y;
  return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
}

export const findNodeById = (
  node: MindmapItemDef,
  id: number | string
): MindmapItemDef | null => {
  if (node?.id === id) {
    return node;
  }

  if (node?.children) {
    for (let i = 0; i < node.children.length; i++) {
      const foundNode = findNodeById(node.children[i], id);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return null;
};

export const findParentNodeByChildId = (
  node: MindmapItemDef,
  childId: number | string
): MindmapItemDef | null => {
  if (
    node &&
    node.children &&
    node.children.find((item) => item.id === childId)
  ) {
    return node;
  }

  if (node.children) {
    for (let i = 0; i < node.children.length; i++) {
      const foundNode = findParentNodeByChildId(node.children[i], childId);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return null;
};

export const searchNodesByName = (tree: MindmapItemDef, character: string) => {
  const results: MindmapItemDef[] = [];

  if (tree.name.toLowerCase().includes(character.toLowerCase())) {
    results.push(tree);
  }

  if (tree.children) {
    for (const child of tree.children) {
      const childResults = searchNodesByName(child, character);
      results.push(...childResults);
    }
  }

  return results;
};

export const filterRoot = (root: MindmapItemDef) => {
  if (!root.children.length) {
    return;
  }
  root.children = root.children.filter((child) => child);
  root.children.forEach((item, index) => {
    item.description.index = index;
    filterRoot(item);
  });
};

export const renderStatus = (status: TaskStatus) => {
  return `<div 
  class="d-flex align-items-center"><div 
  class="${styles.tag}" 
  style="background:${TASK_STATUS_COLOR[status].color}"
  ></div><span class="fs-12 ms-1"
  style="color:${TASK_STATUS_COLOR[status].color}"
  >${TASK_STATUS_COLOR[status].label}<span></div>`;
};

export const renderTitle = (
  name: string,
  fontSize = 14,
  color = "#2d3282",
  decoration = DecorationEnums.INITIAL
) => {
  return `<div class='d-flex text-decoration-${decoration}'
  ><p style="font-size:${fontSize}px;color:${color}" class='${styles.title}'>${name}</p></div>`;
};

export const renderAssignUser = (data: MindmapItemDef) => {
  if (!data.description.assignees.length) {
    return "";
  }
  let avatar = "";
  data.description.assignees.forEach((item) => {
    avatar =
      avatar +
      `<img 
    class="${styles.avatar}" 
    src="${item.user.photo ? getUrlImage(item.user.photo) : Avatar}" 
    />`;
  });
  return `<div class="d-flex align-items-center"><img 
  class="${styles.avatar} ${styles["avatar-owner"]}" 
  src="${getUrlImage(data.description.owner.photo)}" />${avatar}</div>`;
};

export const renderDescription = (description: string) => {
  return `<div class='d-flex'
  ><div class='${styles.description}'>${description}</div></div>`;
};

export const renderHashtags = (hastags: string[] = []) => {
  let textHashtag = "";
  hastags.forEach((hashtag) => {
    textHashtag += `<span class="text-brand-primary fs-12 cursor-pointer">#${hashtag}&ensp;</span>`;
  });
  return `<div class='d-flex'>${textHashtag}</div>`;
};

export const updateHelperButtonPosition = ({
  node,
  currentZoomTransform,
}: {
  node: HierarchyPointNode<MindmapItemDef> | MindmapNodeDef;
  currentZoomTransform: ZoomTransform;
}) => {
  select("#btn-toggle-node")
    .style("visibility", "visible")
    .style(
      "top",
      `${node.x * currentZoomTransform.k + (currentZoomTransform?.y || 0)}px`
    )
    .style(
      "left",
      `${
        node.y * currentZoomTransform.k + (currentZoomTransform?.x || 0) + 44
      }px`
    )
    .style("transform", "translateY(-50%)");
};

export const flatNode = (node: MindmapItemDef, result: FlatNodeSearch[]) => {
  const dummyChildren = node.children.length ? node.children : node.children;
  dummyChildren.forEach((item, index) => {
    const { id, name, description, permissions = [] } = item;
    result.push({
      id,
      name,
      description: {
        ...description,
        index,
      },
      parentId: node.id,
      permissions,
    });
    flatNode(item, result);
  });
  return result;
};

export const getExampleNode = ({
  id,
  index,
  owner,
  parentId,
  permissions,
}: {
  id: string;
  index: number;
  owner: UserDef;
  parentId: string;
  permissions: string[][];
}) => {
  return {
    id,
    name: DEFAULT_NAME_NODE,
    description: {
      role: null,
      color: DEFAULT_COLOR_NODE,
      fontSize: DEFAULT_FONT_SIZE_NODE,
      decoration: DEFAULT_DECORATION_NODE,
      fill: DEFAULT_FILL_NODE,
      lineColor: DEFAULT_CONNECTOR_NODE,
      index,
      owner,
      assignees: [],
      tasks: [],
      descriptionTask: "",
      hashtags: [],
      intendTime: null,
    },
    parentId,
    children: [],
    _children: [],
    permissions,
  };
};
