import AccountSettingLayout from "@/features/account/layouts/AccountSettingLayout";
import { RouteItemDef } from "@/types/app.types";

import { AccountPathsEnum } from "../constants/account.paths";
import Billing from "../screens/Billing";
import Notification from "../screens/Notification";
import Preferences from "../screens/Preferences";
import ProfileSetting from "../screens/ProfileSetting";
import Security from "../screens/Security";

export const ACCOUNT_ROUTES: RouteItemDef[] = [
  {
    id: AccountPathsEnum.ACCOUNT,
    path: AccountPathsEnum.ACCOUNT,
    component: AccountSettingLayout,
    children: [
      {
        id: AccountPathsEnum.ACCOUNT,
        path: AccountPathsEnum.ACCOUNT,
        component: ProfileSetting,
      },
      {
        id: AccountPathsEnum.SECURITY,
        path: AccountPathsEnum.SECURITY,
        component: Security,
      },
      {
        id: AccountPathsEnum.NOTIFICATION,
        path: AccountPathsEnum.NOTIFICATION,
        component: Notification,
      },
      {
        id: AccountPathsEnum.BILLING,
        path: AccountPathsEnum.BILLING,
        component: Billing,
      },
      {
        id: AccountPathsEnum.ORG_SETTING,
        path: AccountPathsEnum.ORG_SETTING,
        component: Billing,
      },
      {
        id: AccountPathsEnum.PREFERENCES,
        path: AccountPathsEnum.PREFERENCES,
        component: Preferences,
      },
    ],
  },
];
