import React, { FC } from "react";

import cx from "classnames";
import { Form, Formik } from "formik";
import { Col, Modal, ModalProps, Row } from "react-bootstrap";
import { v4 as uuid4 } from "uuid";

import Divider from "@/components/Divider/Divider";
import TGNButton from "@/components/TGNButton/TGNButton";
import TGNSwitch from "@/components/TGNCheckboxRadio/TGNSwitch";
import TGNInputField from "@/components/TGNInput/TGNInputField";
import { createTaskSchema } from "@/features/mindmap/helpers/form.helpers";
import { updateTasks } from "@/features/mindmap/mindmap";
import { useAppDispatch } from "@/redux/store";

import styles from "./CreateTaskModal.module.scss";

type InitialValues = {
  title: string;
  description: string;
};

const INITIAL_CREATE_TASK: InitialValues = {
  title: "",
  description: "",
};

type CreateTaskModalProps = {
  onHide: () => void;
  sectionId: string;
} & ModalProps;

const CreateTaskModal: FC<CreateTaskModalProps> = ({
  onHide,
  sectionId,
  ...props
}) => {
  const dispatch = useAppDispatch();

  const handleSubmit = (values: InitialValues) => {
    if (!sectionId) {
      return;
    }
    dispatch(
      updateTasks({
        id: uuid4(),
        title: values.title.trim(),
        description: values.description.trim(),
        members: [],
        sectionId,
        createdAt: new Date().getTime(),
      })
    );
    onHide();
  };
  return (
    <div
      aria-hidden
      onClick={(e) => e.stopPropagation()}
    >
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onHide}
      >
        <Modal.Body className="p-12">
          <Formik
            initialValues={INITIAL_CREATE_TASK}
            validationSchema={createTaskSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange, touched, isValid }) => (
              <Form>
                <div className="fs-14 text-color-gray-6 d-flex align-items-center justify-content-between mb-12">
                  <div className="d-flex align-items-center gap-2">
                    <span>ECY-290920190</span>
                    <i className="tgn-outline-direction-right-01 fs-20"></i>
                    <span className="text-primary-color-gray-10 fw-600">
                      Khởi tạo Tác vụ
                    </span>
                  </div>
                  <div>
                    <i
                      aria-hidden
                      className="tgn-outline-chat-close cursor-pointer"
                      onClick={onHide}
                    ></i>
                  </div>
                </div>
                <TGNInputField
                  classNameInput={styles.title}
                  name="title"
                  placeholder="Tiêu đề Tác vụ"
                  onChange={handleChange}
                ></TGNInputField>

                <Row>
                  <Col
                    xs={3}
                    className="d-flex align-items-center gap-2"
                  >
                    <i className="tgn-outline-loading-01 fs-20"></i>
                    <span className="fs-14">Trạng thái</span>
                  </Col>
                  <Col xs={9}>
                    <span className="fs-14">Đã Mở</span>
                  </Col>
                  <Col
                    xs={3}
                    className="d-flex align-items-center gap-2 mt-2"
                  >
                    <i className="tgn-outline-user fs-20"></i>
                    <span className="fs-14">Người phụ trách</span>
                  </Col>
                  <Col
                    xs={9}
                    className="mt-2"
                  >
                    <div className={styles["btn-add"]}>
                      <i className="tgn-solid-plus fs-20"></i>
                    </div>
                  </Col>
                </Row>
                <Divider
                  width="100%"
                  height={1}
                  className="mt-3"
                />
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className="text-tertiary-color-gray-5 d-flex align-items-center gap-3">
                    <div className="d-flex align-items-center gap-2">
                      <i className="tgn-outline-link fs-16"></i>
                      <span className="fs-12">Add Attachments</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <TGNSwitch
                        label=""
                        className="mb-0"
                      />
                      <span className="fs-14">Tạo nhiều Tác vụ</span>
                    </div>
                  </div>
                  <TGNButton
                    type="submit"
                    disabled={!(touched && isValid)}
                  >
                    <i className="tgn-outline-plus"></i>
                    <span className="fs-14 fw-semibold">Khởi tạo</span>
                  </TGNButton>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateTaskModal;
