import React, { FC, memo, useState } from "react";

import { DotMark } from "@carbon/icons-react";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import {
  IGroupInvitation,
  IGroupPersonalRequest,
} from "@/features/groups/types/group.type";
import { formatDistance } from "@/utils/date";

type Props = {
  rootGroupId: number;
  invites: (IGroupInvitation | IGroupPersonalRequest)[];
};

const ListView: FC<Props> = ({ invites, rootGroupId }) => {
  return (
    <>
      <div className="flex items-center gap-1 text-secondary-600 text-xs -mb-4 pl-3">
        <span className="font-medium">Danh sách</span>
        <DotMark
          size={5}
          color="#4B5563"
          className="ml-1 mr-1"
        ></DotMark>
        <span className="font-semibold">{invites.length}</span>
      </div>
      <div className="flex-none min-w-full px-0 overflow-hidden lg:overflow-auto h-[400px]">
        {invites
          .filter((invite) => {
            return invite.groupId !== rootGroupId;
          })
          .map((invite, index) => {
            return (
              <div
                key={index}
                className="w-full h-16 p-3 bg-white justify-between items-center inline-flex hover:bg-secondary-50"
              >
                <div className="justify-start items-center gap-2 flex">
                  <img
                    className="w-10 h-10 rounded-[12px]"
                    src={invite.photoUrl ?? GroupCover}
                    alt=""
                  />
                  <div className="flex-col justify-start items-start inline-flex">
                    <div className="self-stretch text-secondary-900 text-sm font-medium ">
                      {invite.name}
                    </div>
                    {"sender" in invite && invite.sender && (
                      <div className="justify-start items-start gap-1 inline-flex">
                        <div className="text-secondary-600 text-xs font-normal leading-none">
                          Được mời bởi
                        </div>
                        <div className="text-secondary-600 text-xs font-medium underline leading-none">
                          {invite?.sender.name}
                        </div>
                        <div className="text-secondary-600 text-xs font-normal leading-none">
                          {invite.invitedAt &&
                            formatDistance(invite?.invitedAt)}
                        </div>
                      </div>
                    )}
                    {"requestedAt" in invite && (
                      <div className="justify-start items-start gap-1 inline-flex">
                        <div className="text-secondary-600 text-xs font-normal leading-none">
                          Đã gửi yêu cầu
                        </div>
                        <div className="text-secondary-600 text-xs font-normal leading-none">
                          {invite.requestedAt &&
                            formatDistance(invite.requestedAt)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
export default memo(ListView);
