import React, { memo, useEffect, useMemo, useState } from "react";

import {
  ConnectionSignal,
  Devices,
  OverflowMenuVertical,
  TrashCan,
} from "@carbon/icons-react";

import Loading3Dot from "@/components/TGNLoading/Loading3Dot";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import {
  cancelDeviceAuthenticationRequest,
  getMyGroupDevice,
  removeMyDevice,
  verifyRemoveMyDevice,
} from "@/features/groups/api/group.api";
import VerifyOtpDialog from "@/features/groups/components/VerifyOtpDialog";
import { GroupDeviceStatus } from "@/features/groups/constants/group.constant";
import { getDeviceName } from "@/features/groups/helpers/group.helper";
import {
  DeviceDef,
  GetMyGroupDeviceResponse,
} from "@/features/groups/types/group.type";
import useToastError from "@/hooks/useToastError";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDistance, formatViDate } from "@/utils/date";

import RemoveDeviceRequestDialog from "./RemoveDeviceRequestDialog";
import RemoveMyDeviceDialog from "./RemoveMyDeviceDialog";

const AdvanceSetting = () => {
  const { showToastError } = useToastError();
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const [myGroupDevice, setMyGroupDevice] = useState<GetMyGroupDeviceResponse>(
    []
  );
  const [openRemoveMyDeviceDialog, setOpenRemoveMyDeviceDialog] =
    useState(false);
  const [openRemoveDeviceRequestDialog, setOpenRemoveDeviceRequestDialog] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRemoveMyDevice, setLoadingRemoveMyDevice] = useState(false);
  const [loadingVerifyRemoveMyDevice, setLoadingVerifyRemoveMyDevice] =
    useState(false);
  const [deviceSelected, setDeviceSelected] = useState<DeviceDef | null>(null);
  const [openVerifyOtpDialog, setOpenVerifyOtpDialog] = useState(false);
  const [uid, setUid] = useState("");
  const { toast } = useToast();

  const fetchMyGroupDevice = async () => {
    if (!groupInfo) {
      return;
    }
    try {
      setLoading(true);
      const result = await getMyGroupDevice(
        groupInfo.rootGroupId || groupInfo.id
      );
      setMyGroupDevice(result.data);
    } catch (err) {
      showToastError(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchMyGroupDevice();
  }, []);
  const currentDevice = useMemo(() => {
    return myGroupDevice.find((device) => device.currentDevice);
  }, [myGroupDevice]);
  const otherDevice = useMemo(() => {
    return myGroupDevice.filter(
      (device) =>
        !device.currentDevice && device.status === GroupDeviceStatus.APPROVED
    );
  }, [myGroupDevice]);
  const requestedDevice = useMemo(() => {
    return myGroupDevice.filter(
      (device) => device.status === GroupDeviceStatus.REQUESTED
    );
  }, [myGroupDevice]);

  const submitRemoveMyDevice = async () => {
    if (!deviceSelected || !groupInfo) {
      return;
    }
    try {
      setLoadingRemoveMyDevice(true);
      const result = await removeMyDevice({
        groupId: groupInfo.id,
        deviceUniqueId: deviceSelected.deviceUniqueId,
      });
      if (result) {
        setUid(result.data.uid);
        setOpenRemoveMyDeviceDialog(false);
        setOpenVerifyOtpDialog(true);
      }
    } catch (err) {
      showToastError(err);
    } finally {
      setLoadingRemoveMyDevice(false);
    }
  };
  const handleVerifyRemoveMyDevice = async (otp: string) => {
    if (!deviceSelected || !groupInfo || !uid || !user) {
      return;
    }
    try {
      setLoadingVerifyRemoveMyDevice(true);
      const result = await verifyRemoveMyDevice({
        code: otp,
        deviceUniqueId: deviceSelected.deviceUniqueId,
        groupId: groupInfo.id,
        uid: uid,
        userId: user.id,
      });
      if (result) {
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <p>
              Đã gỡ thiết bị{" "}
              <span className="font-semibold">
                {getDeviceName(deviceSelected)}
              </span>
            </p>
          ),
        });
        setLoadingVerifyRemoveMyDevice(false);
        setOpenVerifyOtpDialog(false);
        await fetchMyGroupDevice();
      }
    } catch (err) {
      showToastError(err);
    } finally {
      setLoadingVerifyRemoveMyDevice(false);
    }
  };
  const submitRemoveDeviceRequest = async () => {
    if (!deviceSelected || !groupInfo || !user) {
      return;
    }
    try {
      dispatch(
        loadingAction.show({
          title: "Đang xử lý...",
          description: "Vui lòng chờ tới khi xử lý hoàn tất.",
        })
      );
      await cancelDeviceAuthenticationRequest({
        deviceUniqueId: deviceSelected.deviceUniqueId,
        groupId: groupInfo.rootGroupId || groupInfo.id,
        userId: user.id,
      });
      setOpenRemoveDeviceRequestDialog(false);
      toast({
        title: "Thành công!",
        variant: "success",
        description: (
          <p>
            Đã gỡ yêu cầu xác thực thiết bị{" "}
            <span className="font-semibold">
              {getDeviceName(deviceSelected)}
            </span>
          </p>
        ),
      });
      await fetchMyGroupDevice();
    } catch (err) {
      showToastError(err);
    } finally {
      dispatch(loadingAction.hide());
    }
  };

  return (
    <>
      <div className="mt-6">
        <p className="text-base font-semibold text-secondary-900">
          Cài đặt nâng cao
        </p>
        <p className="text-xs text-secondary-600 flex items-center">
          Quản lý thiết lập nâng cao của cá nhân trong Tổ chức/Nhóm
        </p>
        <p className="text-secondary-600 uppercase font-medium text-xs mt-6">
          Quản lý truy cập
        </p>

        <div className="mt-4 rounded-lg py-2 bg-white">
          <p className="text-sm font-medium text-secondary-900 mb-2 px-6 mt-4">
            Thiết bị truy cập
          </p>
          {loading ? (
            <div className="flex items-center justify-center">
              <Loading3Dot
                setWidth="30"
                setHeight="30"
              />
            </div>
          ) : (
            <>
              <p className="text-sm text-secondary-600 mb-2 px-6 ">
                Bạn đang đăng nhập trên thiết bị này:
              </p>
              {currentDevice && (
                <div className="w-full py-3 justify-between items-center inline-flex px-6">
                  <div className="h-16 justify-between items-center flex">
                    <div className="justify-start items-center gap-3 flex">
                      <div className="w-16 h-16 bg-secondary-50 rounded-full flex-col justify-center items-center inline-flex">
                        <Devices
                          className="text-secondary-600 "
                          size={24}
                        />
                      </div>
                      <div className="self-stretch flex-col justify-center items-start gap-2 inline-flex">
                        <div className="flex-col justify-start items-start gap-0.5 flex">
                          <div className="justify-start items-start gap-1 inline-flex">
                            <div className="text-secondary-900 text-sm font-medium">
                              {currentDevice.device.name ||
                                "Thiết bị không xác định"}
                            </div>
                          </div>
                          <div className="text-secondary-400 text-xs font-normal">
                            ID: {currentDevice.device.deviceUniqueId}
                          </div>
                        </div>
                        <div className="justify-start items-start gap-1 inline-flex">
                          <ConnectionSignal className="text-emerald-500" />
                          <div className="text-emerald-500 text-xs font-medium">
                            Đang hoạt động
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <p className="text-sm text-secondary-600 mb-2 mt-4 px-6 font-medium">
                Thiết bị khác ・
                <span className="text-secondary-600">{otherDevice.length}</span>
              </p>
              {otherDevice.map((item, index) => {
                return (
                  <div
                    className="w-full px-6 py-3 justify-between items-center inline-flex hover:bg-secondary-100"
                    key={index}
                  >
                    <div className="h-16 justify-between items-center flex">
                      <div className="justify-start items-center gap-3 flex">
                        <div className="w-16 h-16 bg-secondary-50 rounded-full flex-col justify-center items-center inline-flex">
                          <Devices
                            className="text-secondary-600 "
                            size={24}
                          />
                        </div>
                        <div className="self-stretch flex-col justify-center items-start gap-2 inline-flex">
                          <div className="flex-col justify-start items-start gap-0.5 flex">
                            <div className="justify-start items-start gap-1 inline-flex">
                              <div className="text-secondary-900 text-sm font-medium">
                                {item.device.name || "Thiết bị không xác định"}
                              </div>
                            </div>
                            <div className="text-secondary-400 text-xs font-normal">
                              ID: {item.device.deviceUniqueId}
                            </div>
                          </div>
                          <div className="h-4 justify-start items-start gap-1 inline-flex">
                            <div className="text-secondary-600 text-xs ">
                              Hoạt động
                            </div>
                            <div className="text-secondary-600 text-xs ">
                              {formatViDate(item.lastActive || new Date())}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          className="px-3 py-2"
                          variant={"text"}
                        >
                          <OverflowMenuVertical />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <Button
                          variant="ghost"
                          className="w-full"
                          onClick={() => {
                            setDeviceSelected(item.device);
                            setOpenRemoveMyDeviceDialog(true);
                          }}
                        >
                          <TrashCan className="me-1" />
                          Gỡ thiết bị này
                        </Button>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                );
              })}
            </>
          )}
        </div>

        <div className="mt-4 rounded-lg py-2 bg-white">
          {loading ? (
            <div className="flex items-center justify-center">
              <Loading3Dot
                setWidth="30"
                setHeight="30"
              />
            </div>
          ) : (
            <>
              <p className="text-sm text-secondary-600 mb-2 mt-4 px-6 font-medium">
                Đang chờ xác thực ・
                <span className="text-secondary-600">
                  {requestedDevice.length}
                </span>
              </p>
              {requestedDevice.map((item, index) => {
                return (
                  <div
                    className="w-full px-6 py-3 justify-between items-center inline-flex hover:bg-secondary-100"
                    key={index}
                  >
                    <div className="h-16 justify-between items-center flex">
                      <div className="justify-start items-center gap-3 flex">
                        <div className="w-16 h-16 bg-secondary-50 rounded-full flex-col justify-center items-center inline-flex">
                          <Devices
                            className="text-secondary-600 "
                            size={24}
                          />
                        </div>
                        <div className="self-stretch flex-col justify-center items-start gap-2 inline-flex">
                          <div className="flex-col justify-start items-start gap-0.5 flex">
                            <div className="justify-start items-start gap-1 inline-flex">
                              <div className="text-secondary-900 text-sm font-medium">
                                {item.device.name || "Thiết bị không xác định"}
                              </div>
                            </div>
                            <div className="text-secondary-400 text-xs font-normal">
                              ID: {item.device.deviceUniqueId}
                            </div>
                          </div>
                          <div className="h-4 justify-start items-start gap-1 inline-flex">
                            <div className="text-secondary-600 text-xs ">
                              Hoạt động
                            </div>
                            <div className="text-secondary-600 text-xs ">
                              {formatDistance(item.lastActive || new Date())}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          className="px-3 py-2"
                          variant={"text"}
                        >
                          <OverflowMenuVertical />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <Button
                          variant="ghost"
                          className="w-full"
                          onClick={() => {
                            setDeviceSelected(item.device);
                            setOpenRemoveDeviceRequestDialog(true);
                          }}
                        >
                          <TrashCan className="me-1" />
                          Gỡ yêu cầu
                        </Button>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      <RemoveMyDeviceDialog
        open={openRemoveMyDeviceDialog}
        onClose={() => setOpenRemoveMyDeviceDialog(false)}
        onSubmit={submitRemoveMyDevice}
        device={deviceSelected}
        loading={loadingRemoveMyDevice}
      />
      <RemoveDeviceRequestDialog
        open={openRemoveDeviceRequestDialog}
        onClose={() => setOpenRemoveDeviceRequestDialog(false)}
        onSubmit={submitRemoveDeviceRequest}
        device={deviceSelected}
        loading={loadingRemoveMyDevice}
      />
      <VerifyOtpDialog
        open={openVerifyOtpDialog}
        onClose={() => setOpenVerifyOtpDialog(false)}
        email={user ? user.email : ""}
        title="Xác minh"
        reFetchUid={submitRemoveMyDevice}
        onSubmit={handleVerifyRemoveMyDevice}
        loading={loadingVerifyRemoveMyDevice}
      />
    </>
  );
};

export default memo(AdvanceSetting);
