import React, { memo, useEffect, useMemo, useState } from "react";

import {
  Devices,
  OverflowMenuVertical,
  Add,
  Subtract,
  TrashCan,
} from "@carbon/icons-react";
import { isAxiosError } from "axios";
import cx from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

import { Empty } from "@/assets/icons";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import Loading3Dot from "@/components/TGNLoading/Loading3Dot";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import { PAGE_DEFAULT } from "@/constants/app.constants";
import { UserDef } from "@/features/auth/types/auth.types";
import {
  getGroupDeviceByStatus,
  removeGroupDeviceByManager,
  updateGroupDeviceStatus,
} from "@/features/groups/api/group.api";
import { GroupDeviceStatus } from "@/features/groups/constants/group.constant";
import { getDeviceName } from "@/features/groups/helpers/group.helper";
import { DeviceDef } from "@/features/groups/types/group.type";
import { GetGroupDeviceByManagerResponse } from "@/features/groups/types/groupDevice.type";
import usePagination from "@/hooks/usePagination";
import { useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import DeviceRequestedItem from "./DeviceRequestedItem";

const Approved = () => {
  const { toast } = useToast();
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [groupDeviceSelected, setGroupDeviceSelected] = useState<{
    user: UserDef | null;
    device: DeviceDef | null;
  }>({
    user: null,
    device: null,
  });
  const [groupDeviceList, setGroupDeviceList] = useState<
    GetGroupDeviceByManagerResponse[]
  >([]);
  const groupInfo = useAppSelector((state) => state.group.data);
  const user = useAppSelector((state) => state.auth.user);

  const { pagination, setPagination } = usePagination();
  const [open, setOpen] = useState(false);

  const fetchInfiniteScroll = async (pageScroll: number) => {
    if (!groupInfo) {
      return;
    }
    try {
      setIsLoadMore(true);
      const result = await getGroupDeviceByStatus({
        pageNumber: pageScroll,
        pageSize: pagination.size,
        groupId: groupInfo.id,
        status: GroupDeviceStatus.REQUESTED,
      });
      if (!result.data.length || pagination.current <= result.totalPage) {
        setIsLoadMore(false);
      }
      setPagination({
        current: result.pageIndex,
        size: pagination.size,
        total: result.totalPage,
      });
      setGroupDeviceList((prev) => [...prev, ...result.data]);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    }
  };

  useEffect(() => {
    fetchInfiniteScroll(pagination.current);
  }, []);

  const handleAcceptOrRejectRequest = async (
    user: UserDef,
    device: DeviceDef,
    accept: boolean
  ) => {
    if (!groupInfo || !user) {
      return;
    }
    setGroupDeviceSelected({
      device,
      user,
    });
    try {
      setLoading(true);
      const result = await updateGroupDeviceStatus({
        groupId: groupInfo.id,
        userId: user.id,
        deviceUniqueId: device.deviceUniqueId,
        accept,
      });
      if (result.data) {
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <p>
              Đã {accept ? "phê duyệt" : "từ chối"} thiết bị{" "}
              <span className="font-semibold">{getDeviceName(device)}</span>
            </p>
          ),
        });
        setGroupDeviceList([]);
        setGroupDeviceSelected({
          device: null,
          user: null,
        });
        await fetchInfiniteScroll(PAGE_DEFAULT);
      }
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const countDevice = useMemo(() => {
    return groupDeviceList.reduce((prev, current) => {
      return prev + current.devices.length;
    }, 0);
  }, [groupDeviceList]);

  return (
    <div>
      <div className="mt-6">
        <p className="text-base font-semibold text-secondary-900">
          Thiết bị chờ phê duyệt ・
          <span className="text-secondary-600">{countDevice}</span>
        </p>
        <p className="text-xs text-secondary-600 flex items-center">
          Tinh chỉnh quyền truy cập đối với hệ thống Tổ chức của bạn và người
          dùng TAGA
        </p>

        <div className="mt-4 rounded-lg border border-secondary-200 bg-white py-2">
          {groupDeviceList.length === 0 && !isLoadMore && (
            <div className="flex flex-col items-center justify-center text-center">
              <Empty />
              <span className="text-secondary-600 text-xs mt-4 font-semibold">
                Không có thiết bị nào đang chờ phê duyệt
              </span>
            </div>
          )}
          <InfiniteScroll
            className="px-0"
            dataLength={groupDeviceList.length}
            next={() => {
              setPagination((prev) => ({ ...prev, current: prev.current + 1 }));
              fetchInfiniteScroll(pagination.current + 1);
            }}
            hasMore={isLoadMore}
            loader={
              <div className="flex items-center justify-center mt-6">
                <Loading3Dot
                  setWidth="30"
                  setHeight="30"
                />
              </div>
            }
          >
            {groupDeviceList.map((groupDevice, index) => {
              return (
                <DeviceRequestedItem
                  key={index}
                  groupDevice={groupDevice}
                  groupDeviceSelected={groupDeviceSelected}
                  handleAcceptOrRejectRequest={handleAcceptOrRejectRequest}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default memo(Approved);
