import React, { FC, ReactNode, memo, useState } from "react";

import { ArrowUpRight, Close, InformationFilled } from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import {
  getGroupInvitationForUser,
  getUserRequests,
  userCancelRequestToGroup,
} from "@/features/groups/api/group.api";
import { GroupUserStatusEnum } from "@/features/groups/constants/group.constant";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { getGroupInfo } from "@/features/groups/group";
import {
  IGroupInvitation,
  IGroupPersonalRequest,
} from "@/features/groups/types/group.type";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch } from "@/redux/store";
import { COMMON_MESSAGES, getCustomMessage } from "@/utils/message";

import ListView from "../../GroupInvitesScreen/component/ListView";

type CancelModalProps = {
  button: ReactNode;
  isDetail?: boolean;
  groupId: number;
  inviteId: number;
  groupName: string;
  rootGroupId?: number;
  groupLock: boolean;
};

const CancelModal: FC<CancelModalProps> = ({
  groupName,
  inviteId,
  rootGroupId,
  groupId,
  button,
  isDetail,
  groupLock,
}) => {
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);

  const [invites, setInvites] = useState<
    (IGroupInvitation | IGroupPersonalRequest)[]
  >([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const rejectFn = async (inviteId: number) => {
    if (groupLock) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG80,
      });
    } else {
      dispatch(
        loadingAction.show({
          title: "Đang xử lý...",
          description: "Vui lòng chờ tới khi xử lý hoàn tất.",
        })
      );
      try {
        await userCancelRequestToGroup(inviteId);
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: getCustomMessage("MSG73", String(groupName)),
              }}
            ></div>
          ),
        });
        if (isDetail) {
          dispatch(
            getGroupInfo({
              group_id: groupId,
            })
          );
        }
      } catch (error: any) {
        dispatch(loadingAction.hide());

        toast({
          title: "Thất bại!",
          variant: "error",
          description: COMMON_MESSAGES.MSG12,
        });
      } finally {
        dispatch(loadingAction.hide());
      }
    }
  };

  const handleReject = async (inviteId: number) => {
    if (groupLock) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG80,
      });
    } else {
      // dispatch(
      //   loadingAction.show({
      //     title: "Đang xử lý...",
      //     description: "Vui lòng chờ tới khi xử lý hoàn tất.",
      //   })
      // );
      try {
        if (inviteId) {
          const childRequests = await getUserRequests({
            rootGroupId: rootGroupId,
            pageNumber: 1,
            pageSize: 10,
          });
          const childRequestsAvailable = childRequests.data.filter(
            (item) => item.groupId !== rootGroupId && item.groupId !== groupId
          );
          const childInvites = await getGroupInvitationForUser({
            rootGroupId: rootGroupId,
            pageNumber: 1,
            pageSize: 10,
          });
          const childInviteAvailable = childInvites.data.filter(
            (item) =>
              item.groupId !== groupId &&
              item.status !== GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
          );
          if (
            (childRequestsAvailable.length > 0 ||
              childInviteAvailable.length > 0) &&
            rootGroupId === groupId
          ) {
            setOpenModal(true);
            setInvites([...childRequestsAvailable, ...childInviteAvailable]);
          } else {
            await userCancelRequestToGroup(inviteId);
            toast({
              title: "Thành công!",
              variant: "success",
              description: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getCustomMessage("MSG73", String(groupName)),
                  }}
                ></div>
              ),
            });
            if (isDetail && groupId) {
              dispatch(
                getGroupInfo({
                  group_id: groupId,
                })
              )
                .then(unwrapResult)
                .catch(() => {
                  navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
                });
            }
          }
        }
      } catch (error: any) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: COMMON_MESSAGES.MSG12,
        });
      } finally {
        dispatch(loadingAction.hide());
      }
    }
  };
  return (
    <>
      <div
        className="w-full"
        onClick={() => {
          handleReject(inviteId);
        }}
      >
        {button}
      </div>
      <Dialog open={openModal}>
        <DialogContent className={"sm:max-w-[600px] bg-white"}>
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-0 right-0 rounded-full w-[36px]"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <Close />
            </Button>
            <DialogTitle className="text-center max-w-[460PX] text-lg mx-auto">
              Lời mời tham gia Tổ chức
            </DialogTitle>
          </DialogHeader>
          <Formik
            initialValues={{
              name: "",
              description: "",
            }}
            onSubmit={(values: any, { setSubmitting }) => {
              console.log(11);
            }}
          >
            {({ setFieldValue, isValid, values }) => (
              <Form className="grid gap-4">
                <div className="flex gap-2  grid-cols-2 bg-primary-25 p-4 rounded-md">
                  <div>
                    <span className="block border-[6px] p-1 text-primary-600 border-primary-50 bg-primary-100 rounded-full">
                      <InformationFilled size={24} />
                    </span>
                  </div>
                  <span className="w-[calc(100%_-_48px)] text-secondary-900 text-sm">
                    Bạn có một số lời mời/yêu cầu liên quan tới các nhóm thuộc
                    tổ chức này. Việc thực hiện từ chối vào tổ chức đồng nghĩa
                    với việc bạn không thể trở thành thành viên của các nhóm
                    thuộc tổ chức này.
                    <span className="font-medium inline-flex items-center">
                      Tìm hiểu thêm <ArrowUpRight size={14} />
                    </span>
                  </span>
                </div>
                {rootGroupId && (
                  <ListView
                    rootGroupId={rootGroupId}
                    invites={invites}
                  />
                )}
                <label
                  htmlFor="rejectAll"
                  className="items-center flex space-x-2 "
                >
                  <Checkbox
                    name="rejectAll"
                    id="rejectAll"
                    onCheckedChange={(value) => {
                      setFieldValue("rejectAll", value);
                    }}
                  />

                  <p className="text-secondary-600 text-xs">
                    Từ chối lời mời/hoàn tác yêu cầu tham gia các nhóm trong tổ
                    chức này
                  </p>
                </label>
                <DialogFooter className="justify-center sm:justify-center">
                  <Button
                    disabled={!values.rejectAll}
                    onClick={() => {
                      rejectFn(inviteId);
                    }}
                  >
                    Xác nhận
                  </Button>
                </DialogFooter>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(CancelModal);
