import React, { FC, memo, useMemo, useState } from "react";

import {
  Add,
  Devices,
  OverflowMenuVertical,
  Subtract,
  TrashCan,
} from "@carbon/icons-react";
import cx from "classnames";
import { isAfter } from "date-fns";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserDef } from "@/features/auth/types/auth.types";
import { DeviceDef } from "@/features/groups/types/group.type";
import { GetGroupDeviceByManagerResponse } from "@/features/groups/types/groupDevice.type";
import { useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";

type DeviceRequestedItemProps = {
  groupDevice: GetGroupDeviceByManagerResponse;
  groupDeviceSelected: {
    user: UserDef | null;
    device: DeviceDef | null;
  };
  handleAcceptOrRejectRequest: (
    user: UserDef,
    device: DeviceDef,
    accept: boolean
  ) => void;
};

const DeviceRequestedItem: FC<DeviceRequestedItemProps> = ({
  groupDevice,
  groupDeviceSelected,
  handleAcceptOrRejectRequest,
}) => {
  const [open, setOpen] = useState(false);
  const user = useAppSelector((state) => state.auth.user);
  const deviceRequestEarliest = useMemo(() => {
    let result = groupDevice.devices[0];
    groupDevice.devices.forEach((item) => {
      if (isAfter(new Date(item.createdAt), new Date(result.createdAt))) {
        result = item;
      }
    });
    return result;
  }, [groupDevice]);

  return (
    <div>
      <Collapsible>
        <CollapsibleTrigger
          className="w-full text-left"
          onClick={() => setOpen(!open)}
        >
          <div
            className={cx(
              open ? "border-b border-secondary-100" : "",
              "w-full px-6 py-3  justify-between items-center inline-flex hover:bg-secondary-100"
            )}
          >
            <div className="justify-between items-center flex">
              <div className="justify-start items-center gap-3 flex">
                <div className="w-16 bg-secondary-50 rounded-full flex-col justify-center items-center inline-flex">
                  <TGNAvatar
                    className="w-16 h-16 rounded-full cursor-pointer"
                    src={groupDevice.user.photoUrl}
                    userPhoto={groupDevice.user.photoUrl}
                    name={groupDevice.user.name}
                    alt=""
                  />
                </div>
                <div className="self-stretch flex-col items-start gap-2 inline-flex">
                  <div className="flex-col justify-start items-start gap-0.5 flex">
                    <div className="text-secondary-900 text-sm font-medium">
                      <span>
                        {groupDevice.user.name || groupDevice.user.email}
                        {user?.id == groupDevice.user.id ? "( Bạn )" : " "}
                        <span className="font-normal">
                          đã gửi {groupDevice.devices.length} yêu cầu xác thực
                          thiết bị
                        </span>
                      </span>
                    </div>
                    <div className="text-secondary-600 text-xs font-normal">
                      {groupDevice.user.email}
                    </div>
                    <span className="text-xs text-secondary-600 mt-2">
                      Đã gửi {formatDistance(deviceRequestEarliest.createdAt)}{" "}
                      trước
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {open ? <Subtract /> : <Add />}
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent
          className={cx(open ? "border-b border-secondary-100" : "")}
        >
          {groupDevice.devices.map((item, index) => {
            return (
              <div
                className="w-full px-6 py-3 bg-secondary-50 justify-between items-center inline-flex hover:bg-secondary-100"
                key={index}
              >
                <div className="justify-between items-center flex">
                  <div className="justify-start items-center gap-3 flex">
                    <div className="w-16 bg-secondary-50 rounded-full flex-col justify-center items-center inline-flex ">
                      <Devices
                        className="text-secondary-600 "
                        size={24}
                      />
                    </div>
                    <div className="self-stretch flex-col justify-center items-start gap-2 inline-flex">
                      <div className="flex-col justify-start items-start gap-0.5 flex">
                        <div className="justify-start items-start gap-1 inline-flex">
                          <div className="text-secondary-900 text-sm font-medium">
                            {item.device.name || "Thiết bị không xác định"}
                          </div>
                        </div>
                        <div className="text-secondary-400 text-xs font-normal">
                          ID: {item.device.deviceUniqueId}
                        </div>
                      </div>
                      <div className="h-4 justify-start items-start gap-1 inline-flex">
                        <div className="text-secondary-600 text-xs ">
                          Đã gửi
                        </div>
                        <div className="text-secondary-600 text-xs ">
                          {formatDistance(item.createdAt)} trước
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    size="default"
                    variant="primary"
                    onClick={() =>
                      handleAcceptOrRejectRequest(
                        groupDevice.user,
                        item.device,
                        true
                      )
                    }
                    disabled={
                      groupDeviceSelected.user?.id === groupDevice.user.id &&
                      groupDeviceSelected.device?.id === item.device.id
                    }
                  >
                    Chấp nhận
                  </Button>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        className="px-3 py-2"
                        variant={"text"}
                      >
                        <OverflowMenuVertical />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <Button
                        variant="ghost"
                        className="w-full"
                        onClick={() =>
                          handleAcceptOrRejectRequest(
                            groupDevice.user,
                            item.device,
                            false
                          )
                        }
                        disabled={
                          groupDeviceSelected.user?.id ===
                            groupDevice.user.id &&
                          groupDeviceSelected.device?.id === item.device.id
                        }
                      >
                        <TrashCan className="me-1" />
                        Từ chối yêu cầu
                      </Button>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            );
          })}
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};

export default memo(DeviceRequestedItem);
