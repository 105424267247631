import React, { useEffect, useState } from "react";

import {
  CaretDown,
  ChevronLeft,
  Close,
  Group,
  InformationFilled,
  Locked,
} from "@carbon/icons-react";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { getCustomMessage, getMessage } from "@/utils/message";

import { ComboboxMembers, IComboboxMemberItem } from "./ComboboxMembers";
// eslint-disable-next-line import/namespace
import {
  getFriend,
  getSharedUsers,
  removeMemberReference,
  shareContent,
} from "../api/lib.api";
import { IContent, ICreator } from "../types/lib.types";

interface IShareContentFolderModalProps {
  isShow: boolean;
  content: IContent;
  onSubmitSuccess?: () => void;
  onOpenChange?: (val: boolean) => void;
  onChangeMember?: () => void;
}

export default function ShareContentFolderModal({
  content,
  isShow,
  onSubmitSuccess,
  onOpenChange,
  onChangeMember,
}: IShareContentFolderModalProps) {
  const [selected, setSelected] = useState<ICreator[]>([]);
  const [members, setMembers] = useState<IComboboxMemberItem[]>([]);
  const [sharedUsers, setSharedUsers] = useState<ICreator[]>([]);
  const fetchSharedUsers = async () => {
    const result = await getSharedUsers(content.id);
    if (result.data) {
      setSharedUsers(result.data.map((item) => item.user));
    }
  };
  const [manage, setManage] = useState<boolean>(false);
  const fetchMembers = async () => {
    try {
      if (!content) return;

      const resMember = await getFriend();
      const sharedContent = await getSharedUsers(content.id);
      const memberInContent = sharedContent.data || [];
      if (resMember && resMember.data) {
        const tmpMembers = [...resMember.data].map((m) => ({
          ...m,
          shared: memberInContent.some(
            (mic: any) => mic.user.email === m.email
          ),
        }));
        setMembers(tmpMembers);
      } else {
        setMembers([]);
      }
    } catch {
      console.log("error");
    }
  };
  useEffect(() => {
    fetchSharedUsers();
    isShow && fetchMembers();
    setManage(false);
  }, [isShow]);

  const onSelectMemberChange = (val: ICreator[]) => {
    setSelected(val);
  };

  const onAcceptContent = async () => {
    try {
      if (!content || !selected || !selected.length) return;
      await shareContent(
        content?.id,
        selected.map((s) => s.id)
      );
      toast({
        title: "Thành công!",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: getCustomMessage("MSGTV35", content.name),
            }}
          ></div>
        ),
      });
      onSubmitSuccess && onSubmitSuccess();
    } catch (e: any) {
      toast({
        title: "Thất bại!",
        description: getMessage("MSG_ERROR"),
      });
    }
  };
  const onRemove = async (memberId: number) => {
    try {
      if (!content) return;
      await removeMemberReference(content.id, memberId);
      fetchSharedUsers();
    } catch (e: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(e.data?.errors[0]),
      });
    }
  };

  return (
    <Dialog
      open={isShow}
      onOpenChange={onOpenChange}
    >
      <DialogContent className="w-[600px] max-w-full">
        <span
          className="absolute top-4 right-4 cursor-pointer "
          onClick={() => onOpenChange && onOpenChange(false)}
        >
          <Close />
        </span>
        {manage ? (
          <div className="h-96 ">
            <span
              className="font-medium flex items-center mb-4 cursor-pointer text-sm text-secondary-600"
              onClick={() => setManage(!manage)}
            >
              <ChevronLeft className="mr-2 h-4 w-4" />
              Trở lại
            </span>
            <div className="font-semibold text-lg">Danh sách truy cập</div>
            <ManageContentAccessModalMembers
              members={sharedUsers}
              onRemove={onRemove}
            />
          </div>
        ) : (
          <div className="grid gap-6">
            <DialogHeader>
              <div className="flex items-center">
                <div className="font-semibold text-lg">
                  Chia sẻ "{content?.name}"
                </div>
              </div>
            </DialogHeader>
            <div className="flex flex-row items-center">
              <div className="w-100 h-10 justify-start items-start gap-3 inline-flex">
                <div className="w-[40px] h-[40px] p-3 rounded-full bg-secondary-50 text-secondary-600">
                  <Locked />
                </div>
                <div className="flex-col justify-start items-start gap-1 inline-flex">
                  <div className="justify-start items-start gap-1 inline-flex">
                    <div className="text-secondary-900 text-sm font-medium ">
                      Hạn chế
                    </div>
                  </div>
                  <div className="text-secondary-600 text-xs">
                    Chỉ có sẵn với một số người dùng được cấp quyền truy cập.{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4 bg-primary-25">
              <div className="flex flex-row space-x-2 text-primary">
                <InformationFilled />
                <div className="text-xs">
                  Bạn chỉ có thể chia sẻ nội dung với bạn bè trên TAGA.
                </div>
              </div>
            </div>
            <div>
              <ComboboxMembers
                placeholder="Nhập tên của bạn bè ..."
                onSelected={onSelectMemberChange}
                options={members}
              />
            </div>
            <div className="flex flex-row items-center">
              <div className="mr-auto">
                <Button
                  variant="text"
                  className="font-medium text-tg-text-secondary"
                  onClick={() => setManage(true)}
                >
                  <Group className="mr-2 h-4 w-4" />
                  Danh sách truy cập
                </Button>
              </div>
              <div className="space-x-2 flex justify-end">
                <Button
                  variant="outline"
                  onClick={() => onOpenChange && onOpenChange(false)}
                >
                  Hủy
                </Button>
                <Button
                  onClick={onAcceptContent}
                  disabled={!selected || !selected.length}
                >
                  Chia sẻ
                </Button>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

const ManageContentAccessModalMembers = ({
  members,
  onRemove,
}: {
  members: IComboboxMemberItem[];
  onRemove?: (memberId: number) => void;
}) => {
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  return (
    <div>
      <div className="-mx-3">
        {members.map((member, index) => (
          <div
            key={member.id}
            className={cn(
              "flex flex-row items-center gap-2 py-2 px-3 hover:bg-secondary-100 cursor-pointer rounded-md [&:hover>button]:visible",
              index > 4 && !isShowMore ? "hidden" : ""
            )}
          >
            <TGNAvatar
              size="32"
              src={member.photo || ""}
              userName={member.name ?? member.email}
            />
            <div className="flex flex-col">
              <div className="flex flex-row gap-2">
                <div className="font-medium text-sm">
                  {member.username ||
                    member.name ||
                    member.email ||
                    "Taga User"}
                </div>
              </div>
              <div className="text-xs text-tg-text-secondary">
                {member.email}
              </div>
            </div>
            <Button
              onClick={() => onRemove && onRemove(member.id)}
              variant="text"
              className=" ml-auto invisible "
            >
              <div className="flex items-center">
                <Close /> <span>Gỡ</span>
              </div>
            </Button>
          </div>
        ))}
      </div>
      {members.length > 5 && !isShowMore && (
        <Button
          variant="ghost"
          size="sm"
          className="text-tg-text-secondary text-sm -ml-2 mt-2"
          onClick={() => setIsShowMore(true)}
        >
          <CaretDown className="mr-2" /> Xem thêm
        </Button>
      )}
    </div>
  );
};
