import React, { FC, memo, useMemo, useRef, useState, useEffect } from "react";

import cx from "classnames";

import Divider from "@/components/Divider/Divider";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import TGNButton from "@/components/TGNButton/TGNButton";
import Typography from "@/components/Typography/Typography";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDate } from "@/utils/date";
import { getUrlImage } from "@/utils/image";

import styles from "./NodeProperties.module.scss";
import { findNodeById } from "../../helpers/mindmap.helpers";
import { updateStyleNode, UpdateDescriptionType } from "../../mindmap";
import DateOverlay from "../DateOverlay/DateOverlay";
import Members from "../Overview/Members/Members";

type NodePropertiesProps = {
  isFullScreen?: boolean;
};

const NodeProperties: FC<NodePropertiesProps> = ({ isFullScreen }) => {
  const {
    currentSheet: { selectedNode, root },
  } = useAppSelector((state) => state.mindmap);
  const [isHashtagMode, setisHashtagMode] = useState(false);
  const hashtagRef = useRef<HTMLInputElement | null>(null);
  const [textHashtag, setTextHashtag] = useState("");
  const dispatch = useAppDispatch();

  const selectedTask = useMemo(() => {
    if (selectedNode) {
      return findNodeById(root, selectedNode?.data.id);
    }
    return null;
  }, [root, selectedNode]);

  useEffect(() => {
    setTextHashtag((selectedNode?.data.description.hashtags || []).join(" "));
  }, [selectedNode]);

  const updateDescription = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    dispatch(
      updateStyleNode({
        type: UpdateDescriptionType.DESCRIPTION,
        content: e.currentTarget.innerHTML,
      })
    );
  };
  const onUpdateName = (name: string) => {
    if (!name.trim()) {
      return;
    }
    dispatch(
      updateStyleNode({
        type: UpdateDescriptionType.NAME,
        name: name.trim(),
      })
    );
  };
  const changeHashtagMode = () => {
    setisHashtagMode(true);
  };
  useEffect(() => {
    if (hashtagRef.current && isHashtagMode) {
      hashtagRef.current.focus();
    }
  }, [isHashtagMode]);
  const onBlurHashtag = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setisHashtagMode(false);
    dispatch(
      updateStyleNode({
        type: UpdateDescriptionType.HASHTAG,
        hastag: e.target.value.trim(),
      })
    );
  };

  return (
    <div className={cx(styles.wrapper, isFullScreen && styles.full)}>
      <div
        className="fw-600 text-gray-900 fs-14"
        contentEditable
        onBlur={(e) => onUpdateName(e.currentTarget.innerHTML)}
        suppressContentEditableWarning
      >
        {selectedNode?.data?.name}
      </div>

      <div className="d-flex align-items-center gap-3 mt-12">
        <div className="text-gray-600 d-flex align-items-center gap-2 p-4">
          <i className="tgn-solid-user-polygon fs-20"></i>
          <span className="text-nowrap">Node ID</span>
        </div>
        <Typography className="fs-14 text-gray-600">
          {selectedNode?.data?.id}
        </Typography>
      </div>

      <div className="d-flex align-items-center gap-3 mt-2 mb-4">
        <div className="text-gray-600 d-flex align-items-center gap-2 p-4">
          <i className="tgn-solid-user-polygon fs-20"></i>
          <span>Chủ sở hữu</span>
        </div>
        <div className="d-flex align-items-center gap-1">
          <TGNAvatar
            name={selectedNode?.data.description.owner.name}
            size="24"
            round
            src={
              selectedNode?.data.description.owner.photo
                ? getUrlImage(selectedNode?.data.description.owner.photo)
                : ""
            }
          />
          <span className="text-primary-color-gray-10 fs-14 fw-semibold">
            {selectedNode?.data.description.owner.name}
          </span>
        </div>
      </div>

      <Divider
        width="100%"
        height={1}
      />
      <div
        className="px-2"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Member */}
        <div className="py-2 mt-8 d-flex align-items-center gap-2">
          <span className="tgn-solid-user fs-20 text-gray-400"></span>
          <span className="fs-14">Thành viên</span>
        </div>
        <div className="d-flex flex-wrap">
          <Members assignees={selectedTask?.description.assignees || []} />
        </div>
        {/* Time */}
        <div className="d-flex align-items-center gap-2 mt-12">
          <i className="tgn-outline-calendar-minus fs-20"></i>
          <span className="fs-14">Thời gian dự kiến</span>
        </div>
        <div
          className="mt-2"
          onClick={(e) => e.stopPropagation()}
        >
          <DateOverlay>
            <Typography className="fs-14 d-flex align-items-center gap-4 text-gray-900">
              <span>
                {selectedNode?.data?.description?.intendTime?.start
                  ? formatDate(
                      selectedNode?.data?.description?.intendTime?.start
                    )
                  : "-"}
              </span>
              <i className="tgn-solid-arrow-right fs-20"></i>
              <span>
                {selectedNode?.data?.description?.intendTime?.end
                  ? formatDate(selectedNode?.data?.description?.intendTime?.end)
                  : "-"}
              </span>
            </Typography>
          </DateOverlay>
        </div>

        {/* Description */}
        <div className="text-secondary-color-gray-7 d-flex align-items-center gap-1 mt-12">
          <i className="tgn-outline-text-wrap fs-20"></i>
          <span className="fs-14">Mô tả</span>
        </div>
        <div
          className={styles.description}
          contentEditable
          aria-placeholder="Add description"
          onBlur={(e) => updateDescription(e)}
          dangerouslySetInnerHTML={{
            __html: selectedNode?.data.description.descriptionTask || "",
          }}
        ></div>

        {/* Hastag */}
        <div className="text-secondary-color-gray-7 d-flex align-items-center gap-1 mt-12">
          <i className="tgn-outline-zoom-text fs-20"></i>
          <span className="fs-14">Hashtag</span>
        </div>
        {!selectedNode?.data.description.hashtags && !isHashtagMode && (
          <div
            className="cursor-pointer"
            onClick={changeHashtagMode}
          >
            --
          </div>
        )}

        {isHashtagMode ? (
          <input
            ref={hashtagRef}
            type="text"
            className={styles.hashtag}
            value={textHashtag}
            onChange={(e) => setTextHashtag(e.target.value)}
            onBlur={(e) => onBlurHashtag(e)}
          />
        ) : selectedNode?.data.description.hashtags.length ? (
          (selectedNode?.data.description.hashtags || []).map((hashtag) => (
            <span
              key={hashtag}
              className="text-brand-primary fs-14 cursor-pointer"
              onClick={changeHashtagMode}
            >
              #{hashtag}&ensp;
            </span>
          ))
        ) : (
          <TGNButton
            variant="outline"
            className="mt-4"
            height={40}
            onClick={() => setisHashtagMode(true)}
          >
            <i className="tgn-outline-plus fs-16"></i>
            <span className="fs-12">Thêm hashtag</span>
          </TGNButton>
        )}
      </div>
    </div>
  );
};

export default memo(NodeProperties);
