import React, { memo, useRef, useState, useMemo, useEffect } from "react";

import cx from "classnames";
import { select } from "d3-selection";
import { OverlayTrigger, Popover } from "react-bootstrap";

import TGNSwitch from "@/components/TGNCheckboxRadio/TGNSwitch";
import Typography from "@/components/Typography/Typography";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import styles from "./SearchForm.module.scss";
import SearchItem from "./SearchItem";
import { flatNode } from "../../helpers/mindmap.helpers";
import {
  SearchListConvertedType,
  SearchTypeEnum,
  SearchValue,
  setSelectedNode,
  setShowSearchBar,
} from "../../mindmap";

const SearchForm = () => {
  const {
    currentSheet: { root },
    zoomBehavior,
    showSearchBar,
  } = useAppSelector((state) => state.mindmap);
  const dispatch = useAppDispatch();
  const [inputSearch, setInputSearch] = useState("");
  const [selectingIndex, setSelectingIndex] = useState(0);
  const [searchList, setSearchList] = useState<Record<string, SearchValue>>({});
  const [searchType, setSearchType] = useState<Record<SearchTypeEnum, boolean>>(
    {
      title: true,
      description: false,
      hashtag: false,
      member: false,
    }
  );
  const selectingIndexRef = useRef(0);
  const flatNodes = useMemo(() => {
    return flatNode(root, []);
  }, [root]);
  useEffect(() => {
    const keyword = inputSearch.trim();
    if (!keyword) {
      if (showSearchBar) {
        dispatch(setShowSearchBar(false));
      }
    }
    if (!showSearchBar && keyword) {
      dispatch(setShowSearchBar(true));
    }
    const result: Record<string, SearchValue> = flatNodes.reduce(
      (prev, current) => {
        const {
          name,
          description: { descriptionTask, hashtags, assignees },
        } = current;
        const isMatchTitle = searchType.title
          ? name.toLowerCase().includes(keyword.toLocaleLowerCase())
            ? name
            : ""
          : "";
        const isMatchDescription = searchType.description
          ? descriptionTask.toLowerCase().includes(keyword.toLowerCase())
            ? descriptionTask
            : ""
          : "";
        const isMatchHashtag = searchType.hashtag
          ? hashtags.filter((item) =>
              item.toLowerCase().includes(keyword.toLowerCase())
            )
          : [];
        const isMatchMember = searchType.member
          ? assignees.filter((item) =>
              item.user.username.toLowerCase().includes(keyword.toLowerCase())
            )
          : [];
        return {
          ...prev,
          [current.id]: {
            title: isMatchTitle,
            description: isMatchDescription,
            hashtag: isMatchHashtag,
            member: isMatchMember,
          },
        };
      },
      {}
    );
    setSearchList(result);
  }, [inputSearch, flatNodes, dispatch, searchType, showSearchBar]);

  const searchListConverted: SearchListConvertedType[] = useMemo(() => {
    const result: SearchListConvertedType[] = [];
    Object.keys(searchList).forEach((item) => {
      Object.keys(searchList[item]).forEach((key) => {
        switch (key) {
          case SearchTypeEnum.TITLE: {
            if (searchList[item].title) {
              result.push({
                type: SearchTypeEnum.TITLE,
                value: searchList[item].title,
                id: item,
              });
            }
            break;
          }
          case SearchTypeEnum.DESCRIPTION: {
            if (searchList[item].description) {
              result.push({
                type: SearchTypeEnum.DESCRIPTION,
                value: searchList[item].description,
                id: item,
              });
            }
            break;
          }
          case SearchTypeEnum.HASHTAG: {
            if (searchList[item].hashtag.length) {
              searchList[item].hashtag.forEach((hastag) => {
                result.push({
                  type: SearchTypeEnum.HASHTAG,
                  value: hastag,
                  id: item,
                });
              });
            }
            break;
          }
          case SearchTypeEnum.MEMBER: {
            if (searchList[item].member.length) {
              searchList[item].member.forEach((member) => {
                result.push({
                  type: SearchTypeEnum.MEMBER,
                  value: member,
                  id: item,
                });
              });
            }
            break;
          }
          default:
            break;
        }
      });
    });
    return result;
  }, [searchList]);

  useEffect(() => {
    selectingIndexRef.current = selectingIndex;
  }, [selectingIndex]);

  useEffect(() => {
    if (!searchListConverted.length) {
      setSelectingIndex(0);
      return;
    }
    const listener = (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowDown": {
          handleClickKeyDown();
          break;
        }
        case "ArrowUp": {
          handleClickKeyUp();
          break;
        }
        case "Enter": {
          handleChoose();
          break;
        }
        default:
          break;
      }
    };
    if (showSearchBar) {
      window.addEventListener("keydown", listener);
    }
    return () => {
      window.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchListConverted, showSearchBar]);

  const handleChoose = () => {
    const selected = searchListConverted[selectingIndexRef.current].id;
    if (!selected) {
      return;
    }
    const svg = select("#mindmap");
    const node = select(`#g${selected}`);
    const [x, y] = node
      .attr("transform")
      .slice(10, -1)
      .split(",")
      .map((item) => Number(item.trim()));
    if (zoomBehavior) {
      // eslint-disable-next-line
      zoomBehavior.translateTo(svg as any, (x || 0) + 280, y || 0);
    }
    // eslint-disable-next-line
    node.attr("style", (node: any) => {
      dispatch(setSelectedNode(node));
      return "";
    });
  };
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(e.target.value);
  };
  const handleClickKeyDown = () => {
    const index =
      selectingIndexRef.current === searchListConverted.length - 1
        ? 0
        : selectingIndexRef.current + 1;
    selectingIndexRef.current = index;
    setSelectingIndex(index);
  };
  const handleClickKeyUp = () => {
    const index =
      selectingIndexRef.current === 0
        ? searchListConverted.length - 1
        : selectingIndexRef.current - 1;
    selectingIndexRef.current = index;
    setSelectingIndex(index);
  };
  const handleButtonDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    handleClickKeyDown();
    handleChoose();
  };
  const handleButtonUp = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    handleClickKeyUp();
    handleChoose();
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={cx(
            "d-flex align-items-center gap-2 py-8",
            styles["input-search"]
          )}
        >
          <i
            className={cx(
              "tgn-outline-search-01 cursor-pointer",
              styles["text-gray-05"]
            )}
          ></i>
          <input
            className={styles["search-input"]}
            value={inputSearch}
            type="text"
            placeholder="Tìm kiếm ID, tên node"
            onChange={(e) => handleChangeSearch(e)}
          />
          {inputSearch && (
            <i
              className={cx("tgn-solid-remove-circle", styles["btn-remove"])}
              onClick={() => setInputSearch("")}
            ></i>
          )}
          <OverlayTrigger
            trigger="click"
            placement="bottom-start"
            rootClose
            overlay={
              <Popover className={styles["body-popup"]}>
                <Popover.Body>
                  <div>
                    <Typography className="text-gray-600 mb-8 fs-12 fw-semibold">
                      Search in
                    </Typography>
                    <div className="d-flex align-items-center mb-8">
                      <span className="flex-1 text-gray-900 fs-14">
                        Node Title
                      </span>
                      <TGNSwitch
                        checked={searchType.title}
                        onChange={() =>
                          setSearchType((prev) => ({
                            ...prev,
                            title: !prev.title,
                          }))
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center mb-8">
                      <span className="flex-1 text-gray-900 fs-14">
                        Description
                      </span>
                      <TGNSwitch
                        checked={searchType.description}
                        onChange={() =>
                          setSearchType((prev) => ({
                            ...prev,
                            description: !prev.description,
                          }))
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center mb-8">
                      <span className="flex-1 text-gray-900 fs-14">Member</span>
                      <TGNSwitch
                        checked={searchType.member}
                        onChange={() =>
                          setSearchType((prev) => ({
                            ...prev,
                            member: !prev.member,
                          }))
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="flex-1 text-gray-900 fs-14">
                        Hashtag
                      </span>
                      <TGNSwitch
                        checked={searchType.hashtag}
                        onChange={() =>
                          setSearchType((prev) => ({
                            ...prev,
                            hashtag: !prev.hashtag,
                          }))
                        }
                      />
                    </div>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <div className={styles.setting}>
              <i className="tgn-outline-more-horizontal"></i>
            </div>
          </OverlayTrigger>
          {/* <div className={styles.setting}>
            <i className="tgn-outline-more-horizontal"></i>
          </div> */}
        </div>
        <div className="d-flex align-items-center gap-2">
          <i
            className={cx("tgn-outline-filter-01-1", styles["text-gray-05"])}
          ></i>
          <span className={cx("fs-14", styles["text-gray-05"])}>Filter</span>
        </div>
      </div>
      <div className={cx(styles.overlay, !inputSearch && styles["hidden-bar"])}>
        <div
          className={cx(
            "d-flex align-items-center justify-content-between px-12",
            styles.result
          )}
        >
          <span className="fs-12">{searchListConverted.length} kết quả</span>
          <div className="d-flex align-items-center gap-2">
            <i
              className="tgn-solid-direction-up-01 fs-20 cursor-pointer"
              onClick={handleButtonUp}
            ></i>
            <i
              className="tgn-solid-direction-down-01 fs-20 cursor-pointer"
              onClick={handleButtonDown}
            ></i>
          </div>
        </div>
        {searchListConverted.map((item, index) => (
          <SearchItem
            key={item.type + index}
            item={item}
            onClick={() => {
              selectingIndexRef.current = index;
              setSelectingIndex(index);
              handleChoose();
            }}
            active={selectingIndex === index}
          />
        ))}
      </div>
    </>
  );
};

export default memo(SearchForm);
