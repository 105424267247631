import React, { FC, memo, useState } from "react";

import { LoadingIcon } from "@/assets/icons";
import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent } from "@/components/ui/dialog";
import { cn } from "@/lib/utils";

type LogoutDialogProps = {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onOk: () => void;
};

const LogoutDialog: FC<LogoutDialogProps> = ({
  open,
  loading,
  onClose,
  onOk,
}) => {
  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
    >
      <DialogContent
        className={cn(
          "pt-4 pb-10 border border-solid border-secondary-200",
          loading
            ? "min-w-full h-full rounded-none sm:rounded-none"
            : "w-[364px] rounded-xl"
        )}
      >
        {loading ? (
          <div className="flex items-center justify-center h-full flex-col">
            <LoadingIcon className="animate-spin duration-[3000ms] mx-auto" />
            <p className="text-lg text-secondary-900 font-semibold mt-4">
              Đăng xuất...
            </p>
          </div>
        ) : (
          <>
            <p className="text-lg text-secondary-900 font-semibold">
              Đăng xuất khỏi Taga?
            </p>
            <p className="text-xs text-secondary-600 -mt-3 mb-3">
              Bạn có thể đăng nhập lại bất cứ lúc nào.
            </p>
            <div>
              <Button
                className="w-full"
                variant="secondary"
                onClick={onOk}
              >
                Đăng xuất
              </Button>
            </div>
            <div>
              <Button
                variant="ghost"
                className="w-full"
                onClick={onClose}
              >
                Huỷ bỏ
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(LogoutDialog);
