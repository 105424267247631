import React, { FC, memo, useState } from "react";
import "react-multi-carousel/lib/styles.css";

import { Close, View, ViewOff } from "@carbon/icons-react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";

import { LoadingIcon } from "@/assets/icons";
import LockOrg from "@/assets/images/lockorg.png";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { settingGroup } from "@/features/groups/api/group.api";
import { getGroupInfo } from "@/features/groups/group";
import { useAppDispatch } from "@/redux/store";
import { COMMON_MESSAGES, getMessage } from "@/utils/message";

const ChangePassword: FC = () => {
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [viewPass, setViewPass] = useState(false);

  return (
    <>
      <Dialog
        open={openModal}
        onOpenChange={setOpenModal}
      >
        <DialogTrigger asChild>
          <Button
            variant="outline"
            onClick={() => setOpenModal(true)}
          >
            Chỉnh sửa
          </Button>
        </DialogTrigger>
        <DialogContent className={"sm:max-w-[600px]  bg-white "}>
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-0 right-0 rounded-full w-[36px]"
              onClick={() => {
                setOpenModal(false);
                setOpenConfirmModal(false);
              }}
            >
              <Close />
            </Button>
            <DialogTitle className="text-center max-w-[460PX] text-lg mx-auto">
              Thay dổi mặt khẩu
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 pb-[12px] h-full">
            <div className="relative">
              <Label className="text-sm text-secondary-900 mt-2">
                {" "}
                Mật khẩu cũ
              </Label>
              <Input
                type={viewPass ? "text" : "password"}
                name="password"
                placeholder={`********`}
              />
              <div
                onClick={() => setViewPass(!viewPass)}
                className="absolute right-2 bottom-3 text-secondary-600"
              >
                {viewPass ? <View /> : <ViewOff />}
              </div>
            </div>
            <div className="relative">
              <Label className="text-sm text-secondary-900 mt-2">
                {" "}
                Mật khẩu mới
              </Label>
              <Input
                type={viewPass ? "text" : "password"}
                name="password"
                placeholder={`********`}
              />
              <div
                onClick={() => setViewPass(!viewPass)}
                className="absolute right-2 bottom-3 text-secondary-600"
              >
                {viewPass ? <View /> : <ViewOff />}
              </div>
            </div>
            <div className="relative">
              <Label className="text-sm text-secondary-900 mt-2">
                {" "}
                Xác nhận mật khẩu mới
              </Label>
              <Input
                type={viewPass ? "text" : "password"}
                name="password"
                placeholder={`********`}
              />
              <div
                onClick={() => setViewPass(!viewPass)}
                className="absolute right-2 bottom-3 text-secondary-600"
              >
                {viewPass ? <View /> : <ViewOff />}
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setOpenModal(false)}
            >
              Hủy
            </Button>
            <Button onClick={() => setOpenModal(false)}>Tiếp tục</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(ChangePassword);
