import { RouteItemDef } from "@/types/app.types";

import { FriendsPathsEnum } from "../constants/friends";
import DefaultLayout from "../layouts/DefaultLayout";
// import BlankScreen from "../screens/BlankScreen/BlankScreen";
import FriendListScreen from "../screens/FriendList";

export const FRIENDS_ROUTES: RouteItemDef[] = [
  {
    id: FriendsPathsEnum.FRIENDS,
    path: FriendsPathsEnum.FRIENDS,
    component: DefaultLayout,
    children: [
      {
        id: FriendsPathsEnum.FRIENDS,
        path: "",
        component: FriendListScreen,
      },
      // {
      //   id: FriendsPathsEnum.BLANK,
      //   path: FriendsPathsEnum.BLANK,
      //   component: BlankScreen,
      // },
    ],
  },
];
