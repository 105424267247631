import axios from "axios";

import api from "@/api/api_instance";
import { UserDef } from "@/features/auth/types/auth.types";
import {
  AppResponse,
  PaginationParams,
  PaginationResponse,
} from "@/types/app.types";

import { GroupSortEnum } from "../constants/group.constant";
import { GroupEndpointsEnum } from "../constants/group.endpoints";
import {
  DeleteDeviceAuthenticationRequestParams,
  GetGroupDeviceByManagerResponse,
  GetGroupStructureListParams,
} from "../types/groupDevice.type";
// eslint-disable-next-line import/order
import {
  UpdateGroupParams,
  CreateGroupParams,
  GetGroupFriendParams,
  GetGroupInviteParams,
  GetGroupInviteResponse,
  GetGroupParams,
  GetRelationshipFriendParams,
  GetUsersOfGroupParams,
  GroupInfoDef,
  GroupInvitationRequest,
  GroupsListResponse,
  GroupParams,
  JoinRequestedDef,
  LeaveRequestDef,
  UpdateMembershipResponse,
  UpdateStatusDeviceRequestParams,
  UserRequest,
  RemoveDeviceRequestParams,
  IGroupInfoResponse,
  GroupsListRestore,
  GroupInfoDeleteResponse,
  SettingGroupParams,
  ResponseGroupInvite,
  FriendListResponse,
  GroupInvitationPersonalRequest,
  GroupPersonalRequest,
  GroupRequestResponse,
  IMemberGroup,
  DetailMemberResponse,
  GetGroupDeviceByStatusParams,
  RemoveDeviceByManagerParams,
  GetMyGroupDeviceResponse,
  RemoveDeviceByMeParams,
  VerifyRemoveDeviceByMeParams,
  UpdateGroupDeviceStatusParams,
  GroupStructureDetailDef,
  GroupDeletedDef,
  SubGroupDef,
} from "./../types/group.type";

export const searchGroup = ({
  keyword = "",
  pageNumber = 1,
  pageSize = 10,
  signal,
}: GetGroupParams): Promise<GroupsListResponse> => {
  return api.get(GroupEndpointsEnum.SEARCH_GROUP, {
    params: {
      pageNumber,
      pageSize,
      keyword,
    },
    signal,
  });
};

export const getMyGroups = (
  params: GetGroupParams
): Promise<GroupsListResponse> => {
  return api.get(GroupEndpointsEnum.MY_GROUP, {
    params,
  });
};

export const getDiscoverGroups = ({
  pageNumber = 1,
  pageSize = 10,
}): Promise<GroupsListResponse> => {
  return api.get(GroupEndpointsEnum.DISCOVER_GROUP, {
    params: {
      pageNumber,
      pageSize,
    },
  });
};

export const createGroup = (
  groupInfo: CreateGroupParams
): Promise<IGroupInfoResponse> => {
  return api.post(GroupEndpointsEnum.GROUP, groupInfo);
};

export const generatePresignedUrl = (
  file: string,
  size: number,
  groupId: number
) => {
  return api.post(GroupEndpointsEnum.GROUP_PHOTO, {
    size,
    fileName: file,
    targetId: groupId,
    type: "GROUP",
  });
};

export const deleteGroupPhoto = (groupId: number) => {
  return api.delete(
    GroupEndpointsEnum.GROUP_DELETE_PHOTO.replace(":id", String(groupId))
  );
};

export const uploadGroupPhoto = (
  file: File,
  url: string,
  typeFile: string,
  fileLength: number
) => {
  return axios.put(url, file, {
    headers: {
      "Content-Type": typeFile,
      "Content-Length": fileLength,
    },
  });
};

export const updateGroup = (
  params: UpdateGroupParams
): Promise<IGroupInfoResponse> => {
  return api.put(GroupEndpointsEnum.GROUP, params);
};

export const settingGroup = (params: SettingGroupParams) => {
  return api.put(GroupEndpointsEnum.SETTING_GROUP, params);
};

export const getGroupById = (id: number): Promise<IGroupInfoResponse> => {
  return api.get(GroupEndpointsEnum.DETAIL.replace(":id", `${id}`));
};

export const getConfirmInfoDelete = (
  id: number
): Promise<GroupInfoDeleteResponse> => {
  return api.get(GroupEndpointsEnum.CONFIRM_DELETE.replace(":id", `${id}`));
};

export const deleteGroup = (id: number) => {
  return api.delete(GroupEndpointsEnum.DELETE, { params: { groupId: id } });
};

export const deletedGroups = (id: number): Promise<GroupsListResponse> => {
  return api.get(GroupEndpointsEnum.DELETED_GROUPS.replace(":id", `${id}`));
};

export const destroyGroup = (id: number) => {
  return api.delete(GroupEndpointsEnum.DESTROY.replace(":id", `${id}`));
};

export const restoreGroup = (id: number) => {
  return api.put(GroupEndpointsEnum.RESTORE, { groupId: id });
};

export const switchDeviceRequestMode = (id: number) => {
  return api.put(
    GroupEndpointsEnum.DEVICE_REQUEST_MODE.replace(":id", `${id}`)
  );
};

export const switchLockMode = (id: number) => {
  return api.put(GroupEndpointsEnum.LOCK_MODE.replace(":id", `${id}`));
};

export const switchUserRequestRequiredMode = (id: number) => {
  return api.put(
    GroupEndpointsEnum.USER_REQUEST_REQUIRED_MODE.replace(":id", `${id}`)
  );
};

export const getGroupDeviceByStatus = (
  params: GetGroupDeviceByStatusParams
): Promise<PaginationResponse<GetGroupDeviceByManagerResponse[]>> => {
  const { groupId, pageNumber = 1, pageSize = 50, status } = params;
  return api.get(GroupEndpointsEnum.GROUP_DEVICE, {
    params: {
      pageNumber,
      pageSize,
      groupId,
      status,
    },
  });
};

export const getDeviceGroupPersonalRequests = (groupId: number) => {
  return api.get(GroupEndpointsEnum.DEVICE_PERSONAL_REQUEST, {
    params: {
      pageNum: 0,
      pageSize: 50,
      groupId,
    },
  });
};

export const getListFriendForGroupInvitation = (
  params: GetGroupFriendParams
): Promise<FriendListResponse> => {
  return api.get(GroupEndpointsEnum.INVITE_FRIEND, {
    params,
  });
};

export const createInvitationToFriend = (
  groupId: number,
  ids: number[]
): Promise<FriendListResponse> => {
  return api.post(GroupEndpointsEnum.INVITE_FRIEND, {
    groupId,
    ids,
  });
};

export const getListMemberForGroupInvitation = (
  params: GetGroupFriendParams
): Promise<FriendListResponse> => {
  return api.get(GroupEndpointsEnum.INVITE_MEMBER, {
    params,
  });
};

export const createInvitationToMember = (
  groupId: number,
  ids: number[]
): Promise<FriendListResponse> => {
  return api.post(GroupEndpointsEnum.INVITE_MEMBER, {
    groupId,
    ids,
  });
};

export const getGroupInvitationForUser = (params: {
  pageNumber?: number;
  pageSize?: number;
  rootGroupId?: number;
  sort?: GroupSortEnum;
}): Promise<GroupInvitationPersonalRequest> => {
  return api.get(GroupEndpointsEnum.PERSONAL_INVITE, { params });
};
export const getGroupInvitationRootForUser = (params: {
  pageNum?: number;
  pageSize?: number;
}): Promise<GroupInvitationPersonalRequest> => {
  return api.get(GroupEndpointsEnum.PERSONAL_INVITE_ROOT, { params });
};
export const getGroupRootGroupDeleted = (params: {
  pageNumber: number;
  pageSize: number;
  sort?: string;
}): Promise<GroupsListRestore> => {
  return api.get(GroupEndpointsEnum.DELETED_ROOT_GROUPS, { params });
};

export const getListGroupInvited = (params: {
  groupId: number;
  pageNumber?: number;
  pageSize?: number;
}): Promise<GroupInvitationRequest> => {
  return api.get(GroupEndpointsEnum.GROUP_INVITE, { params });
};

export const cancelGroupInvited = (
  id: number
): Promise<GroupInvitationRequest> => {
  return api.put(GroupEndpointsEnum.GROUP_INVITE_CANCEL, { id });
};

export const respondGroupInvitation = (status: boolean, groupId: number) => {
  const url = GroupEndpointsEnum.PERSONAL_INVITE_RESPOND.replace(
    ":status",
    String(status)
  ).replace(":groupId", String(groupId));
  return api.put(url);
};

export const respondGroupEmailInvitation = (
  status: boolean,
  groupId: number
) => {
  const url = GroupEndpointsEnum.PERSONAL_EMAIL_INVITE_RESPOND.replace(
    ":status",
    String(status)
  ).replace(":groupId", String(groupId));
  return api.put(url);
};

export const getListPersonalInvited = ({
  pageNum = 1,
  pageSize = 10,
}): Promise<GroupInfoDef[]> => {
  return api.get(GroupEndpointsEnum.GROUP_INVITE, {
    params: {
      pageNum,
      pageSize,
    },
  });
};

export const acceptGroupInvitation = (accept: boolean, id: number) => {
  return api.put(GroupEndpointsEnum.PERSONAL_INVITE, {
    accept: accept,
    id: id,
  });
};

export const rejectGroupInvitation = (accept: boolean, id: number) => {
  return api.put(GroupEndpointsEnum.PERSONAL_INVITE, {
    accept: accept,
    id: id,
  });
};

export const cancelGroupInvitation = (requestId: number) => {
  return api.delete(
    `${GroupEndpointsEnum.PERSONAL_INVITE}?requestId=${requestId}`
  );
};

export const getUsersOfGroup = (
  params: GetUsersOfGroupParams,
  signal?: AbortSignal
): Promise<PaginationResponse<UserDef[]>> => {
  const { pageNum = 1, pageSize = 9999, groupId, role, keyword } = params;
  return api.get(GroupEndpointsEnum.USER, {
    params: {
      pageNum,
      pageSize,
      groupId,
      role,
      keyword,
    },
    signal,
  });
};

export const getGroupMembers = (params: {
  groupId: number;
  pageSize?: number;
  pageNumber?: number;
}): Promise<PaginationResponse<IMemberGroup[]>> => {
  return api.get(GroupEndpointsEnum.MEMBERS_GROUP, {
    params,
  });
};

export const getDetailGroupMember = (params: {
  groupId: number;
  userId: number;
}): Promise<DetailMemberResponse> => {
  return api.get(GroupEndpointsEnum.MEMBER_GROUP, {
    params,
  });
};

export const updateGroupMember = (
  groupId: number,
  userId: number,
  alias?: string
): Promise<DetailMemberResponse> => {
  return api.put(
    `${GroupEndpointsEnum.MEMBER_GROUP}?alias=${alias}&groupId=${groupId}&userId=${userId}`,
    {
      alias,
      groupId,
      userId,
    }
  );
};

export const deleteGroupMember = (
  groupId: number,
  userId: number
): Promise<DetailMemberResponse> => {
  return api.delete(
    `${GroupEndpointsEnum.MEMBER_GROUP}?groupId=${groupId}&userId=${userId}`
  );
};

export const assignRole = (groupId: number, userId: number, code: string) => {
  return api.put(GroupEndpointsEnum.MEMBER_ROLE, {
    groupId,
    userId,
    code,
  });
};

export const getMemberTransferOwner = (params: {
  groupId: number;
  pageNumber?: number;
  pageSize?: number;
}): Promise<PaginationResponse<IMemberGroup[]>> => {
  return api.get(GroupEndpointsEnum.TRANSFER_MEMBER, {
    params,
  });
};

export const memberTransferOwnerRequest = (params: {
  groupId: number;
  userId: number;
}): Promise<{ data: { uid?: string } }> => {
  return api.post(GroupEndpointsEnum.TRANSFER_REQUEST, params);
};

export const memberTransferOwnerVerify = (params: {
  groupId: number;
  userId: number;
  uid: string;
  otp: string;
}) => {
  const { otp, uid, groupId, userId } = params;
  return api.post(
    GroupEndpointsEnum.TRANSFER_ACCEPT,
    { groupId, userId },
    {
      headers: {
        "tg-code": otp,
        "tg-uuid": uid,
      },
    }
  );
};

export const createLeaveRequest = (groupId: number, rootGroupId: number) => {
  return api.post(GroupEndpointsEnum.LEAVE_REQUEST, {
    groupId,
    rootGroupId,
  });
};

export const userCancelRequestToLeaveGroup = (groupId: number) => {
  return api.delete(GroupEndpointsEnum.LEAVE_REQUEST, {
    params: {
      groupId,
    },
  });
};

export const userRequestToGroup = (groupId: number) => {
  return api.post(GroupEndpointsEnum.USER_REQUEST_TO_GROUP, {
    groupId: groupId,
  });
};

export const userCancelRequestToGroup = (groupId: number) => {
  return api.put(GroupEndpointsEnum.USER_CANCEL_REQUEST_TO_GROUP, {
    id: groupId,
  });
};

export const deleteUserFromGroup = (groupId: number, userIds: number[]) => {
  return api.put(GroupEndpointsEnum.USER, { groupId, userIds });
};

export const getUserRequests = (params: {
  pageNumber?: number;
  pageSize?: number;
  rootGroupId?: number;
  sort?: GroupSortEnum;
}): Promise<GroupPersonalRequest> => {
  return api.get(GroupEndpointsEnum.USER_REQUEST, {
    params,
  });
};
export const getGroupRequestRootForUser = (params: {
  pageNum?: number;
  pageSize?: number;
}): Promise<GroupPersonalRequest> => {
  return api.get(GroupEndpointsEnum.USER_REQUEST_ROOT, { params });
};
export const getRequestsOfGroup = (params: {
  pageNumber?: number;
  pageSize?: number;
  groupId: number;
}): Promise<GroupRequestResponse> => {
  const { groupId, pageNumber = 1, pageSize = 10 } = params;
  return api.get(GroupEndpointsEnum.GROUP_REQUEST, {
    params: {
      pageNumber,
      pageSize,
      groupId,
    },
  });
};

export const getInviteRequests = (
  params: GroupParams
): Promise<JoinRequestedDef[]> => {
  const { groupId, pageNum = 1, pageSize = 10 } = params;
  return api.get(GroupEndpointsEnum.GROUP_REQUEST, {
    params: {
      pageNum,
      pageSize,
      groupId,
    },
  });
};

export const updateStatusJoinRequest = (params: {
  id: number;
  groupId: number;
  accept: boolean;
}) => {
  return api.put(GroupEndpointsEnum.UPDATE_REQUEST_TO_GROUP, params);
};

export const getMyGroupRequests = (
  params: PaginationParams
): Promise<UserRequest[]> => {
  return api.get(GroupEndpointsEnum.USER_REQUEST_PERSONAL, {
    params,
  });
};

export const getGroupTreemap = (
  id: string,
  pageNum = 1,
  pageSize = 9999
): Promise<{
  content: GroupInfoDef[];
}> => {
  return api.get(GroupEndpointsEnum.TREEMAP.replace(":id", id), {
    params: {
      pageNum,
      pageSize,
    },
  });
};

export const createInvitation = (
  groupId: number,
  emails: string[]
): Promise<ResponseGroupInvite> => {
  return api.post(GroupEndpointsEnum.INVITE, {
    groupId,
    emails,
  });
};

export const getRelationshipFriend = ({
  pageNum = 1,
  pageSize = 50,
  name = "",
  groupId,
  signal,
}: GetRelationshipFriendParams): Promise<PaginationResponse<UserDef[]>> => {
  return api.get(GroupEndpointsEnum.RELATIONSHIP_FRIEND, {
    params: {
      pageNum,
      pageSize,
      keyword: name || undefined,
      groupId,
    },
    signal,
  });
};

export const updateStatusDeviceRequest = (
  params: UpdateStatusDeviceRequestParams
) => {
  return api.put(GroupEndpointsEnum.DEVICE, params);
};

export const removeDevices = (params: RemoveDeviceRequestParams) => {
  return api.put(GroupEndpointsEnum.REMOVE_DEVICE, params);
};

export const getLeaveRequests = (params: {
  groupId: number;
  pageNumber?: number;
  pageSize?: number;
}): Promise<PaginationResponse<LeaveRequestDef[]>> => {
  return api.get(GroupEndpointsEnum.LEAVE_REQUEST, {
    params,
  });
};

export const updateStatusLeaveRequest = (params: {
  userId: number;
  groupId: number;
  accept: boolean;
}): Promise<{ id: string; status: string }> => {
  return api.put(GroupEndpointsEnum.LEAVE_REQUEST, params);
};

export const cancelStatusLeaveRequest = (
  groupId: number,
  rootGroupId: number
) => {
  return api.delete(GroupEndpointsEnum.LEAVE_REQUEST, {
    data: {
      groupId,
      rootGroupId,
    },
  });
};

export const updateMember = async (
  params: UpdateMembershipResponse
): Promise<UpdateMembershipResponse> => {
  return api.put(GroupEndpointsEnum.MEMBERS_GROUP, params);
};

export const getGroupInvite = (
  params: GetGroupInviteParams
): Promise<GetGroupInviteResponse> => {
  return api.get(
    GroupEndpointsEnum.INVITE.replace(/:id/, String(params.groupId)),
    {
      params,
    }
  );
};

export const sendRequestDeviceVerify = (params: { groupId: number }) => {
  return api.post(GroupEndpointsEnum.DEVICE, params);
};

export const verifyDevice = (params: {
  groupId: number;
  otp: string;
  uuid: string;
}) => {
  const { groupId, otp, uuid } = params;
  return api.post(
    GroupEndpointsEnum.DEVICE_VERIFY,
    {
      groupId,
    },
    {
      headers: {
        "tg-code": otp,
        "tg-uuid": uuid,
      },
    }
  );
};

export const cancelDeviceRequest = (groupId: number) => {
  return api.delete(
    GroupEndpointsEnum.DEVICE_CANCEL_REQUEST.replace(":id", String(groupId))
  );
};

export const removeGroupDeviceByManager = (
  params: RemoveDeviceByManagerParams
) => {
  return api.post(GroupEndpointsEnum.GROUP_DEVICE_REMOVE, params);
};

export const getMyGroupDevice = (
  groupId: number
): Promise<AppResponse<GetMyGroupDeviceResponse>> => {
  return api.get(GroupEndpointsEnum.MY_GROUP_DEVICE, {
    params: {
      groupId,
    },
  });
};

export const removeMyDevice = (
  params: RemoveDeviceByMeParams
): Promise<
  AppResponse<{
    uid: string;
  }>
> => {
  return api.post(GroupEndpointsEnum.REMOVE_MY_DEVICE, params);
};

export const verifyRemoveMyDevice = (
  params: VerifyRemoveDeviceByMeParams
): Promise<AppResponse<boolean>> => {
  const { deviceUniqueId, groupId, code, uid, userId } = params;
  return api.post(
    GroupEndpointsEnum.VERIFY_REMOVE_MY_DEVICE,
    {
      deviceUniqueId,
      groupId,
    },
    {
      headers: {
        "tg-code": code,
        "tg-uuid": uid,
        "tg-userid": userId,
      },
    }
  );
};

export const updateGroupDeviceStatus = (
  params: UpdateGroupDeviceStatusParams
): Promise<AppResponse<boolean>> => {
  return api.put(GroupEndpointsEnum.UPDATE_GROUP_DEVICE_STATUS, params);
};

export const cancelDeviceAuthenticationRequest = (
  params: DeleteDeviceAuthenticationRequestParams
): Promise<AppResponse<boolean>> => {
  return api.delete(GroupEndpointsEnum.CANCEL_DEVICE_AUTHENTICATION_REQUEST, {
    data: params,
  });
};

export const getGroupStructureDetail = (
  groupId: number
): Promise<AppResponse<GroupStructureDetailDef>> => {
  return api.get(GroupEndpointsEnum.GROUP_STRUCTURES_DETAIL, {
    params: {
      groupId,
    },
  });
};

export const getGroupStructureList = (
  params: GetGroupStructureListParams
): Promise<PaginationResponse<GroupStructureDetailDef[]>> => {
  return api.get(GroupEndpointsEnum.GROUP_STRUCTURES, {
    params: params,
  });
};

export const getGroupDeletedList = (
  params: {
    groupId: number;
  } & PaginationParams
): Promise<PaginationResponse<GroupDeletedDef[]>> => {
  return api.get(GroupEndpointsEnum.DELETE_SUB_GROUP, {
    params: params,
  });
};

export const recoverGroup = (params: {
  recoveryGroupId: number;
  requestGroupId: number;
}): Promise<AppResponse<boolean>> => {
  return api.put(GroupEndpointsEnum.RECOVER_GROUP, params);
};

export const deleteSubGroup = (
  groupId: number
): Promise<AppResponse<boolean>> => {
  return api.delete(GroupEndpointsEnum.DELETE_SUB_GROUP, {
    params: {
      groupId,
    },
  });
};

export const getSubGroup = (
  groupId: number
): Promise<PaginationResponse<SubGroupDef[]>> => {
  return api.get(GroupEndpointsEnum.SUB_GROUP, {
    params: {
      id: groupId,
      pageSize: 999,
    },
  });
};

export const toggleNotification = (
  groupId: number,
  isNotified: boolean
): Promise<AppResponse<boolean>> => {
  return api.put(GroupEndpointsEnum.TOGGLE_NOTIFY, { groupId, isNotified });
};
