export enum PhotoTypeEnum {
  USER = "USER",
  GROUP = "GROUP",
}

export enum UserMFAEnums {
  OFF = "OFF",
  EMAIL = "EMAIL",
  PHONE_SMS = "PHONE_SMS",
  COMBINE_EMAIL_AND_PHONE_SMS = "COMBINE_EMAIL_AND_PHONE_SMS",
}
