import { UserRoleEnums } from "@/features/auth/types/auth.types";
import GroupsDetailLayout from "@/features/groups/layouts/GroupDetail";
import GroupsLayout from "@/features/groups/layouts/GroupLayout";
import { RouteItemDef } from "@/types/app.types";

import { GroupPathsEnum } from "../constants/group.paths";
import BlankScreen from "../screens/Blank/BlankScreen";
import Department from "../screens/DepartmentAndUser/Department";
import DepartmentAndUser from "../screens/DepartmentAndUser/DepartmentAndUser";
import Member from "../screens/DepartmentAndUser/Member";
import DiscoverScreen from "../screens/DiscoverScreen/DiscoverScreen";
import GroupDeletedScreen from "../screens/GroupDeletedSreen/GroupDeletedScreen";
import GroupDetailScreen from "../screens/GroupDetailScreen/GroupDetailScreen";
import GroupDeviceScreen from "../screens/GroupDeviceScreen";
import GroupInvitesScreen from "../screens/GroupInvitesScreen/GroupInvitesScreen";
import GroupLeaveRequestsScreen from "../screens/GroupLeaveRequestsScreen/GroupLeaveRequestsScreen";
import GroupLibraryScreen from "../screens/GroupLibrary/GroupLibraryScreen";
import GroupMyRequestsScreen from "../screens/GroupMyRequestsScreen/GroupMyRequestsScreen";
import GroupRequestsScreen from "../screens/GroupRequestScreen/GroupRequestsScreen";
import GroupsScreen from "../screens/GroupsScreen/GroupsScreen";
import InviteEmailScreen from "../screens/InviteEmailScreen/InviteEmailScreen";
import InviteRequestedScreen from "../screens/InviteRequestedScreen/InviteRequestedScreen";
import NotAvailableScreen from "../screens/NotAvailableScreen/NotAvailableScreen";
import RemoveGroupSuccessScreen from "../screens/RemoveGroupSuccessScreen/RemoveGroupSuccessScreen";
import RootGroupDeletedScreen from "../screens/RootGroupDeletedScreen/RootGroupDeletedScreen";
import SearchScreen from "../screens/SearchScreen/SearchScreen";
import SettingScreen from "../screens/SettingScreen/SettingScreen";
import SettingUserScreen from "../screens/SettingUserScreen/SettingScreen";

export const GROUP_ROUTES: RouteItemDef[] = [
  {
    id: GroupPathsEnum.GROUP,
    path: GroupPathsEnum.GROUP,
    component: GroupsLayout,
    children: [
      {
        id: GroupPathsEnum.GROUPS_SEARCH,
        path: GroupPathsEnum.GROUPS_SEARCH,
        component: SearchScreen,
      },
      {
        id: GroupPathsEnum.GROUP_DISCOVER,
        path: GroupPathsEnum.GROUP_DISCOVER,
        component: DiscoverScreen,
      },
      {
        id: GroupPathsEnum.GROUP_REQUEST,
        path: GroupPathsEnum.GROUP_REQUEST,
        component: GroupMyRequestsScreen,
      },
      {
        id: GroupPathsEnum.GROUP_INVITE,
        path: GroupPathsEnum.GROUP_INVITE,
        component: GroupInvitesScreen,
      },
      {
        id: GroupPathsEnum.GROUP,
        path: GroupPathsEnum.GROUP,
        component: GroupsScreen,
      },
      {
        id: GroupPathsEnum.USER_GROUP_DELETED,
        path: GroupPathsEnum.USER_GROUP_DELETED,
        component: RootGroupDeletedScreen,
      },
    ],
  },
  {
    id: GroupPathsEnum.GROUP_DETAIL,
    path: GroupPathsEnum.GROUP_DETAIL,
    component: GroupsDetailLayout,
    children: [
      {
        id: GroupPathsEnum.GROUP_DEPARTMENT_USER,
        path: GroupPathsEnum.GROUP_DEPARTMENT_USER,
        component: DepartmentAndUser,
        children: [
          {
            id: GroupPathsEnum.GROUP_DEPARTMENT_USER,
            path: GroupPathsEnum.GROUP_DEPARTMENT_USER,
            component: Member,
          },
          {
            id: GroupPathsEnum.GROUP_DEPARTMENT,
            path: GroupPathsEnum.GROUP_DEPARTMENT,
            component: Department,
          },
        ],
      },
      {
        id: GroupPathsEnum.GROUPS_DELETED,
        path: GroupPathsEnum.GROUPS_DELETED,
        component: GroupDeletedScreen,
        permissions: [UserRoleEnums.OWNER],
      },
      // {
      //   id: GroupPathsEnum.GROUP_EDIT,
      //   path: GroupPathsEnum.GROUP_EDIT,
      //   component: EditScreen,
      //   permissions: [UserRoleEnums.OWNER],
      // },
      {
        id: GroupPathsEnum.GROUP_SETTING,
        path: GroupPathsEnum.GROUP_SETTING,
        component: SettingScreen,
      },
      {
        id: GroupPathsEnum.GROUP_LIBRARY,
        path: GroupPathsEnum.GROUP_LIBRARY,
        component: GroupLibraryScreen,
        // permissions: [UserRoleEnums.OWNER, UserRoleEnums.ADMIN],
      },
      {
        id: GroupPathsEnum.GROUP_LIBRARY_SHARE_WITH_ME,
        path: GroupPathsEnum.GROUP_LIBRARY_SHARE_WITH_ME,
        component: GroupLibraryScreen,
        // permissions: [UserRoleEnums.OWNER, UserRoleEnums.ADMIN],
      },
      {
        id: GroupPathsEnum.GROUP,
        path: "",
        component: GroupDetailScreen,
      },
      {
        id: GroupPathsEnum.GROUP_DISCUSS,
        path: GroupPathsEnum.GROUP_DISCUSS,
        component: BlankScreen,
      },
      {
        id: GroupPathsEnum.LEAVE_REQUEST,
        path: GroupPathsEnum.LEAVE_REQUEST,
        component: GroupLeaveRequestsScreen,
        permissions: [UserRoleEnums.OWNER, UserRoleEnums.ADMIN],
      },
      {
        id: GroupPathsEnum.INVITE_EMAIL,
        path: GroupPathsEnum.INVITE_EMAIL,
        component: InviteEmailScreen,
        permissions: [UserRoleEnums.OWNER, UserRoleEnums.ADMIN],
      },
      {
        id: GroupPathsEnum.GROUP_JOIN_REQUEST,
        path: GroupPathsEnum.GROUP_JOIN_REQUEST,
        component: GroupRequestsScreen,
        permissions: [UserRoleEnums.OWNER, UserRoleEnums.ADMIN],
      },
      {
        id: GroupPathsEnum.GROUP_DEVICE,
        path: GroupPathsEnum.GROUP_DEVICE,
        component: GroupDeviceScreen,
      },
      {
        id: GroupPathsEnum.GROUP_ROLE,
        path: GroupPathsEnum.GROUP_ROLE,
        component: BlankScreen,
        permissions: [UserRoleEnums.OWNER],
      },
      {
        id: GroupPathsEnum.GROUP_TITLE,
        path: GroupPathsEnum.GROUP_TITLE,
        component: BlankScreen,
        permissions: [UserRoleEnums.OWNER],
      },
      {
        id: GroupPathsEnum.INVITE_REQUESTED,
        path: GroupPathsEnum.INVITE_REQUESTED,
        component: InviteRequestedScreen,
        permissions: [UserRoleEnums.OWNER, UserRoleEnums.ADMIN],
      },
      {
        id: GroupPathsEnum.GROUP_SETTING_USER,
        path: GroupPathsEnum.GROUP_SETTING_USER,
        component: SettingUserScreen,
        // permissions: [UserRoleEnums.OWNER, UserRoleEnums.ADMIN],
      },
    ],
  },
  {
    id: GroupPathsEnum.GROUP_NOT_AVAILABLE,
    path: GroupPathsEnum.GROUP_NOT_AVAILABLE,
    component: NotAvailableScreen,
  },
  {
    id: GroupPathsEnum.REMOVE_GROUP_SUCCESS,
    path: GroupPathsEnum.REMOVE_GROUP_SUCCESS,
    component: RemoveGroupSuccessScreen,
  },
];
