import React, { FC, memo } from "react";

import { InformationFilled } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { getDeviceName } from "@/features/groups/helpers/group.helper";
import { DeviceDef } from "@/features/groups/types/group.type";

type RemoveDeviceRequestDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  device: DeviceDef | null;
  currentDevice?: boolean;
};

const RemoveDeviceRequestDialog: FC<RemoveDeviceRequestDialogProps> = ({
  open,
  onClose,
  onSubmit,
  loading,
  device,
  currentDevice = false,
}) => {
  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
    >
      <DialogContent
        className="sm:max-w-[552px] bg-white gap-6 text-center"
        onInteractOutside={() => onClose()}
      >
        <DialogHeader className="flex flex-row gap-4">
          <div>
            <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full block">
              <InformationFilled size={20} />
            </span>
          </div>
          <div className="flex-1">
            <DialogTitle className="mb-2">
              Gỡ yêu cầu xác thực thiết bị?
            </DialogTitle>
            <DialogDescription className="text-secondary-600">
              {currentDevice ? (
                "Bạn chắc chắn gỡ yêu cầu xác thực thiết bị này mà không chờ tổ chức phê duyệt."
              ) : (
                <div>
                  {" "}
                  Bạn chắc chắn gỡ yêu cầu xác thực thiết bị
                  <span className="font-semibold">
                    {" "}
                    {getDeviceName(device)}
                  </span>{" "}
                  mà không chờ tổ chức phê duyệt.
                </div>
              )}
              <p className="text-secondary-900 font-semibold">Tìm hiểu thêm↗</p>
            </DialogDescription>
            <div className="text-right mt-10">
              <Button
                variant="outline"
                className="mr-2"
                size="sm"
                onClick={onClose}
              >
                Hủy bỏ
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={onSubmit}
                loading={loading}
              >
                Xác nhận
              </Button>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default memo(RemoveDeviceRequestDialog);
