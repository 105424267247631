import React, { memo, useState, FC } from "react";

import cx from "classnames";
import { toast, useToasterStore } from "react-hot-toast";

import { Excel } from "@/assets/icons";
import Typography from "@/components/Typography/Typography";
import { getFileSize } from "@/utils/file";

import styles from "./FileManagement.module.scss";

type UploadToastResultProps = {
  id: string;
  file: File;
  type?: "success" | "cancel" | "error";
};

const UploadToastResult: FC<UploadToastResultProps> = ({
  id,
  file,
  type = "success",
}) => {
  const { toasts } = useToasterStore();
  const [showBtnClose, setShowBtnClose] = useState(false);

  return (
    <div
      className={styles.toast}
      onClick={(e) => e.stopPropagation()}
      onMouseOver={() => setShowBtnClose(true)}
      onMouseOut={() => setShowBtnClose(false)}
    >
      <div
        className={cx(styles.close, showBtnClose && styles.show)}
        onClick={() => {
          if (toasts.length === 2) {
            toast.remove();
          }
          toast.dismiss(id);
        }}
      >
        <i className="tgn-outline-remove fs-18"></i>
      </div>
      <div className={styles["thumbnail"]}>
        <Excel />
      </div>
      <div className="flex-1">
        <Typography
          style={{
            maxWidth: type === "cancel" ? 150 : 210,
          }}
          className={cx(
            "text-truncate text-primary-color-gray-10 fs-12 fw-semibold",
            type === "cancel" && styles.disableText
          )}
        >
          {file.name}
        </Typography>
        {type === "error" ? (
          <Typography className="text-color-background-error fs-12">
            Tải tệp lên không thành công
          </Typography>
        ) : (
          <Typography
            className={cx(
              "fs-12 text-secondary-color-gray-7",
              type === "cancel" && styles.disableText
            )}
          >
            {getFileSize(file.size)}
          </Typography>
        )}
      </div>
      {type === "cancel" ? (
        <Typography className="text-secondary-color-gray-7 fs-12 text-nowrap">
          Upload canceled
        </Typography>
      ) : type === "error" ? (
        <i className="tgn-solid-remove-circle text-color-background-error fs-18"></i>
      ) : (
        <i className="tgn-solid-check-circle text-color-background-success fs-18"></i>
      )}
    </div>
  );
};

export default memo(UploadToastResult);
