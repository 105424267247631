import React, { FC, memo, useEffect, useRef, useState } from "react";

import { Add, ArrowLeft, Close, Renew, Warning } from "@carbon/icons-react";
import { isAxiosError } from "axios";
import { Field, Form, Formik } from "formik";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { COUNT_DOWN } from "@/constants/app.constants";
import { useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import { UpdateEmailOrPhoneNumberParams } from "../account";
import { checkExistForEmailOrPhoneNumber, sendOtp } from "../api/account.api";
import { updateEmailSchema } from "../helpers/form.helper";

interface UpdateEmailModalProps {
  open: boolean;
  onSubmit: (params: UpdateEmailOrPhoneNumberParams) => void;
  onClose: () => void;
  onDelete: () => void;
}

enum UpdateEmailSteps {
  NO_EMAIL,
  DEFAULT,
  UPDATE,
  REMOVE,
  OTP,
}

const UpdateEmailModal: FC<UpdateEmailModalProps> = ({
  open,
  onClose,
  onSubmit,
  onDelete,
}) => {
  const user = useAppSelector((state) => state.auth.user);
  const [step, setStep] = useState(
    user?.email ? UpdateEmailSteps.DEFAULT : UpdateEmailSteps.NO_EMAIL
  );
  const [countDown, setCountDown] = useState(COUNT_DOWN);
  const inputRef = useRef<HTMLInputElement>(null);
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [newEmail, setNewEmail] = useState("");
  const [uid, setUid] = useState("");
  const [exist, setExist] = useState(false);
  const { toast } = useToast();
  const reSendOtp = () => {
    if (countDown === 0) {
      setCountDown(COUNT_DOWN);
      handleSendOtp({ email: newEmail });
    }
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1, value.length);
    setActiveOtpIndex(value ? index + 1 : index - 1);
    setOtp(newOtp);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown((seconds) => seconds - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [activeOtpIndex]);
  useEffect(() => {
    setStep(user?.email ? UpdateEmailSteps.DEFAULT : UpdateEmailSteps.NO_EMAIL);
  }, [open]);
  const handleSendOtp = async (values: { email: string }) => {
    if (!user) {
      return;
    }
    try {
      const isExist = await checkExistForEmailOrPhoneNumber({
        email: values.email,
      });
      setExist(isExist.data);
      if (isExist.data) {
        return;
      }
      const result = await sendOtp(values.email);
      if (result.data.uid) {
        setUid(result.data.uid);
      }
      setNewEmail(values.email);
      setStep(UpdateEmailSteps.OTP);
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      }
    }
  };
  const handleSubmit = () => {
    onSubmit({
      uid,
      email: newEmail,
      otp: otp.join(""),
    });
  };
  return (
    <Dialog open={open}>
      <DialogContent
        className={
          step === UpdateEmailSteps.REMOVE ? "max-w-[364px]" : "max-w-[600px]"
        }
      >
        {step !== UpdateEmailSteps.REMOVE && (
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute rounded-full right-0 top-0"
              onClick={onClose}
            >
              <Close />
            </Button>
          </DialogHeader>
        )}
        {step === UpdateEmailSteps.NO_EMAIL && (
          <div>
            <DialogTitle>Bạn chưa thêm địa chỉ email</DialogTitle>
            <p className="text-xs text-secondary-600 mt-2">
              Bạn có sử dụng địa chỉ email này để đăng nhập vào tài khoản.{" "}
              <span className="text-primary font-medium">Tìm hiểu thêm</span>
            </p>
            <div className="text-center mt-10 flex justify-center">
              <Button className="flex items-center">
                <Add className="mr-2 text-white" />
                <span>Thêm địa chỉ email</span>
              </Button>
            </div>
          </div>
        )}
        {step === UpdateEmailSteps.DEFAULT && (
          <>
            <DialogTitle>{user?.email}</DialogTitle>
            <DialogDescription>
              <p className="text-xs text-secondary-600">
                Bạn có thể sử dụng địa chỉ email để đăng nhập vào tài khoản.{" "}
                <span className="text-primary-600">Tìm hiểu thêm</span>
              </p>
            </DialogDescription>
            <div className="mt-10">
              <Button
                className="block w-full"
                onClick={() => setStep(UpdateEmailSteps.UPDATE)}
              >
                Cập nhật địa chỉ email
              </Button>
              <Button
                className="block w-full mt-2 text-error-600 bg-white hover:bg-error-50"
                onClick={() => setStep(UpdateEmailSteps.REMOVE)}
              >
                Xoá
              </Button>
            </div>
          </>
        )}
        {step === UpdateEmailSteps.UPDATE && (
          <Formik
            initialValues={{
              email: user?.email || "",
              oldEmail: user?.email || "",
            }}
            validationSchema={updateEmailSchema}
            onSubmit={handleSendOtp}
          >
            {({ isSubmitting, handleChange, errors, touched, isValid }) => (
              <Form>
                <div>
                  <DialogTitle className="mb-2 text-lg">
                    Cập nhật địa chỉ email
                  </DialogTitle>
                  <DialogDescription>
                    <p className="text-xs text-secondary-600">
                      Địa chỉ email hiện tại của bạn là{" "}
                      <span className="text-secondary-900 font-medium">
                        {user?.email}
                      </span>
                      . Bạn cần sử dụng địa chỉ email mới để đăng nhập vào tài
                      khoản này trên TAGA.
                    </p>
                  </DialogDescription>
                  <p className="mt-10 text-secondary-900 text-xs font-medium mb-1">
                    Email
                  </p>
                  <Field
                    name="email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setExist(false);
                      handleChange(e);
                    }}
                    error={errors.email}
                    as={Input}
                    className="Nhập địa chỉ email mới"
                  ></Field>
                  {touched.email && errors.email && (
                    <span className="text-xs text-red-600 flex gap-1 mt-1">
                      <Warning />
                      {errors.email}
                    </span>
                  )}
                  {exist && (
                    <span className="text-xs text-red-600 flex gap-1 mt-1">
                      <Warning />
                      {getMessage("MSG_TK33")}
                    </span>
                  )}
                  <div className="flex gap-2 mt-6">
                    <Checkbox />
                    <p className="text-xs text-secondary-600 font-medium">
                      Cho phép mọi người trên Taga có thể tìm kiếm và kết nối
                      với bạn thông qua địa chỉ email này.{" "}
                      <span className="text-primary font-medium">
                        Tìm hiểu thêm
                      </span>
                    </p>
                  </div>
                  <div className="text-center mt-10">
                    <Button
                      type="submit"
                      loading={isSubmitting}
                      disabled={!isValid || isSubmitting || !touched.email}
                    >
                      Tiếp theo
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        {step === UpdateEmailSteps.OTP && (
          <div>
            <div
              className="text-secondary-600 inline-flex gap-2 items-center cursor-pointer"
              onClick={() => setStep(UpdateEmailSteps.UPDATE)}
            >
              <ArrowLeft />
              <span className="text-sm">Trở lại</span>
            </div>
            <div className="text-sm text-secondary-600">
              <p className="text-lg font-semibold text-secondary-900 mt-4">
                Xác minh
              </p>
              <p className="mt-1 mb-4">
                Nhập mã xác nhận bao gồm 6 chữ số chúng tôi vừa gửi đến địa chỉ
                email của bạn{" "}
                <span className="text-secondary-900 font-medium">
                  {newEmail}
                </span>
              </p>
            </div>
            <div className="w-full h-40 px-4 py-12 bg-secondary-50 rounded justify-center items-center gap-2 inline-flex">
              {otp.map((_, index) => {
                return (
                  <React.Fragment key={index}>
                    <Input
                      ref={index === activeOtpIndex ? inputRef : null}
                      type="number"
                      className="w-16 h-16 p-1 bg-white rounded-lg shadow border border-secondary-200 text-5xl font-medium text-center text-primary spin-button-none active:border-primary-600 placeholder:text-secondary-300"
                      onChange={(e) => handleChange(e, index)}
                      value={otp[index]}
                      onKeyDown={(event) => {
                        if ([event.code, event.key].includes("Backspace")) {
                          event.preventDefault();
                          const newOtp = [...otp];
                          newOtp[index] = "";
                          setOtp(newOtp);
                          setActiveOtpIndex(activeOtpIndex - 1);
                        }
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="0"
                    />
                    {index === 2 && (
                      <span className="w-2 py-0.5 bg-secondary-400" />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="flex justify-between items-center mt-7">
              <div className="flex-col justify-center items-start gap-1 inline-flex mr-auto">
                <div className="text-gray-900 text-sm font-normal ">
                  Không nhận được mã xác nhận?
                </div>

                <span className="flex gap-1 items-center text-primary font-medium text-sm ">
                  <span
                    onClick={reSendOtp}
                    className={
                      countDown > 0
                        ? "flex opacity-50 items-center gap-1"
                        : "flex items-center gap-1 cursor-pointer"
                    }
                  >
                    <Renew />
                    Gửi lại
                  </span>
                  {countDown > 0 && <span>sau {countDown}s</span>}
                </span>
              </div>
              <div>
                <Button
                  type="button"
                  variant="outline"
                  className="mr-3"
                  onClick={onClose}
                >
                  Hủy bỏ
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={otp.some((item) => !item)}
                >
                  Xác nhận
                </Button>
              </div>
            </div>
          </div>
        )}
        {step === UpdateEmailSteps.REMOVE && (
          <div>
            <DialogTitle>Xoá địa chỉ email</DialogTitle>
            <p className="text-xs text-secondary-600 mt-1">
              Thao tác này sẽ xóa địa chỉ email{" "}
              <span className="text-secondary-900 font-medium">
                {user?.email}
              </span>{" "}
              khỏi tài khoản của bạn. Bạn sẽ không thể sử dụng email để đăng
              nhập nữa.
            </p>
            <div className="mt-6">
              <Button
                variant="destructive"
                className="w-full"
                onClick={onDelete}
              >
                Xoá
              </Button>
            </div>
            <div className="mt-3">
              <Button
                variant="ghost"
                className="w-full"
                onClick={onClose}
              >
                Huỷ bỏ
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(UpdateEmailModal);
