import * as React from "react";

import {
  CheckmarkFilled,
  CloseFilled,
  InformationFilled,
  WarningFilled,
} from "@carbon/icons-react";

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        let icon = (
          <CheckmarkFilled
            size={20}
            className="text-success-500"
          />
        );
        switch (props.variant) {
          case "default":
            icon = (
              <CheckmarkFilled
                size={20}
                className="text-success-500"
              />
            );
            break;
          case "error":
            icon = (
              <CloseFilled
                size={20}
                className="text-error-600"
              />
            );
            break;
          case "warning":
            icon = (
              <WarningFilled
                size={20}
                className="text-warning-500"
              />
            );
            break;
          case "info":
            icon = (
              <InformationFilled
                size={20}
                className="text-primary-600"
              />
            );
            break;

          default:
            break;
        }
        return (
          <Toast
            key={id}
            {...props}
          >
            <div className="flex gap-2 items-center">
              <div>{icon}</div>
              <div className="grid gap-1">
                {/* {title && <ToastTitle>{title}</ToastTitle>} */}
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
