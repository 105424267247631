import React, { FC, memo, useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";

import { ArrowLeft, ArrowRight, ArrowUpRight } from "@carbon/icons-react";
import Carousel from "react-multi-carousel";

import OnBoarding2 from "@/assets/images/Accept.png";
import OnBoarding3 from "@/assets/images/Cancel.png";
import OnBoarding from "@/assets/images/invite.png";
import { Button } from "@/components/ui/button";

interface Props {
  active: () => void;
}

const CarouselInvite: FC<Props> = ({ active }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
  };
  return (
    <>
      <Carousel
        responsive={responsive}
        autoPlay
        autoPlaySpeed={3000}
        keyBoardControl={true}
        className="-mx-6"
        customLeftArrow={
          <Button
            className="absolute top-1/2 left-1 w-9"
            variant={"text"}
            size={"sm"}
          >
            <ArrowLeft />
          </Button>
        }
        customRightArrow={
          <Button
            variant={"text"}
            className="absolute top-1/2 right-1 w-9"
            size={"sm"}
          >
            <ArrowRight />
          </Button>
        }
        showDots={true}
        shouldResetAutoplay
        infinite={true}
        draggable={true}
        partialVisible={false}
        beforeChange={(nextSlide, { currentSlide }) => {
          if (currentSlide == 3) {
            active();
          }
        }}
      >
        <div className="grid gap-6 px-10 pb-10">
          <img
            src={OnBoarding}
            alt=""
            className="w-[100px] h-[100px] mx-auto"
          />
          <p className="text-xl font-semibold text-secondary-900">
            Danh sách lời mời
          </p>
          <p className="text-secondary-900">
            Bạn đang nhận được một số lời mời tham gia từ các Nhóm thuộc Tổ chức
            này. Việc thực hiện đồng ý hay từ chối lời mời của Tổ chức sẽ gây
            ảnh hưởng tới những lời mời tới bạn, hoặc yêu cầu gửi đi đã được
            thực hiện trước đó.{" "}
            <span className="font-medium inline-flex items-center">
              Tìm hiểu thêm <ArrowUpRight size={14} />
            </span>
          </p>
          <p className="text-secondary-900">
            Hãy xử lý các yêu cầu hoặc lời mời liên quan, nhằm bảo vệ quyền
            riêng tư cá nhân.
          </p>
        </div>
        <div className="grid gap-4 px-10">
          <img
            src={OnBoarding2}
            alt=""
            className="w-[100px] h-[100px] mx-auto"
          />
          <p className="text-xl font-semibold text-secondary-900">
            Đồng ý lời mời
          </p>
          <p className="text-secondary-900">
            Việc chấp nhận lời mời tham gia vào tổ chức đồng nghĩa với việc bạn
            sẽ là thành viên của tổ chức và tất cả các nhóm mà bạn đã xác nhận
            lời mời trước đó.
          </p>
          <p className="text-secondary-900">
            Ngoài ra, các yêu cầu tham gia đang được xử lý của bạn tới những
            nhóm thuộc Tổ chức này sẽ tự động được phê duyệt.
          </p>
        </div>
        <div className="grid gap-4 px-10">
          <img
            src={OnBoarding3}
            alt=""
            className="w-[100px] h-[100px] mx-auto"
          />
          <p className="text-xl font-semibold text-secondary-900">
            Từ chối lời mời
          </p>
          <p className="text-secondary-900">
            Việc từ chối lời mời tham gia vào tổ chức đồng nghĩa với việc bạn
            xác nhận sẽ không là thành viên của tổ chức. Do đó bạn sẽ không thể
            tham gia các nhóm thuộc Tổ chức này.
          </p>
          <p className="text-secondary-900">
            Hệ thống sẽ tiến hành huỷ bỏ các yêu cầu của bạn đã được gửi tới
            những nhóm thuộc tổ chức này trước đó.
          </p>
        </div>
      </Carousel>
    </>
  );
};
export default memo(CarouselInvite);
