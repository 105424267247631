import * as React from "react";

import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-transparent bg-blue-50 text-blue-500",
        secondary: "border-transparent bg-secondary text-secondary-foreground",
        ghost: "border-transparent bg-secondary-100 text-secondary",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground",
        outline: "text-foreground",
        orange: "border-transparent bg-orange-50 text-orange-500",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const badgeSize = cva("py-px pl-1 pr-2  text-xs", {
  variants: {
    size: {
      sm: "py-px pl-2 pr-2 text-xs",
      md: "py-px pl-2 pr-2 text-sm",
      lg: "py-1 px-3 text-sm",
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  size?: "sm" | "md" | "lg";
}

function Badge({ className, variant, size, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant }), badgeSize({ size }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
