import React, { FC, ReactNode, memo, useState } from "react";

import { InformationFilled } from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import {
  deleteGroupMember,
  rejectGroupInvitation,
} from "@/features/groups/api/group.api";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import {
  getGroupInfo,
  getGroupMembersListThunk,
} from "@/features/groups/group";
import {
  IMemberGroup,
  MemberDetailDef,
} from "@/features/groups/types/group.type";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getCustomMessage, getMessage } from "@/utils/message";

type DeleteConfirmModalProps = {
  user: MemberDetailDef | IMemberGroup;
  button: ReactNode;
  onCloseSheet?: React.Dispatch<React.SetStateAction<any>>;
};

const DeleteConfirmModal: FC<DeleteConfirmModalProps> = ({
  user,
  button,
  onCloseSheet,
}) => {
  const groupInfo = useAppSelector((state) => state.group.data);
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDeleteMember = async () => {
    try {
      dispatch(
        loadingAction.show({
          title: "Đang xử lý...",
          description: "Vui lòng chờ tới khi xử lý hoàn tất.",
        })
      );
      if (groupInfo) {
        await deleteGroupMember(groupInfo.id, user.userId);
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: getCustomMessage("MSG28", user.name),
              }}
            ></div>
          ),
        });
        setOpenModal(false);
        if (onCloseSheet) {
          onCloseSheet(false);
        }
        navigate(
          GroupPathsEnum.GROUP_DEPARTMENT_USER.replace(
            ":id",
            String(groupInfo.id)
          )
        );
        dispatch(
          getGroupMembersListThunk({
            pageNumber: 1,
            pageSize: 9,
            groupId: groupInfo.id,
          })
        );
        dispatch(
          getGroupInfo({
            group_id: groupInfo.id,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
          });
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      }
    } finally {
      dispatch(loadingAction.hide());
    }
  };
  return (
    <>
      {user && (
        <>
          <Dialog open={openModal}>
            <DialogTrigger asChild>
              <div
                className="w-full"
                onClick={() => setOpenModal(true)}
              >
                {button}
              </div>
            </DialogTrigger>
            <DialogContent className="max-w-[600px]">
              <DialogHeader>
                <div className="flex gap-4 grid-cols-2">
                  <div>
                    <span className="block border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full">
                      <InformationFilled size={24} />
                    </span>
                  </div>
                  <span className="w-[calc(100%_-_48px)]  text-sm">
                    <p className="text-secondary-900 text-lg font-semibold mb-2">
                      Xoá thành viên
                    </p>
                    <span className="font-normal text-secondary-600 leading-tight">
                      Bạn chắc chắn muốn xoá{" "}
                      <span className="font-semibold">{user.name}</span>? Người
                      này sẽ không còn là thành viên Tổ chức và các nhóm đã tham
                      gia trước đó nữa.
                    </span>
                  </span>
                </div>
              </DialogHeader>
              <DialogFooter>
                <Button
                  variant={"outline"}
                  type="button"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  size="sm"
                >
                  Hủy bỏ
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={handleDeleteMember}
                >
                  Xác nhận
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default memo(DeleteConfirmModal);
