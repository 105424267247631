import React, { useState } from "react";

import { Button } from "@/components/ui/button";

import ContentDetails from "../../components/ContentDetails";
import DialogConfirmDeleteContent from "../../components/DialogConfirmDeleteContent";
import ManageContentAccessModal from "../../components/ManageContentAccessModal";
import ShareContentFolderModal from "../../components/ShareContentFolderModal";
import FolderListModal from "../../components/move/FolderListModal";
// eslint-disable-next-line import/namespace
import { IContent } from "../../types/lib.types";

const BlankScreen = () => {
  const [contentSelected, setContentSelected] = useState<IContent>();
  const [isShowConfirmDeleteContent, setIsShowConfirmDeleteContent] =
    useState<boolean>(false);

  const [isShowDialogConfirmMove, setIsShowDialogConfirmMove] =
    useState<boolean>(false);
  const [isShowManageContentAccessModal, setIsShowManageContentAccessModal] =
    useState(false);
  const [rootContent, setRootContent] = useState<IContent>();

  const [isShowAccessRequestListModal, setIsShowAccessRequestListModal] =
    useState<boolean>(false);
  const [isShowShare, setIsShowShare] = useState<boolean>(false);
  const [contentSelectedDetails, setContentSelectedDetails] =
    useState<IContent>();
  const [isShowDetails, setIsShowDetails] = useState<boolean>(false);
  const [isShowAccessRequestModal, setIsShowAccessRequestModal] =
    useState<boolean>(false);
  const [isShowMove, setIsShowMove] = useState<boolean>(false);

  //  funtion
  const onDeleteSubmit = async () => {
    setIsShowConfirmDeleteContent(false);
    console.log("delete");
  };
  const onAcceptMove = async () => {
    setIsShowDialogConfirmMove(false);
    console.log("move");
  };
  const onChangeAccessType = async () => {
    console.log("move");
  };
  const onManageContentAccessModalSubmitSuccess = () => {
    setIsShowManageContentAccessModal(false);
  };
  const onOpenChangeShare = () => {
    setIsShowShare(false);
  };

  const onCloseDetail = () => {
    // setContentSelectedDetails(undefined);
    setIsShowDetails(false);
  };

  const onOpenChangeFolderList = (content: IContent | undefined) => {
    setIsShowMove(false);
  };
  return (
    <div>
      <div className="flex gap-6">
        <Button onClick={() => setIsShowConfirmDeleteContent(true)}>
          click delete
        </Button>
        <Button onClick={() => setIsShowDialogConfirmMove(true)}>
          click move
        </Button>
        <Button onClick={() => setIsShowManageContentAccessModal(true)}>
          click mana
        </Button>
        <Button onClick={() => setIsShowShare(true)}>click share</Button>
        <Button onClick={() => setIsShowDetails(true)}>click detail</Button>
        <Button onClick={() => setIsShowMove(true)}>click LIST</Button>
      </div>
      <h1>BlankScreen</h1>
      {/* 
      <DialogConfirmDeleteContent
        isShow={isShowConfirmDeleteContent}
        content={contentSelected}
        onAcceptContent={() => onDeleteSubmit()}
        onRejectContent={() => setIsShowConfirmDeleteContent(false)}
      /> */}

      {/* <ManageContentAccessModal
        content={contentSelected}
        isShow={isShowManageContentAccessModal}
        onSubmit={onChangeAccessType}
        onSubmitSuccess={onManageContentAccessModalSubmitSuccess}
        onOpenAccessRequestList={() => {
          setIsShowManageContentAccessModal(false);
          setIsShowAccessRequestListModal(true);
        }}
        onOpenChange={() => setIsShowManageContentAccessModal(false)}
      /> */}
      {/* <ShareContentFolderModal
        isShow={isShowShare}
        content={contentSelected}
        onShowChangeContentType={() => {
          setIsShowShare(false);
          setIsShowManageContentAccessModal(true);
        }}
        onSubmitSuccess={() => setIsShowShare(false)}
        onOpenChange={onOpenChangeShare}
      /> */}
      <div className="container">
        <div className="flex flex-row gap-3">
          <div className="flex flex-grow">
            <div className="bg-white rounded-md flex flex-col flex-1  border border-gray-100 w-full"></div>
            {isShowDetails && contentSelectedDetails && (
              <ContentDetails
                contentDetail={contentSelectedDetails}
                onClose={onCloseDetail}
                onShowChangeContentType={() =>
                  setIsShowManageContentAccessModal(true)
                }
                onShowAccessRequest={() => setIsShowAccessRequestModal(true)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlankScreen;
