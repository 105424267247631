import React, { memo } from "react";

import cx from "classnames";
import { select } from "d3-selection";
import { Dropdown } from "react-bootstrap";

import Divider from "@/components/Divider/Divider";
import TGNButton from "@/components/TGNButton/TGNButton";
import { downloadSVG } from "@/utils/export-to-png";

import styles from "./FileSetting.module.scss";

const FileSetting = () => {
  const exportToPng = () => {
    select("#mindmap g").attr("transform", "translate(100,500) scale(1)");
    const width =
      document.querySelector("#mindmap g")?.getBoundingClientRect().width || 0;
    const options = {
      width: width + 600,
      height: 1000,
      svg: document.getElementById("mindmap"),
      filename: "Barchart.png",
    };

    downloadSVG(options);
  };
  const MENU_ACTIONS = [
    {
      icon: "tgn-solid-plus",
      label: "Create new",
      subMenus: [
        {
          icon: "tgn-solid-plus",
          label: "Create new",
        },
      ],
    },
    {
      icon: "tgn-outline-pencil",
      label: "Rename",
      subMenus: [],
    },
    {
      icon: "tgn-solid-link",
      label: "Copy link",
      subMenus: [],
    },
    {
      icon: "tgn-outline-star",
      label: "Add to favourites",
      subMenus: [],
    },
    {
      icon: "tgn-outline-star",
      label: "Duplicate",
      subMenus: [],
    },
    {
      icon: "tgn-outline-star",
      label: "Move file",
      subMenus: [],
    },
    {
      icon: "tgn-outline-star",
      label: "Save as png",
      subMenus: [],
      onClick: exportToPng,
    },
    {
      icon: "tgn-outline-mail",
      label: "Email to list",
      subMenus: [],
      isEnd: true,
    },
    {
      icon: "tgn-outline-download",
      label: "Import templates",
      subMenus: [
        {
          icon: "tgn-solid-plus",
          label: "Create new",
        },
      ],
    },
    {
      icon: "tgn-outline-setting",
      label: "Setting",
      subMenus: [
        {
          icon: "tgn-solid-plus",
          label: "Create new",
        },
      ],
      isEnd: true,
    },
    {
      icon: "tgn-outline-archive-download",
      label: "Archives",
      subMenus: [],
    },
    {
      icon: "tgn-outline-trash",
      label: "Delete",
      subMenus: [],
    },
  ];
  return (
    <Dropdown
      className="me-12 cursor-pointer"
      autoClose="outside"
    >
      <Dropdown.Toggle
        as="span"
        id="dropdown-autoclose-inside"
        className={styles.toggleArrow}
      >
        <span className="d-flex">
          <i className="tgn-solid-more-horizontal"></i>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className={styles["dropdown-wrapper"]}>
        {MENU_ACTIONS.map((menu) => {
          return (
            <React.Fragment key={menu.icon + menu.label}>
              <Dropdown.Item className={cx("w-100 p-0 py-4", styles.item)}>
                {menu.subMenus.length ? (
                  <Dropdown
                    className="cursor-pointer"
                    drop="end"
                  >
                    <Dropdown.Toggle
                      as="div"
                      className={cx(
                        "w-100 d-flex align-items-center px-8 justify-content-between",
                        styles.toggle
                      )}
                    >
                      <div className="d-flex align-items-center">
                        <i
                          className={cx("fs-20 me-4", menu.icon, styles.icon)}
                        ></i>
                        <span className="fs-14">{menu.label}</span>
                      </div>
                      <i className="tgn-solid-direction-right-01"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles["sub-dropdown-wrapper"]}>
                      <Dropdown.Item
                        className={cx("d-flex align-items-center", styles.item)}
                      >
                        <i className="tgn-solid-plus"></i>
                        <span className="fs-14">Create new</span>
                        <i className="tgn-solid-direction-right-01"></i>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <div
                    className="d-flex align-items-center px-8"
                    onClick={menu.onClick}
                  >
                    <i
                      className={cx(
                        "fs-18 me-4",
                        menu.icon,
                        styles.icon,
                        menu.label === "Delete" && styles["text-delete"]
                      )}
                    ></i>
                    <span
                      className={cx(
                        "fs-14",
                        menu.label === "Delete" && styles["text-delete"]
                      )}
                    >
                      {menu.label}
                    </span>
                  </div>
                )}
              </Dropdown.Item>
              {menu.isEnd && (
                <div className="px-8 my-4">
                  <Divider
                    width="100%"
                    height={1}
                  />
                </div>
              )}
            </React.Fragment>
          );
        })}
        <Dropdown.Item
          className="px-8 mt-4"
          as="div"
        >
          <TGNButton className="w-100 border-0 fs-14">
            Sharing & Permissions
          </TGNButton>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default memo(FileSetting);
