import React, { memo, useState } from "react";

import {
  CheckmarkFilled,
  InformationFilled,
  Locked,
  Wikis,
} from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { settingGroup } from "@/features/groups/api/group.api";
import { GroupModeEnums } from "@/features/groups/constants/group.constant";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { getGroupInfo, groupActions } from "@/features/groups/group";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { COMMON_MESSAGES, getMessage } from "@/utils/message";

import AccessSetting from "./AccessSetting";
const AccessManagement = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useAppDispatch();
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const [selectedMode, setSelectedMode] = useState(groupInfo?.mode);
  const [selectedRequireRequestMode, setSelectedRequireRequestMode] = useState(
    groupInfo?.requireRequestApprove
  );
  const [modalChangeMode, setModalChangeMode] = useState(false);
  const handleChangeMode = async () => {
    try {
      setLoading(true);

      if (groupInfo) {
        await settingGroup({
          deviceRequireAccept: true,
          groupId: groupInfo.id,
          lock: groupInfo.groupLock,
          mode: selectedMode ?? 0,
          requireApprove: groupInfo.requireRequestApprove,
        });
        if (selectedMode === GroupModeEnums.PRIVATE) {
          setModalChangeMode(true);
        } else {
          toast({
            title: "Thành công!",
            variant: "success",
            description: getMessage("MSG4"),
          });
        }
        setActiveTab(0);
        setSelectedTab(0);
        dispatch(
          getGroupInfo({
            group_id: groupInfo.id,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
          });
      }
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    } finally {
      setLoading(false);
    }
  };
  const handleChangeRequireRequestMode = async () => {
    try {
      setLoading(true);

      if (groupInfo && selectedRequireRequestMode !== undefined) {
        await settingGroup({
          deviceRequireAccept: true,
          groupId: groupInfo.id,
          lock: groupInfo.groupLock,
          mode: groupInfo.mode,
          requireApprove: selectedRequireRequestMode,
        });
        toast({
          title: "Thành công!",
          variant: "success",
          description: getMessage("MSG4"),
        });
        setActiveTab(0);
        setSelectedTab(0);
        dispatch(
          getGroupInfo({
            group_id: groupInfo.id,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
          });
      }
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {groupInfo && (
        <>
          <div className="mt-6">
            <p className="text-base font-semibold text-secondary-900">
              Quản lý Truy cập
            </p>
            <p className="text-xs text-secondary-600">
              Tinh chỉnh quyền truy cập đối với{" "}
              {groupInfo.rootGroupId
                ? "Nhóm của bạn trong Tổ chức"
                : "hệ thống Tổ chức của bạn"}{" "}
              và người dùng TAGA
            </p>
            {groupInfo && (
              <div className="grid gap-4 mt-6">
                <p className="text-secondary-600 text-xs font-medium uppercase">
                  Quyền truy cập
                </p>
                <Dialog open={modalChangeMode}>
                  <DialogContent className="p-6 flex justify-center flex-col gap-6 max-w-[464px]">
                    <span className="flex items-center justify-center border-[6px] p-1 text-success-600 border-success-50 bg-success-100 rounded-full mx-auto">
                      <CheckmarkFilled
                        size={20}
                        className="text-success-500"
                      />
                    </span>
                    <div>
                      <p className="text-lg text-secondary-900 text-center font-semibold">
                        Thiết lập {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}{" "}
                        thành công!
                      </p>
                      <p className="text-sm text-secondary-600 mt-2 text-center">
                        {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} của bạn đã
                        được chuyển quyền truy cập sang chế độ{" "}
                        <span className="font-medium">“Riêng tư”</span>. Trạng
                        thái phê duyệt yêu cầu tham gia mặc định là{" "}
                        <span className="font-medium">“Bật”</span>, bạn có thể
                        cập nhật sau.
                      </p>
                    </div>
                    <div className="text-center">
                      {groupInfo.id && (
                        <Link
                          className="px-2 py-3 text-sm font-medium text-primary-600"
                          to={GroupPathsEnum.GROUP_DETAIL.replace(
                            ":id",
                            String(groupInfo.id)
                          )}
                        >
                          Về trang chủ Tổ chức
                        </Link>
                      )}
                    </div>
                  </DialogContent>
                </Dialog>
                {activeTab === 1 ? (
                  <div className="p-3 bg-white border border-secondary-200 rounded-lg shadow-lg">
                    <div
                      className={`p-3 flex gap-2 rounded pr-9 relative cursor-pointer ${
                        selectedMode === GroupModeEnums.PUBLIC
                          ? "bg-primary-25"
                          : ""
                      }`}
                      onClick={() => setSelectedMode(GroupModeEnums.PUBLIC)}
                    >
                      <div>
                        <span className="p-3 bg-secondary-200 rounded-full block">
                          <Wikis />
                        </span>
                      </div>
                      <div>
                        <p className="text-secondary-900 text-sm font-medium">
                          Công khai
                        </p>
                        <p className="text-xs mt-1 text-secondary-600">
                          Bất kỳ ai cũng có thể tìm thấy{" "}
                          {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} và gửi
                          yêu cầu tham gia{" "}
                          {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} của bạn,
                          Thành viên trong{" "}
                          {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} có thể
                          gửi lời mời tới người dùng trên TAGA.
                        </p>
                      </div>
                      {selectedMode === GroupModeEnums.PUBLIC && (
                        <span className="inline-block text-primary-600 absolute top-3 right-3">
                          <CheckmarkFilled />
                        </span>
                      )}
                    </div>
                    <div
                      className={`p-3 flex gap-2 rounded pr-9 relative cursor-pointer ${
                        selectedMode === GroupModeEnums.PRIVATE
                          ? "bg-primary-25"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedMode(GroupModeEnums.PRIVATE);
                      }}
                    >
                      <div>
                        <span className="p-3 bg-secondary-200 rounded-full block">
                          <Locked />
                        </span>
                      </div>
                      <div>
                        <p className="text-secondary-900 text-sm font-medium">
                          Riêng tư
                        </p>
                        <p className="text-xs mt-1 text-secondary-600">
                          Chỉ thành viên trong{" "}
                          {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} hoặc
                          người dùng được gửi lời mời tham gia mới có thể tìm
                          thấy {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}.
                        </p>
                      </div>
                      {selectedMode === GroupModeEnums.PRIVATE && (
                        <span className="inline-block text-primary-600 absolute top-3 right-3">
                          <CheckmarkFilled />
                        </span>
                      )}
                    </div>
                    <div className="h-[1px] bg-secondary-100 w-full my-4"></div>
                    <div className="flex gap-3 justify-end">
                      <Button
                        disabled={loading}
                        size="sm"
                        variant="outline"
                        type="button"
                        onClick={() => {
                          setActiveTab(0);
                          setSelectedMode(groupInfo.mode);
                          setSelectedTab(0);
                        }}
                      >
                        Huỷ bỏ
                      </Button>

                      <Button
                        disabled={selectedMode === groupInfo.mode}
                        loading={loading}
                        onClick={() => {
                          handleChangeMode();
                        }}
                        size="sm"
                      >
                        Lưu thay đổi
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${
                      selectedTab !== 0 && selectedTab !== 1 ? "opacity-60" : ""
                    } p-6 bg-white border border-secondary-200 rounded-lg flex gap-4 ${
                      activeTab === 0 ? "hover:shadow-lg" : ""
                    }`}
                  >
                    <div className="flex-1">
                      <p className="text-secondary-900 text-sm font-medium flex items-center">
                        {groupInfo.mode === 1 ? (
                          <Locked className="mr-1" />
                        ) : (
                          <Wikis className="mr-1" />
                        )}
                        {groupInfo.mode === 1 ? "Riêng tư" : "Công khai"}
                        {groupInfo.rootGroupId && (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger
                                className="ml-1"
                                asChild
                              >
                                <InformationFilled />
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>
                                  Quyền truy cập của Nhóm phụ thuộc vào quyền
                                  truy cập của Tổ chức.
                                </p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        )}
                      </p>
                      <p className="text-secondary-600 text-xs mt-1">
                        {groupInfo.mode === GroupModeEnums.PUBLIC
                          ? `Bất kỳ ai cũng có thể tìm thấy ${
                              groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"
                            } và gửi yêu cầu
                        tham gia ${
                          groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"
                        } của bạn,
                        Thành viên trong ${
                          groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"
                        } có thể gửi lời mời tới người dùng trên TAGA.`
                          : `Chỉ thành viên
                        trong ${
                          groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"
                        } hoặc người
                        dùng được gửi lời mời tham gia mới có thể tìm thấy ${
                          groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"
                        }.`}
                      </p>
                    </div>
                    {!groupInfo.rootGroupId && (
                      <Button
                        disabled={activeTab !== 0 && activeTab !== 1}
                        type="button"
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setActiveTab(1);
                          setSelectedTab(1);
                        }}
                      >
                        Chỉnh sửa
                      </Button>
                    )}
                  </div>
                )}
                {!groupInfo.rootGroupId && (
                  <div
                    className={`${
                      selectedTab !== 0 && selectedTab !== 2 ? "opacity-60" : ""
                    } p-6 bg-white border border-secondary-200 rounded-lg flex gap-4  ${
                      activeTab === 0 ? "hover:shadow-lg" : ""
                    }`}
                  >
                    <div className="flex-1">
                      <p className="text-secondary-900 text-sm font-medium flex items-center">
                        Xác thực thiết bị
                      </p>
                      <p className="text-primary-600 text-sm font-semibold">
                        Bật
                      </p>
                      <p className="text-secondary-600 text-xs mt-1">
                        Yêu cầu người dùng là Thành viên tổ chức (bao gồm Quản
                        trị viên và Chủ sở hữu) cần tiến hành xác thực khi truy
                        cập Tổ chức/Nhóm thuộc tổ chức trên các thiết bị khác
                        nhau để chỉnh sửa, sử dụng tài sản của Tổ chức.
                      </p>
                    </div>
                    <Button
                      disabled={true}
                      type="button"
                      variant="outline"
                      size="sm"
                    >
                      Chỉnh sửa
                    </Button>
                  </div>
                )}
                {activeTab === 3 ? (
                  <div className="p-3 bg-white border border-secondary-200 rounded-lg shadow-lg">
                    <p className="text-secondary-900 text-sm font-medium p-3">
                      Phê duyệt yêu cầu tham gia
                    </p>
                    <div
                      className={`p-3 flex gap-2 rounded pr-9 relative cursor-pointer ${
                        selectedRequireRequestMode === false
                          ? "bg-primary-25"
                          : ""
                      }`}
                      onClick={() => setSelectedRequireRequestMode(false)}
                    >
                      <div>
                        <p className="text-secondary-900 text-sm font-medium">
                          Tắt
                        </p>
                        {groupInfo.rootGroupId ? (
                          <p className="text-xs mt-1 text-secondary-600">
                            Mọi yêu cầu tham gia Nhóm sẽ được hệ thống tự động
                            thông qua và chuyển tiếp lên Tổ chức phê duyệt.
                            Trong trường hợp Tổ chức tắt chức năng phê duyệt
                            này, người dùng sẽ trực tiếp được trở thành thành
                            viên của Nhóm và Tổ chức.
                          </p>
                        ) : (
                          <p className="text-xs mt-1 text-secondary-600">
                            {groupInfo.mode === GroupModeEnums.PUBLIC
                              ? "Mọi người trên TAGA tự động được chấp thuận làm thành viên của Tổ chức mà không cần có sự phê duyệt nào từ Chủ sở hữu hay Quản trị viên của Tổ chức"
                              : "Mọi yêu cầu tham gia Tổ chức thông qua lời mời chuyển tiếp của Nhóm tự động được chấp thuận là thành viên của Tổ chức mà không cần có sự phê duyệt nào từ Chủ sở hữu hay Quản trị viên của Tổ chức."}
                          </p>
                        )}
                      </div>
                      {selectedRequireRequestMode === false && (
                        <span className="inline-block text-primary-600 absolute top-3 right-3">
                          <CheckmarkFilled />
                        </span>
                      )}
                    </div>
                    <div
                      className={`p-3 flex gap-2 rounded pr-9 relative cursor-pointer ${
                        selectedRequireRequestMode === true
                          ? "bg-primary-25"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedRequireRequestMode(true);
                      }}
                    >
                      <div>
                        <p className="text-secondary-900 text-sm font-medium">
                          Bật
                        </p>
                        {groupInfo.rootGroupId ? (
                          <p className="text-xs mt-1 text-secondary-600">
                            Mọi người trên TAGA khi yêu cầu tham gia Nhóm đều
                            cần có sự phê duyệt từ Chủ sở hữu hoặc Quản trị viên
                            của nhóm và chuyển tiếp lên Tổ chức phê duyệt. Trong
                            trường hợp Tổ chức tắt chức năng phê duyệt này,
                            người dùng sẽ trực tiếp được trở thành thành viên
                            của Nhóm và Tổ chức.
                          </p>
                        ) : (
                          <p className="text-xs mt-1 text-secondary-600">
                            {groupInfo.mode === GroupModeEnums.PUBLIC
                              ? "Mọi người trên TAGA khi yêu cầu tham gia Tổ chức đều cần có sự phê duyệt từ Chủ sở hữu hoặc Quản trị viên của Tổ chức."
                              : "Mọi yêu cầu tham gia Tổ chức thông qua lời mời chuyển tiếp của Nhóm đều cần có sự phê duyệt từ Chủ sở hữu hay Quản trị viên của Tổ chức."}
                          </p>
                        )}
                      </div>
                      {selectedRequireRequestMode === true && (
                        <span className="inline-block text-primary-600 absolute top-3 right-3">
                          <CheckmarkFilled />
                        </span>
                      )}
                    </div>
                    <div className="h-[1px] bg-secondary-100 w-full my-4"></div>
                    <div className="flex gap-3 justify-end">
                      <Button
                        disabled={loading}
                        size="sm"
                        variant="outline"
                        type="button"
                        onClick={() => {
                          setActiveTab(0);
                          setSelectedRequireRequestMode(
                            groupInfo.requireRequestApprove
                          );
                          setSelectedTab(0);
                        }}
                      >
                        Huỷ bỏ
                      </Button>

                      <Button
                        disabled={
                          selectedRequireRequestMode ===
                          groupInfo.requireRequestApprove
                        }
                        loading={loading}
                        onClick={() => {
                          handleChangeRequireRequestMode();
                        }}
                        size="sm"
                      >
                        Lưu thay đổi
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    {groupInfo.mode === GroupModeEnums.PUBLIC ||
                    (groupInfo.mode === GroupModeEnums.PRIVATE &&
                      !groupInfo.rootGroupId) ? (
                      <div
                        className={`${
                          selectedTab !== 0 && selectedTab !== 3
                            ? "opacity-60"
                            : ""
                        } p-6 bg-white border border-secondary-200 rounded-lg flex gap-4 ${
                          activeTab === 0 ? "hover:shadow-lg" : ""
                        }`}
                      >
                        <div className="flex-1">
                          <p className="text-secondary-900 text-sm font-medium flex items-center">
                            Phê duyệt yêu cầu tham gia
                          </p>
                          {groupInfo.requireRequestApprove ? (
                            <p className="text-primary-600 text-sm font-semibold">
                              Bật
                            </p>
                          ) : (
                            <p className="text-secondary-400 text-sm font-semibold">
                              Tắt
                            </p>
                          )}
                          <p className="text-secondary-600 text-xs mt-1">
                            {groupInfo.mode === GroupModeEnums.PUBLIC &&
                              !groupInfo.rootGroupId &&
                              groupInfo.requireRequestApprove &&
                              "Mọi người trên TAGA khi yêu cầu tham gia Tổ chức đều cần có sự phê duyệt từ Chủ sở hữu hoặc Quản trị viên của Tổ chức."}
                            {groupInfo.mode === GroupModeEnums.PUBLIC &&
                              !groupInfo.rootGroupId &&
                              !groupInfo.requireRequestApprove &&
                              "Mọi người trên TAGA tự động được chấp thuận làm thành viên của Tổ chức mà không cần có sự phê duyệt nào từ Chủ sở hữu hay Quản trị viên của Tổ chức"}
                            {groupInfo.mode === GroupModeEnums.PUBLIC &&
                              groupInfo.rootGroupId &&
                              groupInfo.requireRequestApprove &&
                              "Mọi người trên TAGA khi yêu cầu tham gia Nhóm đều cần có sự phê duyệt từ Chủ sở hữu hoặc Quản trị viên của nhóm và chuyển tiếp lên Tổ chức phê duyệt. Trong trường hợp Tổ chức tắt chức năng phê duyệt này, người dùng sẽ trực tiếp được trở thành thành viên của Nhóm và Tổ chức."}
                            {groupInfo.mode === GroupModeEnums.PUBLIC &&
                              groupInfo.rootGroupId &&
                              !groupInfo.requireRequestApprove &&
                              "Mọi yêu cầu tham gia Nhóm sẽ được hệ thống tự động thông qua và chuyển tiếp lên Tổ chức phê duyệt. Trong trường hợp Tổ chức tắt chức năng phê duyệt này, người dùng sẽ trực tiếp được trở thành thành viên của Nhóm và Tổ chức."}
                            {groupInfo.mode === GroupModeEnums.PRIVATE &&
                              !groupInfo.rootGroupId &&
                              groupInfo.requireRequestApprove &&
                              "Mọi yêu cầu tham gia Tổ chức thông qua lời mời chuyển tiếp của Nhóm đều cần có sự phê duyệt từ Chủ sở hữu hay Quản trị viên của Tổ chức."}
                            {groupInfo.mode === GroupModeEnums.PRIVATE &&
                              !groupInfo.rootGroupId &&
                              !groupInfo.requireRequestApprove &&
                              "Mọi yêu cầu tham gia Tổ chức thông qua lời mời chuyển tiếp của Nhóm tự động được chấp thuận là thành viên của Tổ chức mà không cần có sự phê duyệt nào từ Chủ sở hữu hay Quản trị viên của Tổ chức."}
                          </p>
                        </div>
                        <Button
                          disabled={activeTab !== 0 && activeTab !== 3}
                          type="button"
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            setActiveTab(3);
                            setSelectedTab(3);
                          }}
                        >
                          Chỉnh sửa
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <AccessSetting disable={activeTab !== 0} />
        </>
      )}
    </>
  );
};

export default memo(AccessManagement);
