import React, { useState } from "react";

import { Button } from "@/components/ui/button";

const UserCard = () => {
  return (
    <div className=" min-h-60  w-[210px] px-4 pt-6 pb-4 bg-white rounded hover:shadow border border-secondary-200 flex-col justify-start items-center gap-4 inline-flex relative overflow-hidden  ">
      <div className="flex-col justify-start items-center gap-3 flex w-full">
        <div className="justify-start items-center inline-flex flex-col">
          <div>
            <img
              className="w-24 h-24 rounded-full cursor-pointer"
              src={`https://unsplash.it/150/200?image=${Math.round(
                Math.random()
              )}`}
              alt=""
            />
          </div>
        </div>
        <div className="flex-col justify-start items-center gap-1 flex w-full">
          <div className="w-full text-secondary-900 text-sm text-center font-semibold  hover:text-primary-600 inline cursor-pointer truncate  text-ellipsis overflow-hidden">
            Jerome Bell
          </div>
          <p className="text-secondary-600 text-xs font-normal ">
            CEO & Founder
          </p>
        </div>
      </div>
      {!Math.round(Math.random()) ? (
        <Button className="w-full">Kết bạn</Button>
      ) : (
        <Button
          variant="secondaryColor"
          className="w-full"
        >
          Nhắn tin
        </Button>
      )}
    </div>
  );
};

export default UserCard;
