import React, { useEffect, memo, useState } from "react";

import InsideLayout from "@/components/layout/InsideLayout";
import RoutesAuth from "@/routes/RoutesAuth";

const Auth = () => {
  return (
    <>
      <RoutesAuth />
    </>
  );
};

export default memo(Auth);
