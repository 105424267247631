import React, { FC, ReactNode, memo } from "react";

import { Button, ButtonProps } from "../ui/button";

type TGNButtonProps = {
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
  disabled?: boolean;
  height?: number;
  width?: number;
  rounded?: boolean;
  children: ReactNode;
} & ButtonProps;

const TGNButton: FC<TGNButtonProps> = ({
  size = "default",
  asChild = false,
  variant = "primary",
  disabled = false,
  className,
  children,
  iconBefore,
  iconAfter,
}) => {
  return (
    <Button
      variant={variant}
      asChild={asChild}
      size={size}
      disabled={disabled}
      className={className}
    >
      {iconBefore && <span className="-ml-1 mr-1">{iconBefore}</span>}
      {children}
      {iconAfter && <span className="-mr-1 ml-1">{iconAfter}</span>}
    </Button>
  );
};

export default memo(TGNButton);
