import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import { Renew, Warning } from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { loadingAction } from "@/redux/common.slice";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { getSecretEmail } from "@/utils/extractUsername";
import { getMessage } from "@/utils/message";

import {
  memberTransferOwnerRequest,
  memberTransferOwnerVerify,
} from "../../../api/group.api";
import { GroupPathsEnum } from "../../../constants/group.paths";
import { getGroupInfo } from "../../../group";
import { IMemberGroup } from "../../../types/group.type";

type Props = {
  openFn: Dispatch<SetStateAction<boolean>>;
  uid: string;
  user: IMemberGroup;
  open: boolean;
};
const COUNT_DOWN = 60;

const AssignOwnerRoleVerify: FC<Props> = ({ openFn, uid, user, open }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: group } = useAppSelector((state) => state.group);
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const [refreshUid, setRefreshUid] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1, value.length);
    setActiveOtpIndex(value ? index + 1 : index - 1);
    setOtp(newOtp);
    if (error) {
      setError(false);
    }
  };
  const [countDown, setCountDown] = useState(COUNT_DOWN);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [activeOtpIndex]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown((seconds) => seconds - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  const handleVerify = async () => {
    dispatch(
      loadingAction.show({
        title: "Đang chuyển giao...",
        description:
          "Quá trình này có thể mất tới vài phút. Vui lòng chờ tới khi xử lý hoàn tất.",
      })
    );
    try {
      if (group) {
        await memberTransferOwnerVerify({
          groupId: group.id,
          otp: otp.join(""),
          uid: refreshUid !== "" ? refreshUid : uid,
          userId: user.userId,
        });
        setError(false);
        setOtp(new Array(6).fill(""));
        openFn(false);
        toast({
          title: "Thành công!",
          variant: "success",
          description: getMessage("MSG39"),
        });
        navigate(GroupPathsEnum.GROUP_DETAIL.replace(":id", String(group.id)));
        dispatch(
          getGroupInfo({
            group_id: group.id,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
          });
      }
    } catch (error: any) {
      setError(true);
    } finally {
      dispatch(loadingAction.hide());
    }
  };

  const refetchUid = async () => {
    try {
      if (group) {
        const result = await memberTransferOwnerRequest({
          groupId: group.id,
          userId: user.userId,
        });
        if (result.data.uid) {
          setRefreshUid(result.data.uid);
        }
        setCountDown(COUNT_DOWN);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    } finally {
      dispatch(loadingAction.hide());
    }
  };

  return (
    <>
      <Dialog open={open}>
        <DialogContent className="max-w-[600px] gap-6">
          <DialogHeader className="relative">
            <DialogTitle className="text-gray-900 text-lg font-semibold">
              Xác thực hành động
            </DialogTitle>
            <p className="text-sm text-secondary-600 font-normal">
              Nhập mã xác nhận bao gồm 6 chữ số chúng tôi vừa gửi đến email đăng
              ký tài khoản của bạn{" "}
              <span className="font-medium text-secondary-900">
                {getSecretEmail(user?.email)}
              </span>
              .
            </p>
          </DialogHeader>
          <div>
            <div className="w-full h-40 px-4 py-12 bg-secondary-50 rounded flex justify-center">
              <div className="grid">
                <div className="justify-center items-center gap-2 inline-flex">
                  {otp.map((_, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Input
                          ref={index === activeOtpIndex ? inputRef : null}
                          type="number"
                          className={`w-16 h-16 p-1 bg-white rounded-lg shadow border border-secondary-200 text-5xl font-medium text-center text-primary spin-button-none active:border-primary-600 placeholder:text-secondary-300 ${
                            error
                              ? "border-error-300 text-error-600 hover:border-error-300 focus:border-error-300 focus:shadow-none active:border-error-300"
                              : ""
                          }`}
                          onChange={(e) => handleChange(e, index)}
                          value={otp[index]}
                          onKeyDown={(event) => {
                            if ([event.code, event.key].includes("Backspace")) {
                              event.preventDefault();
                              const newOtp = [...otp];
                              newOtp[index] = "";
                              setOtp(newOtp);
                              setActiveOtpIndex(activeOtpIndex - 1);
                            }
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="0"
                        />
                        {index === 2 && (
                          <span className="w-2 py-0.5 bg-secondary-400" />
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
                {error && (
                  <span className="text-xs text-error-600 inline-flex gap-1 mt-2">
                    <Warning />
                    Mã OTP không hợp lệ
                  </span>
                )}
              </div>
            </div>
          </div>
          <DialogFooter>
            <div className="h-11 flex-col justify-center items-start gap-1 inline-flex mr-auto">
              <div className="text-gray-900 text-sm font-normal ">
                Không nhận được mã xác nhận?
              </div>

              <span className="flex gap-1 items-center text-primary font-medium text-sm ">
                <span
                  onClick={() => countDown === 0 && refetchUid()}
                  className={
                    countDown > 0
                      ? "flex opacity-50 items-center gap-1"
                      : "flex items-center gap-1 cursor-pointer"
                  }
                >
                  <Renew />
                  Gửi lại
                </span>
                {countDown > 0 && <span>sau {countDown}s</span>}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <Button
                size="sm"
                variant={"outline"}
                onClick={() => {
                  openFn(false);
                  setOtp(new Array(6).fill(""));
                }}
              >
                Hủy bỏ
              </Button>
              <Button
                disabled={otp.join("").length !== 6}
                size="sm"
                onClick={() => handleVerify()}
              >
                Xác nhận
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssignOwnerRoleVerify;
