import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Renew, Warning } from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

import NoConnection from "@/assets/images/slide/NoConnection.png";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { loadingAction } from "@/redux/common.slice";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { getSecretEmail } from "@/utils/extractUsername";
import { getMessage } from "@/utils/message";

import { sendRequestDeviceVerify, verifyDevice } from "../../api/group.api";
import {
  GroupDeviceStatus,
  GroupUserRoleEnums,
} from "../../constants/group.constant";
import { GroupPathsEnum } from "../../constants/group.paths";
import { getGroupInfo } from "../../group";
import { GroupInfoDef } from "../../types/group.type";

type Props = {
  groupInfo?: GroupInfoDef;
  button?: ReactNode;
  isRemove?: boolean;
  closeCf?: () => void;
  title?: string;
};
const COUNT_DOWN = 60;

const DeviceVerify: FC<Props> = ({
  isRemove = false,
  button,
  closeCf,
  title,
}) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { data: group } = useAppSelector((state) => state.group);
  const { user } = useAppSelector((state) => state.auth);
  const [uid, setUid] = useState("");
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1, value.length);
    setActiveOtpIndex(value ? index + 1 : index - 1);
    setOtp(newOtp);
    if (error) {
      setError(false);
    }
  };
  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [activeOtpIndex]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown((seconds) => seconds - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  const handleAuthenticateDevice = async () => {
    try {
      if (group) {
        await verifyDevice({
          groupId: group.rootGroupId ?? group.id,
          otp: otp.join(""),
          uuid: uid,
        });
        setError(false);
        if (!isRemove) {
          setOpenModal(false);
          setIsSuccess(true);
          setOtp(new Array(6).fill(""));
        } else {
          setOpenModal(false);
          closeCf && closeCf();
          toast({
            title: "Thành công",
            variant: "success",
            description: getMessage("MSG20"),
          });
          dispatch(
            getGroupInfo({
              group_id: group.id,
            })
          )
            .then(unwrapResult)
            .catch(() => {
              navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
            });
        }
        setOtp(new Array(6).fill(""));
      }
    } catch (error: any) {
      setError(true);
      // if (error.response?.data.errors[0]) {
      //   toast({
      //     title: "Thất bại!",
      //     variant: "error",
      //     description: getMessage(error.response?.data.errors[0]),
      //   });
      // } else {
      //   toast({
      //     title: "Thất bại!",
      //     variant: "error",
      //     description: getMessage("MSG12"),
      //   });
      // }
    }
  };

  const refetchUid = async () => {
    try {
      if (group) {
        const result = await sendRequestDeviceVerify({
          groupId: group.rootGroupId ?? group.id,
        });
        setUid(result.data.uid);
        setOpenModal(true);
        setCountDown(COUNT_DOWN);
      }
    } catch (error: any) {
      if (error.response?.data.errors[0]) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    } finally {
      dispatch(loadingAction.hide());
    }
  };

  const sendRequest = async () => {
    dispatch(
      loadingAction.show({
        title: "Đang xử lý...",
        description: "Vui lòng chờ tới khi xử lý hoàn tất.",
      })
    );
    try {
      if (group) {
        const result = await sendRequestDeviceVerify({
          groupId: group.rootGroupId ?? group.id,
        });
        setUid(result.data.uid);
        setOpenModal(true);
        setCountDown(COUNT_DOWN);
      }
    } catch (error: any) {
      if (error.response?.data.errors[0]) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    } finally {
      dispatch(loadingAction.hide());
    }
  };

  return (
    <>
      <Dialog open={openModal}>
        <DialogTrigger asChild>
          <div
            onClick={() => {
              if (group?.deviceStatus !== GroupDeviceStatus.REQUESTED) {
                sendRequest();
              }
            }}
          >
            {button}
          </div>
        </DialogTrigger>
        <DialogContent className="max-w-[600px] gap-6">
          <DialogHeader className="relative">
            <DialogTitle className="text-gray-900 text-lg font-semibold">
              {title ? title : "Xác thực thiết bị"}
            </DialogTitle>
            <p className="text-sm text-secondary-600 font-normal">
              Nhập mã xác nhận bao gồm 6 chữ số chúng tôi vừa gửi đến email đăng
              ký tài khoản của bạn{" "}
              <span className="font-medium text-secondary-900">
                {getSecretEmail(user?.email)}
              </span>
              .
            </p>
          </DialogHeader>
          <div>
            <div className="w-full h-40 px-4 py-12 bg-secondary-50 rounded flex justify-center">
              <div className="grid">
                <div className="justify-center items-center gap-2 inline-flex">
                  {otp.map((_, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Input
                          ref={index === activeOtpIndex ? inputRef : null}
                          type="number"
                          className={`w-16 h-16 p-1 bg-white rounded-lg shadow border border-secondary-200 text-5xl font-medium text-center text-primary spin-button-none active:border-primary-600 placeholder:text-secondary-300 ${
                            error
                              ? "border-error-300 text-error-600 hover:border-error-300 focus:border-error-300 focus:shadow-none active:border-error-300"
                              : ""
                          }`}
                          onChange={(e) => handleChange(e, index)}
                          value={otp[index]}
                          onKeyDown={(event) => {
                            if ([event.code, event.key].includes("Backspace")) {
                              event.preventDefault();
                              const newOtp = [...otp];
                              newOtp[index] = "";
                              setOtp(newOtp);
                              setActiveOtpIndex(activeOtpIndex - 1);
                            }
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="0"
                        />
                        {index === 2 && (
                          <span className="w-2 py-0.5 bg-secondary-400" />
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
                {error && (
                  <span className="text-xs text-error-600 inline-flex gap-1 mt-2">
                    <Warning />
                    Mã OTP không hợp lệ
                  </span>
                )}
              </div>
            </div>
          </div>
          <DialogFooter>
            <div className="h-11 flex-col justify-center items-start gap-1 inline-flex mr-auto">
              <div className="text-gray-900 text-sm font-normal ">
                Không nhận được mã xác nhận?
              </div>

              <span className="flex gap-1 items-center text-primary font-medium text-sm ">
                <span
                  onClick={() => countDown === 0 && refetchUid()}
                  className={
                    countDown > 0
                      ? "flex opacity-50 items-center gap-1"
                      : "flex items-center gap-1 cursor-pointer"
                  }
                >
                  <Renew />
                  Gửi lại
                </span>
                {countDown > 0 && <span>sau {countDown}s</span>}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <Button
                size="sm"
                variant={"outline"}
                onClick={() => (
                  setOpenModal(false),
                  setOtp(new Array(6).fill("")),
                  closeCf && closeCf()
                )}
              >
                Hủy bỏ
              </Button>
              <Button
                disabled={otp.join("").length !== 6}
                size="sm"
                onClick={() => handleAuthenticateDevice()}
              >
                Xác nhận
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={isSuccess}>
        <DialogContent className="max-w-[600px]">
          <div className="text-center gap-2 grid ">
            <DialogTitle className="text-center text-lg">
              Xác thực thiết bị
            </DialogTitle>
          </div>
          <div className=" h-[290px] pt-10 pb-6 bg-white flex-col justify-start items-center gap-10 inline-flex">
            <div className="bg-white flex-col justify-start items-center gap-6 flex">
              <img
                className="w-[100px] h-[100px] "
                src={NoConnection}
                alt=""
              />
              <div className="text-center text-secondary-900 text-xl font-semibold ">
                {group?.role?.code === GroupUserRoleEnums.OWNER
                  ? "Thiết bị của bạn đã được xác thực!"
                  : "Thiết bị của bạn đang chờ xác thực"}
              </div>
              <div className="flex-col justify-start items-center gap-6 flex">
                <div className="flex-col justify-start items-center gap-4 flex">
                  <div className="text-center text-secondary-900 text-base font-normal ">
                    {group?.role?.code === GroupUserRoleEnums.OWNER
                      ? "Thiết bị này đã được xác thực, bạn sẽ được mở khoá truy cập tính năng nâng cao."
                      : "Thiết bị này đang chờ Chủ sở hữu của Tổ chức phê duyệt. Sau khi thiết bị được xác thực, bạn sẽ được mở khoá truy cập tính năng nâng cao."}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DialogFooter className="sm:justify-center pb-4">
            <Button
              variant="primary"
              size={"sm"}
              onClick={() => {
                setIsSuccess(false);
                if (group) {
                  dispatch(
                    getGroupInfo({
                      group_id: group.id,
                    })
                  )
                    .then(unwrapResult)
                    .catch(() => {
                      navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
                    });
                }
              }}
            >
              Đã hiểu
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeviceVerify;
