import React, { memo, ReactNode, FC, useState, useRef } from "react";

import { Overlay } from "react-bootstrap";

import styles from "./DateOverlay.module.scss";
import DatePopup from "../Popup/DatePopup/DatePopup";

type DateOverlayProps = {
  children: ReactNode;
};

const DateOverlay: FC<DateOverlayProps> = ({ children }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <>
      <div
        role="presentation"
        className="d-inline-flex cursor-pointer"
        ref={target}
        onClick={() => setShow(!show)}
      >
        {children}
      </div>
      <Overlay
        target={target.current}
        show={show}
        rootClose
        placement="bottom-start"
        onHide={() => setShow(false)}
      >
        {({
          /* eslint-disable */
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
          /* eslint-enable */
        }) => (
          <div
            {...props}
            className={styles.overlay}
          >
            <DatePopup />
          </div>
        )}
      </Overlay>
    </>
  );
};

export default memo(DateOverlay);
