import React, { FC } from "react";

import cx from "classnames";
import { Dropdown } from "react-bootstrap";

import { SelectItem } from "@/types/app.types";

import style from "./Select.module.scss";

type TGNSelectProps = {
  variant?: "contained" | "outlined" | "text";
  itemList: SelectItem[];
  placeholder?: string;
  value?: SelectItem;
  disabled?: boolean;
  onChange: (value: SelectItem) => void;
};

const TGNSelect: FC<TGNSelectProps> = ({
  variant = "outlined",
  itemList,
  disabled,
  value,
  placeholder,
  onChange,
}) => {
  return (
    <div className={style["tgn-select"]}>
      <Dropdown>
        <Dropdown.Toggle
          className={cx(style["btn-select"], style[variant])}
          as="div"
        >
          <span className="fs-12 mt-0">
            {value ? value.label : placeholder}
          </span>
          {/* <i className="tgn-solid-direction-down-01 fs-18 text-primary-color-gray-10"></i> */}
        </Dropdown.Toggle>

        <Dropdown.Menu className="shadow-gray">
          {itemList.map((item) => (
            <Dropdown.Item
              key={item.value}
              className="fs-12"
              disabled={disabled}
              onClick={() => onChange(item)}
            >
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default TGNSelect;
