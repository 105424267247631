import React, { FC, memo } from "react";

import { TrashCan } from "@carbon/icons-react";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import { Button } from "@/components/ui/button";
import { GroupDeletedDef } from "@/features/groups/types/group.type";
import { formatDistance } from "@/utils/date";

type GroupDeletedCardProps = {
  group: GroupDeletedDef;
  onOk: () => void;
};

const GroupDeletedCard: FC<GroupDeletedCardProps> = ({ group, onOk }) => {
  return (
    <div className="rounded-lg overflow-hidden text-sm">
      <img
        className="object-cover h-[140px] w-full"
        src={group.photoUrl ? group.photoUrl : GroupCover}
        alt=""
      />
      <div className="p-4">
        <p className="text-xs font-medium">NHÓM</p>
        <p className="mt-2 font-semibold text-base">{group.name}</p>
        <div className="text-secondary-600 mt-2 flex items-center gap-1">
          <TrashCan size={16} />
          <span>Đã xoá</span>
          <span>{formatDistance(group.deletedAt)} </span>trước
        </div>
        <div className="mt-10">
          <Button
            className="w-full"
            variant="ghost"
            onClick={onOk}
          >
            Khôi phục
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(GroupDeletedCard);
