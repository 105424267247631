import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import cloneDeep from "lodash.clonedeep";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

import Divider from "@/components/Divider/Divider";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import TGNCheckbox from "@/components/TGNCheckboxRadio/TGNCheckbox";
import TGNInputField from "@/components/TGNInput/TGNInputField";
import Typography from "@/components/Typography/Typography";
import { getUsersOfGroup } from "@/features/groups/api/group.api";
import { findNodeById } from "@/features/mindmap/helpers/mindmap.helpers";
import {
  AssignRolesEnum,
  AssigneeDef,
  MindmapItemDef,
} from "@/features/mindmap/mindmap";
import { useAppSelector } from "@/redux/store";
import { getUrlImage } from "@/utils/image";

import MemberItem from "./MemberItem";

type AssigneePopupProps = {
  nodeId: string;
};

const MemberPopup: FC<AssigneePopupProps> = ({ nodeId }) => {
  const {
    currentSheet: { root },
    mindmapIO,
  } = useAppSelector((state) => state.mindmap);
  const [assignees, setAssignees] = useState<AssigneeDef[]>([]);
  const [loading, setLoading] = useState(false);
  const [memberNode, setMemberNode] = useState<MindmapItemDef | null>(null);

  useEffect(() => {
    getAssignees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAssignees = async () => {
    try {
      const node = findNodeById(root, nodeId);
      if (!node) {
        return;
      }
      setLoading(true);
      setMemberNode(node);
      const response = await getUsersOfGroup({
        groupId: 78,
        pageNum: 0,
        pageSize: 50,
      });
      const assigneesResult = response.content.map((user) => {
        const assignee = node.description.assignees.find(
          (assignee) => assignee.user.id === user.id
        );
        return {
          user,
          role:
            node.description.owner.id === user.id
              ? AssignRolesEnum.OWNER
              : assignee
              ? assignee.role
              : null,
        };
      });
      setAssignees(assigneesResult);
    } catch {
      toast.error("Đã có lỗi xảy ra");
    } finally {
      setLoading(false);
    }
  };

  const assigneeOptions = useMemo(
    () =>
      assignees.filter((assignee) => assignee.role !== AssignRolesEnum.OWNER),
    [assignees]
  );
  const handleRemove = useCallback(
    (assignee: AssigneeDef) => {
      if (mindmapIO && memberNode) {
        const { id, name, description, parentId } = memberNode;
        const permissions: string[][] = [];
        assignees.forEach((assignee) => {
          if (assignee.role) {
            permissions.push([assignee.user.username, assignee.role]);
          }
        });
        const memberAssignees = [...description.assignees];
        const removeIndex = memberAssignees.findIndex(
          (item) => item.user.id === assignee.user.id
        );
        if (removeIndex > -1) {
          memberAssignees.splice(removeIndex, 1);
          const assigneeOption = assignees.find(
            (item) => item.user.id === assignee.user.id
          );
          if (assigneeOption) {
            assigneeOption.role = null;
            setAssignees([...assignees]);
          }
          mindmapIO.updateTask({
            id,
            name,
            description: {
              ...description,
              assignees: memberAssignees,
            },
            parentId,
            permissions,
          });
        }
      }
    },
    [assignees, memberNode, mindmapIO]
  );

  const handleUpdate = useCallback(
    (assignee: AssigneeDef) => {
      if (mindmapIO && memberNode) {
        const { id, name, description, parentId } = memberNode;
        const permissions: string[][] = [];
        assignees.forEach((assignee) => {
          if (assignee.role) {
            permissions.push([assignee.user.username, assignee.role]);
          }
        });
        const memberAssignees = cloneDeep(description.assignees);
        const updateAssignee = memberAssignees.find(
          (item) => item.user.id === assignee.user.id
        );
        const assigneeOption = assignees.find(
          (item) => item.user.id === assignee.user.id
        );
        if (assigneeOption) {
          assigneeOption.role = assignee.role;
          setAssignees([...assignees]);
        }
        if (updateAssignee) {
          updateAssignee.role = assignee.role;
        } else {
          memberAssignees.push(assignee);
        }
        mindmapIO.updateTask({
          id,
          name,
          description: {
            ...description,
            assignees: memberAssignees,
          },
          parentId,
          permissions,
        });
      }
    },
    [assignees, memberNode, mindmapIO]
  );
  return (
    <div
      aria-hidden
      style={{
        minWidth: 392,
      }}
      className="p-3"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="d-flex align-items-center gap-2 mb-8">
        <span className="text-primary-color-gray-10 fs-14 fw-600">
          Set Assignees
        </span>
        <i className="tgn-solid-information-circle text-tertiary-color-gray-5 fs-18"></i>
      </div>
      <TGNInputField
        placeholder="Search for ID, Users"
        isSearch
      />
      <div className="d-flex align-items-center gap-2 mt-8 mb-12">
        <TGNAvatar
          name={memberNode?.description.owner.name}
          round
          size="40"
          src={
            memberNode?.description.owner.photo
              ? getUrlImage(memberNode?.description.owner.photo)
              : ""
          }
        />
        <div className="flex-1">
          <Typography
            className="text-primary-color-gray-10 fs-14 text-truncate"
            style={{
              maxWidth: 100,
            }}
          >
            {memberNode?.description.owner.name}
          </Typography>
          <span className="fs-12 text-tertiary-color-gray-5">
            {memberNode?.description.owner.username}
          </span>
        </div>
        <span className="fs-14 text-primary-color-gray-10">Owner</span>
      </div>

      <Divider
        height={1}
        width="100%"
      />
      <div className="d-flex align-items-center gap-2 my-4">
        <TGNCheckbox label="" />
        <span className="fs-12 text-secondary-color-gray-7">
          Assign to @everyone
        </span>
      </div>
      <Divider
        height={1}
        width="100%"
      />
      {loading ? (
        <div className="d-flex justify-content-center mt-8">
          <Spinner
            variant="primary"
            animation="border"
          />
        </div>
      ) : (
        assigneeOptions.map((assignee) => (
          <MemberItem
            key={assignee.user.id}
            assignee={assignee}
            onRemove={handleRemove}
            onUpdate={handleUpdate}
          />
        ))
      )}
    </div>
  );
};

export default memo(MemberPopup);
