import React, { FC, memo, useEffect, useState } from "react";

import { Close, InformationFilled, ReplyAll } from "@carbon/icons-react";

import Divider from "@/components/Divider/Divider";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
} from "@/components/ui/sheet";
import { useToast } from "@/components/ui/use-toast";
import { FormatDateEnums } from "@/constants/date.constants";
import { UserDef } from "@/features/auth/types/auth.types";
import { formatViDate } from "@/utils/date";
import { formatBytes } from "@/utils/file";
import { getMessage } from "@/utils/message";

import FileIcon from "./FileIcon";
import { getContentDetail, getSharedUsers } from "../api/lib.api";
import { ContentTypeEnums } from "../constants/lib.constants";
import { IContent } from "../types/lib.types";

type ContentDetailPopupProps = {
  content: IContent;
  open: boolean;
  onClose: () => void;
  isShare?: boolean;
  onShowChangeContentType?: () => void;
  onChangeMember?: boolean;
};

const ContentDetailPopup: FC<ContentDetailPopupProps> = ({
  content,
  open,
  isShare,
  onClose,
  onShowChangeContentType,
  onChangeMember,
}) => {
  const { toast } = useToast();
  const [sharedUsers, setSharedUsers] = useState<UserDef[]>([]);
  const [contentDetail, setContentDetail] = useState<IContent>();
  const fetchSharedUsers = async () => {
    const result = await getSharedUsers(content.id);
    if (result.data) {
      setSharedUsers(result.data.map((item) => item.user));
    }
  };
  const fetchContentDetail = async () => {
    try {
      const result = await getContentDetail(content.id);
      setContentDetail(result.data);
    } catch {
      toast({
        variant: "error",
        description: getMessage("MSG_ERROR"),
      });
    }
  };
  useEffect(() => {
    if (open) {
      fetchContentDetail();
    }
    if (!isShare) {
      fetchSharedUsers();
    }
  }, [content, open]);

  useEffect(() => {
    fetchSharedUsers();
  }, [onChangeMember]);
  return (
    <Sheet
      open={open}
      modal={false}
    >
      <SheetContent className="w-[344px] p-0 h-[calc(100vh-140px)] top-[unset] border-[1px] border-secondary-200 rounded-sm border-solid bottom-0 shadow-[0px 10px 15px -3px rgba(17, 24, 39, 0.10), 0px 4px 6px -4px rgba(17, 24, 39, 0.10)]">
        <SheetHeader>
          <SheetDescription>
            <div className="py-6 px-4 border-b-[1px] border-solid border-secondary-100">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <FileIcon
                    extension={content.extension}
                    size={24}
                  />
                  <p className="text-xs font-semibold break-words">
                    {content.name}
                  </p>
                </div>
                <Close
                  className="cursor-pointer"
                  size={28}
                  onClick={onClose}
                />
              </div>
              {!isShare && (
                <div>
                  <p className="text-secondary-600 font-medium text-sm mt-6 mb-3">
                    Quyền truy cập
                  </p>
                  <div className="flex items-center gap-4">
                    <TGNAvatar
                      name={contentDetail?.creator.name}
                      src={contentDetail?.creator.photo || undefined}
                      size="32"
                      className="border-[1.5px] border-solid border-white"
                    />
                    {sharedUsers.length > 0 && (
                      <Divider
                        width="1px"
                        height="24px"
                      />
                    )}
                    <div className="flex items-center">
                      {sharedUsers.slice(0, 5).map((user) => (
                        <TGNAvatar
                          key={user.id}
                          name={user.name}
                          src={user.photo}
                          size="32"
                          className="border-[1.5px] border-solid border-white"
                        />
                      ))}
                      {sharedUsers.length > 5 && (
                        <TGNAvatar
                          name="+ 1"
                          color="#F5FAFE"
                          size="32"
                          fgColor="#1589ee"
                          className="border-[1.5px] -ml-1 border-solid border-white"
                        />
                      )}
                    </div>
                  </div>
                  <p className="text-xs mt-2">
                    Danh sách thành viên có quyền truy cập đối với nội dung này.
                  </p>
                  <div className="mt-6">
                    <Button
                      variant="ghost"
                      className="w-full"
                      onClick={onShowChangeContentType}
                    >
                      <ReplyAll
                        size={16}
                        className="mr-1"
                      />
                      Quản lý truy cập
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="px-4 py-4">
              <div className="flex items-center gap-2">
                <InformationFilled
                  className="text-slate-600"
                  size={16}
                />
                <span>Chi tiết</span>
              </div>
              <div className="mt-4 grid grid-cols-2 gap-3 text-xs text-secondary-600">
                <div>Lưu tại</div>
                <div className="text-secondary-900">
                  {contentDetail?.parent
                    ? contentDetail.parent.name
                    : "Thư viện"}
                </div>
                <div>Kích thước</div>
                <div className="text-secondary-900">
                  {contentDetail?.type === ContentTypeEnums.FOLDER
                    ? "--"
                    : formatBytes(content.size)}
                </div>
                <div>Loại</div>
                <div className="text-secondary-900">Tài liệu</div>
                {contentDetail?.type === ContentTypeEnums.FOLDER && (
                  <>
                    <div>Số lượng</div>
                    <div className="text-secondary-900">
                      {contentDetail?.child_content_quantity} tệp tin
                    </div>
                  </>
                )}
                <div>Chỉnh sửa</div>
                <div className="text-secondary-900">
                  {formatViDate(
                    contentDetail?.updated_at || "",
                    FormatDateEnums.DATE_HOUR_SLASH
                  )}
                </div>
                <div>Thời gian tạo</div>
                <div className="text-secondary-900">
                  {formatViDate(
                    contentDetail?.created_at || "",
                    FormatDateEnums.DATE_HOUR_SLASH
                  )}
                </div>
              </div>
            </div>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default memo(ContentDetailPopup);
