import * as React from "react";

import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { Loader2 } from "lucide-react";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center items-center whitespace-nowrap rounded text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary:
          "bg-primary text-white hover:bg-primary-700 hover:shadow-sm focus:shadow-active disabled:bg-primary-300 disabled:opacity-100 font-medium",
        destructive:
          "bg-error-600 text-white hover:bg-error-700 hover:text-white hover:shadow-sm focus:shadow-error focus:bg-error-600 focus:text-white disabled:text-white disabled:bg-error-300 disabled:opacity-100 font-medium",
        outline:
          "border border-secondary-200 text-secondary-600 bg-background hover:bg-secondary-50 hover:text-secondary-900 hover:shadow-sm active:shadow-sm active:bg-secondary-50 focus:shadow-sm disabled:text-secondary-300 disabled:border-secondary-100 disabled:opacity-100 font-medium",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary-700 hover:shadow-sm focus:shadow-active disabled:bg-secondary-300 disabled:opacity-100 font-medium",
        ghost:
          "bg-secondary-50 text-secondary-600 hover:bg-secondary-100 hover:text-secondary-900 hover:shadow-sm focus:shadow-active focus:bg-secondary-50 focus:text-secondary-600 disabled:text-secondary-300 disabled:bg-secondary-50 disabled:opacity-100 font-medium",
        secondaryColor:
          "bg-primary-50 text-primary-600 hover:bg-primary-100 hover:text-primary-900 hover:shadow-sm focus:shadow-active focus:bg-secondary-50 focus:text-primary-600 disabled:text-primary-300 disabled:text-primary-300 disabled:opacity-100 font-medium",
        text: "bg-transparent text-secondary-600 hover:bg-secondary-50 hover:text-secondary-900 focus:shadow-active focus:bg-secondary-50 focus:text-secondary-600 disabled:text-secondary-300 disabled:bg-white disabled:opacity-100 font-medium",
        link: "text-primary underline-offset-4 hover:underline",
        brand:
          "bg-brand text-white hover:bg-brand-700 hover:shadow-sm focus:shadow-active disabled:bg-brand-300 disabled:opacity-100 font-medium",
      },
      size: {
        default: "h-[40px] px-4 py-[9px] text-sm",
        sm: "h-[36px] px-3 py-[7px] text-sm",
        lg: "h-[44px] px-5 py-[9px] text-base",
        xl: "h-[48px] px-5 py-[11px] text-base",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      iconBefore,
      iconAfter,
      loading = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={loading || props.disabled}
        {...props}
      >
        {loading ? (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          iconBefore && <span className="-ms-1 me-2">{iconBefore}</span>
        )}
        {children}
        {iconAfter && <span className="-me-1 ms-2">{iconAfter}</span>}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
