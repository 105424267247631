import React, { memo, useState } from "react";

import { DotMark, Maximize, Search, UserAvatar } from "@carbon/icons-react";
import { Link, useNavigate } from "react-router-dom";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import Loading3Dot from "@/components/TGNLoading/Loading3Dot";
import { Badge } from "@/components/ui/badge";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import formatNumber from "@/utils/formatNumber";

import { searchGroup } from "../../api/group.api";
import { GroupPathsEnum } from "../../constants/group.paths";
import { GroupDef } from "../../types/group.type";

const SearchModal = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [searchResult, setSearchResult] = useState<GroupDef[] | []>([]);
  const [openModal, setOpenModal] = useState(false);
  const [totalElement, setTotalElement] = useState(0);
  const [searching, setSearching] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [controllerApi, setControllerApi] = useState<AbortController>();
  const [debounceTimeout, setDebounceTimeout] =
    useState<ReturnType<typeof setTimeout>>();

  const fetchSearchGroup = async (searchString: string) => {
    try {
      if (controllerApi) {
        controllerApi.abort();
      }
      const controller = new AbortController();
      setControllerApi(controller);
      const result = await searchGroup({
        keyword: searchString,
        pageNumber: 1,
        pageSize: 6,
        signal: controller?.signal,
      });
      setTotalElement(result.totalElements);
      setSearchResult(result.data);
      setSearching(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && event.currentTarget.value) {
      setOpenModal(false);
      return navigate(
        GroupPathsEnum.GROUPS_SEARCH.replace(
          ":keyword",
          encodeURIComponent(event.currentTarget.value)
        )
      );
    }
    const newValue = event.currentTarget.value;

    if (newValue !== "") {
      setKeyword(newValue);
      setSearching(true);
      setShowSearchBox(true);
      if (debounceTimeout) clearTimeout(debounceTimeout);
      setDebounceTimeout(
        setTimeout(() => {
          fetchSearchGroup(newValue);
        }, 500)
      );
    } else {
      setShowSearchBox(false);
    }
  };

  return (
    <div className="relative">
      <Search className="absolute top-3 left-3 text-secondary-600" />
      <Input
        className="pl-9 h-auto min-w-[380px] bg-secondary-50"
        placeholder="Tìm kiếm nhóm, tổ chức, “TAGA Co. Ltd.,”"
        size={16}
        onFocus={() => setOpenModal(true)}
      />

      <Dialog
        open={openModal}
        onOpenChange={() => {
          setShowSearchBox(false);
          setSearching(false);
          setTotalElement(0);
          setSearchResult([]);
          setKeyword("");
          setOpenModal(false);
        }}
      >
        <DialogContent className="p-0 border-0 gap-1">
          <div className="relative">
            <Search className="absolute top-3 left-3 text-secondary-600" />
            <Input
              className="pl-9 h-auto min-w-[380px] bg-secondary-50 focus:shadow-lg min-h-[40px]"
              placeholder="Tìm kiếm nhóm, tổ chức, “TAGA Co. Ltd.,”"
              size={16}
              onKeyUp={(e) => handleChangeSearch(e)}
            />
          </div>
          {/* <div className="p-1 bg-white rounded">
            <p className="text-xs text-secondary-600 p-2">Tìm kiếm gần đây</p>
            <div className="p-2 hover:bg-secondary-100">
              <div className="flex gap-2">
                <img
                  src={avatar}
                  alt=""
                  className="w-10 h-10 rounded-[12px] object-contain"
                />
                <div className="relative pr-8 flex-1 group">
                  <p className="text-sm text-secondary-900 font-semibold leading-5 mb-1">
                    Microsoft Back-Office Server/ MS Annual Report
                  </p>
                  <p className="flex items-center">
                    <Badge className="border border-blue-200 font-medium">
                      <UserAvatar className="mr-1" />
                      Đã tham gia
                    </Badge>
                    <span className="text-sm text-secondary-600 ml-1">
                      ∙ {formatNumber(1000)} thành viên
                    </span>
                    <Button
                      variant="ghost"
                      className="hidden group-hover:block absolute -translate-y-1/2 top-1/2 right-2 bg-secondary-100 p-0"
                    >
                      <Close className="text-secondary-600" />
                    </Button>
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          {showSearchBox && (
            <>
              <div className="bg-white rounded overflow-hidden">
                {searching ? (
                  <div className="flex items-center justify-center">
                    <Loading3Dot
                      setWidth="30"
                      setHeight="30"
                    />
                  </div>
                ) : (
                  <div className="p-1 ">
                    <p className="text-xs text-secondary-600 p-2">
                      Kết quả tìm kiếm・{totalElement} kết quả
                    </p>
                    {searchResult.length > 0 &&
                      searchResult.map((group) => {
                        return (
                          <Link
                            to={GroupPathsEnum.GROUP_DETAIL.replace(
                              ":id",
                              String(group.groupId)
                            )}
                            key={group.groupId}
                            className="p-2 hover:bg-secondary-100 cursor-pointer block"
                          >
                            <div className="flex gap-2">
                              <img
                                src={
                                  group.photoUrl ? group.photoUrl : GroupCover
                                }
                                alt=""
                                className="w-10 h-10 rounded-[12px] object-cover"
                              />
                              <div className="relative pr-8 flex-1 group">
                                <span className="text-sm text-secondary-900 hover:text-primary-600 font-semibold leading-5 mb-1">
                                  {group.name}
                                </span>

                                <p className="flex items-center">
                                  {group.role && (
                                    <>
                                      <Badge className="border border-blue-200 font-medium text-[10px] leading-[14px]">
                                        <UserAvatar
                                          className="mr-1"
                                          size={12}
                                        />
                                        Đã tham gia
                                      </Badge>
                                      <DotMark
                                        size={5}
                                        color="#4B5563"
                                        className="ml-1 mr-1"
                                      ></DotMark>
                                    </>
                                  )}
                                  <span className="text-sm text-secondary-600">
                                    {formatNumber(group.numberOfMembers)} thành
                                    viên
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                  </div>
                )}
                <div>
                  <Link
                    onClick={() => setOpenModal(false)}
                    to={GroupPathsEnum.GROUPS_SEARCH.replace(
                      ":keyword",
                      encodeURIComponent(keyword)
                    )}
                    className="p-3 bg-secondary-50 hover:bg-secondary-100 hover:text-secondary-900 flex justify-center items-center  text-secondary-600 p-2 pr-3 w-full text-sm font-medium min-h-[60px]"
                  >
                    <Maximize className="mr-2" /> Xem tất cả
                  </Link>
                </div>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(SearchModal);
